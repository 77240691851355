import { create } from "zustand";

export const useApplicantStore = create((set) => ({
  p_limit: 10,
  p_offset: 0,
  r_limit: 10,
  r_offset: 0,
  a_limit: 10,
  a_offset: 0,
  pendingPermits: [],
  rejectedPermits: [],
  approvedPermit: [],

  totalPendingAssignedPermit: 0,
  totalApprovedAssignedPermit: 0,
  totalRejectedAssignedPermit: 0,

  selectedTab: "pending",

  assignedRenewalPermit:[], 
  totalRenewalAssignedPermit:0, 
  setTotalRenewalAssignedPermit: (totalRenewalAssignedPermit) => set({totalRenewalAssignedPermit}), 
  setAssignedRenewalPermit:(assignedRenewalPermit) => set({assignedRenewalPermit}),

  WorkPermitsRenewal: [],
  setWorkPermitsRenewal: (WorkPermitsRenewal) => set({ WorkPermitsRenewal }),

  setPendingOffset: (p_offset) => set({ p_offset }),
  setRejectedOffset: (r_offset) => set({ r_offset }),
  setApprovedOffset: (a_offset) => set({ a_offset }),

  setLimitPending: (p_limit) => set({ p_limit }),
  setLimitApproved: (a_limit) => set({ a_limit }),
  setLimitRejected: (r_limit) => set({ r_limit }),

  setSelectedTab: (selectedTab) => set({ selectedTab }),
  setPendingPermits: (pendingPermits) => set({ pendingPermits }),
  setRejectedPermits: (rejectedPermits) => set({ rejectedPermits }),
  setApprovedPermits: (approvedPermit) => set({ approvedPermit }),

  setTotalPendingAssignedPermit: (totalPendingAssignedPermit) =>
    set({ totalPendingAssignedPermit }),
  setTotalApprovedAssignedPermit: (totalApprovedAssignedPermit) =>
    set({ totalApprovedAssignedPermit }),
  setTotalRejectedAssignedPermit: (totalRejectedAssignedPermit) =>
    set({ totalRejectedAssignedPermit }),
}));
