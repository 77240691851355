import React, { useState } from "react";
import { WorkPermitForms } from "../../utils/constants";
import EmploymentInformation from "../Forms/Application/EmploymentInformationForm";
import WorkPlace from "../Forms/Application/WorkPlaceForm";
import CounterPart from "../Forms/Application/CounterPartForm";
import Finance from "../Forms/Application/FinanceForm";
import ConfirmSubmit from "../Forms/Application/ConfirmSubmit";
import { convertString } from "../../utils/utilityFunctions";
import EmploymentInformationSwap from "../Forms/Application/EmploymentInformationFormSwap";

const FormAreaSwap = ({
  title,
  activeStep,
  initialValues,
  setActiveStep,
  handleSubmit,
  handleClearCache,
}) => {
  console.log("initial values: ", initialValues);

  const handleForm = (name, e) => {
    console.log({ name, e });
    handleSubmit(name, e);
  };

  return (
    <>
      <div className="w-full md:w-[100%] pb-10 rounded-xl flex flex-col gap-10 h-full">
        <div className="bg-white w-full px-6 h-full overflow-hidden noScrollBar">
          <h1 className="text-md select-none font-bold ml-4 border-2 rounded-2xl border-primary w-fit px-4 mt-5 font-['Open_Sans'] text-primary">
            {title}
          </h1>
          {activeStep === WorkPermitForms.EMPLOYEE_INFORMATION && (
            <EmploymentInformationSwap
              handleFormSubmit={handleForm}
              changeState={setActiveStep}
              defaultValues={
                initialValues &&
                initialValues[
                  Object.keys(initialValues)
                    .filter(
                      (i) =>
                        i ===
                        convertString(WorkPermitForms.EMPLOYEE_INFORMATION)
                    )
                    .map((i) => i)
                ]
              }
            />
          )}
          {activeStep === WorkPermitForms.WORK_PLACE && (
            <WorkPlace
              handleFormSubmit={handleForm}
              changeState={setActiveStep}
              defaultValues={
                initialValues &&
                initialValues[
                  Object.keys(initialValues)
                    .filter(
                      (i) => i === convertString(WorkPermitForms.WORK_PLACE)
                    )
                    .map((i) => i)
                ]
              }
            />
          )}
          {activeStep === WorkPermitForms.COUNTER_PART && (
            <CounterPart
              handleFormSubmit={handleForm}
              changeState={setActiveStep}
              defaultValues={
                initialValues &&
                initialValues[
                  Object.keys(initialValues)
                    .filter(
                      (i) => i === convertString(WorkPermitForms.COUNTER_PART)
                    )
                    .map((i) => i)
                ]
              }
            />
          )}
          {activeStep === WorkPermitForms.FINANCE && (
            <Finance
              handleFormSubmit={handleForm}
              changeState={setActiveStep}
              defaultValues={
                initialValues &&
                initialValues[
                  Object.keys(initialValues)
                    .filter((i) => i === convertString(WorkPermitForms.FINANCE))
                    .map((i) => i)
                ]
              }
            />
          )}
          {activeStep === WorkPermitForms.FINAL && (
            <ConfirmSubmit
              handleFormSubmit={handleForm}
              changeState={setActiveStep}
              // defaultValues={
              //   initialValues &&
              //   initialValues[
              //     Object.keys(initialValues)
              //       .filter((i) => i === WorkPermitForms.SKILLS)
              //       .map((i) => i)
              //   ]
              // }
            />
          )}

          {/* {activeStep === WorkPermitForms.FINAL && <ConfirmSubmit />} */}
        </div>
      </div>
    </>
  );
};

export default FormAreaSwap;
