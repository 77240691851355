import React from "react";
import { MdArrowBack } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import FileInput from "../../../components/Forms/Fields/FileInput";
import CButton from "../../../components/Forms/CButton";
import {
  CInputField,
  CSelectField,
  FormProvider,
} from "../../../components/Forms/Fields";
import { useForm } from "react-hook-form";
import { useLocalStore } from "../../../store";
import {
  BASE_DOCUMENT_CATAGORY_TYPES,
  GET_PERMIT_DOCUMENTS,
} from "../../../graph-query/queries";
import { useState } from "react";
import { useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  INSERT_EXPATRIATE_DOC,
  UPDATE_WORK_PERMIT_BANK,
  UPLOAD_FILE,
} from "../../../graph-query/mutation";
import { getBase64 } from "../../../utils/utilityFunctions";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import {
  BANK_SLIP_ID,
  PROFILE_IMAGE_ID,
  WORK_PERMIT_TYPES,
} from "../../../utils/constants";
import WorkPermitCard from "../../../components/cards/WorkPermitCard";

const ImportDocument = () => {
  const router = useNavigate();
  const { id } = useParams();

  const [tempFiles, setTempFile] = useState([]);
  const [permitDocument, setPermitDocument] = useState([]);
  const [documentCatagory, setDocumentCatagory] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  const { documentTypes } = useLocalStore();

  const [fileUpload, { loading: uploadLoading }] = useMutation(UPLOAD_FILE);

  const [getDocumentCatagory, {}] = useLazyQuery(BASE_DOCUMENT_CATAGORY_TYPES, {
    onCompleted: (d) => {
      setDocumentCatagory(d?.base_document_type_categories);
    },
  });

  useEffect(() => {
    getDocumentCatagory();
    getPermitDocuments();
  }, []);

  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const watchCatagory = watch("catagory", false);
  const selectedCatagory = watch("document_type_id", false);

  const [createDocument, { loading }] = useMutation(INSERT_EXPATRIATE_DOC, {
    onCompleted: () => {
      toast.success("Document Insert Successfully ");
      setTempFile([]);
      reset();
      router(-1);
      window.location.reload();
    },
    // onError: (e) => {
    //   toast.error('Document is not Uploaded, Try again!')
    // }
  });

  const [updatePermitReference, {}] = useMutation(UPDATE_WORK_PERMIT_BANK);

  const [getPermitDocuments, {}] = useLazyQuery(GET_PERMIT_DOCUMENTS, {
    variables: {
      permitID: id,
    },
    onCompleted: (d) => {
      console.log(d); 
      setPermitDocument(d?.expatriate_work_permit_documents);
    },
  });

  const filesUpload = (uploadedFile, cb) => {
    getBase64(uploadedFile)
      .then((result) => {
        let fileType = (uploadedFile?.type).split("/").length
          ? (uploadedFile?.type).split("/")[1]
          : "";
        // upload the file to the server
        trackPromise(
          fileUpload({
            variables: {
              extension: fileType,
              file: result,
            },
            onCompleted(data) {
              // console.log(data);
              cb &&
                cb({ name: uploadedFile.name, path: data.uploadFile.info.Key });
              toast.success("Document Uploaded Successfully!");
            },
            onError(error) {
              toast.error("Document upload failed!");
            },
          })
        );
      })
      .catch((err) => {
        // handle error
      });
  };

  //   const { documentTypes } = useLocalStore();
  const handleFileChange = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        //   setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      filesUpload(file, (filePath) =>
        setTempFile((prevFiles) => [
          ...prevFiles,
          {
            ...filePath,
          },
        ])
      );
    }
  };

  const getTitle = (id) => {
    return documentTypes.filter((t) => t.id === id).map((j) => j.name)[0];
  };

  function handleToggle() {
    setIsChecked(!isChecked);
  }

  const handleFileSubmit = (e) => {
    if (tempFiles.length !== 0) {
      if (selectedCatagory === BANK_SLIP_ID) {
        if (e.bank_reference_number === "") {
          return toast.error("Enter Bank Reference Number");
        } else {
          const _prop = {
            name: tempFiles[0]?.name,
            path: tempFiles[0].path,
            title: getTitle(e?.document_type_id),
            document_type_id: e.document_type_id,
            catagory: e.catagory,
          };
          console.log("submit", _prop);
          createDocument({
            variables: {
              documentId: e.document_type_id,
              expatriate_work_permit_id: id,
              files: `${tempFiles[0].path}`,
              properties: _prop,
              title: getTitle(e?.document_type_id),
            },
          });

          updatePermitReference({
            variables: {
              id: id,
              reference: e?.bank_refernce_number,
            },
          });
        }
      } else {
        const _prop = {
          name: tempFiles[0]?.name,
          path: tempFiles[0].path,
          title: getTitle(e?.document_type_id),
          document_type_id: e.document_type_id,
          catagory: e.catagory,
        };
        console.log("submit", _prop);
        createDocument({
          variables: {
            documentId: e.document_type_id,
            expatriate_work_permit_id: id,
            files: `${tempFiles[0].path}`,
            properties: _prop,
            title: getTitle(e?.document_type_id),
          },
        });
      }
    } else {
      toast.warn("Please Upload Document and Fill all fields");
    }
  };

  return (
    <div className="flex flex-wrap md:flex-nowrap gap-6">
      <div className="w-full md:w-2/3 space-y-6">
        <div className="bg-white shadow-sm p-4 rounded-md space-y-5">
          <button
            onClick={() => router(-1)}
            className="bg-gray px-3 py-2 border-2 rounded-md flex gap-2 items-center"
          >
            <MdArrowBack /> Back
          </button>
          <h2 className="md:ml-8 text-primary capitalize font-semibold">
            Document Upload
          </h2>

          <div className="bg-blue-100 p-2 rounded mt-5 mx-2 md:mx-8">
            <span className="font-semibold text-lg">Notice</span>
            <p className="">
              Please upload only the files that have been rejected or forgotten
              and have not been uploaded yet. The remaining part is currently
              disabled and inaccessible.
            </p>
          </div>

          <FormProvider onSubmithandler={handleSubmit(handleFileSubmit)}>
            <div className="flex flex-col p-2 md:p-8 gap-3">
              <CSelectField
                name="catagory"
                register={register}
                errors={errors}
                validation={{ required: "Document Catagory is required" }}
                options={documentCatagory
                  ?.filter(
                    (i) =>
                      i?.code !== "REPLACEMENT"
                  )
                  ?.map((reg) => {
                    const _filterDoc = permitDocument.filter(
                      (doc) => doc?.properties?.catagory === reg?.id
                    );
                    const isDocumentRejectedOrVerified = _filterDoc.some(
                      (doc) =>
                        // doc?.properties?.catagory === reg?.id &&
                        doc?.review_status === true ||
                        doc?.review_status === null
                    );
                    return {
                      ...reg,
                      value: reg?.id,
                      name: reg?.code,
                      // disabled: isDocumentRejectedOrVerified,
                    };
                  })}
                label="Document Catagory"
                placeholder="Select Catagroy Type"
              />
              <CSelectField
                name="document_type_id"
                register={register}
                errors={errors}
                disabled={!watchCatagory}
                validation={{ required: "Document Type is required" }}
                options={documentTypes
                  .filter(
                    (i) =>
                      // i?.work_permit_type_id === WORK_PERMIT_TYPES.NEW &&
                      i?.documents_type_category_id === watchCatagory
                  )
                  ?.map((reg) => {
                    const isDocumentRejectedOrVerified = permitDocument.some(
                      (doc) =>
                        doc?.document_type_id === reg?.id &&
                        (doc?.review_status === true ||
                          doc?.review_status === null)
                    );
                    return {
                      ...reg,
                      value: reg?.id,
                      name: reg?.name,
                      disabled: isDocumentRejectedOrVerified,
                    };
                  })}
                label="Document Type"
                placeholder="Select Document Type"
              />

              {selectedCatagory === BANK_SLIP_ID && (
                <CInputField
                  label={"Bank Reference Number"}
                  errors={errors}
                  name={"bank_refernce_number"}
                  register={register}
                  type="text"
                />
              )}

              <FileInput
                type={
                  selectedCatagory === PROFILE_IMAGE_ID ||
                  selectedCatagory === BANK_SLIP_ID
                    ? "image"
                    : "file"
                }
                onChange={handleFileChange}
                enablePreview={true}
              />
              <CButton
                classes={"bg-primary"}
                loading={loading}
                btnLabel={"Submit Document"}
                type={"submit"}
              />
            </div>
          </FormProvider>
        </div>
      </div>
      <div className="w-full md:w-1/3">
        <div className="bg-white p-3">
          <h4 className="font-semibold">Uploaded Documents</h4>
          <div className="flex flex-col gap-2">
            {permitDocument &&
              permitDocument.map((item) => (
                <WorkPermitCard
                  title={item?.title}
                  path={item?.files}
                  note={item?.review_note}
                  status={
                    item?.review_status
                      ? "Verified"
                      : item?.review_status === false
                      ? "Rejected"
                      : "Pending"
                  }
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportDocument;
