import { create } from "zustand";
import { openDB, deleteDB } from "idb";
import { convertString } from "../utils/utilityFunctions";

const useStepperFormStore = create((set) => ({
  currentStep: 0,
  loading: false,
  loadingRenewal: false,
  data: [],
  renewal: [],
  error: null,

  setLoading: (isLoading) => set({ loading: isLoading }),
  setLoadingRenewal: (isLoading) => set({ loadingRenewal: isLoading }),
  clearIndexedDB: async (DB_NAME) => {
    try {
      await deleteDB(DB_NAME || "new_permit");

      set({ data: [] });
      console.log("IndexedDB database cleared.");
    } catch (error) {
      console.error("Error clearing IndexedDB:", error);
      // Handle the error appropriately
    }
  },

  // Save the form data to IndexedDB
  saveFormData: async (dbValue, data) => {
    const __storeName = await convertString(Object.keys(data)[0]);
    set({ loading: true });
    const getStoreNames = () => {
      return [
        "general_information",
        "employement_information",
        "educational_information",
        "work_experience",
        "work_place",
        "counterpart",
        "skills",
        "finance",
      ];
    };

    const storeNames = getStoreNames();

    try {
      const db = await openDB(dbValue, 1, {
        upgrade(db) {
          for (const name of storeNames) {
            if (!db.objectStoreNames.contains(name)) {
              db.createObjectStore(name);
            }
          }
        },
      });

      const transaction = await db.transaction(__storeName, "readwrite");

      const store = transaction.objectStore(__storeName);

      const storeData = Object.values(data)[0];
      console.log("__store__:", storeData);

      for (const key in storeData) {
        console.log("_store_:", storeData[key], key);
        try {
          await store.put(storeData[key], key);
        } catch (error) {
          recreateIndexedDB(dbValue);
          console.log("Error occurred while putting data:", error);
          // Handle the error appropriately
          // set({loading: false});
          throw error;
        }
      }

      await transaction.complete;
      set({ loading: false });

      db.close();
    } catch (error) {
      // await indexedDB.deleteDatabase("new_permit");
      deleteDB(dbValue);
      recreateNew();
      set({ loading: false });
      console.log("Error occurs", error);
      throw error;
    }
  },

  // Save the form data to IndexedDB
  saveFormDataRenewal: async (dbValue, data) => {
    const __storeName = await convertString(Object.keys(data)[0]);
    console.log("store name:", __storeName);
    set({ loadingRenewal: true });
    const getStoreNames = () => {
      return [
        "emr",
        "general_information",
        "employement_information",
        "educational_information",
        "work_experience",
        "work_place",
        "counterpart",
        "skills",
        "finance",
      ];
    };

    const storeNames = getStoreNames();

    try {
      const db = await openDB(dbValue, 1, {
        upgrade(db) {
          for (const name of storeNames) {
            if (!db.objectStoreNames.contains(name)) {
              db.createObjectStore(name);
            }
          }
        },
      });

      const transaction = await db.transaction(__storeName, "readwrite");

      const store = transaction.objectStore(__storeName);

      const storeData = Object.values(data)[0];
      console.log("__store__:", storeData);

      for (const key in storeData) {
        console.log("_store_:", storeData[key], key);
        try {
          await store.put(storeData[key], key);
          set({ loadingRenewal: false });
        } catch (error) {
          console.log("Error occurred while putting data:", error);
          set({ loadingRenewal: false });
          // Handle the error appropriately
          this.recreateRenewal();
          throw error;
        }
      }

      await transaction.complete;
      set({ loadingRenewal: false });

      db.close();
    } catch (error) {
      await indexedDB.deleteDatabase("renewal_permit");
      // deleteDB(dbValue);
      this.recreateRenewal();
      // recreateIndexedDB(dbValue);
      set({ loadingRenewal: false });
      console.log("Error occurs", error);
      throw error;
    }
  },

  fetchDataFromIndexedDB: async (dbName) => {
    try {
      const data = await retrieveDataFromIndexedDB(dbName);
      console.log("____indexDB_all:", data);
      set({ data });
      // console.log("Data retrieved from IndexedDB:", data);
    } catch (error) {
      console.log("Error retrieving data:", error);
    }
  },

  // renewal recreate
  recreateRenewal: () => {
    return recreateIndexedDBRenewal("renewal_permit");
  },
}));
const recreateNew = () => {
  return recreateIndexedDB("new_permit");
};

const retrieveDataFromIndexedDB = async (name) => {
  const db = await openDB(name, 1);
  const getStoreNames = () => {
    return [
      "general_information",
      "employement_information",
      "educational_information",
      "work_experience",
      "work_place",
      "counterpart",
      "skills",
      "finance",
      "emr",
    ];
  };

  const storeNames = getStoreNames();
  let result = {};
  for (let index = 0; index < storeNames.length; index++) {
    let element = storeNames[index];
    // console.log("___elements:", element);
    if (db.objectStoreNames.contains(element)) {
      const tx = db.transaction(element, "readonly");
      const objectStore = tx.objectStore(element);

      const keys = await objectStore.getAllKeys();
      const data = {};
      for (const key of keys) {
        const element = await objectStore.get(key);
        data[key] = element;
      }
      // console.log("____indexDB_data:", data);
      result[element] = data;
    }
  }
  db.close();

  return result;
};

const recreateIndexedDB = async (DB_NAME) => {
  try {
    // Manually delete the existing database
    await deleteDB(DB_NAME);

    // Increment the database version
    const dbVersion = 1;

    console.log("db recreate....");
    const getStoreNames = () => {
      return [
        "general_information",
        "employement_information",
        "educational_information",
        "work_experience",
        "work_place",
        "counterpart",
        "skills",
        "finance",
      ];
    };

    const storeNames = getStoreNames();

    const db = await openDB(DB_NAME, dbVersion, {
      upgrade(db) {
        for (const name of storeNames) {
          if (!db.objectStoreNames.contains(name)) {
            db.createObjectStore(name);
          }
        }
      },
    });

    console.log("IndexedDB database recreated:", db);
  } catch (error) {
    console.error("Error recreating IndexedDB:", error);
    // Handle the error appropriately
  }
};
const recreateIndexedDBRenewal = async (DB_NAME) => {
  try {
    // Manually delete the existing database
    await deleteDB(DB_NAME);

    // Increment the database version
    const dbVersion = 1;

    const getStoreNames = () => {
      return [
        "emr",
        "general_information",
        "employement_information",
        "educational_information",
        "work_experience",
        "work_place",
        "counterpart",
        "skills",
        "finance",
      ];
    };

    const storeNames = getStoreNames();

    const db = await openDB(DB_NAME, dbVersion, {
      upgrade(db) {
        for (const name of storeNames) {
          if (!db.objectStoreNames.contains(name)) {
            db.createObjectStore(name);
          }
        }
      },
    });

    console.log("IndexedDB database recreated:", db);
  } catch (error) {
    console.error("Error recreating IndexedDB:", error);
    // Handle the error appropriately
  }
};

export default useStepperFormStore;
