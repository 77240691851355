import React from 'react'
import CustomDatepicker from '../components/Forms/Fields/Datepicker'

const TestPage = () => {
  return (
    <div>
        <CustomDatepicker />
    </div>
  )
}

export default TestPage