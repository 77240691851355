import React, { useState, useEffect, useRef } from "react";
import {
  GET_ALL_ORGANIZATION,
  GET_ALL_ORGANIZATION_FILTER,
  GET_APPLICANT_REQUEST,
  GET_ASSIGNED_ORGANIZATION,
  GET_ASSIGNED_ORGANIZATION_FILTER,
  GET_ORGANIZATION,
  SEARCH_ORGANIZATION_BG_TIN,
  TOTAL_ORGANIZATION,
  TOTAL_ORGANIZATION_ASSIGNED,
} from "../../graph-query/queries";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import CTable from "../../components/Table/CTable";
import { useLocalStore } from "../../store";
import { toast } from "react-toastify";
import { roles } from "../../utils/roles";
import { OrganizationColumns } from "../../datasets/organizations/organizationColumns";
import { CInputField, FormProvider } from "../../components/Forms/Fields";
import { AiFillFilter } from "react-icons/ai";
import { useForm } from "react-hook-form";

const IncomingOrganization = () => {
  const [rowdata, setRowData] = useState([]);
  const [assignedTotal, setAssignedTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [loadingRows, setLoadingRows] = useState([]);
  const fetchIdRef = useRef(0);
  // const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  // const { error, data } = useQuery(GET_APPLICANT_REQUEST);
  // console.log(data);
  //==========================================================
  const {
    limitOrg,
    offsetOrg,
    currentRole,
    setOffsetOrg,
    setLimitOrg,
    setIncomingOrganization,
    incomingOrganizations,
  } = useLocalStore();

  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const watchFilterValue = watch("filterValue", "").length;

  const { data } = useQuery(TOTAL_ORGANIZATION);

  const [totalAssignedCount, { loading: assignedOrgTotal }] = useLazyQuery(
    TOTAL_ORGANIZATION_ASSIGNED,
    {
      onCompleted: (d) => {
        setAssignedTotal(
          d?.assigned_organization_reviewers_aggregate?.aggregate?.count
        );
      },
    }
  );
  const [dumData, setDumData] = useState([]);

  const [getDataFromServer, { loading, error }] = useLazyQuery(
    GET_ALL_ORGANIZATION,
    {
      variables: {
        limit: limitOrg,
        offset: offsetOrg * limitOrg,
      },
      onCompleted: (data) => {
        console.log("___", data);
        setIncomingOrganization(data?.organizations);
      },
    }
  );

  const [filterOrganization, {}] = useLazyQuery(GET_ALL_ORGANIZATION_FILTER, {
    onCompleted: (_data) => {
      setIncomingOrganization(_data?.organizations);
    },
  });

  const [getAssignedOrg, { loading: assignedOrg, error: assignedErr }] =
    useLazyQuery(GET_ASSIGNED_ORGANIZATION, {
      variables: {
        limit: limitOrg,
        offset: offsetOrg * limitOrg,
        empID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
      },
      onCompleted: (data) => {
        console.log("___", data);
        setDumData(data?.assigned_organization_reviewers);
      },
    });

  const [filterAssignedOrganization, { loading: filterLoading }] = useLazyQuery(
    GET_ASSIGNED_ORGANIZATION_FILTER,
    {
      onCompleted: (_data) => {
        setDumData(_data?.assigned_organization_reviewers);
      },
    }
  );

  //request new data if not found
  useEffect(() => {
    // if (incomingOrganizations?.length === 0) {
    switch (currentRole) {
      case roles.WP_DOC_CHECKER:
        getAssignedOrg();
        totalAssignedCount({
          variables: {
            id: JSON.parse(localStorage.getItem("work-permit"))?.userID,
          },
        });
        break;
      case roles.WP_FINANCE:
        getAssignedOrg();
        totalAssignedCount({
          variables: {
            id: JSON.parse(localStorage.getItem("work-permit"))?.userID,
          },
        });
        break;

      case roles.WP_DESK_HANDLER:
        getDataFromServer();
        break;
      default:
        break;
    }
    // }
  }, []);
  // =========================================================================

  const handleRenewalRequest = (c) => {
    // Set loading state for the specific row
    setLoadingRows((prevLoadingRows) => [...prevLoadingRows, c]);
    setTimeout(() => {
      toast.success("Request Renewal successfully");
      setLoadingRows((prevLoadingRows) =>
        prevLoadingRows.filter((rowId) => rowId !== c)
      );
    }, 3000);
  };

  const handlePreviousPage = () => {
    if (offsetOrg > 0) {
      setOffsetOrg(offsetOrg - 1);
    }
  };

  const handleNextPage = () => {
    setOffsetOrg(offsetOrg + 1); //the first offset
  };

  const handlePageChange = (newOffset) => {
    setOffsetOrg(newOffset - 1);
  };
  const [searchByTin, {}] = useLazyQuery(SEARCH_ORGANIZATION_BG_TIN, {
    onCompleted: (d) => {
      setDumData(d?.assigned_organization_reviewers);
    },
  });

  const handleSearch = (r) => {
    console.log("search value", r?.search);
    if (r) {
      searchByTin({
        variables: {
          empID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
          searchValue: r?.search,
        },
      });
    } else {
      getDataFromServer();
    }
  };

  const handleRefershTable = async () => {
    console.log("refersh clicked");
    setOffsetOrg(0);
    if (currentRole !== roles.WP_DESK_HANDLER) {
      setDumData([]);
      await getAssignedOrg({
        variables: { limit: limitOrg, offset: 0 * limitOrg },
        fetchPolicy: "network-only",
      });
    } else {
      setIncomingOrganization([]);
      await getDataFromServer({
        variables: { limit: limitOrg, offset: 0 * limitOrg },
        fetchPolicy: "network-only",
      });
    }
  };

  const resetFilter = () => {
    reset();
    getDataFromServer();
    getAssignedOrg();
  };
  const FilterTableData = async (e) => {
    console.log("organization filter: ", e);
    switch (currentRole) {
      case roles.WP_DESK_HANDLER:
        setIncomingOrganization([]);
        await filterOrganization({
          variables: {
            searchInput: e?.filterValue,
          },
        });
        break;

      case roles.WP_DOC_CHECKER:
        setDumData([]);
        await filterAssignedOrganization({
          variables: {
            empID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
            searchInput: e?.filterValue,
          },
        });
        break;

      default:
        break;
    }
  };


  const handlePageLimit = (_limit) => {
    setLimitOrg(Number(_limit))
  }

  return (
    <div>
      <div className="p-5 mb-2 bg-white w-full">
        <h3>Filter Organization</h3>
        <FormProvider onSubmithandler={handleSubmit(FilterTableData)}>
          <div className="flex gap-4 items-center">
            {/*  */}
            <div className="w-full">
              <CInputField
                register={register}
                errors={errors}
                name={"filterValue"}
                label={""}
                placeholder={"Filter by Tin number and Name"}
                classes={""}
              />
            </div>
            {/* <div className="w-full self-center justify-start"></div> */}
            <div className="">
              <button
                type="submit"
                disabled={watchFilterValue < 2}
                className="disabled:opacity-70 disabled:pointer-events-none flex gap-3 items-center px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <AiFillFilter /> Filter
              </button>
            </div>
            <div className="flex items-center">
              <button
                type="button"
                onClick={resetFilter}
                className="flex gap-3 items-center px-4 py-2 text-sm font-medium text-white bg-red50 rounded-md hover:bg-red60 focus:outline-none focus:ring-2 focus:ring-red50"
              >
                Reset
              </button>
            </div>
          </div>
        </FormProvider>
      </div>

      {currentRole === roles.WP_DESK_HANDLER && (
        <>
          <CTable
            columns={OrganizationColumns.deskHandlerOrganizationColumn(
              navigate,
              loadingRows
            )}
            tableData={incomingOrganizations}
            pageSize={pageCount}
            loading={loading}
            error={error}
            offset={offsetOrg}
            limit={limitOrg}
            title={"Incoming Organization"}
            headerClassess="bg-primary"
            handleRefersh={handleRefershTable}
            handlePageLimit={handlePageLimit}
            totalPages={data?.organizations_aggregate?.aggregate?.count}
            handleNextPage={handleNextPage}
            handlePageChange={handlePageChange}
            handlePreviousPage={handlePreviousPage}
            searchQuery={handleSearch}
            pageCount={Math.ceil(
              data?.organizations_aggregate?.aggregate?.count / limitOrg
            )}
            // fetchData={fetchData}
          />
        </>
      )}
      {currentRole === roles.WP_DOC_CHECKER && (
        <CTable
          columns={OrganizationColumns.documentOrganizationColumn(navigate)}
          tableData={dumData.map((i) => i.organization)}
          pageSize={pageCount}
          loading={assignedOrg || filterLoading}
          error={error}
          title={"Incoming Organization"}
          headerClassess="bg-primary"
          offset={offsetOrg}
          limit={limitOrg}
          totalPages={assignedTotal}
          handlePageLimit={handlePageLimit}
          handleRefersh={handleRefershTable}
          handleNextPage={handleNextPage}
          handlePageChange={handlePageChange}
          handlePreviousPage={handlePreviousPage}
          searchQuery={handleSearch}
        />
      )}
    </div>
  );
};

export default IncomingOrganization;
