import React from "react";
import PropTypes from "prop-types";
import Sidebar from "../common/Sidebar";

SideNav.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function SideNav({ children, disabled }) {
  return <Sidebar disabled={disabled}>{children}</Sidebar>;
}
