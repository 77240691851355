import React from "react";

const MainModal = ({ children }) => {
  return (
    <div>
      <div
        className="z-50 fixed top-0 left-0 h-screen w-full flex items-center justify-center"
        style={{ background: "rgba(0,0,0,0.8)" }}
      >
        {children}
      </div>
    </div>
  );
};

export default MainModal;
