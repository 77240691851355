import React, { useCallback, useState } from "react";
import { CInputField, FormProvider } from "../../components/Forms/Fields";
import { useForm } from "react-hook-form";
import CButton from "../../components/Forms/CButton";
import { GENERATE_REPORT } from "../../graph-query/queries";
import { useReportStore } from "../../store/reportStore";
import { useLazyQuery } from "@apollo/client";
import { MdRemoveRedEye } from "react-icons/md";
import Table from "../../components/Table/CTable";
import { ReportEmployee } from "../../datasets/reports/reportColumns";
import { roles } from "../../utils/roles";
import { useNavigate } from "react-router-dom";

const ReportPage = () => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const router = useNavigate();

  const [dateRange, setDateRange] = useState({ start: null, end: null });

  const { setLoading, setReportData, loading, reportData } = useReportStore();

  const [getReport, { error }] = useLazyQuery(GENERATE_REPORT, {
    onCompleted: (data) => {
      console.log(data);
      setReportData(data?.employees);
      setLoading(false);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const handleFilter = (e) => {
    console.log("filter", e);
    if (e?.start_date !== "" && e?.end_date !== "") {
      setLoading(true);
      getReport({
        variables: {
          weekStartDate: `${e?.start_date}T00:00:00.000Z`,
          weekEndDate: `${e?.end_date}T23:59:59.999Z`,
        },
      });
    }
  };

  const handleClearFilter = () => {
    reset();
    setReportData([]);
    setDateRange({ start: null, end: null });
    setLoading(false);
  };

  const handleTodaysReport = () => {
    console.log(`${new Date().toISOString().split("T")[0]}T00:00:00.000Z)`);
    setLoading(true);
    setDateRange({
      start: new Date().toISOString(),
      end: new Date().toISOString(),
    });
    getReport({
      variables: {
        weekStartDate: `${
          new Date().toISOString().split("T")[0]
        }T00:00:00.000Z`,
        weekEndDate: `${new Date().toISOString().split("T")[0]}T23:59:59.999Z`,
      },
    });
  };

  /**
   * @description detail information sub-table
   */
  const renderRowSubComponent = useCallback(
    ({ row }) => (
      <pre
        style={{
          fontSize: "10px",
        }}
      >
        {row.original?.user_info?.allowed_roles[0]?.role_information?.value !==
        roles.WP_FINANCE ? (
          <div className="grid grid-cols-3 gap-2">
            <div className="p-4 ml-10">
              <span className="font-bold text-lg mb-3+">
                New Work Permits (
                {row?.original?.total_new_permits?.aggregate?.count})
              </span>
              <ul>
                <li>
                  <span
                    rel="nofollow"
                    class="text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Approved Permits: (
                    {row?.original?.approved_new_permits?.aggregate?.count})
                  </span>
                </li>
                <li>
                  <span
                    rel="nofollow"
                    class="text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Rejected Permits: (
                    {row?.original?.rejected_new_permits?.aggregate?.count})
                  </span>
                </li>
                <li>
                  <span
                    rel="nofollow"
                    class="text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Pending Permits: (
                    {row?.original?.pending_new_permits?.aggregate?.count})
                  </span>
                </li>
                <li></li>
              </ul>
            </div>
            <div className="p-4 ml-10">
              <span className="font-bold text-lg mb-3+">
                Renewal Work Permits (
                {row?.original?.total_renewal_permits?.aggregate?.count})
              </span>
              <ul>
                <li>
                  <span
                    rel="nofollow"
                    class="text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Approved Permits: (
                    {row?.original?.approved_renewal_permits?.aggregate?.count})
                  </span>
                </li>
                <li>
                  <span
                    rel="nofollow"
                    class="text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Rejected Permits: (
                    {row?.original?.rejected_renewal_permits?.aggregate?.count})
                  </span>
                </li>
                <li>
                  <span
                    rel="nofollow"
                    class="text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Pending Permits: (
                    {row?.original?.pending_renewal_permits?.aggregate?.count})
                  </span>
                </li>
                <li></li>
              </ul>
            </div>
            <div className="p-4 ml-10">
              <span className="font-bold text-lg mb-3+">
                Cancellation Permits (
                {row?.original?.total_cancellation_permits?.aggregate?.count})
              </span>
              <ul>
                <li>
                  <span
                    rel="nofollow"
                    class="text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Approved Permits: 0
                  </span>
                </li>
                <li>
                  <span
                    rel="nofollow"
                    class="text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Rejected Permits: 0
                  </span>
                </li>
                <li>
                  <span
                    rel="nofollow"
                    class="text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Pending Permits: 0
                  </span>
                </li>
                <li></li>
              </ul>
            </div>
          </div>
        ) : (
          <>
            <div className="p-4 ml-10">
              <span className="font-bold text-lg mb-3+">
                No sub group for Finance
              </span>
            </div>
          </>
        )}
      </pre>
    ),
    []
  );

  const handleRoute = (id) => {
    console.log('id:', id)
    if (id) {
      return router(`/report/detail/employee/${id}`);
    }
  };

  return (
    <div className="bg-white w-full p-5 rounded-md">
      <h3 className="font-bold my-3 flex justify-between text-2xl">
        Generate Report{" "}
        <button
          onClick={handleTodaysReport}
          className="ml-4 border rounded-md px-4 py-1 text-sm text-normal hover:bg-primary hover:text-white ease-in-out"
        >
          Today's Report
        </button>
      </h3>

      <div className="">
        <FormProvider onSubmithandler={handleSubmit(handleFilter)}>
          <div className="grid gap-4 items-center grid-cols-1 md:grid-cols-3">
            <CInputField
              type="date"
              label={"Start Date"}
              name={"start_date"}
              register={register}
              errors={errors}
              placeholder={"Start Date"}
            />
            <CInputField
              type="date"
              label={"End Date"}
              name={"end_date"}
              register={register}
              errors={errors}
              placeholder={"Start Date"}
            />
            <div className="grid grid-cols-3 items-center justify-center gap-2">
              <CButton
                btnLabel={"Apply Filter"}
                type={"submit"}
                classes={"bg-primary scale-90 col-span-2"}
              />
              <CButton
                btnLabel={"Clear"}
                type={"button"}
                handleClick={handleClearFilter}
                classes="scale-90 border text-[#000] dark:text-black border-gray-600 px-3 py-2 self-center rounded-md"
              />
            </div>
          </div>
        </FormProvider>

        {loading && (
          <div className="mt-10 flex flex-col gap-5 h-full justify-center items-center">
            <svg
              aria-hidden="true"
              class="w-16 h-16 text-gray-200 animate-spin dark:text-gray-400 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <p className="font-normal text-sm">Loading...</p>
          </div>
        )}

        <div className="bg-white mt-8 w-full">
          {dateRange.start !== null && (
            <div>
              <h2 className="font-semibold text-gray-500 my-3">
                From:
                <span className="ml-3 text-black">
                  {new Date(dateRange?.start).toDateString()} -{" "}
                  {new Date(dateRange?.end).toDateString()}
                </span>
              </h2>
            </div>
          )}

          {!loading && reportData?.length > 0 && (
            <Table
              columns={ReportEmployee.reportColumns(handleRoute)}
              tableData={reportData}
              loading={loading}
              error={error}
              handlePageLimit={() => {}}
              renderRowSubComponent={renderRowSubComponent}
              title={"Employees:  Work Permit"}
              headerClassess="bg-primary"
              // pageCount={pageCount}
            />
          )}
          {/* 
          {!loading && reportData?.length > 0 && (
            <div className="table w-full mt-5">
              <div class="relative overflow-x-auto">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" class="px-6 py-3">
                        Employee
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Role
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Total Permit Assigned
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Total Organization Assigned
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Approved
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportData &&
                      reportData?.map((item) => (
                        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <th
                            scope="row"
                            class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-gray-500"
                          >
                            {item?.user_info?.name}
                          </th>
                          <td class="px-6 py-4">
                            <span className="rounded-2xl bg-primary px-3 py-1 text-white">{
                              item?.user_info?.allowed_roles[0]
                                ?.role_information?.value
                            }</span>
                          </td>
                          <td class="px-6 py-4">
                            {item?.total_assigned_permits?.aggregate?.count}
                          </td>
                          <td class="px-6 py-4">{item?.assigned_organization_reviewers_aggregate?.aggregate?.count}</td>
                          <td class="px-6 py-4">{item?.approved_permit?.aggregate?.count}</td>
                          <td class="px-6 py-4">
                            <button className="rounded-full w-8 h-8 flex justify-center items-center hover:bg-gray-100"><MdRemoveRedEye /> </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default ReportPage;
