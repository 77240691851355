import React, { useMemo, useState } from "react";
import {
  useTable,
  useExpanded,
  usePagination,
  useFilters,
  useSortBy,
} from "react-table";
import Pagination from "./Pagination";
import { useForm } from "react-hook-form";
import { RiRefreshLine } from "react-icons/ri";
import { useRef } from "react";
import { useEffect } from "react";
import { BiSortDown, BiSortUp } from "react-icons/bi";
import { Can } from "../../permission/Can";

const CTable = ({
  columns,
  tableData,
  loading,
  error,
  renderRowSubComponent,
  pageCount: controlledPageCount,
  title,
  headerClassess,
  totalPages,
  limit,
  offset,
  handleNextPage,
  handlePreviousPage,
  handlePageChange,
  handleRefersh,
  handlePageLimit,
  filterOptions,
  onSearchChange,
  onFilterChange,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount: tablePageCount,
    visibleColumns,
    gotoPage,
    state: { pageIndex, pageSize, expanded },
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: controlledPageCount,
      manualFilters: true,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [tableFilterTab, setTableFilterTab] = useState("all");
  const dropdownRef = useRef(null);

  const renderPageButtons = useMemo(() => {
    const maxDisplayedPages = 10;
    const pageCount = Math.ceil(totalPages / limit);
    const _offset = Math.floor(maxDisplayedPages / 2);
    const startPage = Math.max(1, offset - _offset);
    const endPage = Math.min(pageCount, startPage + maxDisplayedPages - 1);

    // Render a button for each page, showing an ellipsis for pages not in the visible range
    const pageButtons = [];

    // if (startPage > 1) {
    //   pageButtons.push(
    //     <li
    //       key={1}
    //       className={`${
    //         offset === 1 ? "bg-primary" : ""
    //       } flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
    //       onClick={() => handlePageChange(1)}
    //     >
    //       1
    //     </li>
    //   );
    // }

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <li
          key={i - 1}
          className={`flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 hover:text-gray-700 ${
            i - 1 === offset
              ? "bg-primary text-white"
              : "bg-white border border-gray-300 hover:bg-gray-100"
          }`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </li>
      );
    }

    if (endPage < pageCount) {
      // if (endPage < pageCount - 1) {
      pageButtons.push(
        <li
          key="_ellipsis_end_"
          className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          disabled
        >
          ...
        </li>
      );
      // }
      pageButtons.push(
        <li
          key={pageCount}
          className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          onClick={() => handlePageChange(pageCount)}
        >
          {pageCount}
        </li>
      );
    }

    return pageButtons;
  });
  //, [offset, limit, totalPages, handlePageChange]
  const { handleSubmit, register, reset } = useForm();

  const handleSearch = (e) => {
    onSearchChange(e);
    // console.log(e);
  };

  const handleCheckboxChange = (event) => {
    // setSelectedValue(event.target.value);
    onFilterChange(event);
    // const { value, checked } = event.target;
    // if (checked) {
    //   setSelectedValues((prevSelectedValues) => [...prevSelectedValues, value]);
    // } else {
    //   setSelectedValues((prevSelectedValues) =>
    //     prevSelectedValues.filter((selectedValue) => selectedValue !== value)
    //   );
    // }
    // console.log(selectedValues)
  };

  const toggleDropdown = () => {
    setShowFilterMenu(!showFilterMenu);
  };

  useEffect(() => {
    // Add event listener to the document when the dropdown menu is open
    if (showFilterMenu) {
      document.addEventListener("click", handleClickOutside);
    }

    // Cleanup the event listener when the component unmounts or the dropdown menu closes
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showFilterMenu]);

  const handleClickOutside = (event) => {
    // Check if the click event occurred outside the dropdown menu
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowFilterMenu(false);
    }
  };

  return (
    <section id="tblData" className="w-full py-0">
      <div className="w-full">
        <div className="relative overflow-hidden bg-white shadow-md sm:rounded-lg">
          <div className="flex flex-col px-4 bg-primary py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4">
            <div className="flex py-3 items-center flex-1 space-x-4">
              <span className="text-gray-600 dark:text-white">{title}</span>
            </div>

            <div className="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3">
              <button
                type="button"
                onClick={handleRefersh}
                className="flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                {loading ? (
                  <svg
                    className="animate-spin w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      fill="none"
                      stroke="currentColor"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16 12a4 4 0 01-8 0"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                )}
                {loading ? "Loading..." : "Refersh table"}
              </button>
              <Can I={"export"} a={"excel"}>
                {() => {
                  <button
                    type="button"
                    // onClick={() => exportTableToExcel("tblData")}
                    className="flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                  >
                    <svg
                      className="w-4 h-4 mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewbox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                      />
                    </svg>
                    Export
                  </button>;
                }}
              </Can>
            </div>
          </div>

          {/* <div className="flex justify-end mt-5">
            <ul className="flex justify-end border-2 rounded-lg mr-5">
              <li className="w-full">
                <button
                  onClick={() => setTableFilterTab("all")}
                  className={`${
                    tableFilterTab === "all"
                      ? "bg-primary border-blue-300 focus:ring-2 focus:ring-blue-300 focus:outline-none text-white"
                      : ""
                  } inline-block w-full px-4 py-2 text-sm font-medium text-center text-gray-500 divide-x divide-gray-200 rounded-l-lg shadow bg-gray-100 `}
                >
                  All
                </button>
              </li>
              <li className="w-full">
                <button
                  onClick={() => setTableFilterTab("pending")}
                  className={`${
                    tableFilterTab === "pending"
                      ? "bg-primary text-white border-blue-300 focus:ring-2 focus:ring-blue-300 focus:outline-none "
                      : ""
                  }  inline-block w-full px-4 py-2 text-sm font-medium text-center text-gray-500 divide-x divide-gray-200 bg-gray-100`}
                >
                  Pending
                </button>
              </li>
              <li className="w-full">
                <button
                  onClick={() => setTableFilterTab("verified")}
                  className={`${
                    tableFilterTab === "verified"
                      ? "bg-primary text-white border-blue-300 focus:ring-2 focus:ring-blue-300 focus:outline-none"
                      : ""
                  }  inline-block w-full px-4 py-2 text-sm font-medium text-center text-gray-500 divide-x divide-gray-200 bg-gray-100 `}
                >
                  Verified
                </button>
              </li>
              <li className="w-full">
                <button
                  onClick={() => setTableFilterTab("rejected")}
                  className={`${
                    tableFilterTab === "rejected"
                      ? "bg-primary text-white border-blue-300 focus:ring-2 focus:ring-blue-300 focus:outline-none "
                      : ""
                  }  inline-block w-full px-4 py-2 text-sm font-medium text-center text-gray-500 divide-x divide-gray-200 rounded-r-lg bg-gray-100`}
                >
                  Rejected
                </button>
              </li>
            </ul>
          </div> */}

          {loading && (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-300 bg-opacity-50">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
            </div>
          )}

          <div className="overflow-x-auto">
            <table
              {...getTableProps()}
              className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
            >
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        scope="col"
                        className="p-4"
                      >
                        <div className="flex items-center">
                          {column.render("Header")}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <BiSortDown className="sort-icon" />
                            ) : (
                              <BiSortUp className="sort-icon" />
                            )
                          ) : (
                            <span className="sort-icon-placeholder" />
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              {getTableBodyProps() ? (
                <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <React.Fragment {...row.getRowProps()}>
                        <tr
                          className={`${
                            loading ? "opacity-70 pointer-events-none" : ""
                          } border-b dark:border-gray-300 hover:bg-gray-100`}
                          {...row.getRowProps()}
                        >
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap"
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                        {row?.isExpanded ? (
                          <tr>
                            <td colSpan={visibleColumns.length}>
                              {renderRowSubComponent({ row })}
                            </td>
                          </tr>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              ) : (
                "No Data"
              )}
            </table>
          </div>
          <Pagination
            totalPages={Math.ceil(totalPages / limit)}
            limit={limit}
            offset={offset}
            handleNextPage={handleNextPage}
            handlePageLimit={handlePageLimit}
            handlePreviousPage={handlePreviousPage}
            renderPageButtons={renderPageButtons}
          />
        </div>
      </div>
    </section>
  );
};

export default CTable;
