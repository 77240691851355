import React, { useEffect, useState } from "react";
import { MdArrowBack, MdHelpOutline } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import UploadSingleFile from "../../../components/uploads/UploadFile";
import Popover from "../../../components/Popover";
import NoticeCard from "../../../components/cards/NoticeCard";
import { useLocalStore } from "../../../store";
import { getBase64 } from "../../../utils/utilityFunctions";
import CButton from "../../../components/Forms/CButton";
import { CInputField, FormProvider } from "../../../components/Forms/Fields";
import { useForm } from "react-hook-form";
import { trackPromise } from "react-promise-tracker";
import { INSERT_EXPATRIATE_DOC, INSERT_RENEWAL_PERMIT_REQUEST, UPLOAD_FILE } from "../../../graph-query/mutation";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_ORG_EXPATRIATE_BY_ID } from "../../../graph-query/queries";
import { WORK_PERMIT_TYPES } from "../../../utils/constants";
import {toast} from 'react-toastify';

const ReplacementPage = () => {
  const [acceptNotice, setAcceptNotice] = useState(false);
  const [tempFile, setTempFile] = useState([]);
  const [exptData, setExptData] = useState();
  const { permitPrice } = useLocalStore();
  const router = useNavigate();
  const { id } = useParams();

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [fileUpload, { loading: uploadLoading }] = useMutation(UPLOAD_FILE);
  const [permitDocument, { loading: permitLoading }] = useMutation(
    INSERT_EXPATRIATE_DOC
  );
  const uploadDocuments = (result, fileType, cb) => {
    trackPromise(
      fileUpload({
        variables: {
          extension: fileType,
          file: result,
        },
        onCompleted(data) {
          // console.log(data);
          cb && cb({ path: data.uploadFile.info.Key });
          // toast.success("Document Uploaded Successfully!");
        },
        onError(error) {},
      })
    );
  };

  const [getExpatData, {}] = useLazyQuery(GET_ORG_EXPATRIATE_BY_ID, {
    variables: {
      id: id,
    },
    onCompleted: (da) => {
      console.log(da?.expatriates);
      setExptData(da?.expatriates[0]);
    },
  });
  const [create_permit, { loading, error }] = useMutation(
    INSERT_RENEWAL_PERMIT_REQUEST,
    {
      onError: (er) => {
        toast.error(er?.message);
      },
    }
  );

  const handleFile = (file, title) => {
    if (file) {
      getBase64(file).then((result) => {
        let fileType = (file?.type).split("/").length
          ? (file?.type).split("/")[1]
          : "";

        if (title === "evidence") {
          setFile(
            result,
            fileType,
            file.name,
            "695b11e6-4acc-4850-a175-8db2e46d5553",
            "04f002e9-1523-4fde-ba95-69ed60244143",
            "Police Evidence"
          );
        }
        if (title === "residential") {
          setFile(
            result,
            fileType,
            file.name,
            "be8816cd-dccb-42c7-baeb-47c1bab19030",
            "04f002e9-1523-4fde-ba95-69ed60244143",
            "Residential Permit"
          );
        }
        if (title === "finance") {
          setFile(
            result,
            fileType,
            file.name,
            "d0488d16-9f4e-4f4c-bbe7-c93bfa4b9c26",
            "04f002e9-1523-4fde-ba95-69ed60244143",
            "Finance Slip"
          );
        }
        if (title === "application") {
          setFile(
            result,
            fileType,
            file.name,
            "4c1a2ace-c354-445f-bf6d-78f17b81cd9d",
            "ef0e53f7-fdb7-48fb-b0fc-f19ca3a4bc64",
            "Application Letter"
          );
        }
        if (title === "delegation") {
          setFile(
            result,
            fileType,
            file.name,
            "54a025a3-ce9b-4765-b163-56e7f28b9a55",
            "ef0e53f7-fdb7-48fb-b0fc-f19ca3a4bc64",
            "Letter of Delegation"
          );
        }
        //     setTempFile((prevFiles) => [
        //       ...prevFiles,
        //       {
        //         file: result,
        //         fileType: fileType,
        //         name: file.name,
        //         document_type_id: "ad702374-171c-4d4b-b3f6-161170e3db0b",
        //         catagory: "51ff2b29-4d56-449c-a69d-08348d7299b4",
        //         title: "Visa",
        //       },
        //     ]);
      });
    }
  };

  const setFile = (result, fileType, file, document, category, title) => {
    console.log('====================================');
   // console.log({result, fileType, file, document, category, title});
    console.log('====================================');
    return setTempFile((prevFiles) => [
      ...prevFiles,
      {
        file: result,
        fileType: fileType,
        name: file,
        document_type_id: document,
        catagory: category,
        title: title,
      },
    ]);
  };

  const handleApplication = (e) => {
    let renewalFiles = [];
    console.log(".... replacement application submitting ", e);
    console.log(".... ", tempFile);
    // if (tempFile.length > 3) {
    //   console.log(tempFile);
    // }
    if (tempFile.length > 4) {
      const uploadPromises = tempFile?.map((i) => {
        return new Promise((resolve, reject) => {
          uploadDocuments(
            i?.file,
            i.fileType,
            (filePath) => {
              renewalFiles.push({
                ...filePath,
                catagory: i.catagory,
                document_type_id: i.document_type_id,
                title: i.title,
              });
              resolve(filePath);
            },
            reject
          );
        });
      });

      Promise.all(uploadPromises).then(() => {
        create_permit({
          variables: {
            ...exptData.expatriate_work_permits[0],
            expatriate_id: id,
            allowance: exptData?.expatriate_work_permits[0]?.allowance,
            permit_type: WORK_PERMIT_TYPES.REPLACEMENT,
            reference_number: e?.finance_slip,
            employment_end_date: exptData?.expatriate_work_permits[0]?.end_date,
            employment_start_date:
              exptData?.expatriate_work_permits[0]?.start_date,
            salary: exptData?.expatriate_work_permits[0]?.salary,
            organization_id:
              exptData?.expatriate_work_permits[0]?.organization?.id,
            occupation_category_id:
              exptData?.expatriate_work_permits[0]?.occupation_category?.id,
            issued_date: exptData?.expatriate_work_permits[0]?.issued_date,
            expiry_date: exptData?.expatriate_work_permits[0]?.expiry_date,
            emr_number: exptData?.expatriate_work_permits[0]?.emr_number,
          },
          onCompleted: async (data) => {
            // console.log("data__", data);
            await renewalFiles.map(async (i) => {
              console.log(i);
              await permitDocument({
                variables: {
                  documentId: i?.document_type_id,
                  expatriate_work_permit_id:
                    data?.insert_expatriate_work_permits?.returning[0]?.id,
                  files: i?.path,
                  properties: i,
                  title: i?.title,
                },
              }).then(() => {
                toast.success("Replacement requested successfully!");
                router("/organization/requested-work-permit");
              });
            });
          },
        });
      });
    }
  };

  useEffect(() => {
    getExpatData();
  }, []);

  return (
    <>
      <div className="flex flex-wrap md:flex-nowrap gap-6">
        <div className="w-full md:w-full space-y-6">
          <div className="bg-white shadow-sm p-4 rounded-md space-y-5">
            <button
              onClick={() => router(-1)}
              className="bg-gray px-3 py-2 border-2 rounded-md flex gap-2 items-center"
            >
              <MdArrowBack /> Back
            </button>
            <h2 className="text-primary capitalize font-semibold">
              Work Permit Details
            </h2>
          </div>

          {!acceptNotice && (
            <NoticeCard
              amount={
                permitPrice
                  ?.filter((i) => i?.work_permit_type?.name === "Replacement")
                  .map((i) => i?.price)[0]
              }
              handleHandle={() => setAcceptNotice(!acceptNotice)}
            >
              <>
                <p>
                  Below are the payment associated with an replacement work
                  permit
                </p>
                <ul className="list-disc">
                  <li className="md:ml-6">
                    You should make payment the specific amount{" "}
                    {
                      permitPrice
                        ?.filter(
                          (i) => i?.work_permit_type?.name === "Replacement"
                        )
                        .map((i) => i?.price)[0]
                    }{" "}
                    and get the recept before continue
                  </li>
                  <li className="md:ml-6">
                    amount:{" "}
                    <span className="font-bold">
                      {
                        permitPrice
                          ?.filter(
                            (i) => i?.work_permit_type?.name === "Replacement"
                          )
                          .map((i) => i?.price)[0]
                      }
                    </span>{" "}
                    ETB
                  </li>
                </ul>
              </>
            </NoticeCard>
          )}
          {acceptNotice && (
            <div className="flex flex-col gap-9">
              <FormProvider onSubmithandler={handleSubmit(handleApplication)}>
                <div className="p-5 rounded-sm border border-stroke bg-white shadow-sm">
                  <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
                    <h3 className="font-medium text-black dark:text-gray-700">
                      Replacement Application
                    </h3>
                  </div>
                  <div className="flex flex-col gap-5.5 p-6.5">
                    <div className="mt-8">
                      {/* <label className="mb-3 block text-black">
                            Finance Slip Reference ID
                            </label> */}
                      <CInputField
                        register={register}
                        errors={errors}
                        label={"Finance Slip Reference ID"}
                        placeholder={"Enter slip transaction ID"}
                        name={"finance_slip"}
                      />
                      {/* <input
                        type="text"
                        placeholder="Default Input"
                        className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      /> */}
                    </div>

                    <div className="mt-5">
                      <label
                        className="mb-3 text-black flex items-center"
                        htmlFor="one"
                      >
                        Letter of application of the employer
                        <span className="flex">
                          <Popover
                            content={"Letter of application required!"}
                            title={"Replacement"}
                          >
                            <MdHelpOutline />
                          </Popover>
                        </span>
                      </label>
                      <UploadSingleFile
                        type={"file"}
                        watch={watch}
                        setValue={setValue}
                        register={register}
                        onChange={(e) => handleFile(e, "application")}
                      />
                    </div>
                    <div className="mt-5">
                      <label
                        className="mb-3 text-black flex items-center"
                        htmlFor="one"
                      >
                        Attach evidence from Police or Damage Id
                        <span className="flex">
                          <Popover
                            content={"Letter of application required!"}
                            title={"Replacement"}
                          >
                            <MdHelpOutline />
                          </Popover>
                        </span>
                      </label>
                      <UploadSingleFile
                        type={"file"}
                        watch={watch}
                        setValue={setValue}
                        register={register}
                        onChange={(e) => handleFile(e, "evidence")}
                      />
                    </div>
                    <div className="mt-5">
                      <label
                        className="mb-3 text-black flex items-center"
                        htmlFor="one"
                      >
                        Attach residential permit
                        <span className="flex">
                          <Popover
                            content={"Letter of application required!"}
                            title={"Replacement"}
                          >
                            <MdHelpOutline />
                          </Popover>
                        </span>
                      </label>
                      <UploadSingleFile
                        watch={watch}
                        setValue={setValue}
                        register={register}
                        onChange={(e) => handleFile(e, "residential")}
                      />
                    </div>
                    <div className="mt-5">
                      <label
                        className="mb-3 text-black flex items-center"
                        htmlFor="one"
                      >
                        Letter of delegation of the liaison officer accompanied
                        with his/her valid IDs of the organization and kebele.
                        <span className="flex">
                          <Popover
                            content={
                              "(i.e., resident ID); or Power of attorney as appropriate"
                            }
                            title={"Replacement"}
                          >
                            <MdHelpOutline />
                          </Popover>
                        </span>
                      </label>
                      <UploadSingleFile
                        type={"file"}
                        watch={watch}
                        setValue={setValue}
                        register={register}
                        onChange={(e) => handleFile(e, "delegation")}
                      />
                    </div>
                    <div className="mt-5">
                      <label
                        className="mb-3 text-black flex items-center"
                        htmlFor="one"
                      >
                        Finance Slip
                        <span className="flex">
                          <Popover
                            content={"(i.e., 1200 ETB payment recept)"}
                            title={"Payment"}
                          >
                            <MdHelpOutline />
                          </Popover>
                        </span>
                      </label>
                      <UploadSingleFile
                        watch={watch}
                        setValue={setValue}
                        register={register}
                        onChange={(e) => handleFile(e, "finance")}
                        type={"image"}
                      />
                    </div>

                    <CButton
                      type={"submit"}
                      classes={"bg-primary"}
                      btnLabel={"Submit Application"}
                    />
                  </div>
                </div>
              </FormProvider>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ReplacementPage;
