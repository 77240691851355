import { create } from "zustand";

export const useCancellationStore = create((set) => ({
  loading: false,
  // limit default values
  pending_limit: 10,
  rejected_limit: 10,
  approved_limit: 10,

  //offset default value
  pending_offset: 0,
  rejected_offset: 0,
  approved_offset: 0,

  approvedCancellationList: [],
  rejectedCancellationList: [],
  pendingCancellationList: [],

  //count data
  approvalCount: 0,
  rejectedCount: 0,
  pendingCount: 0,

  setLoading: (loading) => set({ loading }),
  setCountApproval: (count) => set({ approvalCount: count }),
  setCountPending: (count) => set({ pendingCount: count }),
  setCountRejected: (count) => set({ rejectedCount: count }),

  /**
   * @description set limit per display
   * @param {*} limit
   * @returns
   */
  setPendingLimit: (limit) => set({ pending_limit: limit }),
  setRejectedLimit: (limit) => set({ rejected_limit: limit }),
  setApprovedLimit: (limit) => set({ approved_limit: limit }),

  /**
   * @description set page offset to be displayed
   * @param {*} offset
   * @returns
   */
  setPendingOffset: (offset) => set({ pending_offset: offset }),
  setRejectedOffset: (offset) => set({ rejected_offset: offset }),
  setApprovedOffset: (offset) => set({ approved_offset: offset }),

  /**
   * @description set Cancellation's Data to store
   * @param {*} data
   * @returns
   */
  setPendingList: (data) => set({ pendingCancellationList: data }),
  setRejectedList: (data) => set({ rejectedCancellationList: data }),
  setApprovedList: (data) => set({ approvedCancellationList: data }),
}));
