import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Nav from "../../../components/Navbar/Navbar";
import SideNav from "../../../components/Navbar/SideNav";
import StepperList from "../../../components/utilities/StepperList";
import { HiBuildingLibrary } from "react-icons/hi2";
import Avatar from "../../../assets/images/avatar.jpg";
import { useLocalStore } from "../../../store";
import { CInputField, FormProvider } from "../../../components/Forms/Fields";
import FormLabel from "../../../components/common/FormLabel";
import { BsBuilding } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { FaFileUpload } from "react-icons/fa";
import { VscFilePdf } from "react-icons/vsc";
import CButton from "../../../components/Forms/CButton";
import { GET_ORGANIZATION_BYID } from "../../../graph-query/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import {
  INSERT_ORGANIZATION_DOC,
  ORGANIZATION_RENEWAL,
  UPLOAD_FILE,
} from "../../../graph-query/mutation";
import { getBase64 } from "../../../utils/utilityFunctions";
import { trackPromise } from "react-promise-tracker";
import { yupResolver } from "@hookform/resolvers/yup";
import { OrganizationRenewalSchema } from "../../../utils/SchemaValidation";

const OrganizationRenewal = () => {
  const router = useNavigate();
  const [fileErrors, setFileErrors] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const {
    currentRole,
    user,
    userOrganizationData,
    setCurrentRole,
    setUserOrganizationData,
  } = useLocalStore();

  const fileRef = useRef();
  const { id } = useParams();

  const [getUserData, { error: userErr }] = useLazyQuery(
    GET_ORGANIZATION_BYID,
    {
      variables: { id: id },
      context: {
        headers: {
          "Content-Type": "application/json",
        },
      },
      onCompleted: (data) => {
        // setUser(data?.credentials[0]);
        console.log("getUserData: ", data.organizations[0]);
        setUserOrganizationData(data?.organizations[0]);
      },
    }
  );
  const [fileUpload, { loading: uploadLoading }] = useMutation(UPLOAD_FILE);

  const [renewalOrg, {}] = useMutation(ORGANIZATION_RENEWAL, {
    onCompleted(data) {
      console.log(data);
      toast.success("Organization Renewal applied");
      router("/workspace");
    },
    onError(er) {
      console.error(er);
    },
  });

  const [createOrgDOC, { error: docErr }] = useMutation(
    INSERT_ORGANIZATION_DOC,
    {
      onError: (er) => {
        console.log(er);
        // toast.error("Something happend while creating organization");
      },
    }
  );

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(OrganizationRenewalSchema),
  });

  const handleBusiness = (_file, e) => {
    console.log("business", _file, e);
    const file = e.target.files[0];
    if (file) {
      if (file.size > "5MB") {
        setFileErrors({
          ...fileErrors,
          [_file?.id]: `File size should not exceed 5MB`,
        });
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          const fileData = reader.result;
          const existingFile = selectedFiles.find(
            (file) => file.document_type_id === _file?.id
          );
          if (existingFile) {
            const updatedFile = {
              document_type_id: _file?.id,
              title: _file?.name,
              file: file,
            };
            setSelectedFiles((prevFiles) => {
              const updatedFiles = prevFiles.map((file) =>
                file.document_type_id === _file?.id ? updatedFile : file
              );
              return updatedFiles;
            });
          } else {
            setSelectedFiles((prevFiles) => [
              ...prevFiles,
              {
                document_type_id: _file?.id,
                title: "Business License Certificate",
                file: file,
              },
            ]);
          }
        };
        reader.readAsDataURL(file);
        setFileErrors({
          ...fileErrors,
          [_file?.id]: undefined,
        });
      }
    }
  };

  const filesUpload = (uploadedFile, cb) => {
    getBase64(uploadedFile)
      .then((result) => {
        // console.log(result);
        // extract the file type to have the folowing pdf,png ...
        let fileType = (uploadedFile?.type).split("/").length
          ? (uploadedFile?.type).split("/")[1]
          : "";
        // upload the file to the server
        trackPromise(
          fileUpload({
            variables: {
              extension: fileType,
              file: result,
            },
            onCompleted(data) {
              // console.log(data);
              cb &&
                cb({ name: uploadedFile.name, path: data.uploadFile.info.Key });
              // toast.success("Document Uploaded Successfully!");
            },
            onError(error) {
              // toast.error("Document upload failed!");
            },
          })
        );
      })
      .catch((err) => {
        // handle error
      });
  };

  const handleRenewalApplication = (e) => {
    if (selectedFiles.length > 0) {
      const updatedTempFiles = [];
      const uploadPromises = selectedFiles.map((i) => {
        return new Promise((resolve, reject) => {
          filesUpload(
            i?.file,
            (filePath) => {
              updatedTempFiles.push({
                ...filePath,
                document_type_id: i.document_type_id,
                title: i.title,
              });
              resolve(filePath);
            },
            reject
          );
        });
      });

      Promise.all(uploadPromises).then(async () => {
        await updatedTempFiles.map(async (i) => {
          console.log(i);
          await createOrgDOC({
            variables: {
              orgID: id,
              document_type: i?.document_type_id,
              files: i,
            },
            onCompleted() {
              renewalOrg({
                variables: {
                  orgID: id,
                  ...e,
                },
              });
            },
          });
        });
      });
    }
    console.log("====================================");
    console.log(e);
    console.log("====================================");
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (userOrganizationData) {
      setValue("name", userOrganizationData?.name);
      setValue(
        "business_license_number",
        userOrganizationData?.business_licence_number
      );
    }
  }, [userOrganizationData, setUserOrganizationData]);

  return (
    <>
      <Nav Avatar={Avatar} userType={currentRole} name={userOrganizationData} />
      <div className="flex flex-col md:flex-row gap-8 2xl:gap-12 pb-10 mt-10 md:mt-20 layout items-start">
        <SideNav>
          {/* <Can I="view" a="newRequest">
            {() => ( */}
          <StepperList
            _link={"/update-organization-profile"}
            icon={<HiBuildingLibrary className="text-xl" />}
            stepperText={"Organization Data "}
            activeClassName="bg-primary text-white"
          />
          {/* )}
          </Can> */}
        </SideNav>
        <div className="w-full">
          {/* Form here */}
          <button
            onClick={() => router(-1)}
            className="border-2 rounded-md border-gray-300 text-gray-600 mb-2 px-4 py-1"
          >
            back
          </button>
          <div className="bg-white shadow-sm p-8">
            <span>Organization Renewal</span>
          </div>

          <FormProvider
            className="w-full flex flex-wrap"
            onSubmithandler={handleSubmit(handleRenewalApplication)}
          >
            <div className="p-6 bg-white">
              <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                <FormLabel
                  labelIcon={<BsBuilding />}
                  labelTitile="Organization Name"
                  labelDescription="Organization name"
                />
                <div className="w-full flex gap-6 py-2">
                  <div className=" w-full">
                    <CInputField
                      name="name"
                      register={register}
                      errors={errors}
                      disabled
                      type="text"
                      label=""
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                <FormLabel
                  labelIcon={<BsBuilding />}
                  labelTitile="Business License Number*"
                  labelDescription="Enter Business license number"
                />
                <div className="w-full flex gap-6 py-2">
                  <div className=" w-full">
                    <CInputField
                      name="business_license_number"
                      register={register}
                      errors={errors}
                      disabled
                      type="text"
                      label=""
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                <FormLabel
                  labelIcon={<BsBuilding />}
                  labelTitile="Business license issued date*"
                  labelDescription="Enter Business license issued date"
                />
                <div className="w-full flex gap-6 py-2">
                  <div className=" w-full">
                    <CInputField
                      name="business_license_issued_date"
                      register={register}
                      errors={errors}
                      type="date"
                      label=""
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                <FormLabel
                  labelIcon={<BsBuilding />}
                  labelTitile="Business license expiry date*"
                  labelDescription="Enter Business license expiry date"
                />
                <div className="w-full flex gap-6 py-2">
                  <div className=" w-full">
                    <CInputField
                      name="business_license_expiry_date"
                      register={register}
                      errors={errors}
                      type="date"
                      label=""
                      placeholder=""
                    />
                  </div>
                </div>
              </div>

              <div className="w-full flex md:flex-row flex-col gap-4 md:gap-6 py-2">
                <div className=" w-full ">
                  <div className="flex flex-col gap-2 bg-white p-6 rounded-t-xl">
                    <p className="text-primary text-sm font-semibold">
                      Upload Business License Documents
                    </p>
                    <div
                      className={`relative bg-primary/5 border-dashed border-2 cursor-pointer rounded-lg px-8 py-12 mt-4 gap-3"
                      }`}
                    >
                      <input
                        onChange={(e) =>
                          handleBusiness(
                            {
                              id: "e97f910e-e9c2-4898-ace3-60a9cc1b065b",
                              name: "Business License Certificate",
                            },
                            e
                          )
                        }
                        ref={fileRef}
                        type="file"
                        className="hidden"
                      />
                      <div
                        onClick={() => fileRef?.current?.click()}
                        className="absolute top-0 left-0 right-0 flex flex-col items-center justify-center px-8 py-4 gap-3"
                      >
                        <FaFileUpload className="text-primary/90 text-4xl" />
                        <p className="text-N70 text-xs md:px-6 text-center">
                          Choose document type
                        </p>
                      </div>
                    </div>
                    {selectedFiles
                      .filter((i) => i.title === "Business License Certificate")
                      .map((item, index) => (
                        <div
                          key={index}
                          className="border-b border-N99/50 p-4 flex justify-between items-center w-full"
                        >
                          <div className="flex gap-2 items-center">
                            <VscFilePdf />
                            <div>
                              <p className="text-xs text-N50">
                                {item.title}.pdf
                              </p>
                              <p className="text-[10px] text-N50">
                                {item?.name}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div className="mt-7">
                <CButton
                  btnLabel={"Submit"}
                  type={"submit"}
                  classes={"bg-primary"}
                />
              </div>
            </div>
          </FormProvider>
        </div>
      </div>
      <div className="mt-16">{/* Footer */}</div>
    </>
  );
};

export default OrganizationRenewal;
