import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import {
  CHART_DATA_REPORT,
  COUNT_DOCUMENT_CHECKER_FINISHED,
  COUNT_EMR_APPROVED,
  COUNT_EXPATRIATE,
  GET_REPORT_COUNTS,
} from "../graph-query/queries";
import { useReportStore } from "../store/reportStore";
import { useLocalStore } from "../store";
import Nav from "../components/Navbar/Navbar";
import SideNav from "../components/Navbar/SideNav";
import StepperList from "../components/utilities/StepperList";
import { ReportDashboardNavLink } from "../utils/sidebarNav";
import { Can } from "../permission/Can";

const ReportLayout = () => {
  const { setReportCount, setChartData } = useReportStore();

  const { currentRole, userData } = useLocalStore();

  const [getReportCount, {}] = useLazyQuery(GET_REPORT_COUNTS, {
    // context: {
    //   headers: {
    //     "x-hasura-admin-secret": "8ShURDi6roXj9tmejrWwX992by5S5Q",
    //   },
    // },
    onCompleted(data) {
      setReportCount(data);
    },
    onError(er) {
      console.log(er);
    },
  });

  const [getChartData, {}] = useLazyQuery(CHART_DATA_REPORT, {
    // context: {
    //   headers: {
    //     "x-hasura-admin-secret": "8ShURDi6roXj9tmejrWwX992by5S5Q",
    //   },
    // },
    onCompleted(data) {
      setChartData(data);
    },
    onError(er) {
      console.log(er);
    },
  });

  useEffect(() => {
    getReportCount();
    getChartData();
  }, []);

  return (
    <>
      <Nav userType={currentRole} name={userData} />
      <div className="flex flex-col md:flex-row gap-6 mt-10 md:mt-20 layout items-start">
        <SideNav>
          {ReportDashboardNavLink?.map((link, index) => (
            // <Can key={index} I="view" a={link.permission}>
            //   {() => (
            <StepperList
              key={index}
              _link={link.path}
              icon={link.icon}
              disabled={link.disabled}
              stepperText={link.title}
              activeClassName="bg-primary text-white"
            />
            //   )}
            // </Can>
          ))}
        </SideNav>

        <div className="md:w-4/5 lg:ml-5 w-full">
          {/* roles: {user} */}
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default ReportLayout;
