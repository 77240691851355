import {
  InMemoryCache,
  ApolloClient,
  createHttpLink,
  split
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { accessTokenInVar, isLoggedInVar, refreshTokenInVar } from "./store";
import { REFRESH_TOKEN } from "./mutation";
import { getMainDefinition } from "@apollo/client/utilities";

const httpLink = createHttpLink({
  // uri: "https://work-permit-api.lmis.gov.et/v1/graphql",
  uri: process.env.REACT_APP_GRAPHQL,
});

// const wsLink = new WebSocketLink({
//   uri: "ws://staging-work-permit-api.lmis.gov.et/v1/graphql",
//   // uri: process.env.REACT_APP_GRAPHQL_WS,
//   options: {
//     reconnect: true,
//   },
// });

const authLink = setContext((operation, { headers }) => {
  // Check if x-hasura-admin-secret is present in the headers
  const hasAdminSecret = headers && headers["x-hasura-admin-secret"];

  if (hasAdminSecret) {
    // Remove Authorization and x-hasura-role headers from the request
    const { Authorization, "x-hasura-role": role, ...newHeaders } = headers;

    return {
      headers: {
        ...newHeaders,
        "x-hasura-admin-secret": headers["x-hasura-admin-secret"],
      },
    };
  } else {
    // Get token from local storage
    const token = JSON.parse(localStorage.getItem("work-permit"));

    if (token) {
      return {
        headers: {
          ...headers,
          "x-hasura-role": token?.roles,
          Authorization: `Bearer ${token?.token}`,
        },
      };
    } else {
      return {
        headers: {
          // ...headers,
        },
      };
    }
  }
});

// Handle error for every request
const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          //   // Retry the request, returning the new observable
          //   return forward(operation);
          case "invalid-jwt":
            if (err.message === "Could not verify JWT: JWTExpired") {
              window.location.href = "/login";
              localStorage.removeItem("work-permit");
            }
            break;

          default:
            console.log(
              `[GrpahQl error]: Message: ${err.message}, Location: ${err.locations}, path: ${err.path}`
            );
            break;
        }
      }
    } else {
      console.log(operation);
    }
    // To retry on network errors, we recommend the RetryLink
    // instead of the onError link. This just logs the error.
    if (networkError) console.log(`[Network error]: ${networkError}`);
  }
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  // wsLink,
  httpLink
);

export const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache(),
});
