import React, { useState } from "react";
import CheckPassport from "../../assets/images/checkPassport.jpg";
import { FormProvider, CTextArea } from "../Forms/Fields";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import {
  REJECT_WORK_PERMIT_DOCUMENT,
  VERIFY_EXPATRIATE_DOCUMENT,
} from "../../graph-query/mutation";
// import PDFDocumentViewer from "../PDFDocumentViewer";
import { useToaster } from "../../hooks/useToaster";
import { Document, Page } from "react-pdf";
import { useLocalStore } from "../../store";

const rejectSchema = yup
  .object({
    // reject: yup.boolean().required(),
    message: yup.string().min(5).required(),
  })
  .required();

const PDFFailed = () => {
  return (
    <div className="flex flex-col gap-5 h-full justify-center items-center">
      <p className="text-red-500 font-bold">Failed to load PDF file.</p>
      <button className="border-2 bg-primary p-3 text-white rounded-md">
        Referesh again
      </button>
    </div>
  );
};

const PdfView = ({ pdfData }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(5);

  /*When document gets loaded successfully*/
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  return (
    <Document
      className="w-full overflow-y-auto "
      file={pdfData}
      error={<PDFFailed />}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      <Page pageNumber={pageNumber} />
    </Document>
  );
};

const CheckDocumentModal = ({ data, handleClick, handleVerify, handleReject }) => {
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(rejectSchema),
  });

  const toast = useToaster();

  const { currentRole } = useLocalStore();

  const watchMessage = watch("message");

  return (
    <div className="h-screen w-full z-[60] bg-white/10 backdrop-blur-sm fixed flex items-center justify-center top-0">
      <div
        onClick={handleClick}
        className="absolute h-full w-full top-0 left-0 z-10"
      ></div>
      <div className="bg-white h-2/3 rounded-md overflow-hidden shadow w-3/4 2xl:w-3/5 flex gap-4 z-20">
        {data?.files?.split(".").pop() !== "pdf" ? (
          <img
            src={
              data?.files
                ? `"https://storagex-api.lmis.gov.et/ewp-storage/DEFAULT/"${data?.files?.replace(
                    /"/g,
                    ""
                  )}`
                : CheckPassport
            }
            className="w-3/5 h-full 2xl:h-[70vh] object-cover"
            alt=""
          />
        ) : (
          <PdfView
            pdfData={
              data?.files
                ? "https://storagex-api.lmis.gov.et/ewp-storage/DEFAULT/" +
                  data?.files
                : ""
            }
          />
        )}
        {currentRole !== "wp:emr:generator" && (
          <div className="py-4 pr-4 capitalize w-full flex flex-col justify-between">
            <div>
              <div className="flex items-center justify-between">
                <h3 className="  font-semibold text-N10">
                  {data?.document_type
                    ? data?.document_type?.file_type
                    : data?.files[0]?.name.split(".").pop()}
                </h3>
                <span className="text-sm text-N40">250kb</span>
              </div>

              <button
                disabled={data.review_status}
                onClick={handleVerify}
                className="disabled:opacity-50 bg-green shadow-lg py-2.5 w-full rounded-sm text-white"
              >
                {!data.review_status ? "verify" : "Already Verfied"}
              </button>
            </div>
            <div>
              <FormProvider onSubmithandler={handleSubmit(handleReject)}>
                {/* <div className="flex mb-4 items-center gap-2 text-N50 capitalize text-sm">
                  <CInputField
                    name="reject"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                    type="checkbox"
                    label="Reject this slip"
                  />
                </div> */}
                <div>
                  <CTextArea
                    name="message"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                    className="h-36 p-2 mb-4 bg-N99/70 w-full border-N95 rounded-sm focus:ring-0 focus:outline-none"
                    placeholder="Here the reason for the rejection"
                  ></CTextArea>
                  <div className="flex justify-center">
                    <button
                      disabled={watchMessage === ""}
                      type="submit"
                      className="bg-red40/70 py-2 w-full font-semibold text-white capitalize"
                    >
                      reject
                    </button>
                  </div>
                </div>
              </FormProvider>
            </div>
          </div>
        )}
      </div>
    </div>

    // <div className="fixed z-[60]  bg-white/10 h-screen backdrop-blur-sm w-full flex items-center justify-center top-0 inset-0 overflow-y-auto">
    //   <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    //     <div className="fixed inset-0 transition-opacity">
    //       <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
    //     </div>
    //     <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
    //       <div className="grid grid-cols-2 gap-6 h-full overflow-y-auto">
    //         <div className="bg-white rounded-md overflow-hidden">
    //           {data?.document_type?.file_type !== "pdf" ? (
    //             <img
    //               src={!data?.files[0] ? data?.files[0]?.path : CheckPassport}
    //               className="w-full h-full object-cover"
    //               alt=""
    //             />
    //           ) : (
    //             <PdfView className="w-full h-full" />
    //           )}
    //         </div>
    //         <div className="py-4 px-6">
    //           <div className="flex justify-between items-center">
    //             <h3 className="text-2xl font-semibold text-gray-800">
    //               {data?.document_type
    //                 ? data?.document_type?.file_type
    //                 : data?.files[0]?.name.split(".").pop()}
    //             </h3>
    //             <span className="text-sm text-gray-500">250kb</span>
    //           </div>
    //           <p className="mt-2 text-left text-sm text-gray-600">
    //             {data?.title}
    //           </p>
    //           <div className="mt-4">
    //             <button
    //               disabled={data.verified}
    //               onClick={handleVerify}
    //               className={`disabled:opacity-50 bg-green-500 py-2.5 px-6 text-white font-semibold rounded ${
    //                 data.verified ? "cursor-default" : ""
    //               }`}
    //             >
    //               {!data.verified ? "Verify" : "Verified"}
    //             </button>
    //           </div>
    //           <div className="mt-4">
    //             <FormProvider onSubmithandler={handleSubmit(handleReject)}>
    //               <div>
    //                 <CTextAreaField
    //                   name="message"
    //                   register={register}
    //                   errors={errors}
    //                   validation={{ required: true }}
    //                   className="h-36 p-2 mb-4 bg-gray-100 w-full border-gray-300 rounded-sm focus:ring-0 focus:outline-none"
    //                   placeholder="Enter the reason for the rejection"
    //                 />
    //                 <div className="flex justify-center">
    //                   <button
    //                     type="submit"
    //                     className="bg-red-500 hover:bg-red-600 text-white py-2 px-6 font-semibold rounded"
    //                   >
    //                     Reject
    //                   </button>
    //                 </div>
    //               </div>
    //             </FormProvider>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default CheckDocumentModal;
