import React, { useEffect, useRef } from "react";
import {
  MdArrowBack,
  MdEdit,
  MdEmail,
  MdFlag,
  MdHouse,
  MdPhone,
  MdWarning,
} from "react-icons/md";
import { useLocalStore } from "../../../store";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { RenewalRequestSchema } from "../../../utils/SchemaValidation";
import {
  BASE_REGION,
  BASE_SUBCITY,
  BASE_WOREDA,
  BASE_ZONE,
  GET_EXPATRIATE_BYID,
  GET_ORG_EXPATRIATE_BY_ID,
} from "../../../graph-query/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { BsCalendarDate, BsFillBuildingFill } from "react-icons/bs";
import InfoCard from "../../../components/cards/InfoCard";
import FileInput from "../../../components/Forms/Fields/FileInput";
import { toast } from "react-toastify";
import {
  INSERT_EXPATRIATE_DOC,
  INSERT_RENEWAL_PERMIT_REQUEST,
  UPLOAD_FILE,
} from "../../../graph-query/mutation";
import { getBase64 } from "../../../utils/utilityFunctions";
import { trackPromise } from "react-promise-tracker";
import { WORK_PERMIT_TYPES } from "../../../utils/constants";

const CancellationDetail = () => {
  const [files, setFiles] = useState([]);
  const [exptData, setExptData] = useState();

  const { id } = useParams();
  const router = useNavigate();

  const { permitPrice } = useLocalStore();

  const [create_permit, { loading, error }] = useMutation(
    INSERT_RENEWAL_PERMIT_REQUEST,
    {
      onError: (er) => {
        toast.error(er?.message);
      },
    }
  );
  const [getExpatData, {}] = useLazyQuery(GET_ORG_EXPATRIATE_BY_ID, {
    variables: {
      id: id,
    },
    onCompleted: (da) => {
      console.log(da?.expatriates);
      setExptData(da?.expatriates[0]);
    },
  });

  useEffect(() => {
    getExpatData();
  }, []);

  // ================================================
  const handleDelegation = (e) => {
    console.log("delegation file", e);
    if (e) {
      getBase64(e).then((result) => {
        let fileType = (e?.type).split("/").length
          ? (e?.type).split("/")[1]
          : "";
        setFiles((prevFiles) => [
          ...prevFiles,
          {
            file: result,
            document_type_id: "ad702374-171c-4d4b-b3f6-161170e3db0b",
            catagory: "bf8fad50-7453-4ff6-aa55-78b9553a4f37",
            title: "Letter of Delegation",
            doc_type: fileType,
          },
        ]);
      });
    }
  };
  const handlePassport = (e) => {
    console.log("passport file", e);
    if (e) {
      getBase64(e).then((result) => {
        let fileType = (e?.type).split("/").length
          ? (e?.type).split("/")[1]
          : "";
        setFiles((prevFiles) => [
          ...prevFiles,
          {
            file: result,
            document_type_id: "56de0cbc-7986-4411-8a35-aba426181a07",
            catagory: "51ff2b29-4d56-449c-a69d-08348d7299b4",
            title: "Passport",
            doc_type: fileType,
          },
        ]);
      });
    }
  };

  const handleClearance = (e) => {
    console.log("clearance file", e);
    if (e) {
      getBase64(e).then((result) => {
        let fileType = (e?.type).split("/").length
          ? (e?.type).split("/")[1]
          : "";

        setFiles((prevFiles) => [
          ...prevFiles,
          {
            file: result,
            document_type_id: "3604b7ce-81e2-404c-aace-06493721a7c1",
            catagory: "bf8fad50-7453-4ff6-aa55-78b9553a4f37",
            title: "Clearance Request Letter",
            doc_type: fileType,
          },
        ]);
      });
    }
  };

  const handlePermit = (e) => {
    console.log("permit file", e);
    if (e) {
      getBase64(e).then((result) => {
        let fileType = (e?.type).split("/").length
          ? (e?.type).split("/")[1]
          : "";

        setFiles((prevFiles) => [
          ...prevFiles,
          {
            file: result,
            document_type_id: "fd09a126-b28f-4aa8-9fae-91b36309d33f",
            catagory: "bf8fad50-7453-4ff6-aa55-78b9553a4f37",
            title: "Work Permit ID Card Copy",
            doc_type: fileType,
          },
        ]);
      });
    }
  };

  const handleBankSlip = (e) => {
    console.log("bank slip file", e);
    if (e) {
      getBase64(e).then((result) => {
        let fileType = e?.type.split("/").length ? e?.type.split("/")[1] : "";
        setFiles((prevFiles) => {
          // Check if the title already exists in the files array
          const existingFileIndex = prevFiles.findIndex(
            (file) => file.title === "Bank Slip"
          );
          if (existingFileIndex !== -1) {
            // Replace the existing file with the new file
            const updatedFiles = [...prevFiles];
            updatedFiles[existingFileIndex] = {
              file: result,
              document_type_id: "3aff76f9-f65a-4ee2-8a4e-9a551802ad26",
              catagory: "166d1c11-6ce1-41ce-87eb-b83bf8ade238",
              title: "Bank Slip",
              doc_type: fileType,
            };
            return updatedFiles;
          } else {
            // Add the new file if the title doesn't exist
            return [
              ...prevFiles,
              {
                file: result,
                document_type_id: "3aff76f9-f65a-4ee2-8a4e-9a551802ad26",
                catagory: "166d1c11-6ce1-41ce-87eb-b83bf8ade238",
                title: "Bank Slip",
                doc_type: fileType,
              },
            ];
          }
        });
      });
    }
  };

  const [fileUpload, { loading: uploadLoading }] = useMutation(UPLOAD_FILE);
  const [permitDocument, { loading: permitLoading }] = useMutation(
    INSERT_EXPATRIATE_DOC
  );

  const [renewalRequest, {}] = useMutation(INSERT_RENEWAL_PERMIT_REQUEST, {
    onCompleted: (d) => {
      toast.success("Renewal Application Requested Successfully!");
    },
  });

  const checkTitleContainsKeywords = (documents, title) => {
    const foundFile = documents.find((file) => {
      const titleLowercase = file.title.toLowerCase();
      return titleLowercase?.includes(title);
    });

    return foundFile !== undefined;
  };

  const uploadDocuments = (result, fileType, cb) => {
    trackPromise(
      fileUpload({
        variables: {
          extension: fileType,
          file: result,
        },
        onCompleted(data) {
          // console.log(data);
          cb && cb({ path: data.uploadFile.info.Key });
          // toast.success("Document Uploaded Successfully!");
        },
        onError(error) {},
      })
    );
  };

  const handleApplication = () => {
    let renewalFiles = [];
    const keywordsToCheck = [
      "Work Permit ID Card Copy",
      "Clearance Request Letter",
      "Letter of Delegation",
    ];
    const clearanceCheck = checkTitleContainsKeywords(
      files,
      "Clearance Request Letter"
    );
    const permitCheck = checkTitleContainsKeywords(
      files,
      "Work Permit ID Card Copy"
    );
    const delegCheck = checkTitleContainsKeywords(
      files,
      "Letter of Delegation"
    );
    // const bankCheck = checkTitleContainsKeywords(files, keywordsToCheck[2]);

    console.log(files);

    // if (!clearanceCheck) {
    //   toast.error("Please Attach Clearance Letter");
    // }
    // if (!permitCheck) {
    //   toast.error("Please Attach Work Permit ID ");
    // }
    // if (!delegCheck) {
    //   toast.error("Please Attach Delegation letter");
    // }
    // else {
    console.log(files);
    const uploadPromises = files?.map((i) => {
      return new Promise((resolve, reject) => {
        uploadDocuments(
          i?.file,
          i.doc_type,
          (filePath) => {
            renewalFiles.push({
              ...filePath,
              catagory: i?.catagory,
              document_type_id: i?.document_type_id,
              title: i.title,
            });
            resolve(filePath);
          },
          reject
        );
      });
    });
    console.log(exptData);
    Promise.all(uploadPromises).then(() => {
      create_permit({
        variables: {
          ...exptData.expatriate_work_permits[0],
          expatriate_id: id,
          allowance: exptData?.expatriate_work_permits[0]?.allowance,
          permit_type: WORK_PERMIT_TYPES.CANCELLATION,
          reference_number: "",
          employment_end_date: exptData?.expatriate_work_permits[0]?.end_date,
          employment_start_date:
            exptData?.expatriate_work_permits[0]?.start_date,
          salary: exptData?.expatriate_work_permits[0]?.salary,
          organization_id:
            exptData?.expatriate_work_permits[0]?.organization?.id,
          occupation_category_id:
            exptData?.expatriate_work_permits[0]?.occupation_category?.id,
          issued_date: exptData?.expatriate_work_permits[0]?.issued_date,
          expiry_date: exptData?.expatriate_work_permits[0]?.expiry_date,
          emr_number: exptData?.expatriate_work_permits[0]?.emr_number,
        },
        onCompleted: async (data) => {
          console.log("data__", data);
          await renewalFiles.map(async (i) => {
            console.log(i);
            await permitDocument({
              variables: {
                documentId: i?.document_type_id,
                expatriate_work_permit_id:
                  data?.insert_expatriate_work_permits?.returning[0]?.id,
                files: i?.path,
                properties: i,
                title: i?.title,
              },
            }).then(() => {
              toast.success("Cancellation Requested Successfully!");
              router("/organization/requested-work-permit");
            });
          });
        },
      });
    });
    // }
  };

  return (
    <>
      <div className="flex flex-wrap md:flex-nowrap gap-6">
        <div className="w-full md:w-2/3 space-y-6">
          <div className="bg-white shadow-sm p-4 rounded-md space-y-5">
            <button
              onClick={() => router(-1)}
              className="bg-gray px-3 py-2 border-2 rounded-md flex gap-2 items-center"
            >
              <MdArrowBack /> Back
            </button>
            <h2 className="text-primary capitalize font-semibold">
              Work Permit Details
            </h2>
            <div className="p-5">
              <div
                className="p-4 mb-4 text-sm text-red10 rounded-lg bg-red50"
                role="alert"
              >
                <span className="font-medium">Danger alert!</span> This notice
                is to inform you that the work permit for this expatriate is
                about to be canceled. Please take appropriate action to
                ascertain the current status and activities of the expatriate.
                <br />
                <span className="font-semibold mt-4">
                  {exptData?.expatriate_work_permits[0]?.emr_number}
                </span>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="w-full">
                  <h3>Attach Request Letter for clearance</h3>
                  <FileInput
                    onChange={(e) => handleClearance(e)}
                    enablePreview={true}
                    type={"file"}
                  />
                </div>
                <div>
                  <h3>Attach Work Permit ID and Immigration ID</h3>
                  <FileInput
                    onChange={(e) => handlePermit(e)}
                    enablePreview={true}
                    type={"file"}
                  />
                </div>
                <div>
                  <h3>Attach Letter of Delegation</h3>
                  <FileInput
                    onChange={(e) => handleDelegation(e)}
                    enablePreview={true}
                    type={"file"}
                  />
                </div>
                <div>
                  <h3>Attach Passport</h3>
                  <FileInput
                    onChange={(e) => handlePassport(e)}
                    enablePreview={true}
                    type={"file"}
                  />
                </div>
                <div className="col-span-2">
                  <h3>Attach Finance Slip</h3>
                  <FileInput
                    onChange={(e) => handleBankSlip(e)}
                    enablePreview={true}
                    type={"image"}
                  />
                </div>
              </div>
              {/* <div className="flex w-full mt-8">
                <div className="border-2 rounded-md shadow-xl p-5">
                  <div className="flex gap-4 text-primary font-semibold text-lg">
                    <MdWarning /> <span className="">Notice</span>
                  </div>
                  <p className="text-sm">
                    Before applying cancellation, the application provider shall
                    pay{" "}
                    <span className="text-lg font-semibold">
                      {
                        permitPrice
                          ?.filter(
                            (i) =>
                              i?.work_permit_type?.name ===
                              WORK_PERMIT_TYPES.CANCELLATION
                          )
                          .map((i) => i?.price)[0]
                      }{" "}
                      ETB
                    </span>
                  </p>
                  <div className="mt-4 flex gap-4 font-semibold text-lg text-primary">
                    <MdWarning /> All documents must be attached.
                  </div>
                </div>
              </div> */}
              {/* <div className="mt-4">
                <h3>Upload Bank Slip</h3>
                <FileInput
                  onChange={(e) => handleBankSlip(e)}
                  enablePreview={true}
                  type={"image"}
                />
              </div> */}

              <button
                onClick={handleApplication}
                type="button"
                className="mt-5 border-2 rounded-md bg-primary text-white text-xs px-4 py-2 hover:opacity-80 hover:shadow-md"
              >
                Request Cancellation Application
              </button>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/3 space-y-6">
          <div className="bg-white shadow-sm p-4 rounded-md">
            <div className="flex flex-col gap-3 items-center -translate-y-1/3">
              <div class="relative inline-flex items-center justify-center w-20 2xl:w-24 h-20 2xl:h-24 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                <span class="font-medium text-gray-600 dark:text-gray-300 text-3xl">
                  {exptData?.first_name?.substring(0, 1) +
                    exptData?.father_name?.substring(0, 1)}
                </span>
              </div>

              <div className=" space-y-1">
                <h3 className="font-semibold text-N20">
                  {exptData?.first_name + " " + exptData?.father_name}
                </h3>
                <p className="text-N50 text-sm text-center">
                  {(exptData?.expatriate_work_permits &&
                    exptData?.expatriate_work_permits[0]
                      ?.expatriate_work_permit_type?.name) ||
                    "New"}{" "}
                  Application
                </p>
              </div>
            </div>
            <div className=" space-y-2 -mt-6 pb-8 border-b border-b-N95 mb-6">
              <InfoCard title="email" email={exptData?.email}>
                <MdEmail />
              </InfoCard>
              <InfoCard title="phone number" email={exptData?.phone_number}>
                <MdPhone />
              </InfoCard>
              <InfoCard title="gender" email={exptData?.genderByGender?.name}>
                <MdPhone />
              </InfoCard>
              <InfoCard title="date of birth" email={exptData?.date_of_birth}>
                <MdHouse />
              </InfoCard>
              <InfoCard
                title="maritial status"
                email={exptData?.maritalStatusByMaritalStatus?.name}
              >
                <MdFlag />
              </InfoCard>
              <InfoCard
                title="passport number"
                email={exptData?.passport_number}
              >
                <MdEmail />
              </InfoCard>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white mt-10"></div>
    </>
  );
};

export default CancellationDetail;
