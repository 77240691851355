import React, { Children } from "react";

const StatusTags = ({ status, children }) => {
  return (
    <span
      className={`p-1 rounded-md flex items-center justify-center w-min mx-auto capitalize text-sm gap-1 ${
        status === "pending" && "border-yellow bg-yellow/10 text-yellow-800"
      } ${status === "verified" && "border-green bg-green/10 text-green"} ${
        status === "rejected" && "border-red bg-red/10 text-red"
      } ${
        status === "uploaded" && "border-primary bg-Prime99/80 text-primary"
      }`}
    >
      {children}
      <p>{status}</p>
    </span>
  );
};

export default StatusTags;
