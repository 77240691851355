export const roles = {
  WP_ORGANIZATION: "wp:organization",
  WP_DESK_MANAGER: "wp:desk:manager",
  WP_DESK_HANDLER: "wp:desk:handler",
  WP_DOC_CHECKER: "wp:doc:checker",
  WP_SYS_ADMIN: "wp:sys:admin",
  WP_REG_ADMIN: "wp:reg:admin",
  WP_EMR_GENERATOR: "wp:emr:generator",
  WP_FINANCE: "wp:finance",
  WP_ARCHIVE_OPERATOR: "wp:arc:operator",
  WP_DEP_MINISTER: "wp:dep:minister",
  WP_OPERATION: "wp:operation",
};
