import { isString } from "lodash";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
// utils
import { fData } from "../../utils/utilityFunctions";
//
import UploadIllustration from "../../assets/illustration_upload";
import { FaFilePdf, FaFileUpload } from "react-icons/fa";
import { BiCloudUpload } from "react-icons/bi";
import { useEffect, useState } from "react";
import { AiTwotoneDelete } from "react-icons/ai";
import { CiShare1 } from "react-icons/ci";
import { toast } from "react-toastify";

// ----------------------------------------------------------------------

UploadSingleFile.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  sx: PropTypes.object,
};

export default function UploadSingleFile({
  onChange,
  type,
  defaultValue,
}) {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    console.log("file default:", defaultValue);
    if (defaultValue) {
      setFile(defaultValue);
      setPreview(URL.createObjectURL(defaultValue));
    }
  }, [defaultValue]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    // console.log("selected",file);
    if (file) {
      const allowedTypes =
        type === "image" ? ["image/png", "image/jpeg"] : ["application/pdf"];

      const maxSize = 1 * 1024 * 1024; // 1 MB in bytes

      if (file.size > maxSize) {
        toast.warn("File size exceeds the limit of 1 MB!");
        return;
      }
      if (allowedTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result);
        };
        reader.readAsDataURL(file);
        onChange(file);
      } else {
        toast.warn(
          "Only " +
            (type === "image" ? "PNG or JPEG images" : "PDF files") +
            " are allowed!"
        );
      }
    } else {
      setPreview(null);
      setPreview(null);
      onChange(null);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setPreview(null);
    onChange(null);
  };

  const openFileInNewTab = () => {
    window.open(preview, "_blank");
    return;
  };

  return (
    <div className="w-full relative overflow-hidden border border-gray-50 !h-[200px] bg-gray-100">
      <div
        className={`${
          file ? "py-12 w-full" : ""
        } w-full h-full text-center cursor-pointer flex flex-col gap-2 justify-center overflow-hidden border-dashed border-2 items-center`}
      >
        <div
          className={`flex justify-center p-4 flex-col w-full items-center`}
        >
          <input
            type="file"
            accept={`${
              type === "image" ? "image/png, image/jpeg" : "application/pdf"
            }`}
            onChange={handleFileChange}
            className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
          />

          <BiCloudUpload width={64} height={52} className="scale-150" />

          <div className="p-3 md:ml-2">
            <h5>{!file ? 'Browse or Select file' : 'File Selected'}</h5>

            {!file && <p className="text-gray-600">
              Browse files here or click&nbsp;
              <span className="text-primary decoration-slice">browse</span>
              &nbsp;thorough your machine
            </p>}
          </div>
        </div>

        <div
          className={`${
            file ? "h-auto p-3 w-full" : "!bg-transparent max-h-none !border-transparent"
          } border flex items-center rounded-sm bg-white px-4 w-[90%]`}
        >
          {file && (
            <div className="relative w-full h-auto flex items-center">
              <>
                {file.type === "application/pdf" ? (
                  <FaFilePdf
                    onClick={openFileInNewTab}
                    className="text-4xl text-gray-400"
                  />
                ) : (
                  <img
                    alt="file preview"
                    src={isString(file) ? file : file?.preview}
                    className="absolute top-2 rounded-sm object-cover w-(calc(100% - 16px)) h-(calc(100% - 16px))"
                  />
                )}
              </>
              <div className="ml-2 flex flex-col text-start">
                <span className="text-xs">{file?.name}</span>
                <span className="text-xs">{file?.type?.includes('application') ? 'PDF' : 'Image'}</span>
              </div>
              <div className="absolute top-2 right-4">
                <CiShare1 onClick={openFileInNewTab} /> 
                <AiTwotoneDelete onClick={handleRemoveFile} /> 
              </div>
            </div>

          )}
        </div>
      </div>

      {/* {fileRejections.length > 0 && <ShowRejectionItems />} */}
    </div>
  );
}
