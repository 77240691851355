import React from "react";
// import EmployerData from "../components/Forms/EmployerData";
import Navbar from "../components/common/Navbar";
import Avatar from "../assets/images/avatar.jpg";
import { Link } from "react-router-dom";

const EmployerProfile = () => {
  return (
    <>
      <div>
        <Navbar custom={"bg-[#e5edf5]"}>
          <Link
            to={"/employer-profile"}
            className="cursor-pointer flex items-center gap-4 px-4 py-2 bg-white rounded-md"
          >
            <div>
              <h3 className="font-medium">john doe</h3>
              <p className="text-xs text-N50">johndoe@mymail.com</p>
            </div>
            <img
              src={Avatar}
              alt="avatar"
              className="w-12 h-12 rounded-full object-cover"
            />
          </Link>
        </Navbar>
        <div className="w-full flex flex-col justify-center layout mt-10">
          <div className="w-full bg-white rounded-t-xl p-6">
            <p className="text-xl font-medium text-primary">Employer Data</p>
            <p className="text-xs font-light text-primary">
              Please fill in the form below
            </p>

            <div className="w-full py-6">
              {/* <EmployerData /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployerProfile;
