import React from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { BiTime } from "react-icons/bi";
import { FaFilePdf } from "react-icons/fa";
import { GiCancel } from "react-icons/gi";

const WorkPermitCard = ({ title, status, path, note }) => {
  return (
    <a
      href={`https://storagex-api.lmis.gov.et/ewp-storage/DEFAULT/${path}`}
      target="_blank"
      className="bg-white p-3 rounded shadow flex items-center gap-2"
    >
      <FaFilePdf className="text-4xl text-N60" />
      <div>
        <h2 className="text-sm font-medium capitalize">{title}</h2>
        <div
          className={`${
            status === "Pending"
              ? "text-yellow50"
              : status === "Verified"
              ? "text-green50"
              : "text-red50"
          } flex items-center gap-q text-xs`}
        >
          {status === "Verified" ? (
            <AiOutlineCheck />
          ) : status === "Rejected" ? (
            <GiCancel />
          ) : (
            <BiTime />
          )}
          <p>{status}</p>
        </div>
        {status === "Rejected" && (
          <small className="text-red text-xs">{note}</small>
        )}
      </div>
    </a>
  );
};

export default WorkPermitCard;
