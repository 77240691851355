import React, { useMemo, useState } from "react";
import { useTable, useExpanded, usePagination, useFilters } from "react-table";
// import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import NoImage from "../assets/no-data.png";
// import { classNames } from "../utils";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Table({
  columns,
  data,
  loading,
  error,
  renderRowSubComponent,
  pageCount: controlledPageCount,
  fetchData,
  title,
  headerClassess,
}) {
  const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);
  const [expand, setExpanded] = useState(false);
  const [dropDoc, setDropDownDoc] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount: tablePageCount,
    visibleColumns,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, expanded },
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageIndex: 0 },
      defaultColumn,
      manualPagination: true,
      pageCount: controlledPageCount,
      manualFilters: true,
    },
    useFilters,
    useExpanded,
    usePagination
  );

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
  };

  const handlePageChange = (e) => {
    const page = e.target.value ? Number(e.target.value) - 1 : 0;
    gotoPage(page);
  };

  const handlePreviousPageClick = () => {
    previousPage();
  };

  const handleNextPageClick = () => {
    nextPage();
  };

  const toggleExpander = (e) => {
    console.log("clicked!", e);
    setDropDownDoc(e?.original?.work_permit_request_documents);
    if (!expand) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  };

  const renderError = () => {
    return (
      <div className="flex items-center justify-center h-32">
        <p className="text-gray-400">{error.message}</p>
      </div>
    );
  };

  const renderNoData = () => {
    return (
      <div className="flex flex-col items-center justify-center h-auto overflow-hidden">
        <img src={NoImage} alt="no data" className="h-72 w-1/2 object-cover" />
        <p className="mt-5 font-display text-lg text-center md:text-xl font-extrabold leading-[1.15] text-black sm:text-2xl sm:leading-[1.15]">
          No {title} available
        </p>
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className="flex items-center justify-center h-32">
        <svg className="animate-spin h-6 w-6 text-gray-400" viewBox="0 0 24 24">
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM20 12a8 8 0 01-8 8v-4c2.485 0 4.746-1.066 6.324-2.764l1.414 1.414A9.969 9.969 0 0020 12zm-6.324-5.236l-1.414-1.414A9.969 9.969 0 0012 4V8a7.962 7.962 0 012 5.291z"
          />
        </svg>
      </div>
    );
  };

  const handleSearch = (e) => {
    console.log(e);
    try {
      setSearchQuery(e);
      // Filter the data based on the search query
      const filtered = data?.filter((item) =>
        // console.log(item)
        item?.expatriate?.first_name?.toLowerCase()?.includes(e?.toLowerCase())
      );

      setFilteredData(filtered);
      console.log("====================================");
      console.log(searchQuery, filteredData);
      console.log("====================================");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        {error ? (
          renderError()
        ) : loading ? (
          renderLoading()
        ) : data.length === 0 ? (
          renderNoData()
        ) : (
          <div className="shadow border overflow-x-auto border-b border-gray-200 sm:rounded-lg">
            <div
              className={`px-5 py-5 flex items-center justify-between pb-4 ${headerClassess}`}
            >
              <div>
                <p className="text-white">{title && title}</p>
              </div>
              <label htmlFor="table-search" class="sr-only">
                Search
              </label>
              <div class="relative">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    class="w-5 h-5 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  id="table-search"
                  onChange={(e) => handleSearch(e.target.value)}
                  class="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search for items"
                />
              </div>
            </div>
            <table
              {...getTableProps()}
              className="w-full text-sm text-left text-gray-500"
            >
              <thead className="text-xs text-black uppercase bg-gray-50">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        // {...column.getSortByToggleProps()}
                      >
                        <div className="flex items-center">
                          <span>{column.render("Header")}</span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {getTableBodyProps() ? (
                <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <React.Fragment {...row.getRowProps()}>
                        <tr
                          className="bg-white border-b dark:border-gray-100 dark:hover:bg-gray-200 dark:hover:text-black"
                          {...row.getRowProps()}
                        >
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                        {row?.isExpanded ? (
                          <tr>
                            <td colSpan={visibleColumns.length}>
                              {renderRowSubComponent({ row })}
                            </td>
                          </tr>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              ) : (
                "No Data"
              )}
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  const onChange = (event) => {
    setFilter(event.target.value || undefined);
  };

  return (
    <input
      type="text"
      // placeholder={Search ${count} records...}
      value={filterValue || ""}
      onChange={onChange}
      classNames="block w-full sm:text-sm border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
    />
  );
}

export default Table;
