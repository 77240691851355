import React, { useEffect, useState } from "react";
import FormArea from "../../../components/common/FormArea";
import ApplicantStepper from "../../../components/common/ApplicantStepper";
import SideNav from "../../../components/Navbar/SideNav";
import Nav from "../../../components/Navbar/Navbar";
import { useLocalStore } from "../../../store";
import avatar from "../../../assets/images/avatar.jpg";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  BASE_DOCUMENT_TYPES,
  BASE_GENDER,
  BASE_MARTIAL_STATUS,
  BASE_NATIONALITY,
  BASE_OCCUPATION_CATEGORIES,
  BASE_ORGANIZATIOIN_TYPE,
  BASE_PROFESSIONAL_TITLE,
  BASE_QUALIFICATION_LEVEL,
  BASE_QUALIFICATION_TYPES,
  BASE_REGION,
  BASE_SECTORS,
  BASE_SERVICE_TYPE,
  BASE_VISA_TYPES,
  BASE_WAY_OF_ENTER,
  GET_ORGANIZATION,
  PERMIT_PRICE,
} from "../../../graph-query/queries";
import { useNavigate } from "react-router-dom";
import { WORK_PERMIT_TYPES, WorkPermitForms } from "../../../utils/constants";
import NoticeCard from "../../../components/cards/NoticeCard";
import {
  MdArrowUpward,
  MdHomeWork,
  MdModelTraining,
  MdOutlineWork,
} from "react-icons/md";
import {
  INSERT_EDUCATION_BACKGROUND,
  INSERT_EXPATERIATE_WORK_PLACE,
  INSERT_EXPATRIATE_DOC,
  INSERT_EXPATRIATE_REQUEST,
  INSERT_WORK_COUNTER_PART,
  INSERT_WORK_EXPERIENCE,
  UPLOAD_FILE,
} from "../../../graph-query/mutation";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import useStepperFormStore from "../../../store/formStore";
import FormAreaRenewal from "../../../components/common/FormAreaRenewal";
import { Bs1Square, BsFileEarmarkPlay, BsFillPersonFill } from "react-icons/bs";
import { FaGraduationCap, FaMoneyCheckAlt } from "react-icons/fa";
import { GiSkills } from "react-icons/gi";
import { deleteDB, openDB } from "idb";

const steps = [
  {
    icon: <Bs1Square />,
    label: "EMR Number",
  },
  {
    icon: <BsFillPersonFill />,
    label: "General Information",
  },
  {
    icon: <BsFileEarmarkPlay />,
    label: "Employement Information",
  },
  {
    icon: <FaGraduationCap />,
    label: "Educational Information",
  },
  {
    icon: <MdOutlineWork />,
    label: "Work Experience",
  },
  {
    icon: <MdHomeWork />,
    label: "Work Place",
  },
  {
    icon: <MdModelTraining />,
    label: "Counterpart",
  },
  {
    icon: <GiSkills />,
    label: "Skills",
  },
  {
    icon: <FaMoneyCheckAlt />,
    label: "Finance",
  },
];

const RequestRenewalApplication = () => {
  const [acceptNotice, setAcceptNotice] = useState(false);
  const [tempFile, setTempFile] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [fileUploadedFile, setFileUploadedFile] = useState([]);

  const [activeStep, setActiveStep] = useState(WorkPermitForms.GENERAL_INFORMATION);

  const router = useNavigate();

  // currentRole,
  // user,
  const {
    // data,
    // getData,
    sectors,
    regions,
    userOrganizationData,
    organizationType,
    professionalTitles,
    qualificationLevels,
    qualificationTypes,
    occupationCatagory,
    documentTypes,
    nationality,
    martialTypes,
    visaTypes,
    expatriateWayEntered,
    genders,
    permitPrice,
    setData,
    serviceType,
    setServiceType,
    setGenders,
    setSectors,
    setRegions,
    setOrganizationType,
    setProfessionalTitles,
    setQualificationTypes,
    setQualificationLevels,
    setNationality,
    setOccupationCatagory,
    setDocumentTypes,
    setMartialTypes,
    setPermitPrice,
    setVisaTypes,
    setExpatriateWayEntered,
    setUserOrganizationData,
  } = useLocalStore();

  const { data, fetchDataFromIndexedDB, setLoadingRenewal, recreateRenewal, clearIndexedDB, saveFormDataRenewal } =
    useStepperFormStore();

  const [getUserData, { error: userErr }] = useLazyQuery(GET_ORGANIZATION, {
    variables: { eq: JSON.parse(localStorage.getItem("work-permit"))?.userID },
    context: {
      headers: {
        "Content-Type": "application/json",
      },
    },
    onCompleted: (data) => {
      setUserOrganizationData(data?.organizations);
    },
  });

  const [getSectors, {}] = useLazyQuery(BASE_SECTORS, {
    onCompleted: (data) => {
      console.log("sector data:", data?.base_sectors);
      setSectors(data?.base_sectors);
    },
  });

  const [getRegions, {}] = useLazyQuery(BASE_REGION, {
    onCompleted: (data) => {
      console.log("regions data:", data?.base_regions);
      setRegions(data?.base_regions);
    },
  });

  const [getOrgType, {}] = useLazyQuery(BASE_ORGANIZATIOIN_TYPE, {
    onCompleted: (data) => {
      console.log("organization type data:", data?.base_organization_types);
      setOrganizationType(data?.base_organization_types);
    },
  });

  const [getProfessionalTitles, {}] = useLazyQuery(BASE_PROFESSIONAL_TITLE, {
    onCompleted: (data) => {
      console.log("professional title data:", data?.base_profession_titles);
      setProfessionalTitles(data?.base_profession_titles);
    },
  });

  const [getQualificationType, {}] = useLazyQuery(BASE_QUALIFICATION_TYPES, {
    onCompleted: (data) => {
      console.log("qualification types data:", data?.base_qualification_types);
      setQualificationTypes(data?.base_qualification_types);
    },
  });

  const [getQualificationLevel, {}] = useLazyQuery(BASE_QUALIFICATION_LEVEL, {
    onCompleted: (data) => {
      console.log("qualification level data:", data?.base_qualification_levels);
      setQualificationLevels(data?.base_qualification_levels);
    },
  });

  const [getNationality, {}] = useLazyQuery(BASE_NATIONALITY, {
    onCompleted: (data) => {
      // console.log("nationality data:", data?.base_nationalities);
      setNationality(data?.base_nationalities);
    },
  });

  const [getOccupation, {}] = useLazyQuery(BASE_OCCUPATION_CATEGORIES, {
    onCompleted: (data) => {
      console.log("occupation catagory:", data?.base_occupation_categories);
      setOccupationCatagory(data?.base_occupation_categories);
    },
  });

  const [getDocumentTypes, {}] = useLazyQuery(BASE_DOCUMENT_TYPES, {
    onCompleted: (data) => {
      console.log("document types:", data?.base_document_types);
      setDocumentTypes(data?.base_document_types);
    },
  });

  const [getMartialStatus, {}] = useLazyQuery(BASE_MARTIAL_STATUS, {
    onCompleted: (data) => {
      console.log("martial status:", data?.base_marital_statuses);
      setMartialTypes(data?.base_marital_statuses);
    },
  });

  const [getVisaTypes, {}] = useLazyQuery(BASE_VISA_TYPES, {
    onCompleted: (data) => {
      console.log("visa types:", data?.base_visa_types);
      setVisaTypes(data?.base_visa_types);
    },
  });

  const [getWayOfEnter, {}] = useLazyQuery(BASE_WAY_OF_ENTER, {
    onCompleted: (data) => {
      console.log("way of visa enter:", data?.base_way_of_entries);
      setExpatriateWayEntered(data?.base_way_of_entries);
    },
  });

  const [getGender, {}] = useLazyQuery(BASE_GENDER, {
    onCompleted: (d) => {
      setGenders(d?.base_genders);
    },
  });

  const [getTypeOfService, {}] = useLazyQuery(BASE_SERVICE_TYPE, {
    onCompleted: (d) => {
      setServiceType(d?.base_service_types);
    },
  });

  const [getPermitPrice, {}] = useLazyQuery(PERMIT_PRICE, {
    onCompleted: (d) => {
      setPermitPrice(d?.base_expatriate_work_permit_prices || []);
    },
  });

  useEffect(() => {
    if (sectors?.length === 0) {
      getSectors();
    }
  }, [sectors, setSectors]);

  useEffect(() => {
    if (regions?.length === 0) {
      getRegions();
    }
  }, [regions, setRegions]);

  useEffect(() => {
    if (organizationType?.length === 0) {
      getOrgType();
    }
  }, [organizationType, setOrganizationType]);

  useEffect(() => {
    if (professionalTitles?.length === 0) {
      getProfessionalTitles();
    }
  }, [professionalTitles, setProfessionalTitles]);

  useEffect(() => {
    if (qualificationLevels?.length === 0) {
      getQualificationLevel();
    }
  }, [qualificationLevels, setQualificationLevels]);

  useEffect(() => {
    if (qualificationTypes?.length === 0) {
      getQualificationType();
    }
  }, [qualificationTypes, setQualificationTypes]);

  useEffect(() => {
    if (nationality?.length === 0) {
      getNationality();
    }
  }, [nationality, setNationality]);

  useEffect(() => {
    if (occupationCatagory?.length === 0) {
      getOccupation();
    }
  }, [occupationCatagory, setOccupationCatagory]);

  useEffect(() => {
    if (documentTypes?.length === 0) {
      getDocumentTypes();
    }
  }, [documentTypes, setDocumentTypes]);

  useEffect(() => {
    if (martialTypes?.length === 0) {
      getMartialStatus();
    }
  }, [martialTypes, setMartialTypes]);

  useEffect(() => {
    if (visaTypes?.length === 0) {
      getVisaTypes();
    }
  }, [visaTypes, setVisaTypes]);

  useEffect(() => {
    if (expatriateWayEntered?.length === 0) {
      getWayOfEnter();
    }
  }, [expatriateWayEntered, setExpatriateWayEntered]);

  useEffect(() => {
    if (genders?.length === 0) {
      getGender();
    }
  }, [genders, setGenders]);

  useEffect(() => {
    if (serviceType?.length === 0) {
      getTypeOfService();
    }
  }, [serviceType, setServiceType]);

  useEffect(() => {
    if (userOrganizationData?.length === 0) {
      getUserData();
    }
  }, [userOrganizationData, setUserOrganizationData]);

  useEffect(() => {
    if (permitPrice?.length === 0) {
      getPermitPrice();
    }
  }, [permitPrice, setPermitPrice]);

  // ======================================================================
  const [fileUpload, { loading: uploadLoading }] = useMutation(UPLOAD_FILE);
  const [createCounterPart, {}] = useMutation(INSERT_WORK_COUNTER_PART);

  const [create_expatriate_document, {}] = useMutation(INSERT_EXPATRIATE_DOC, {
    onError: (err) => console.log(err),
  });

  const [create_work_experience, {}] = useMutation(INSERT_WORK_EXPERIENCE, {
    onError: (e) => {
      console.log("exp: ", e);
    },
  });

  const [createEducation, {}] = useMutation(INSERT_EDUCATION_BACKGROUND, {
    onError: (e) => {
      console.log("exp: ", e);
    },
  });

  const [create_expatriate_work_place, {}] = useMutation(
    INSERT_EXPATERIATE_WORK_PLACE,
    {
      onError: (er) => toast.error("problem occurs while creating work place"),
    }
  );

  const [create_expatriate, { loading, error }] = useMutation(
    INSERT_EXPATRIATE_REQUEST,
    {
      onError: (er) => {
        // toast.error(er?.message);
        console.log(er);
      },
    }
  );

  // load cache data from localstorage
  useEffect(() => {
    fetchDataFromIndexedDB("renewal_permit");
    // if (data) {
    let _final = Object?.keys(data);
    // console.log("_final:", _final);
    // console.log("___:", Object.keys(data?.emr));
    // console.log("___:", _final[_final?.length - 1]);

    switch (_final[_final?.length - 1]) {
      case "emr":
        if (Object?.keys("emr")?.length > 0) {
          setActiveStep(WorkPermitForms.EMR);
        }
        break;
      case "general_information":
        if (Object?.keys("general_information")?.length > 0) {
          setActiveStep(WorkPermitForms.GENERAL_INFORMATION);
        }
        break;
      case "employement_information":
        if (Object?.keys("employement_information")?.length > 0) {
          setActiveStep(WorkPermitForms.EMPLOYEE_INFORMATION);
        }
        break;
      case "educational_information":
        if (Object?.keys("educational_information")?.length > 0) {
          setActiveStep(WorkPermitForms.EDUCATIONAL_INFORMATION);
        }
        break;
      case "work_experience":
        if (Object?.keys("work_experience")?.length > 0) {
          setActiveStep(WorkPermitForms.WORK_EXPREIENCE);
        }
        break;
      case "work_place":
        if (Object?.keys("work_place")?.length > 0) {
          setActiveStep(WorkPermitForms.WORK_PLACE);
        }
        break;
      case "counterpart":
        if (Object?.keys("counterpart")?.length > 0) {
          setActiveStep(WorkPermitForms.COUNTER_PART);
        }
        break;
      case "skills":
        if (Object?.keys("skills")?.length > 0) {
          setActiveStep(WorkPermitForms.SKILLS);
        }
        break;
      case "finance":
        if (Object?.keys("finance")?.length > 0) {
          setActiveStep(WorkPermitForms.FINANCE);
        }
        break;

      default:
        setActiveStep(WorkPermitForms.EMR);
        break;
    }
    // }
    // console.log("DB", data);
  }, []);
  /**
   * @description Handle Move to next page of stepper
   * @returns
   */
  const moveToNext = () => {
    switch (activeStep) {
      case WorkPermitForms.EMR:
        setActiveStep(WorkPermitForms.GENERAL_INFORMATION);
        break;
      case WorkPermitForms.GENERAL_INFORMATION:
        setActiveStep(WorkPermitForms.EMPLOYEE_INFORMATION);
        break;
      case WorkPermitForms.EMPLOYEE_INFORMATION:
        setActiveStep(WorkPermitForms.EDUCATIONAL_INFORMATION);
        break;
      case WorkPermitForms.EDUCATIONAL_INFORMATION:
        setActiveStep(WorkPermitForms.WORK_EXPREIENCE);
        break;
      case WorkPermitForms.WORK_EXPREIENCE:
        setActiveStep(WorkPermitForms.WORK_PLACE);
        break;
      case WorkPermitForms.WORK_PLACE:
        setActiveStep(WorkPermitForms.COUNTER_PART);
        break;
      case WorkPermitForms.COUNTER_PART:
        setActiveStep(WorkPermitForms.SKILLS);
        break;
      case WorkPermitForms.SKILLS:
        setActiveStep(WorkPermitForms.FINANCE);
        break;
      case WorkPermitForms.FINANCE:
        setActiveStep(WorkPermitForms.FINAL);
        break;
      // case WorkPermitForms.FINAL:
      //   setActiveStep(WorkPermitForms.FINAL);
      //   break;
      default:
        return;
    }
  };

  const recreateIndexedDBRenewal = async () => {
    try {
      const DB_NAME = "renewal_permit";
      // Manually delete the existing database
      await deleteDB(DB_NAME);
  
      // Increment the database version
      const dbVersion = 1;
  
      const getStoreNames = () => {
        return [
          "emr",
          "general_information",
          "employement_information",
          "educational_information",
          "work_experience",
          "work_place",
          "counterpart",
          "skills",
          "finance",
        ];
      };
  
      const storeNames = getStoreNames();
  
      const db = await openDB(DB_NAME, dbVersion, {
        upgrade(db) {
          for (const name of storeNames) {
            if (!db.objectStoreNames.contains(name)) {
              db.createObjectStore(name);
            }
          }
        },
      });
  
      console.log("IndexedDB database recreated:", db);
    } catch (error) {
      console.error("Error recreating IndexedDB:", error);
      // Handle the error appropriately
    }
  };
  
  const handleRenewalFormSubmit = async (nameK, e) => {
    if (nameK === "final") {
      console.log("final clicked and process data...");
      fetchDataFromIndexedDB("renewal_permit");
      handleRequestRenewalApplication();
      return;
    } else {
      await saveFormDataRenewal("renewal_permit", { [nameK]: e })
        .then((re) => {
          console.log("savefromData:", re);
          moveToNext();
        })
        .catch(async (er) => {
          setLoadingRenewal(false);
          recreateIndexedDBRenewal();
          // deleteDB("renewal_permit");
          // indexedDB.deleteDatabase("renewal_permit");
          // await clearIndexedDB("renewal_permit");
          // toast.warn("try submitting again!");
          console.log("error____", er);
        });
    }

    // grand_father_name:
    //       data?.grand_father_name === "" ? null : data?.grand_father_name,
    //     organization_id: JSON.parse(localStorage.getItem("currentOrg"))?.id,
    //     created_by: JSON.parse(localStorage.getItem("work-permit"))?.userID,
    //     permit_type: WORK_PERMIT_TYPES.NEW,
    //     ...d,
    //     occupation_category_id: data?.occupation_category_id,
  };

  const uploadFiles = async (files) => {
    console.log("uploadFiles:", files);
    const uploadPromises = files.map(async (item) => {
      try {
        const { fileType, file } = item;
        const { data } = await fileUpload({
          variables: { extension: fileType, file },
        });
        return {
          path: data.uploadFile.info.Key,
          document_type_id: item?.document_type_id,
          catagory: item?.catagory,
          title: item?.title,
          name: item?.name,
        };
      } catch (error) {
        console.error("Document upload failed:", error);
        return null;
      }
    });

    const uploadedFiles = await Promise.all(uploadPromises);
    return uploadedFiles.filter((file) => file !== null);
  };

  const handleRequestRenewalApplication = () => {
    console.log("submitting form....", data);
    const general = data?.general_information;
    const skills = data?.skills;
    const finance = data?.finance;
    const workExp = data?.work_experience;
    const counterP = data?.counterpart;
    const employment = data?.employement_information;
    const education = data?.educational_information;
    const workP = data?.work_place;
    const emr = data?.emr;

    // Upload Documents First
    // filesUpload(file, (filePath) =>
    //   setTempFile((prevFiles) => [
    //     ...prevFiles,
    //     {
    //       ...filePath,
    //       document_type_id: "cde354c9-bece-43f2-9739-72e0c7892553",
    //       catagory: "9169dfa0-5c5d-4e89-a7bc-ccbcb5a52ff3",
    //       title: "Profile Picture",
    //     },
    //   ])
    // );
    if (general && Object?.keys(general)?.length === 0) {
      return toast.error("General Information should be filled!");
    }
    if (finance && Object?.keys(finance)?.length === 0) {
      return toast.error("Finance should be filled!");
    }
    if (employment && Object?.keys(employment)?.length === 0) {
      return toast.error("Employment Information Should be Filled!");
    } else {
      var alluploadedFiles = [];
      const uploadFileData = async () => {
        const _filesToUpload = [
          ...(general?.expatriate_work_permit_documents || []),
          employment?.contract,
          ...(finance?.expatriate_work_permit_documents || []),
          ...(workExp?.expatriate_work_permit_documents || []),
          ...(education?.expatriate_work_permit_documents || []),
        ];

        try {
          const uploadedFiles = await uploadFiles(_filesToUpload);

          setFileUploadedFile((prevFiles) => [...prevFiles, ...uploadedFiles]);
          alluploadedFiles.push([...uploadedFiles]);
        } catch (error) {
          console.error("File upload failed:", error);
        }
      };

      trackPromise(
        uploadFileData().then(() => {
          create_expatriate({
            variables: {
              ...general,
              ...employment,
              date_of_birth: general?.date_of_birth,
              skills: skills?.skills,
              first_name: general?.first_name,
              father_name: general?.father_name,
              grand_father_name:
                general?.grand_father_name === ""
                  ? null
                  : general?.grand_father_name,
              passport_number: general?.passport_number,
              visa_number: general?.visa_number,
              gender_id: general?.gender,
              marital_status_id: general?.marital_status,
              nationality_id: general?.nationality_id,
              profession_title_id: employment?.profession_title_id,
              visa_type_id: general?.visa_type,
              way_of_entry_id: general?.way_of_entry,
              employment_date: employment?.employment_start_date,
              employment_end_date: employment?.employment_end_date,
              salary: employment?.salary,
              allowance: employment?.allowance,
              occupation_category_id: employment?.occupation_category_id,
              passport_issued_date: general?.passport_issued_date,
              passport_expiry_date: general?.passport_expiry_date,
              visa_expiry_date: general?.visa_expiry_date,
              visa_issued_date: general?.visa_issued_date,
              reference_number: finance.reference_number,
              permit_type: WORK_PERMIT_TYPES.RENEWAL,
              emr_number: emr?.emr_number,
              organization_id: JSON.parse(localStorage.getItem("currentOrg"))
                ?.currentOrg?.id,
            },
            onCompleted: async (D) => {
              console.log("all files: ", alluploadedFiles);
              await alluploadedFiles[0]?.map(async (item) => {
                await create_expatriate_document({
                  variables: {
                    documentId: item?.document_type_id,
                    expatriate_work_permit_id:
                      D?.insert_expatriates?.returning[0]
                        ?.expatriate_work_permits[0]?.id,
                    files: item?.path,
                    properties: item,
                    title: item?.title,
                  },
                });
              });
              console.log("file uploaded data: ", fileUploadedFile);
              // await fileUploadedFile?.map(async (item) => {
              //   await create_expatriate_document({
              //     variables: {
              //       documentId: item?.document_type_id,
              //       expatriate_work_permit_id:
              //         D?.insert_expatriates?.returning[0]
              //           ?.expatriate_work_permits[0]?.id,
              //       files: item?.path,
              //       properties: item,
              //       title: item?.title,
              //     },
              //   });
              // });
              setTimeout(() => {
                workExp?.work_experiences?.map(async (item) => {
                  await create_work_experience({
                    variables: {
                      attachment: { path: "" },
                      currently_working: item?.currently_working,
                      end_date: item?.end_date,
                      start_date: item?.start_date,
                      years_of_work_experience: Number(
                        item?.years_of_work_experience
                      ),
                      service_type_id: item?.service_type_id,
                      institution_name: item?.institution_name,
                      position: item?.position,
                      name_of_service: item?.name_of_service,
                      expatriate_id: D?.insert_expatriates?.returning[0]?.id,
                    },
                  });
                });

                counterP?.counterparts?.map(async (item) => {
                  let _counter = {
                    ...item,
                    assigned_date: item?.assignment_date,
                    first_name: item?.counterpart_firstName,
                    last_name: item?.counterpart_lastName,
                    position: item?.position,
                    work_permit_id:
                      D?.insert_expatriates?.returning[0]
                        ?.expatriate_work_permits[0]?.id,
                  };
                  await createCounterPart({
                    variables: { ..._counter },
                  });
                });

                education?.work_educations?.map(async (item) => {
                  let _edu = {
                    attachments: item?.attachments,
                    expatriate_id: D?.insert_expatriates?.returning[0]?.id,
                    field_of_study: item?.field_of_study,
                    institution_name: item?.institute_name,
                    qualification_level_id: item?.qualification_level_id,
                    qualification_type_id: item?.occupation_category_id,
                    received_date: item?.received_date,
                  };
                  await createEducation({ variables: { ..._edu } });
                });
              }, 1000);

              await create_expatriate_work_place({
                variables: {
                  phone_number: [workP?.phone_number],
                  email: workP?.email, //add
                  house_number: workP?.house_number,
                  kebele: workP?.kebele,
                  project_name: workP?.project_name,
                  // created_by: workP?.created_by,
                  district_id: workP?.district_id,
                  expatriate_work_permit_id:
                    D?.insert_expatriates?.returning[0]
                      ?.expatriate_work_permits[0]?.id,
                  region_id: workP?.region_id !== "" ? workP?.region_id : null,
                  subcity_id:
                    workP?.subcity_id !== "" ? workP?.subcity_id : null,
                  woreda_id: workP?.woreda_id !== "" ? workP?.woreda_id : null,
                  zone_id: workP?.zone_id !== "" ? workP?.zone_id : null,
                },
              }).then(() => clearIndexedDB("renewal_permit"));
            },
          }).then(() => {
            // setData("expatriate", {});
            localStorage.removeItem("new_permit");
            router("/organization");
            toast.success("New Expatriate requested successfully");
          });
        })
      );
    }
    create_expatriate({
      variables: {
        ...general,
        ...employment,
        date_of_birth: general?.date_of_birth,
        skills: skills?.skills,
        first_name: general?.first_name,
        father_name: general?.father_name,
        grand_father_name: general?.grand_father_name,
        passport_number: general?.passport_number,
        visa_number: general?.visa_number,
        gender_id: general?.gender,
        reference_number: finance.reference_number,
        marital_status_id: general?.marital_status,
        nationality_id: general?.nationality_id,
        profession_title_id: employment?.profession_title_id,
        visa_type_id: general?.visa_type,
        way_of_entry_id: general?.way_of_entry,
        start_date: employment?.employment_start_date,
        end_date: employment?.employment_end_date,
        salary: employment?.salary,
        allowance: employment?.allowance,
        occupation_category_id: employment?.occupation_category_id,
        passport_issued_date: general?.passport_issued_date, //passport issue date
        passport_expiry_date: general?.passport_expiry_date,
        visa_issued_date: general?.visa_issued_date, //passport issue date
        visa_expiry_date: general?.visa_expiry_date,
        permit_type: WORK_PERMIT_TYPES.RENEWAL,
        organization_id: general?.organization_id,
        intended_position: employment?.intended_position,
        organization_id: JSON.parse(localStorage.getItem("currentOrg"))
          ?.currentOrg?.id,
      },
      onCompleted: async (D) => {
        setTimeout(() => {
          workExp?.work_experiences?.map(async (item) => {
            await create_work_experience({
              variables: {
                attachment: { path: "" },
                currently_working: item?.currently_working,
                end_date: item?.end_date,
                start_date: item?.start_date,
                years_of_work_experience: Number(
                  item?.years_of_work_experience
                ),
                service_type_id: item?.service_type_id,
                institution_name: item?.institution_name,
                position: item?.position,
                name_of_service: item?.name_of_service,
                expatriate_id: D?.insert_expatriates?.returning[0]?.id,
              },
            });
          });

          counterP?.counterparts?.map(async (item) => {
            let _counter = {
              ...item,
              assigned_date: item?.assignment_date,
              first_name: item?.counterpart_firstName,
              last_name: item?.counterpart_lastName,
              position: item?.position,
              work_permit_id:
                D?.insert_expatriates?.returning[0]?.expatriate_work_permits[0]
                  ?.id,
            };
            await createCounterPart({
              variables: { ..._counter },
            });
          });

          education?.work_educations?.map(async (item) => {
            let _edu = {
              attachments: item?.attachments,
              expatriate_id: D?.insert_expatriates?.returning[0]?.id,
              field_of_study: item?.field_of_study,
              institution_name: item?.institute_name,
              qualification_level_id: item?.qualification_level_id,
              qualification_type_id: item?.occupation_category_id,
              received_date: item?.received_date,
            };
            await createEducation({ variables: { ..._edu } });
          });
        }, 1000);

        await create_expatriate_work_place({
          variables: {
            phone_number: [workP?.phone_number],
            email: workP?.email, //add
            house_number: workP?.house_number,
            kebele: workP?.kebele,
            project_name: workP?.project_name,
            // created_by: workP?.created_by,
            district_id: workP.district_id,
            expatriate_work_permit_id:
              D?.insert_expatriates?.returning[0]?.expatriate_work_permits[0]
                ?.id,
            region_id: workP?.region_id,
            subcity_id: workP?.subcity_id,
            woreda_id: workP?.woreda_id,
            zone_id: workP?.zone_id,
          },
        });

        //create employee contract document
        create_expatriate_document({
          variables: {
            documentId: employment?.contract?.document_type_id,
            expatriate_work_permit_id:
              D?.insert_expatriates?.returning[0]?.expatriate_work_permits[0]
                ?.id,
            files: employment?.contract?.path,
            properties: employment?.contract,
            title: employment?.contract?.title,
          },
        });

        setTimeout(() => {
          general?.expatriate_work_permit_documents?.map(async (j) => {
            let v = {
              ...j,
              documentId: j?.document_type_id,
              expatriate_work_permit_id:
                D?.insert_expatriates?.returning[0]?.expatriate_work_permits[0]
                  ?.id,
              files: j.path,
              properties: j,
              title: j.title,
            };
            console.log("doc__", v);
            await create_expatriate_document({ variables: { ...v } });
          });
        }, 2000);
        setData("expatriate", {});
        localStorage.removeItem("renewal");
        router("/organization");
        toast.success("Expatriate renewal requested successfully");
      },
    });
  };

  const handleStartOver = () => {
    localStorage.removeItem("renewal");
    clearIndexedDB('renewal_permit');
    router("/organization/renew-work-permit/request");
    window.location.reload();
  };

  return (
    <>
      <Nav Avatar={avatar} name={userOrganizationData} />
      <div className="flex flex-col md:flex-row gap-8 2xl:gap-12 mt-10 md:mt-20 layout items-start">
        <SideNav>
          <ApplicantStepper steps={steps} activeStep={activeStep} />
        </SideNav>
        <div className="w-full">
          <button
            onClick={() => router("/organization")}
            className="flex gap-2 items-center border-2 rounded-md border-gray-300 text-gray-600 mb-2 px-4 py-1 text-capitalize"
          >
            <MdArrowUpward /> Home
          </button>
          {!acceptNotice && (
            <div className="my-5">
              <NoticeCard
                amount={
                  permitPrice
                    ?.filter((i) => i?.work_permit_type?.name === "Renewal")
                    .map((i) => i?.price)[0]
                }
                handleHandle={() => setAcceptNotice(!acceptNotice)}
              >
                <p>
                  Below are the penalties associated with an expired work permit
                  based on your situation
                </p>
                <ul className="list-disc">
                  <li className="md:ml-6">
                    Should the duration of the expired work permit exceed one
                    month, a penalty of 50% will be imposed.
                  </li>
                  <li className="md:ml-6">
                    Should the duration of the expired work permit exceed two
                    month, a penalty of 75% will be imposed.
                  </li>
                  <li className="md:ml-6">
                    Should the duration of the expired work permit exceed three
                    month, a penalty of 100% will be imposed.
                  </li>
                  <li className="md:ml-6">
                    The work permit will be considered expired if the duration
                    exceeds three months{" "}
                  </li>
                </ul>
                <p className="text-N70 text-sm">
                  If the renewal process is completed within a month without any
                  delays, the payment amount will be. :{" "}
                  <span className="text-error30 font-bold text-xl">
                    {
                      permitPrice
                        ?.filter((i) => i?.work_permit_type?.name === "Renewal")
                        .map((i) => i?.price)[0]
                    }{" "}
                    ETB
                  </span>
                </p>
              </NoticeCard>
            </div>
          )}
          {acceptNotice && (
            <>
              <FormAreaRenewal
                moveToNext={moveToNext}
                activeStep={activeStep}
                title={"Renewal Request"}
                initialValues={data?.renewal}
                handleSubmit={handleRenewalFormSubmit}
                handleClearCache={handleStartOver}
                setActiveStep={setActiveStep}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default RequestRenewalApplication;
