import React from "react";
import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineLinkedin,
  AiOutlineTwitter,
} from "react-icons/ai";

const Footer = () => {
  return (
    <>
      <div className="w-full px-20 py-6 bg-[#0C1C2D] text-white flex flex-col">
        <div className="w-full py-8 flex justify-between items-start border-b-2 border-N60/20">
          <p className="text-xl font-semibold">Logo</p>
          <div className="flex flex-col gap-1">
            <p className="font-medium pb-3">Opening Hours</p>
            <p className="font-light text-xs text-N70">
              Weekdays: Monday - Friday
            </p>
            <p className="font-light text-xs text-N70">
              Saturday, Sunday: 2PM - 6PM
            </p>
          </div>

          {/* Address */}
          <div className="flex flex-col gap-1">
            <p className="font-medium pb-3">Address</p>
            <p className="font-light text-xs text-N70">
              Kasanchis, Addis Ababa Ethiopia
            </p>
          </div>

          {/* Address */}
          <div className="flex flex-col gap-1">
            <p className="font-medium pb-3">Contact</p>
            <p className="font-light text-xs text-N70">Info@example.com</p>
            <p className="font-light text-xs text-N70">(270) 555-0117</p>
          </div>
        </div>

        <div className="w-full flex items-center justify-between pt-6">
          <p className="text-xs text-N80">©2022, All right reserved.</p>

          <div className="flex gap-2 items-center justify-center text-xl text-N8">
            <AiOutlineFacebook />
            <AiOutlineInstagram />
            <AiOutlineTwitter />
            <AiOutlineLinkedin />
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
