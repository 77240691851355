import React from "react";
import { AiOutlineWarning } from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const ReportCard = ({ status }) => {
  const router = useNavigate();

  return (
    <div
      className={`${
        status === "success"
          ? "border-blue-100 bg-blue-100"
          : status === "warning"
          ? "border-yellow/60 bg-yellow30/30"
          : "border-red bg-red/60"
      } mb-5 relative border-2 shadow-lg rounded-lg p-6`}
    >
      <div className="flex items-start">
        <div className="flex-shrink-0">
          <AiOutlineWarning size={21} className="mr-4"/>
        </div>
        <div className="ml-4s">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Notice
          </h3>
          <div className="mt-2 text-md text-gray-800">
            <p>
              Please note that some required information has not been uploaded
              yet.
            </p>
            <p>
              Kindly ensure that you provide all the necessary documents before
              the deadline.
            </p>
          </div>
          <div className="mt-5">
            <button
              onClick={() => router(`/organization/requested-work-permit/`)}
              type="button"
              className={`${status === "success" ? 'border-green hover:bg-green20' : status === "warning" ? 'border-disabledBlue hover:bg-primary' : 'border-red40 hover:bg-red20'} border-2 hover:text-white hover:shadow-md ease-in-out rounded-md px-4 py-2`}
            >
              Upload Document
            </button>
          </div>
        </div>
      </div>
      <div className="absolute top-2 right-2">
        {/* <MdCancel size={16} /> */}
      </div>
    </div>
  );
};

const StatusType = {
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
};
ReportCard.defaultProps = {
  status: StatusType.SUCCESS,
};

export default ReportCard;
