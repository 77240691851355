import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const NewInputField = ({
  type,
  name,
  label,
  register,
  validation,
  errors,
  classes,
  placeholder,
  icon,
  defaultValue,
  disabled,
  helperText,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const { message } = errors[name] || {};

  return (
    <div className="relative">
      <p
        className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600
                  absolute"
      >
        {label}
      </p>
      <div className="flex">
        <input
          placeholder={placeholder}
          type={
            type === "password" ? (showPassword ? "text" : "password") : type
          }
          name={name}
          disabled={disabled}
          {...register(name, validation)}
          className={`border placeholder-gray-400 focus:outline-none
                    focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-1 mr-0 mb-0 ml-0 text-base block bg-white
                    rounded-md ${message ? "bg-red30" : "border-gray-300"}`}
          {...rest}
        />
        {type === "password" && (
          <button
            type="button"
            className="absolute right-2 top-[55%] -translate-y-[40%] focus:outline-none ml-2 mt-0 text-gray-500"
            onClick={toggleShowPassword}
          >
            {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
          </button>
        )}
      </div>
      {message && <div className="text-xs text-red50 mt-1">{message}</div>}
    </div>
  );
};

export default NewInputField;
