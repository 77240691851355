import React from "react";

const InfoCard = ({ children, email, title }) => {
  return (
    <div className="flex items-center justify-between w-full gap-2 text-sm">
      <div className="flex items-center gap-2">
        {children}
        <p className=" text-N20 capitalize">{title}</p>
      </div>
      <p className="font-medium truncate">{email}</p>
    </div>
  );
};

export default InfoCard;
