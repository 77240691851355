import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import Lottie from "react-lottie";
import animationData from "../assets/animation_lkokmrg8.json";
import { Jelly } from "@uiball/loaders";

const Loader = () => {
  const { promiseInProgress } = usePromiseTracker();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    promiseInProgress && (
      <div className="flex justify-center items-center h-screen w-screen fixed top-0 left-0 bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-10 z-50">
        <Jelly size={80} speed={0.9} color="black" />
      </div>
    )
  );
};

export default Loader;
