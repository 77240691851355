import React, { useState } from "react";
import { FormProvider } from "../../../components/Forms/Fields";
import { useForm } from "react-hook-form";
import ExpatriateEmrCard from "../../../components/cards/ExpatriateEmrCard";
import { useNavigate } from "react-router-dom";
import Nav from "../../../components/Navbar/Navbar";
import avatar from "../../../assets/images/avatar.jpg";
import { useLocalStore } from "../../../store";
import { useLazyQuery } from "@apollo/client";
import { SEARCH_EXPAT } from "../../../graph-query/queries";

const TransferExpatriate = () => {
  const [searchedData, setSearchedData] = useState({
    status: null,
    data: null,
  });

  const router = useNavigate();
  const { handleSubmit, register } = useForm();

  const { userOrganizationData } = useLocalStore();

  const [searchExpat, {}] = useLazyQuery(SEARCH_EXPAT, {
    onCompleted(data) {
      console.log(data);
      if (data.expatriates.length > 0) {
        setSearchedData({ status: true, data: data?.expatriates[0] });
      } else {
        setSearchedData({ status: false, data: null });
      }
    },
    onError(er) {
      console.log(er);
    },
  });
  const handleSearchExpat = (e) => {
    console.log("click", e);
    searchExpat({
      variables: {
        passport_number: e?.searchValue,
      },
    });
  };

  return (
    <>
      <Nav Avatar={avatar} name={userOrganizationData} />
      <div className="max-w-7xl mx-auto min-h-[80vh] my-12 max-full">
        <h1 className="text-xl font-bold py-3">
          Please insert expatriates using passport number
        </h1>
        <FormProvider onSubmithandler={handleSubmit(handleSearchExpat)}>
          <div className="relative flex items-center w-full h-16 rounded-lg focus-within:shadow-lg hover:shadow-md bg-white overflow-hidden">
            <div className="grid place-items-center h-full w-12 text-gray-300">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>

            <input
              name="searchValue"
              className="peer h-full w-full w-fulloutline-none text-sm border-none text-gray-700 pr-2"
              type="search"
              {...register("searchValue", { validate: true })}
              id="search"
              placeholder="Search something.."
            />

            <button
              type="submit"
              className="bg-primary rounded-lg text-teal-50 px-4 py-1.5 mr-4"
            >
              Search
            </button>
          </div>
        </FormProvider>

        {searchedData?.status && searchedData.data !== null && (
          <ExpatriateEmrCard
            handleClick={() =>
              router(
                `/organization/sw/new-work-request/${searchedData?.data?.id}`
              )
            }
            title={"New Permit"}
            data={{ expatriate: searchedData.data }}
          />
        )}
        {!searchedData?.status && searchedData.data === null && (
          <div className="w-full mt-5">
            <span>No Data found</span>
          </div>
        )}
      </div>
    </>
  );
};

export default TransferExpatriate;
