import React from "react";

const NewCustomButton = ({
  type = "button",
  label,
  classes,
  loading,
  handleClick,
}) => {
  return (
    <div className="relative">
      <button
        onClick={type !== "submit" ? handleClick : null }
        type={type}
        className={`${classes} w-full inline-block pt-4 pr-5 pb-4 pl-5 cursor-pointer text-xl font-medium text-center text-white bg-indigo-500 rounded-lg transition duration-200 hover:bg-indigo-600 ease`}
      >
        {loading ? "loading..." : label}
      </button>
    </div>
  );
};

export default NewCustomButton;
