import React, { useState } from "react";
import { MdNotifications } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Notification = () => {
  const [notifcation, setNotifiacation] = useState(false);
  const router = useNavigate();
  

  return (
    <div className="">
      <button
        onClick={() => setNotifiacation(!notifcation)}
        type="button"
        className="relative mr-4 inline-flex items-center px-3 py-3 text-sm font-medium text-center text-white bg-blue-100 rounded-full hover:bg-blue-200 focus:ring-4 focus:outline-none focus:ring-blue-300 "
      >
        <MdNotifications size={22} color="black" />
        <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-red bg-orange-300 border-2 border-white rounded-full -top-2 -right-2 ">
          8
        </div>
      </button>
      {notifcation && (
        <div
          id="dropdownNotification"
          className="z-20 w-full max-w-sm bg-white divide-y divide-gray-100 rounded-lg shadow absolute right-0 mt-2 overflow-x-hidden"
          aria-labelledby="dropdownNotificationButton"
        >
          <div className="block px-4 py-2 font-medium text-center text-gray-700 rounded-t-lg bg-gray-50">
            Notifications
          </div>
          <div className="divide-y divide-gray-100 dark:divide-gray-200">
            <a href="#" className="flex px-4 py-3 hover:bg-gray-100 ">
              <div className="flex-shrink-0">
                <img
                  class="w-10 h-10 p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
                  src="https://images.unsplash.com/photo-1692186484223-4a49480c6fe9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80"
                  alt="Bordered avatar"
                />
              </div>
              <div className="w-full pl-3">
                <div className="text-gray-500 text-sm mb-1.5 dark:text-gray-400">
                  Incomplete Document{" "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    Jese Leos
                  </span>
                  "Hey, Please Check and re-upload document"
                </div>
                <div className="text-xs text-blue-600 dark:text-blue-500">
                  a few moments ago
                </div>
              </div>
            </a>
 
          </div>
          <button
            onClick={() => router('/notifications')}
            className="block w-full py-2 text-sm font-medium text-center text-gray-900 rounded-b-lg bg-gray-50 hover:bg-gray-100 "
          >
            <div className="inline-flex items-center ">
              <svg
                class="w-4 h-4 mr-2 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 14"
              >
                <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
              </svg>
              View all
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

export default Notification;
