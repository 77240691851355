import { gql } from "@apollo/client";

export const ORGANIZATION_ID_SUBSRIPTION = gql`
  subscription ORGANIZATION_UPDATED($OrgID: uuid = "") {
    organizations(where: { id: { _eq: $OrgID } }) {
      # activity_code
      email
      business_licence_number
      created_at
      created_by
      expatriates_count
      house_number
      id
      local_employees_count
      # major_activity
      name
      organization_type {
        name
      }
      region {
        name
      }
      sector {
        name
      }
      phone_no
      tin_number
      # town
      expatriate_work_permit {
        id
        expatriate {
          first_name
          father_name
          profession_title {
            id
            name_json
          }
        }
      }
      user_info {
        name
        phone_number
        email
      }
    }
  }
`;

export const NOTIFICATION_LIST = gql`
  subscription permitRequestedByUser($userID: uuid) {
    users(where: { id: { _eq: $userID } }) {
      organization_user {
        review_status
        review_note
        reviewed_by
        exp_work_permits_json
        name
        expatriate_work_permit {
          allowance
          review_status
          reviewed_note
          work_permit_status
          salary
          # permit_type{name}
          emr_number
          document_status
          created_at
        }
      }
    }
  }
`;

export const ORGANIZATION_TRIGGER = gql`
  mutation ORGANIZATION_UPDATE_TRIGGER($OrgID: uuid = "") {
    update_organizations(where: { id: { _eq: $OrgID } }, _inc: { version: 1 }) {
      affected_rows
    }
  }
`;
