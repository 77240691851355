import React from "react";

const ExpatriateCard = ({ data, emr }) => {
  return (
    <div className="flex items-center gap-4 justify-between py-3">
      <div className="flex items-center gap-3">
        <div class="relative inline-flex items-center justify-center w-14 h-14 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
          <span class="font-medium text-gray-600 dark:text-gray-300">{data?.first_name?.substring(0,1) + data?.father_name?.substring(0,1)}</span>
        </div>

        <div>
          <h3 className="font-medium text-N20">
            {data?.first_name + " " + data?.father_name}
          </h3>
          <p className="text-sm text-N60">{emr || "--"}</p>
        </div>
      </div>
      {/* <Button custom="text-primary py-1">view more</Button> */}
    </div>
  );
};

export default ExpatriateCard;
