import React, { useEffect, useState } from "react";
import Nav from "../components/Navbar/Navbar";
import SideNav from "../components/Navbar/SideNav";
import { Outlet, useNavigate } from "react-router-dom";
import Avatar from "../assets/images/avatar.jpg";
import StepperList from "../components/utilities/StepperList";
import { RiMailCloseFill } from "react-icons/ri";
import {
  MdFiberNew,
  MdOutlineAutorenew,
  MdHistory,
  MdDashboard,
  MdVerified,
  MdCancelPresentation,
  MdAutorenew,
  MdVerifiedUser,
  MdCheck,
} from "react-icons/md";
import { Can } from "../permission/Can";
import { defineRulesFor, ability, updateRole } from "../permission/Ability";
import {
  BsShieldFillCheck,
  BsGearFill,
  BsFillInfoCircleFill,
  BsBuildingFillCheck,
  BsArchive,
  BsFolderX,
} from "react-icons/bs";
import { VscNewFolder } from "react-icons/vsc";
import { FaBlackTie, FaWindowClose } from "react-icons/fa";
import { useLocalStore } from "../store";
import { useLazyQuery } from "@apollo/client";
import {
  BASE_DOCUMENT_CATAGORY_TYPES,
  BASE_ROLES,
  GET_ASSIGNED_WORK_PERMIT,
  GET_CREDENTIAL_INFO,
} from "../graph-query/queries";
import { roles } from "../utils/roles";
import { DashboardSidebarLink } from "../utils/sidebarNav";
import { trackPromise } from "react-promise-tracker";

const DashboardLayout = () => {
  const {
    limit,
    offset,
    setCurrentRole,
    currentRole,
    setUser,
    user,
    documentTypeCategories,
    setDocumentTypeCategories,
    baseRoles,
    setBaseRoles,
    setAssignedWorkPermits,
    assginedWorkPermits,
  } = useLocalStore();

  const router = useNavigate();

  const getuserRole = () => {
    // let currentAuth;
    let _local = localStorage.getItem("work-permit");
    _local = JSON.parse(_local)?.roles;
    if (_local) {
      console.log(_local);
      setCurrentRole(_local);
      updateRole(_local);
    } else {
      router("/login");
    }
  };

  const [getAssignedData, { error, loading }] = useLazyQuery(
    GET_ASSIGNED_WORK_PERMIT,
    {
      variables: {
        limit: limit,
        offset: offset,
        id: JSON.parse(localStorage.getItem("work-permit"))?.userID,
      },
      onCompleted: (d) => {
        console.log(d.assigned_workpermit_reviewers);
        setAssignedWorkPermits(d?.assigned_workpermit_reviewers);
      },
      onError: (er) => console.log(er),
    }
  );

  const [getRoles, {}] = useLazyQuery(BASE_ROLES, {
    onCompleted: (d) => {
      setBaseRoles(d?.base_roles);
    },
  });

  const [getDocumentTypes, {}] = useLazyQuery(BASE_DOCUMENT_CATAGORY_TYPES, {
    onCompleted: (data) => {
      setDocumentTypeCategories(
        data ? data?.base_document_type_categories : []
      );
    },
  });

  useEffect(() => {
    if (baseRoles.length === 0) {
      getRoles();
    }
  }, []);

  useEffect(() => {
    if (documentTypeCategories?.length === 0) {
      getDocumentTypes();
    }
  }, [documentTypeCategories, setDocumentTypeCategories]);

  useEffect(() => {
    // if (assginedWorkPermits?.length === 0) {
    if (currentRole !== roles.WP_EMR_GENERATOR) {
      trackPromise(getAssignedData());
    }
    // }
  }, [assginedWorkPermits, setAssignedWorkPermits]);

  // =========================================================================

  useEffect(() => {
    getuserRole();
  }, []);

  return (
    <>
      <Nav Avatar={Avatar} userType={currentRole} name={assginedWorkPermits} />
      <div className="flex flex-col md:flex-row gap-6 mt-10 md:mt-20 layout items-start">
        <SideNav>
          {DashboardSidebarLink.map((link, index) => (
            <Can key={index} I="view" a={link.permission}>
              {() => (
                <StepperList
                  key={index}
                  _link={link.path}
                  icon={link.icon}
                  disabled={link.disabled}
                  stepperText={link.title}
                  activeClassName="bg-primary text-white"
                />
              )}
            </Can>
          ))}
        </SideNav>

        <div className="md:w-4/5 lg:ml-5 w-full">
          {/* roles: {user} */}
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
