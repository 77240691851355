import React from "react";
import { BsCalendarEvent, BsGlobe } from "react-icons/bs";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { CgOrganisation } from "react-icons/cg";
import FormLabel from "../../common/FormLabel";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CInputField, FormProvider } from "../Fields";
import CButton from "../CButton";
import { useLocalStore } from "../../../store";
import { CounterpartSchema } from "../../../utils/SchemaValidation";
import { MdPlusOne } from "react-icons/md";
import { WorkPermitForms } from "../../../utils/constants";
import { toast } from "react-toastify";

const CounterPart = ({ defaultValues, changeState, handleFormSubmit }) => {
  const { setData } = useLocalStore();

  console.log("counter part: ", defaultValues);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CounterpartSchema),
    defaultValues: {
      counterparts: defaultValues?.counterparts
      //!Change assignment_date to YYYY-MM-DD
    },
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "counterparts",
  });

  const onSubmit = (data) => {
    console.log("counter_", data);
    if(data?.counterparts?.length > 0){
      let _final = [];
      data?.counterparts?.forEach((s) => {
        let _res = {
          ...s,
        };
        _final.push(_res);
      });
      console.log(_final);
      const _counterpart = { counterparts: _final };
  
      handleFormSubmit(WorkPermitForms.COUNTER_PART, _counterpart);
    }else{
      toast.warn("Please Enter at least one counter part")
    }

  };
  return (
    <>
      <div className="relative flex p-4 flex-col gap-4 items-start h-full w-full">
        <div className="w-full flex flex-row justify-between items-center gap-1 items-start pb-10">
          <div>
            <h1 className="text-3xl font-semibold text-N40 capitalize">
              Counterpart Information
            </h1>
            <p className="text-xs font-light w-full md:w-2/3 text-N60">
              Organization must fulfill the expatriates work data information in
              order to proceed the work permit process.
            </p>
          </div>
        </div>

        <div className="w-full">
          <FormProvider
            onSubmithandler={handleSubmit(onSubmit)}
            className="w-full"
          >
            {fields.map((field, index) => (
              <div className="mb-7 border-b-2" key={field.id}>
                {/* Counterpart Name */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<CgOrganisation />}
                    labelTitile="Counterpart First Name"
                    labelDescription="Specify the trainer First name"
                  />
                  <div className="w-full flex md:flex-row flex-col gap-4 md:gap-6 py-2">
                    <div className=" w-full md:w-1/2">
                      <CInputField
                        name={`counterparts.${index}.counterpart_firstName`}
                        register={register}
                        errors={errors}
                        // validation={{
                        //   required: "Counter part name is required",
                        // }}
                        type="text"
                        label=""
                        placeholder="Counter First Name"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<CgOrganisation />}
                    labelTitile="Counterpart Last Name"
                    labelDescription="Specify the trainer Last name"
                  />
                  <div className="w-full flex md:flex-row flex-col gap-4 md:gap-6 py-2">
                    <div className=" w-full md:w-1/2">
                      <CInputField
                        name={`counterparts.${index}.counterpart_lastName`}
                        register={register}
                        errors={errors}
                        validation={{
                          required: "Counter last name is required",
                        }}
                        type="text"
                        label=""
                        placeholder="Counter Last Name"
                      />
                    </div>
                  </div>
                </div>

                {/* Position in the Firm */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<CgOrganisation />}
                    labelTitile="Position in the Firm"
                    labelDescription="Specify the trainer work position"
                  />
                  <div className="w-full flex md:flex-row flex-col gap-4 md:gap-6 py-2">
                    <div className=" w-full md:w-1/2">
                      <CInputField
                        name={`counterparts.${index}.position`}
                        register={register}
                        errors={errors}
                        validation={{
                          required: "Position in Firm is required",
                        }}
                        type="text"
                        label=""
                        placeholder="Position in the Firm"
                      />
                    </div>
                  </div>
                </div>

                {/* Date of Assignment */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<BsCalendarEvent />}
                    labelTitile="Date of Assignment"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full md:w-1/2">
                      <CInputField
                        name={`counterparts.${index}.assignment_date`}
                        register={register}
                        errors={errors}
                        validation={{ required: "Assignment Date is required" }}
                        type={"date"}
                        label=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="mt-4 flex gap-4 flex-wrap">
              <button
                type="button"
                onClick={() => append({})}
                className="w-full md:w-1/4 py-2 flex justify-center items-center text-blue-500 border-2 border-primary hover:bg-primary hover:text-white transition-all duration-200 ease-in bg-white rounded-sm text-sm cursor-pointer"
              >
                <MdPlusOne size={22} /> Add Counterpart
              </button>
              <button
                type="button"
                onClick={() => remove(-1)}
                className="w-full md:w-1/4 py-2 flex justify-center items-center text-red50 border-2 border-red hover:bg-red hover:text-white transition-all duration-200 ease-in bg-white rounded-sm text-sm cursor-pointer"
              >
                <MdPlusOne size={22} /> Remove Counterpart
              </button>
            </div>

            <hr />
            {/* Next Button */}
            <div className="w-full mt-6 md:w-1/2 flex gap-4 justify-end py-10">
              <CButton
                handleClick={() => changeState("Work Place")}
                classes=" w-1/3 py-2 flex justify-center items-center text-white bg-gray-400 rounded-sm text-sm"
                btnLabel={"Back"}
              />
              <CButton
                type="submit"
                btnLabel="Continue"
                classes="w-1/3 py-2 flex justify-center items-center text-white bg-primary rounded-sm text-sm"
              />
            </div>

            <div className=" mt-14 flex flex-col gap-4 w-ful items-start p-6 rounded bg-Prime90/50">
              <AiOutlineInfoCircle className="text-xl text-N70" />
              <p className="md:text-sm text-xs text-primary">
                The employer may not attach counterparts to expats in certain
                positions such as Managers and short-term assignments such as
                Machine installation and maintenance, Training / Teaching,
                consultancy , Supervision, Audit and the like and sectors such
                as NGOs, Religious organization.
              </p>
            </div>
          </FormProvider>
        </div>
      </div>
    </>
  );
};

export default CounterPart;
