import React from "react";
import { CInputField, FormProvider } from "../Fields";
import FormLabel from "../../common/FormLabel";
import { BsFillPersonFill } from "react-icons/bs";
import { useForm } from "react-hook-form";
import CButton from "../CButton";
import { WorkPermitForms } from "../../../utils/constants";
import useStepperFormStore from "../../../store/formStore";
import { yupResolver } from "@hookform/resolvers/yup";
import { EmrFormSchema } from "../../../utils/SchemaValidation";

const RenewalEMRForm = ({ changeState, handleFormSubmit, defaultValues }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(EmrFormSchema)
  });

  const { loadingRenewal } = useStepperFormStore();

  const handleEmrSubmit = (e) => {
    // console.log(e);
    let transformEMR = e?.emr_number?.trim()?.toUpperCase();
    console.log("generated: ", transformEMR);
    try {
      handleFormSubmit(WorkPermitForms.EMR, e);
    } catch (error) {
      console.log("error:", error);
    }
  };

  const validateEMR = (value) => {
    return value.startsWith("EMR") && value[3] === value[3].toUpperCase();
  };

  return (
    <div className="bg-white rounded-lg w-full">
      <FormProvider onSubmithandler={handleSubmit(handleEmrSubmit)}>
        {/* Applicants Name  */}
        <div className="flex flex-col justify-start mt-5 gap-4 items-start ">
          <FormLabel
            labelIcon={<BsFillPersonFill />}
            labelTitile="Applicants EMR Number"
            labelDescription="Enter Your EMR Number"
          />

          <div className="w-full lg:w-2/3">
            <CInputField
              name="emr_number"
              register={register}
              errors={errors}
              validation={{ required: "EMR Number is required" }}
              type="text"
              label=""
              placeholder="EMR Number..."
            />
          </div>
        </div>

        <div className="w-fit gap-2 flex justify-end py-10">
          <CButton
            type="submit"
            loading={loadingRenewal}
            btnLabel="Next"
            classes="bg-primary block w-full"
          />
        </div>
      </FormProvider>
    </div>
  );
};

export default RenewalEMRForm;
