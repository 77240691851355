import React, { useEffect } from "react";
import { HiMail, HiPhone, HiHome, HiFlag } from "react-icons/hi";
import { useLocalStore } from "../store";
import { GET_ORGANIZATION } from "../graph-query/queries";
import { useLazyQuery } from "@apollo/client";
import { BsArrowLeft, BsBack, BsBackspaceFill } from "react-icons/bs";

const OrganizationProfile = ({data}) => {
  const { userOrganizationData } = useLocalStore();

  return (
    <>
      <div className="flex md:flex-nowrap flex-wrap gap-6 items-start w-full justify-between md:p-6">
        <div className="flex flex-col gap-10 items-center justify-center w-full md:w-1/2">
          <div className="w-full flex bg-white flex-col p-4 items-center justify-center gap-6 shadow rounded-lg py-6">
            <div className="flex flex-col w-full justify-center items-center">
              <img
                src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5UFbNuEWoiAV6_aIgSIxytS0qbKgCLy9bvQ&usqp=CAU"}
                alt=""
                className="h-24 w-24 rounded-full object-cover border-2 border-primary"
              />
              <p className="text-lg font-bold mt-2">
                {data?.user_info?.name}
              </p>
              <p className="text-sm text-N50">
                {data?.sector?.name}
              </p>
              {/* <Button
                custom={
                  "w-2/5 bg-primary text-white text-sm font-semibold py-1 rounded hover:bg-prime30 mt-2"
                }
              >
                Edit Profile
              </Button> */}
            </div>

            <div className="flex flex-col items-center justify-center gap-3 w-4/5">
              <div className="w-full grid grid-cols-1 gap-2 lg:grid-cols-2 text-sm">
                <p className="flex gap-1">
                  <HiMail />
                  Email
                </p>

                <p>{data?.email}</p>
              </div>
              <div className="w-full grid grid-cols-1 gap-2 lg:grid-cols-2 text-sm">
                <p className="flex gap-1">
                  <HiPhone />
                  Phone
                </p>

                <p>
                  {data?.phone_no &&
                    data?.phone_no[0]}
                </p>
              </div>
              <div className="w-full grid grid-cols-1 gap-2 lg:grid-cols-2 text-sm">
                <p className="flex gap-1">
                  <HiHome />
                  House No.
                </p>

                <p>{data?.house_number}</p>
              </div>
              <div className="w-full grid grid-cols-1 gap-2 lg:grid-cols-2 text-sm">
                <p className="flex gap-1">
                  <HiFlag />
                  Region
                </p>

                <p>{data?.region?.name}</p>
              </div>
              <div className="w-full grid grid-cols-1 gap-2 lg:grid-cols-2 text-sm">
                <p className="flex gap-1">
                  <HiFlag />
                  City
                </p>

                <p>---</p>
              </div>
            </div>
          </div>
          <div className="w-full bg-white flex flex-col p-4 items-center justify-center gap-6 shadow rounded-lg">
            <p className="text-xl font-semibold">Employees</p>
            <div className="w-4/5 grid grid-cols-2">
              <p className="text-sm text-N30 font-medium">Local Employees</p>

              <p className="text-xs text-N70">
                {data?.local_employees_count} Employees
              </p>
            </div>
            <div className="w-4/5 grid grid-cols-2">
              <p className="text-sm text-N30 font-medium">Forign Employees</p>

              <p className="text-xs text-N70">
                {data?.expatriates_count} Employees
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-10 items-center justify-center w-full md:w-1/2">
          <div className="bg-white w-full shadow-sm p-4 rounded-md space-y-5">
            <h2 className="text-N70 capitalize font-medium">
              organization Details
            </h2>
            <div className="flex flex-col items-center gap-4">
              <div className="rounded bg-N99/30 p-3 space-y-1 w-full">
                <p className="text-xs text-N70 capitalize">tin number</p>
                <h3 className="text-primary font-semibold tracking-wider text-sm">
                  {data?.tin_number}
                </h3>
              </div>
              <div className="rounded bg-N99/30 p-3 space-y-1 w-full">
                <p className="text-xs text-N70 capitalize">
                  Business Licence Number
                </p>
                <h3 className=" text-primary font-semibold tracking-wider text-sm">
                  {data?.business_licence_number}
                </h3>
              </div>
              <div className="rounded bg-N99/30 p-3 space-y-1 w-full">
                <p className="text-xs text-N70 capitalize">
                  Local Activity Code
                </p>
                <h3 className="text-primary font-semibold tracking-wider text-sm">
                  {data?.activity_code || "LOCAL-323"}
                </h3>
              </div>
            </div>
            <div className=" space-y-1">
              <h4 className="text-xs text-N70">Activity Type</h4>
              <h4 className="text-primary font-medium">--</h4>
            </div>
            <div className="text-xs space-y-1">
              <h4 className=" text-N70">Major Activity</h4>
              <p className="text-N40">
                --
              </p>
            </div>
          </div>
          <div className="w-full bg-white flex flex-col p-4 gap-6 shadow rounded-lg">
            <p className="text-xl font-semibold">Documents</p>
            <div className="flex gap-2 flex-col">
              {data?.organization_documents?.map((item) => (
                <div className="flex justify-between border-2 rounded-md w-full border-gray-300 px-4 py-2 hover:shadow-lg">
                  <div>
                    <h3>{item?.files?.title}</h3>
                    <small>{item?.files?.name}</small>
                  </div>
                  <div className="flex justify-center items-center">
                    <div className={`${item?.review_status === false ? 'bg-red20' : item?.review_status === true ? 'bg-green20' : 'bg-yellow20' } w-5 h-5 rounded-full ring-2`}></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizationProfile;
