import React, { useEffect, useState } from "react";
import SideNav from "../../components/Navbar/SideNav";
import ApplicantStepper from "../../components/common/ApplicantStepper";
import { WorkPermitForms } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { MdArrowUpward } from "react-icons/md";
import avatar from "../../assets/images/avatar.jpg";
import Navbar from "../../components/common/Navbar";
import { useLocalStore } from "../../store";
import FormArea from "../../components/common/FormArea";
import { useLazyQuery } from "@apollo/client";
import {
  BASE_DOCUMENT_TYPES,
  BASE_GENDER,
  BASE_MARTIAL_STATUS,
  BASE_NATIONALITY,
  BASE_OCCUPATION_CATEGORIES,
  BASE_ORGANIZATIOIN_TYPE,
  BASE_PROFESSIONAL_TITLE,
  BASE_QUALIFICATION_LEVEL,
  BASE_QUALIFICATION_TYPES,
  BASE_REGION,
  BASE_SECTORS,
  BASE_SERVICE_TYPE,
  BASE_VISA_TYPES,
  BASE_WAY_OF_ENTER,
  GET_ORGANIZATION,
  PERMIT_PRICE,
} from "../../graph-query/queries";

const Archive = () => {
  const [acceptNotice, setAcceptNotice] = useState(false);
  // const { userOrganizationData } = useLocalStore();

  const [activeStep, setActiveStep] = useState(
    WorkPermitForms.GENERAL_INFORMATION
  );

  const router = useNavigate();

  const {
    currentRole,
    user,
    sectors,
    regions,
    userOrganizationData,
    organizationType,
    professionalTitles,
    qualificationLevels,
    qualificationTypes,
    occupationCatagory,
    documentTypes,
    nationality,
    martialTypes,
    visaTypes,
    expatriateWayEntered,
    genders,
    permitPrice,
    setData,
    serviceType,
    setServiceType,
    setGenders,
    setSectors,
    setRegions,
    setOrganizationType,
    setProfessionalTitles,
    setQualificationTypes,
    setQualificationLevels,
    setNationality,
    setOccupationCatagory,
    setDocumentTypes,
    setMartialTypes,
    setPermitPrice,
    setVisaTypes,
    setExpatriateWayEntered,
    setUserOrganizationData,
  } = useLocalStore();

  const [getUserData, { error: userErr }] = useLazyQuery(GET_ORGANIZATION, {
    variables: { eq: JSON.parse(localStorage.getItem("work-permit"))?.userID },
    context: {
      headers: {
        "Content-Type": "application/json",
      },
    },
    onCompleted: (data) => {
      setUserOrganizationData(data?.organizations);
    },
  });

  const [getSectors, {}] = useLazyQuery(BASE_SECTORS, {
    onCompleted: (data) => {
      console.log("sector data:", data?.base_sectors);
      setSectors(data?.base_sectors);
    },
  });

  const [getRegions, {}] = useLazyQuery(BASE_REGION, {
    onCompleted: (data) => {
      console.log("regions data:", data?.base_regions);
      setRegions(data?.base_regions);
    },
  });

  const [getOrgType, {}] = useLazyQuery(BASE_ORGANIZATIOIN_TYPE, {
    onCompleted: (data) => {
      console.log("organization type data:", data?.base_organization_types);
      setOrganizationType(data?.base_organization_types);
    },
  });

  const [getProfessionalTitles, {}] = useLazyQuery(BASE_PROFESSIONAL_TITLE, {
    onCompleted: (data) => {
      console.log("professional title data:", data?.base_profession_titles);
      setProfessionalTitles(data?.base_profession_titles);
    },
  });

  const [getQualificationType, {}] = useLazyQuery(BASE_QUALIFICATION_TYPES, {
    onCompleted: (data) => {
      console.log("qualification types data:", data?.base_qualification_types);
      setQualificationTypes(data?.base_qualification_types);
    },
  });

  const [getQualificationLevel, {}] = useLazyQuery(BASE_QUALIFICATION_LEVEL, {
    onCompleted: (data) => {
      console.log("qualification level data:", data?.base_qualification_levels);
      setQualificationLevels(data?.base_qualification_levels);
    },
  });

  const [getNationality, {}] = useLazyQuery(BASE_NATIONALITY, {
    onCompleted: (data) => {
      console.log("nationality data:", data?.base_nationalities);
      setNationality(data?.base_nationalities);
    },
  });

  const [getOccupation, {}] = useLazyQuery(BASE_OCCUPATION_CATEGORIES, {
    onCompleted: (data) => {
      console.log("occupation catagory:", data?.base_occupation_categories);
      setOccupationCatagory(data?.base_occupation_categories);
    },
  });

  const [getDocumentTypes, {}] = useLazyQuery(BASE_DOCUMENT_TYPES, {
    onCompleted: (data) => {
      console.log("document types:", data?.base_document_types);
      setDocumentTypes(data?.base_document_types);
    },
  });

  const [getMartialStatus, {}] = useLazyQuery(BASE_MARTIAL_STATUS, {
    onCompleted: (data) => {
      console.log("martial status:", data?.base_marital_statuses);
      setMartialTypes(data?.base_marital_statuses);
    },
  });

  const [getVisaTypes, {}] = useLazyQuery(BASE_VISA_TYPES, {
    onCompleted: (data) => {
      console.log("visa types:", data?.base_visa_types);
      setVisaTypes(data?.base_visa_types);
    },
  });

  const [getWayOfEnter, {}] = useLazyQuery(BASE_WAY_OF_ENTER, {
    onCompleted: (data) => {
      console.log("way of visa enter:", data?.base_way_of_entries);
      setExpatriateWayEntered(data?.base_way_of_entries);
    },
  });

  const [getGender, {}] = useLazyQuery(BASE_GENDER, {
    onCompleted: (d) => {
      setGenders(d?.base_genders);
    },
  });

  const [getTypeOfService, {}] = useLazyQuery(BASE_SERVICE_TYPE, {
    onCompleted: (d) => {
      setServiceType(d?.base_service_types);
    },
  });

  const [getPermitPrice, {}] = useLazyQuery(PERMIT_PRICE, {
    onCompleted: (d) => {
      setPermitPrice(d?.base_expatriate_work_permit_prices || []);
    },
  });

  useEffect(() => {
    if (sectors?.length === 0) {
      getSectors();
    }
  }, [sectors, setSectors]);

  useEffect(() => {
    if (regions?.length === 0) {
      getRegions();
    }
  }, [regions, setRegions]);

  useEffect(() => {
    if (organizationType?.length === 0) {
      getOrgType();
    }
  }, [organizationType, setOrganizationType]);

  useEffect(() => {
    if (professionalTitles?.length === 0) {
      getProfessionalTitles();
    }
  }, [professionalTitles, setProfessionalTitles]);

  useEffect(() => {
    if (qualificationLevels?.length === 0) {
      getQualificationLevel();
    }
  }, [qualificationLevels, setQualificationLevels]);

  useEffect(() => {
    if (qualificationTypes?.length === 0) {
      getQualificationType();
    }
  }, [qualificationTypes, setQualificationTypes]);

  useEffect(() => {
    if (nationality?.length === 0) {
      getNationality();
    }
  }, [nationality, setNationality]);

  useEffect(() => {
    if (occupationCatagory?.length === 0) {
      getOccupation();
    }
  }, [occupationCatagory, setOccupationCatagory]);

  useEffect(() => {
    if (documentTypes?.length === 0) {
      getDocumentTypes();
    }
  }, [documentTypes, setDocumentTypes]);

  useEffect(() => {
    if (martialTypes?.length === 0) {
      getMartialStatus();
    }
  }, [martialTypes, setMartialTypes]);

  useEffect(() => {
    if (visaTypes?.length === 0) {
      getVisaTypes();
    }
  }, [visaTypes, setVisaTypes]);

  useEffect(() => {
    if (expatriateWayEntered?.length === 0) {
      getWayOfEnter();
    }
  }, [expatriateWayEntered, setExpatriateWayEntered]);

  useEffect(() => {
    if (genders?.length === 0) {
      getGender();
    }
  }, [genders, setGenders]);

  useEffect(() => {
    if (serviceType?.length === 0) {
      getTypeOfService();
    }
  }, [serviceType, setServiceType]);

  useEffect(() => {
    if (userOrganizationData?.length === 0) {
      getUserData();
    }
  }, [userOrganizationData, setUserOrganizationData]);

  useEffect(() => {
    if (permitPrice?.length === 0) {
      getPermitPrice();
    }
  }, [permitPrice, setPermitPrice]);

  const moveToNext = () => {
    switch (activeStep) {
      case WorkPermitForms.GENERAL_INFORMATION:
        setActiveStep(WorkPermitForms.EMPLOYEE_INFORMATION);
        break;
      case WorkPermitForms.EMPLOYEE_INFORMATION:
        setActiveStep(WorkPermitForms.EDUCATIONAL_INFORMATION);
        break;
      case WorkPermitForms.EDUCATIONAL_INFORMATION:
        setActiveStep(WorkPermitForms.WORK_EXPREIENCE);
        break;
      case WorkPermitForms.WORK_EXPREIENCE:
        setActiveStep(WorkPermitForms.WORK_PLACE);
        break;
      case WorkPermitForms.WORK_PLACE:
        setActiveStep(WorkPermitForms.COUNTER_PART);
        break;
      case WorkPermitForms.COUNTER_PART:
        setActiveStep(WorkPermitForms.SKILLS);
        break;
      case WorkPermitForms.SKILLS:
        setActiveStep(WorkPermitForms.FINANCE);
        break;
      case WorkPermitForms.FINANCE:
        setActiveStep(WorkPermitForms.FINAL);
        break;
      default:
        return;
    }
  };

  const handleArchiveFormSubmit = (e) => {
    console.log("archivesubmit", e);
    setData("archive", e);
  };

  return (
    <div>
      <Navbar Avatar={avatar} name={userOrganizationData} />
      <div className="flex flex-col md:flex-row gap-8 2xl:gap-12 mt-10 md:mt-20 layout items-start">
        <SideNav>
          <ApplicantStepper activeStep={activeStep} />
        </SideNav>
        <div className="w-full">
          <button
            onClick={() => router(-1)}
            className="flex gap-2 items-center border-2 rounded-md border-gray-300 text-gray-600 mb-2 px-4 py-1 text-capitalize"
          >
            <MdArrowUpward /> Home
          </button>
          {!acceptNotice && (
            <FormArea
              moveToNext={moveToNext}
              activeStep={activeStep}
              title={"Add to Archive Documents"}
              handleSubmit={handleArchiveFormSubmit}
              setActiveStep={setActiveStep}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Archive;
