import { useState } from "react";
import { useForm, Controller } from "react-hook-form";

function CInputFile({}) {
  const [selectedFile, setSelectedFile] = useState(null);
  const { register, handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    console.log(selectedFile);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  return (
    <div>
      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2" htmlFor="file">
          Select File
        </label>
        <input
          type="file"
          id="file"
          name="file"
          className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          onChange={handleFileChange}
          ref={register}
        />
      </div>
      <div className="mb-6">
        <Controller
          name="description"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <textarea
              {...field}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Description"
            />
          )}
        />
      </div>
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        type="submit"
      >
        Submit
      </button>
    </div>
  );
}

export default CInputFile;
