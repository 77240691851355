import React from "react";
import { MdHourglassFull, MdVerifiedUser } from "react-icons/md";
// import logoStatus from "../assets/icons/logo-status.png";
import Moment from "react-moment";
import Tooltip from "./Tooltip";

const StatusCard = ({ status, date, user }) => {
  console.log("user reviewer data: ", user, status);
  return (
    <div
      className={`p-4 rounded-md w-full pb-8 max-h-fit border-2 bg-gradient-to-br relative flex items-start gap-4 justify-between ${
        status === "pending" && "from-yellow50 to-yellow60"
      } ${status === "rejected" && "from-red50 to-red70"} ${
        status === "verified" && "from-green50 to-green70"
      }`}
    >
      <div>
        <div className="flex mb-3 items-start justify-between h-max flex-col gap-1">
          <span className=" capitalize text-white font-medium">
            verification status
          </span>
          <div
            className={`w-fit py-1 px-2 rounded-full border-blue-100 border-2 capitalize  flex items-center justify-center gap-2 ${
              status === "rejected" && "bg-white/20 text-N99"
            } ${status === "pending" && "bg-white/50 text-N30"}
            ${status === "verified" && "bg-green20/70 text-white"}`}
          >
            <MdHourglassFull />
            <h3>{status}</h3>
          </div>
        </div>
        {user &&
          user?.map((item) => (
            <Tooltip tooltipsText="Who Verified this organization">
              <div className="flex gap-2 mt-1">
                <MdVerifiedUser />
                <span className="text-sm">
                  {item?.employee?.user_info?.name}
                </span>
              </div>
            </Tooltip>
          ))}
      </div>
      <p
        className={`absolute bottom-2 capitalize text-sm font-medium left-4 ${
          status == "rejected" && "text-N99"
        } ${status == "pending" && "text-N40"}`}
      >
        Updated At: <Moment format="YYYY/MM/DD">{date}</Moment>
      </p>
    </div>
  );
};

export default StatusCard;
