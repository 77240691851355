import numeral from "numeral";

export const AddisID = "63d8d1de-db25-485b-ad2c-ec756f7a0086";
export const DireDawaID = "967ea1d6-8e4c-4b6d-b839-64b046bfdd8b";

export const BANK_SLIP_ID = "3aff76f9-f65a-4ee2-8a4e-9a551802ad26";
export const PROFILE_IMAGE_ID = "cde354c9-bece-43f2-9739-72e0c7892553";

export const FINANCE_ROLE = "57ff8832-a6ca-4c5d-b053-57505591c457";
export const DOCUMENT_ROLE = "06b85061-a3c6-40b5-bcbf-966c25bee615";

export const WORK_PERMIT_TYPES = {
  NEW: "8989ab07-2f8d-4a5d-b161-0e08cfa14299",
  RENEWAL: "f4f0b403-3c62-4890-8700-8c25ff87c05d",
  REPLACEMENT: "94040d3a-eca7-4f26-b7d7-18811ead8090",
  CANCELLATION: "ab997561-d4f2-4c22-a8bc-2dc1adc7168e",
};

export const WorkPermitForms = {
  GENERAL_INFORMATION: "General Information",
  EMPLOYEE_INFORMATION: "Employement Information",
  EDUCATIONAL_INFORMATION: "Educational Information",
  WORK_EXPREIENCE: "Work Experience",
  WORK_PLACE: "Work Place",
  COUNTER_PART: "Counterpart",
  FINANCE: "Finance",
  SKILLS: "Skills",
  FINAL: "Confirm",
  EMR: "Emr",
};

export function fNumber(number) {
  return numeral(number).format();
}

export function fData(number) {
  const format = number ? numeral(number).format("0.0 b") : "";

  return result(format, ".0");
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}
