import React from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import PdfSvg from "../../assets/images/pdf.svg";
const SingleApplicationDetail = () => {
  return (
    <>
      <>
        <div className="w-full md:w-[70%] bg-white pb-10 rounded-xl flex flex-col gap-10 h-full">
          <div className="w-full flex">
            <div className="flex justify-center items-center py-3 px-6 text-primary font-medium">
              Application Status
            </div>
          </div>

          <div className="w-full px-6 h-full overflow-auto noScrollBar flex flex-col gap-4">
            {/* single application detail card */}

            {/* Succesfull card  */}
            <div
              className="w-full bg-N99/30 shadow-md shadow-black/10 p-6 flex justify-between items-center
            "
            >
              <div className="flex items-center gap-6">
                <BsFillCheckCircleFill className="text-green" />
                <img src={PdfSvg} alt="PDF Svg" />

                <div className="flex flex-col items-start">
                  <p className="text-green font-medium text-lg">
                    Education Folder
                  </p>
                  <p className="text-green font-light text-xs">Degree.pdf</p>
                </div>
              </div>

              <p className="px-6 py-1 flex justify-center items-center w-[8rem] bg-yellow/10 text-yellow30">
                Updated
              </p>
            </div>

            {/* Failed Card  */}
            <div
              className="w-full bg-N99/30 shadow-md shadow-black/10 p-6 flex justify-between items-center
            "
            >
              <div className="flex items-center gap-6">
                <img src={PdfSvg} alt="PDF Svg" />

                <div className="flex flex-col items-start">
                  <p className="text-red font-medium text-lg">
                    Education Folder
                  </p>
                  <p className="text-red font-light text-xs">Degree.pdf</p>
                </div>
              </div>

              <p className="px-6 py-1 flex justify-center items-center w-[8rem] bg-yellow/10 text-yellow30">
                Update
              </p>
            </div>

            {/* Verified Card  */}
            <div
              className="w-full bg-N99/30 shadow-md shadow-black/10 p-6 flex justify-between items-center
            "
            >
              <div className="flex items-center gap-6">
                <img src={PdfSvg} alt="PDF Svg" />

                <div className="flex flex-col items-start">
                  <p className="text-N40 font-medium text-lg">
                    Education Folder
                  </p>
                  <p className="text-N40 font-light text-xs">Degree.pdf</p>
                </div>
              </div>

              <p className="px-6 py-1 flex justify-center items-center w-[8rem] bg-green/10 text-green">
                Verified
              </p>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default SingleApplicationDetail;
