import React, { useEffect } from "react";
import { BiCategory } from "react-icons/bi";
import { CgOrganisation } from "react-icons/cg";
import FormLabel from "../../common/FormLabel";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import {
  BASE_DISTRICT,
  BASE_SUBCITY,
  BASE_WOREDA,
  BASE_ZONE,
} from "../../../graph-query/queries";
import { CInputField, CSelectField, FormProvider } from "../Fields";
import CButton from "../CButton";
import { useLocalStore } from "../../../store";
import { AddisID, DireDawaID, WorkPermitForms } from "../../../utils/constants";
import { WorkPlaceSchema } from "../../../utils/SchemaValidation";
import { toast } from "react-toastify";

const WorkPlace = ({ changeState, handleFormSubmit, defaultValues }) => {
  const { permitId } = useParams();

  const {
    setData,
    regions,
    woredas,
    subcities,
    districts,
    zones,
    setDistricts,
    setWoredas,
    setSubCities,
    setZones,
  } = useLocalStore();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(WorkPlaceSchema),
    defaultValues: defaultValues,
  });

  console.log("work:", defaultValues);

  const selectRegion = watch("region_id");
  const selectSubcity = watch("subcity_id");
  const selectZone = watch("zone_id");

  const [getWoreda, {}] = useLazyQuery(BASE_WOREDA, {
    variables: { subcity: selectSubcity },
    onCompleted: (data) => {
      setWoredas(data?.base_woredas);
    },
  });

  const [getSubCity, {}] = useLazyQuery(BASE_SUBCITY, {
    variables: { region: selectRegion },
    onCompleted: (d) => {
      setSubCities(d?.base_subcities);
    },
  });

  const [getDistrict, {}] = useLazyQuery(BASE_DISTRICT, {
    variables: { zone: selectZone },
    onCompleted: (da) => {
      setDistricts(da?.base_districts);
    },
  });

  const [getZone, {}] = useLazyQuery(BASE_ZONE, {
    variables: { region: selectRegion },
    onCompleted: (da) => {
      setZones(da?.base_zones);
    },
  });

  useEffect(() => {
    if (selectSubcity !== "") {
      getWoreda(selectSubcity);
    }
  }, [selectSubcity]);

  useEffect(() => {
    if (selectRegion !== "") {
      getSubCity(selectRegion);
    }
  }, [selectRegion]);

  useEffect(() => {
    if (selectZone !== "") {
      getDistrict();
    }
  }, [selectRegion]);

  useEffect(() => {
    if (selectRegion !== "") {
      getZone();
    }
  }, [selectRegion]);

  const onErrors = (error) => {
    console.log(error);
  };

  const onSubmit = (data) => {
    if (data.zone_id === "") {
      data.zone_id = null;
    }
    if (data.district_id === "") {
      data.district_id = null;
    }
    if (![AddisID,DireDawaID].includes(selectRegion)) {
      if (data.district_id === "" || data.district_id === null) {
        toast.error("Please Select District");
      } else if (data.zone_id === "" || data.zone_id === null) {
        toast.error("Please Select Zone");
      } else {
        let r = {
          ...data,
        };
        // console.log("work place: ", r);
        // setData("expatriate", r);
        // moveToNext();
        handleFormSubmit(WorkPermitForms.WORK_PLACE, r);
      }
    } else {
      if (data.subcity_id === "" || data.subcity_id === null) {
        toast.error("Please Select Town");
      } else if (data.woreda_id === "" || data.woreda_id === null) {
        toast.error("Please Select Woreda");
      } else {
        let r = {
          ...data,
        };
        handleFormSubmit(WorkPermitForms.WORK_PLACE, r);
        // console.log("work place: ", r);
        // setData("expatriate", r);
        // moveToNext();
      }
    }
    // trackPromise(
    //   createWorkplace({
    //     variables: {
    //       object: {
    //         ...data,
    //         work_permit_request_id: permitId
    //           ? permitId
    //           : context.work_permit_request_id,
    //       },
    //     },

    //     onCompleted: (data) => {
    //       moveToNext();
    //     },

    //     onError: (err) => {},
    //   })
    // );
  };

  return (
    <>
      <div className="flex flex-col p-4 gap-4 items-start h-full w-full">
        <div className="flex flex-col gap-1 items-start pb-10">
          <h1 className="text-3xl font-semibold text-N40">Work Place</h1>
          <p className="text-xs font-light w-full md:w-2/3 text-N60">
            Organization must fulfill the expatriates work place information in
            order to proceed the work permit process.
          </p>
        </div>
        <div className="w-full">
          <FormProvider
            onSubmithandler={handleSubmit(onSubmit)}
            className="w-full grid grid-cols-2"
          >
            {/* Project Name */}
            <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
              <FormLabel
                labelIcon={<CgOrganisation />}
                labelTitile="Project Name"
                labelDescription="Specify the project name where the expatriate works"
              />
              <div className="w-full flex md:flex-row flex-col gap-4 md:gap-6 py-2">
                <div className="w-2/3">
                  <CInputField
                    name="project_name"
                    register={register}
                    errors={errors}
                    type="text"
                    label=""
                    placeholder=""
                  />
                </div>
              </div>
            </div>

            {/* Region */}
            <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
              <FormLabel
                labelIcon={<BiCategory />}
                labelTitile="Region"
                labelDescription="Specific site where the expatriate employed"
              />
              <div className="w-full flex gap-6 py-2">
                <div className=" w-full xl:w-2/3">
                  <CSelectField
                    name="region_id"
                    register={register}
                    errors={errors}
                    options={regions.map((item) => {
                      return {
                        ...item,
                        value: item.id,
                      };
                    })}
                    label=""
                    placeholder=""
                  />
                </div>
              </div>
            </div>

            {selectRegion && ![AddisID, DireDawaID].includes(selectRegion) && (
              <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                <FormLabel
                  labelIcon={<CgOrganisation />}
                  labelTitile="Zone"
                  labelDescription="Specify the project zone place where the expatriate works"
                />
                <div className="w-full flex md:flex-row flex-col gap-4 md:gap-6 py-2">
                  <div className=" w-full xl:w-2/3">
                    <CSelectField
                      name="zone_id"
                      register={register}
                      errors={errors}
                      // validation={{ required: "Zone is required" }}
                      options={zones?.map((item) => {
                        return {
                          ...item,
                          name: item?.name_json?.en,
                          value: item?.id,
                        };
                      })}
                      label=""
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            )}

            {selectZone && ![AddisID, DireDawaID].includes(selectRegion)  && (
              <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                <FormLabel
                  labelIcon={<CgOrganisation />}
                  labelTitile="District"
                  labelDescription="Specify the project district place where the expatriate works"
                />
                <div className="w-full flex md:flex-row flex-col gap-4 md:gap-6 py-2">
                  <div className=" w-full xl:w-2/3">
                    <CSelectField
                      name="district_id"
                      register={register}
                      errors={errors}
                      // validation={{ required: "District is required" }}
                      options={districts?.map((item) => {
                        return {
                          ...item,
                          name: item?.name_json?.en,
                          value: item?.id,
                        };
                      })}
                      label=""
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Town or City */}
            {selectRegion && [AddisID, DireDawaID].includes(selectRegion) && (
              <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                <FormLabel
                  labelIcon={<CgOrganisation />}
                  labelTitile="Town"
                  labelDescription="Specify the project place where the expatriate works"
                />
                <div className="w-full flex md:flex-row flex-col gap-4 md:gap-6 py-2">
                  <div className=" w-full xl:w-2/3">
                    <CSelectField
                      name="subcity_id"
                      register={register}
                      errors={errors}
                      options={subcities.map((item) => {
                        return {
                          ...item,
                          name: item?.name_json?.en,
                          value: item?.id,
                        };
                      })}
                      label=""
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Woreda */}
            {selectSubcity && (
              <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                <FormLabel
                  labelIcon={<BiCategory />}
                  labelTitile="Woreda"
                  labelDescription="Specify the project place where the expatriate works"
                />
                <div className="w-full flex gap-6 py-2">
                  <div className=" w-full xl:w-2/3">
                    <CSelectField
                      name="woreda_id"
                      register={register}
                      errors={errors}
                      options={woredas.map((item) => {
                        return {
                          ...item,
                          name: item?.name_json?.en,
                          value: item.id,
                        };
                      })}
                      label=""
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Kebele */}
            <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
              <FormLabel
                labelIcon={<BiCategory />}
                labelTitile="Kebele"
                labelDescription="Specify the project place where the expatriate works"
              />
              <div className="w-full flex gap-6 py-2">
                <div className=" w-full xl:w-2/3">
                  <CInputField
                    name="kebele"
                    register={register}
                    errors={errors}
                    type="number"
                    label=""
                    placeholder=""
                  />
                </div>
              </div>
            </div>

            {/* Phone Number */}
            <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
              <FormLabel
                labelIcon={<CgOrganisation />}
                labelTitile="Phone Number"
                labelDescription="Specify the phone number of the work place"
              />
              <div className="w-full flex md:flex-row flex-col gap-4 md:gap-6 py-2">
                <div className=" w-full xl:w-2/3">
                  <CInputField
                    name="phone_number"
                    register={register}
                    errors={errors}
                    type="tel"
                    label=""
                    placeholder=""
                  />
                </div>
              </div>
            </div>

            {/* House Number */}
            <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
              <FormLabel
                labelIcon={<CgOrganisation />}
                labelTitile="House Number"
                labelDescription="Specify the project place where the expatriate works"
              />
              <div className="w-full flex md:flex-row flex-col gap-4 md:gap-6 py-2">
                <div className=" w-full xl:w-2/3">
                  <CInputField
                    name="house_number"
                    register={register}
                    errors={errors}
                    type="text"
                    label=""
                    placeholder=""
                  />
                </div>
              </div>
            </div>

            {/* Email Adderss */}
            {/* <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
              <FormLabel
                labelIcon={<CgOrganisation />}
                labelTitile="Email Adderss"
                labelDescription="Specify the email address of the work place"
              />
              <div className="w-full flex md:flex-row flex-col gap-4 md:gap-6 py-2">
                <div className=" w-full xl:w-2/3">
                  <CInputField
                    name="email"
                    register={register}
                    errors={errors}
                    validation={{ required: "Email is required" }}
                    type="email"
                    label=""
                    placeholder=""
                  />
                </div>
              </div>
            </div> */}

            {/* Next Button */}
            <div className="w-full md:w-1/2 flex gap-4 justify-end py-10">
              <CButton
                type={"button"}
                handleClick={() => changeState("Work Experience")}
                // onClick={() => changeState("Work Experience")}
                btnLabel={"Back"}
                classes=" w-1/3 py-2 flex justify-center items-center text-white bg-gray-400 rounded-sm text-sm"
              />
              <CButton
                type="submit"
                btnLabel="Continue"
                classes=" w-1/3 py-2 flex justify-center items-center text-white bg-primary rounded-sm text-sm cursor-pointer"
              />
            </div>
          </FormProvider>
        </div>
      </div>
    </>
  );
};

export default WorkPlace;
