import React from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { FormProvider, CTextArea } from "../Forms/Fields";
import ReasonCard from "./ReasonCard";
import { useForm } from "react-hook-form";

const SendMessageModal = ({ data, handleClick }) => {
  console.log(data);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const handleReject = (w) => {
    console.log("rejection email", w);
  };

  const copyDivToClipboard = () => {
    var range = document.createRange();
    range.selectNode(document.getElementById("test"));
    window.getSelection().removeAllRanges(); // clear current selection
    window.getSelection().addRange(range); // to select text
    document.execCommand("copy");
    window.getSelection().removeAllRanges(); // to deselect
    alert("message Copied ");
  };

  return (
    <div className=" h-screen w-full z-[60] bg-white/10 backdrop-blur-sm fixed flex items-center justify-center top-0">
      <div
        onClick={handleClick}
        className="absolute h-full w-full top-0 left-0 z-10"
      ></div>
      <div className=" bg-white p-4 2xl:p-8 rounded-md shadow w-3/5 flex gap-8 z-20">
        <div className="w-3/5 space-y-4">
          <div className="bg-prime40/10 p-4 rounded-sm">
            <div className="flex gap-8 items-center w-4/5 justify-between">
              <div className="flex items-center gap-8">
                <div className="h-full  text-white rounded-sm text-[6rem] bg-prime40/25 flex items-center justify-center p-4">
                  <BsFillPersonFill />
                </div>
                <div>
                  <h3 className=" text-primary capitalize font-medium">
                    {data?.first_name + " " + data?.father_name}
                  </h3>
                  <p className="text-sm text-success50 font-light">
                    {data?.email}
                  </p>
                </div>
              </div>

              <p>{data?.nationality?.name}</p>
            </div>
          </div>
          <FormProvider onSubmithandler={handleSubmit(handleReject)}>
            <CTextArea
              name="message"
              register={register}
              errors={errors}
              validation={{ required: true }}
              className="h-36 p-2 mb-4 bg-N99/70 w-full border-N95 rounded-sm focus:ring-0 focus:outline-none"
              placeholder="Here the reason for the rejection"
            ></CTextArea>
            <div className=" w-full flex gap-4">
              <button
                onClick={handleClick}
                className="py-2 border border-primary w-1/2 rounded-sm text-primary"
              >
                cancel
              </button>
              <button
                type="submit"
                className="py-2 border border-primary w-1/2 rounded-sm text-white bg-primary"
              >
                send email
              </button>
            </div>
          </FormProvider>
          {/* <textarea
            type="text"
            className="h-44 w-full p-2 border-N99 rounded-sm focus:ring-0 focus:outline-none"
            placeholder="Here the reason for the rejection"
          ></textarea> */}
        </div>
        <div className="w-2/5">
          <h2 className="text-N70 mb-3 capitalize font-medium">
            rejection reasons
          </h2>
          <div className="flex flex-col gap-2 2xl:max-h-[50vh] max-h-[60vh] noScrollBar overflow-auto">
            {data?.work_permit_request_documents
              .filter((d) => d.verification_remark !== null)
              .map((item) => (
                <ReasonCard
                  onClick={copyDivToClipboard}
                  id={item.id}
                  title={item.title}
                  description={item.verification_remark}
                />
              ))}
            {/* <ReasonCard
              title="Passport"
              description="Educational document is corrupted you need to upload them better"
            />
            <ReasonCard
              title="Work Doc."
              description="Work EXP document is corrupted you need to upload them better "
            />
            <ReasonCard
              title="VISA"
              description="VISA document is corrupted you need to upload them better "
            />
            <ReasonCard
              title="Finance"
              description="Finance number is corrupted you need to upload them better "
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendMessageModal;
