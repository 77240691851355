import React, { useState, useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useReplacementStore } from "../../store/replacementStore";
import { BANK_SLIP_ID, WORK_PERMIT_TYPES } from "../../utils/constants";
import FilterBox from "../../components/Forms/FilterBox";
import { useLocalStore } from "../../store";
import { roles } from "../../utils/roles";
import Table from "../../components/Table/CTable";
import { ApplicantColumns } from "../../datasets/applicants/applicantColumns";
import {
  APPROVED_EXPATIATES_PERMITS_RENEWAL,
  APPROVED_EXPATIATES_PERMITS_RENEWAL_FILTER,
  COUNT_NOT_ASSIGNED_PERMITS,
  DOCUMENT_ALL_APPROVED_WORK_PERMITS,
  GET_ALL_ASSIGNED_WORK_PERMITS,
  GET_FILTERED_PERMIT,
  GET_NOT_ASSIGNED_FILTERED_PERMIT,
  SEARCH_EXPATIATES_PERMITS_FILTER_DOCUMENT_CHECKER_FINANCE,
  SEARCH_EXPATIATES_PERMITS_RENEWAL_FILTER_DOCUMENT_CHECKER,
  SEARCH_WORK_PERMITS_FILTER,
  TOTAL_ASSIGNED_WORK_PERMIT,
  TOTAL_ASSIGNED_WORK_PERMIT_ALL,
  TOTAL_ASSIGNED_WORK_PERMIT_BY_STATUS,
  TOTAL_PERMIT_EXPAT_COUNT,
} from "../../graph-query/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Can } from "../../permission/Can";
import {
  ASSIGN_WORK_PERMIT,
  UPDATE_REPLACEMENT,
  VERIFY_EXPATRIATE_DOCUMENT,
} from "../../graph-query/mutation";
import FinanceDocument from "../../components/utilities/FinanceDocument";
import { VerifiedApplicants } from "../../datasets/verified/verifiedColumns";
import EMRReplacementModal from "../../components/modals/ReplacementModal";

const Placement = () => {
  const [openReplacementModal, setOpenReplacementModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [currentTab, setCurrentTab] = useState("not_assigned");
  const [selectedTab, setSelectedTab] = useState("pending");
  const [loadingRows, setLoadingRows] = useState([]);

  //initiate replacement store
  // =======================================================STORE======================
  const { currentRole } = useLocalStore();
  const {
    isLoading,
    pending_offset,
    approved_offset,
    rejected_offset,
    pending_limit,
    approved_limit,
    rejected_limit,
    replacementPendingList,
    replacementApprovedList,
    replacementRejectedList,
    pendingCount,
    approvalCount,
    rejectedCount,
    setPendingCount,
    setApprovalCount,
    setRejectedCount,
    setIsLoading,
    setPendingOffset,
    setApprovedOffset,
    setRejectedOffset,
    setPendingLimit,
    setApprovedLimit,
    setRejectedLimit,
    setReplacementPendingList,
    setReplacementApprovedList,
    setReplacementRejectedList,
  } = useReplacementStore();
  // ===============================================================================

  const navigate = useNavigate();

  const [assign, {}] = useMutation(ASSIGN_WORK_PERMIT, {
    onCompleted: (res) => toast.success("Work Permit Assigned successfully"),
    onError: (er) => toast.error("Error assigning work permit"),
  });

  // ==============================================DESK HANDLER=================================
  const [countNotAssigned, { error }] = useLazyQuery(
    COUNT_NOT_ASSIGNED_PERMITS,
    {
      variables: {
        permitType: WORK_PERMIT_TYPES.REPLACEMENT,
      },
      onCompleted: (d) => {
        setPendingCount(d?.expatriate_work_permits_aggregate?.aggregate?.count);
      },
    }
  );

  const [countAssigned, {}] = useLazyQuery(TOTAL_PERMIT_EXPAT_COUNT, {
    variables: {
      permitType: WORK_PERMIT_TYPES.REPLACEMENT,
    },
    onCompleted: (d) => {
      setApprovalCount(d?.expatriate_work_permits_aggregate?.aggregate?.count);
    },
  });
  /**
   * @description GET all assigned renewal work permit
   * @access desk:handler
   */
  const [getAssignedList, { loading }] = useLazyQuery(GET_FILTERED_PERMIT, {
    variables: {
      permitType: WORK_PERMIT_TYPES.REPLACEMENT,
      limit: Number(approved_limit),
      offset: approved_offset * Number(approved_limit),
    },
    onCompleted: (data) => {
      setReplacementApprovedList(data?.expatriate_work_permits);
    },
  });

  /**
   * @description get Not assigned renewal permits
   */
  const [getNotAssignedList, {}] = useLazyQuery(
    GET_NOT_ASSIGNED_FILTERED_PERMIT,
    {
      variables: {
        limit: Number(pending_limit),
        offset: pending_offset * Number(pending_limit),
        permitType: WORK_PERMIT_TYPES.REPLACEMENT,
      },
      onCompleted: (res) => {
        setReplacementPendingList(res?.expatriate_work_permits);
      },
    }
  );
  // ===========================================================================================

  // ==================================================DOCUMENT CHECKER=========================
  /**
   * @description get Total count for assigned user
   * @access DOC:checker | finance
   */
  const [getTotalPendingPermits, {}] = useLazyQuery(
    TOTAL_ASSIGNED_WORK_PERMIT,
    {
      variables: {
        employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
        permit: WORK_PERMIT_TYPES.REPLACEMENT,
      },
      onCompleted: (d) => {
        setPendingCount(
          d?.assigned_workpermit_reviewers_aggregate?.aggregate?.count
        );
      },
    }
  );
  // Count Approved permit (DOC Checker)
  const [getTotalApprovePermits, {}] = useLazyQuery(
    TOTAL_ASSIGNED_WORK_PERMIT_BY_STATUS,
    {
      variables: {
        employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
        checkerStatus: true,
        permit: WORK_PERMIT_TYPES.REPLACEMENT,
      },
      onCompleted: (d) => {
        setApprovalCount(
          d?.assigned_workpermit_reviewers_aggregate?.aggregate?.count
        );
      },
    }
  );
  //Count Rejected permits (DOC Checker)
  const [getTotalRejectedPermits, {}] = useLazyQuery(
    TOTAL_ASSIGNED_WORK_PERMIT_BY_STATUS,
    {
      variables: {
        employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
        checkerStatus: false,
        permit: WORK_PERMIT_TYPES.REPLACEMENT,
      },
      onCompleted: (d) => {
        setRejectedCount(
          d?.assigned_workpermit_reviewers_aggregate?.aggregate?.count
        );
      },
    }
  );
  const [getAllAssignedPermits, { loading: permitLoading }] = useLazyQuery(
    GET_ALL_ASSIGNED_WORK_PERMITS,
    {
      variables: {
        limit: Number(pending_limit),
        offset: pending_offset * Number(pending_limit),
        permit: WORK_PERMIT_TYPES.REPLACEMENT,
        employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
      },
      onCompleted: (d) => {
        setReplacementPendingList(d?.assigned_workpermit_reviewers);
      },
      onError: (er) => {
        console.log(er);
      },
    }
  );
  /**
   * @description get all approved permits
   */
  const [getAllApprovedPermits, { loading: approvedLoading }] = useLazyQuery(
    DOCUMENT_ALL_APPROVED_WORK_PERMITS,
    {
      variables: {
        limit: Number(approved_limit),
        offset: approved_offset * Number(approved_limit),
        checkerStatus: true,
        permit: WORK_PERMIT_TYPES.REPLACEMENT,
        employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
      },
      onCompleted: (d) => {
        setReplacementApprovedList(d?.assigned_workpermit_reviewers);
      },
      onError: (er) => {
        console.log(er);
      },
    }
  );

  /**
   * @description get all rejected permits
   */
  const [getAllRejectedPermits, { loading: rejectedLoading }] = useLazyQuery(
    DOCUMENT_ALL_APPROVED_WORK_PERMITS,
    {
      variables: {
        limit: Number(rejected_limit),
        offset: rejected_offset * Number(rejected_limit),
        checkerStatus: false,
        permit: WORK_PERMIT_TYPES.REPLACEMENT,
        employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
      },
      onCompleted: (d) => {
        setReplacementRejectedList(d?.assigned_workpermit_reviewers);
      },
      onError: (er) => {
        console.log(er);
      },
    }
  );
  // ===========================================================================================

  // setReplacementPendingList(data?.expatriate_work_permits);
  // =========================================================================
  const renderRowSubComponent = useCallback(
    ({ row }) => (
      <pre
        style={{
          fontSize: "10px",
        }}
      >
        <table className="w-11/12 mx-auto capitalize">
          <thead className="bg-[#F0F6FD]">
            <tr>
              <th className="text-left text-N60 p-2 py-4">Organization Name</th>
              <th className="text-left text-N60 p-2 py-4">Type</th>
              <th className="text-left text-N60 p-2 py-4">Tin Number</th>
              <th className="text-left text-N60 p-2 py-4">Business Licence</th>
            </tr>
          </thead>
          <tbody className="alternate-bg text-base">
            <tr>
              <td className="px-2 py-4 font-semibold">
                {row?.original?.organization?.name}
              </td>
              <td className="px-2 py-4 font-semibold">
                {row?.original?.organization?.organization_type?.name}
              </td>
              <td className="px-2 py-4 font-semibold">
                <span className="bg-Prime90/40 border px-2 py-1 rounded border-prime50 text-primary">
                  {row?.original?.organization?.tin_number}
                </span>
              </td>
              <td className="px-2 py-4 font-semibold">
                <div className="flex flex-col text-xs">
                  {row?.original?.organization?.business_licence_number}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </pre>
    ),
    []
  );

  const handlePlacementRenewal = (c) => {
    //TODO
  };

  const resetFilter = () => {
    if (currentRole === roles.WP_DESK_HANDLER) {
      countNotAssigned();
      countAssigned();
      getAssignedList();
      getNotAssignedList();
    }
    // if (currentRole === roles.WP_EMR_GENERATOR) {
    //   getTotalAssignedPermitsPending();
    //   getTotalAssignedPermitsApproved();
    //   getTotalAssignedPermitsRejected();
    //   getAllPendingPermitsDoc();
    //   getAllApprovedPermitsDoc();
    //   getAllRejectedPermitsDoc();
    // }
    else {
      getAllAssignedPermits();
      getAllApprovedPermits();
      getAllRejectedPermits();
      getTotalPendingPermits();
      getTotalApprovePermits();
      getTotalRejectedPermits();
    }
  };

  const [searchRenewalPermit, {}] = useLazyQuery(SEARCH_WORK_PERMITS_FILTER, {
    variables: {
      permit: WORK_PERMIT_TYPES.REPLACEMENT,
    },
    onCompleted: (res) => {
      setReplacementPendingList(res?.expatriate_work_permits);
    },
    onError: (er) => console.log(er),
  });

  const [searchPermitEMR, {}] = useLazyQuery(
    APPROVED_EXPATIATES_PERMITS_RENEWAL_FILTER,
    {
      onCompleted: (d) => {
        setReplacementPendingList(d?.expatriate_work_permits);
      },
      onError: (er) => console.log(er),
    }
  );
  /**
   * @access DOCUMENT CHECKER
   */
  const [searchPermitDocumentChecker, {}] = useLazyQuery(
    SEARCH_EXPATIATES_PERMITS_RENEWAL_FILTER_DOCUMENT_CHECKER,
    {
      onCompleted: (d) => {
        setReplacementPendingList(d?.assigned_workpermit_reviewers);
      },
      onError: (er) => console.log(er),
    }
  );

  //search for Finance (filter)
  const [searchFinance, {}] = useLazyQuery(
    SEARCH_EXPATIATES_PERMITS_FILTER_DOCUMENT_CHECKER_FINANCE,
    {
      onCompleted: (d) => {
        setReplacementPendingList(d?.assigned_workpermit_reviewers);
      },
      onError: (er) => console.log(er),
    }
  );

  const handleDocumentHandlerFilter = (e) => {
    if (e) {
      searchPermitDocumentChecker({
        variables: {
          searchVal: e?.filterValue,
          permit: WORK_PERMIT_TYPES.REPLACEMENT,
          employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
        },
      });
    }
  };

  const handleEMRGeneratorHandlerFilter = (e) => {
    if (e) {
      searchPermitEMR({
        variables: {
          emr: e,
          permit: WORK_PERMIT_TYPES.REPLACEMENT,
        },
      });
    }
  };

  // ========================================================EMR====================================================
  const [getRenewalVerifiedPermits, { loading: emrLoading }] = useLazyQuery(
    APPROVED_EXPATIATES_PERMITS_RENEWAL,
    {
      variables: {
        limit: Number(pending_limit),
        permit: WORK_PERMIT_TYPES.REPLACEMENT,
        offset: pending_offset * Number(pending_limit),
      },
      onCompleted: (d) => {
        setReplacementPendingList(d?.expatriate_work_permits);
      },
      onError: (er) => console.log(er),
    }
  );
  /**
   * @description get total permits (renewal only all data for EMR generator)
   */
  const [getTotalAssignedPermitsAll, {}] = useLazyQuery(
    TOTAL_ASSIGNED_WORK_PERMIT_ALL,
    {
      variables: {
        permit: WORK_PERMIT_TYPES.REPLACEMENT,
      },
      onCompleted: (d) => {
        console.log(
          "emr count: ",
          d?.expatriate_work_permits_aggregate?.aggregate?.count
        );
        setPendingCount(d?.expatriate_work_permits_aggregate?.aggregate?.count);
      },
    }
  );
  // ========================================================END EMR====================================================

  const handleFianceFilter = (e) => {
    if (e) {
      searchFinance({
        variables: {
          searchVal: e?.filterValue,
          permit: WORK_PERMIT_TYPES.REPLACEMENT,
          employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
        },
      });
    }
  };
  const handleFilter = (e) => {
    switch (currentRole) {
      case roles.WP_DESK_HANDLER:
        setCurrentTab("not_assigned");
        searchRenewalPermit({
          variables: {
            searchValue: e?.filterValue,
          },
        });
        break;
      case roles.WP_DOC_CHECKER:
        setSelectedTab("pending");
        handleDocumentHandlerFilter(e);
        break;
      case roles.WP_FINANCE:
        console.log("finance filter");
        handleFianceFilter(e);
        break;
      case roles.WP_EMR_GENERATOR:
        handleEMRGeneratorHandlerFilter(e?.filterValue);
        console.log("emr generator filter");
        break;

      default:
        break;
    }
  };

  /**
   * @description verify document Bank graphQl query
   */
  const [verify_doc, { fin_data, loading: signInLoading }] = useMutation(
    VERIFY_EXPATRIATE_DOCUMENT,
    {
      onError(err) {
        const error = `${err}`.split(":").reverse()[0];
        console.log(error.trim());
      },
      onCompleted(d) {
        console.log(d);
      },
    }
  );

  /**
   * @description reject bank slip
   * @access wp:finance
   */
  const handleReject = async (w) => {
    console.log("rejecte document", w, modalData);
    await verify_doc({
      variables: {
        id: modalData?.id,
        review_status: false,
        review_note: w?.message,
      },
    }).then(() => {
      // toast.SuccessToast('Finance Docuemnt Verified Successfully')
      toast.warn("Finance Document Rejected!");
      handleClose();
    });
  };

  const handleClose = () => {
    setShowModal(false);
    //get applicant data
    getTotalPendingPermits({ fetchPolicy: "network-only" });
  };

  /**
   * @description handle verify button Click on finance
   * @access wp:finance
   */
  const handleVerify = async () => {
    await verify_doc({
      variables: { id: modalData?.id, review_status: true },
    }).then(() => {
      handleClose();
      toast.success("Finance Document verified successfully");
    });
  };

  const handleAssign = (c) => {
    setLoadingRows((prevLoadingRows) => [...prevLoadingRows, c]);
    assign({ variables: { id: c } }).then((re) => {
      if (re.errors) {
        toast.error("Permit not Assigned");
      } else {
        // toast.success("Assigned for FInance and Doc checker");
        getNotAssignedList({ fetchPolicy: "network-only" });
        getAssignedList({ fetchPolicy: "network-only" });
        setLoadingRows((prevLoadingRows) =>
          prevLoadingRows.filter((rowId) => rowId !== c)
        );
      }
    });
  };
  const handlePopUp = (data) => {
    console.log("====================================");
    // console.log(data);
    console.log("====================================");
    // setOpenReplacementModal(!openReplacementModal);
  };

  const handlePopUpEMR = (data) => {
    console.log("====================================");
    console.log(data);
    console.log("====================================");
    setModalData(data);
    setOpenReplacementModal(!openReplacementModal);
  };

  //pagination
  const handleNextPage = () => {};
  const handlePageChange = () => {};
  const handlePreviousPage = () => {};
  const handleRefreshTable = () => {};
  const handlePageLimit = () => {};

  useEffect(() => {
    if (currentRole === roles.WP_DESK_HANDLER) {
      countNotAssigned();
      countAssigned();
      getAssignedList();
      getNotAssignedList();
    }

    if (currentRole === roles.WP_DOC_CHECKER) {
      getAllAssignedPermits();
      getAllApprovedPermits();
      getAllRejectedPermits();
      getTotalPendingPermits();
      getTotalApprovePermits();
      getTotalRejectedPermits();
    }
    if (currentRole === roles.WP_FINANCE) {
      console.log("click replacement");
      getAllAssignedPermits();
      getAllApprovedPermits();
      getAllRejectedPermits();
      getTotalPendingPermits();
      getTotalApprovePermits();
      getTotalRejectedPermits();
    }
    if (currentRole === roles.WP_EMR_GENERATOR) {
      getRenewalVerifiedPermits();
      getTotalAssignedPermitsAll();
    }
  }, [currentRole]);

  const handleBankSlip = (c) => {
    // console.log("_ bank", c);
    const reviewedDocument =
      c?.expatriate_work_permit?.expatriate_work_permit_documents
        ?.filter(
          (e) =>
            e?.document_type?.id === BANK_SLIP_ID && e?.review_status === true
        )
        ?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at))[0];

    if (reviewedDocument) {
      return reviewedDocument;
    }

    return c?.expatriate_work_permit?.expatriate_work_permit_documents
      ?.filter((e) => e?.document_type?.id === BANK_SLIP_ID)
      ?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at))
      .map((i) => i)[0];
  };
  const handleReviewStatus = (c) => {
    let r = handleBankSlip(c);
    // console.log(r);
    if (r?.review_status === true) {
      return "Verifed";
    }
    if (r?.review_status === false) {
      return "Rejected";
    }
    if (r?.review_status === null) {
      return "Pending";
    }
  };

  const openModal = (e) => {
    console.log("slip", e);
    setShowModal(true);
    let files = handleBankSlip(e?.row?.original);
    setModalData({
      files: files?.files,
      id: files?.id,
      organization:
        e?.row?.original?.expatriate_work_permit?.organization?.name,
      name:
        e.row.original?.expatriate_work_permit?.expatriate?.first_name +
        " " +
        e?.row?.original?.expatriate_work_permit?.expatriate?.father_name,
      reference_number:
        e?.row?.original?.expatriate_work_permit?.reference_number,
    });
  };

  /* @description handle previous page
   * @access all
   */
  const handlePreviousPageEMR = () => {
    if (pending_offset > 0) {
      setPendingOffset(pending_offset - 1);
    }
  };
  const handleNextPageEMR = () => {
    setPendingOffset(pending_offset + 1);
  };

  const handlePageChangeEMR = (newNum) => {
    setPendingOffset(newNum);
  };
  const handlePageLimitEMR = (val) => {
    setPendingLimit(val);
  };

  const handleRefershTable = () => {
    getRenewalVerifiedPermits({ fetchPolicy: "network-only" });
  };
  const handlePreview = (id) => {
    if (id) {
      navigate(`/permit/d/${id}`);
    }
  };

  const [updateReplacement, {}] = useMutation(UPDATE_REPLACEMENT, {
    onCompleted(data) {
      console.log(data);
      setOpenReplacementModal(false);
      setModalData([])
      toast.success("Replacement Update Successfully");
    },
    onError(err) {
      err.graphQLErrors.forEach((er) => {
        toast.error(er.message);
      });
    },
  });
  const handleSubmitApplication = (data) => {
    console.log("====================================");
    console.log("submitted application", {
      ...data,
      emr_number: modalData?.emr,
    });
    console.log("====================================");
    updateReplacement({
      variables: {
        permitID: modalData?.id,
        expiryDate: data?.expiryDate,
        issueData: data?.requestedDate,
        job: data?.job
      },
    });
  };

  return (
    <div>
      <FilterBox
        placeholder={`${
          currentRole === roles.WP_EMR_GENERATOR
            ? "EMR Number"
            : currentRole === roles.WP_FINANCE
            ? "Reference Number, Tin Number and passport"
            : currentRole === roles.WP_DOC_CHECKER
            ? "Tin Number, Passport Number or First Name"
            : "First Name and Passport Number"
        }`}
        handleReset={resetFilter}
        handleFilterData={handleFilter}
      />

      {currentRole === roles.WP_DESK_HANDLER && (
        <>
          <div className="mb-5 text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-200">
            <ul className="flex flex-wrap -mb-px">
              <li className="mr-2">
                <button
                  onClick={() => setCurrentTab("not_assigned")}
                  className={`${
                    currentTab === "not_assigned"
                      ? "text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                      : "hover:text-gray-600 hover:border-gray-300 border-transparent"
                  } inline-block p-4 border-b-2 rounded-t-lg`}
                >
                  Not Assigned
                </button>
              </li>
              <li className="mr-2">
                <button
                  onClick={() => setCurrentTab("assigned")}
                  className={`${
                    currentTab === "assigned"
                      ? "text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                      : "hover:text-gray-600 hover:border-gray-300 border-transparent"
                  } inline-block p-4 border-b-2 rounded-t-lg`}
                  aria-current="page"
                >
                  Assigned
                </button>
              </li>
            </ul>
          </div>

          {currentTab === "not_assigned" && (
            <Table
              columns={ApplicantColumns.deskHandlerRenewalApplicantColumn(
                navigate,
                handleAssign,
                loadingRows,
                handlePopUp
              )}
              tableData={replacementPendingList}
              loading={isLoading}
              error={error}
              offset={pending_offset}
              limit={pending_limit}
              totalPages={pendingCount}
              handleNextPage={handleNextPage}
              handlePageChange={handlePageChange}
              handlePreviousPage={handlePreviousPage}
              handleRefersh={handleRefreshTable}
              handlePageLimit={handlePageLimit}
              renderRowSubComponent={renderRowSubComponent}
              title={"Not assigned Replacement:  Work Permit Applicants"}
              headerClassess="bg-primary"
              pageCount={10}
            />
          )}

          {currentTab === "assigned" && (
            <Table
              columns={ApplicantColumns.deskHandlerRenewalApplicantColumn(
                navigate,
                handleAssign,
                loadingRows,
                handlePopUp
              )}
              tableData={replacementApprovedList}
              loading={isLoading}
              error={error}
              offset={approved_offset}
              limit={approved_limit}
              totalPages={approvalCount}
              handleNextPage={handleNextPage}
              handlePageChange={handlePageChange}
              handlePreviousPage={handlePreviousPage}
              handleRefersh={handleRefreshTable}
              handlePageLimit={handlePageLimit}
              renderRowSubComponent={renderRowSubComponent}
              title={"Assigned Replacement:  Work Permit Applicant"}
              headerClassess="bg-primary"
              pageCount={10}
            />
          )}
        </>
      )}

      {[roles.WP_DOC_CHECKER, roles.WP_FINANCE].includes(currentRole) && (
        <>
          <Can I={"action"} a={"filterTab"}>
            {() => (
              <div
                className="my-4 inline-flex rounded-md shadow-sm"
                role="group"
              >
                <button
                  type="button"
                  onClick={() => setSelectedTab("pending")}
                  className={`${
                    selectedTab === "pending"
                      ? "bg-yellow30 text-white hover:opacity-75 border-yellow40 "
                      : "bg-white border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                  } px-4 py-2 text-sm font-medium text-gray-900 border  rounded-l-lg `}
                >
                  Pending
                </button>
                <button
                  type="button"
                  onClick={() => setSelectedTab("rejected")}
                  className={`${
                    selectedTab === "rejected"
                      ? "bg-red30 text-white hover:opacity-75 border-red20 "
                      : "bg-white border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                  } px-4 py-2 text-sm font-medium text-gray-900 border `}
                >
                  Rejected
                </button>
                <button
                  type="button"
                  onClick={() => setSelectedTab("approved")}
                  className={`${
                    selectedTab === "approved"
                      ? "bg-green30 text-white hover:opacity-75 border-green40 "
                      : "bg-white border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                  } px-4 py-2 text-sm font-medium text-gray-900 border rounded-r-lg `}
                >
                  Approved
                </button>
              </div>
            )}
          </Can>

          <Table
            columns={
              currentRole === roles.WP_DOC_CHECKER
                ? ApplicantColumns.documentHandlerApplicantColumn(
                    navigate,
                    handleReviewStatus
                  )
                : ApplicantColumns.financeHandlerApplicantColumn(
                    handleReviewStatus,
                    openModal
                  )
            }
            tableData={
              selectedTab === "pending"
                ? replacementPendingList
                : selectedTab === "approved"
                ? replacementApprovedList
                : replacementRejectedList
            }
            loading={permitLoading}
            offset={
              selectedTab === "pending"
                ? pending_offset
                : selectedTab === "approved"
                ? approved_offset
                : rejected_offset
            }
            // filterOptions={filterOptionTable}
            limit={
              selectedTab === "pending"
                ? pending_limit
                : selectedTab === "approved"
                ? approved_limit
                : rejected_limit
            }
            error={error}
            handleRefersh={() => {}}
            totalPages={
              selectedTab === "pending"
                ? pendingCount
                : selectedTab === "approved"
                ? approvalCount
                : rejectedCount
            }
            handleNextPage={handleNextPage}
            handlePageChange={handlePageChange}
            handlePreviousPage={handlePreviousPage}
            handlePageLimit={handlePageLimit}
            // onFilterChange={handleSelectedFilterOption}
            renderRowSubComponent={renderRowSubComponent}
            title={"Applicant:  Renewal Work Permit Applicants"}
            headerClassess="bg-primary"
            pageCount={10}
          />
        </>
      )}

      {currentRole === roles.WP_EMR_GENERATOR && (
        <>
          <Table
            columns={
              VerifiedApplicants.emrColumnsRenewal(
                navigate,
                handlePopUpEMR,
                handlePreview
              ) //to be replaced
            }
            tableData={replacementPendingList}
            loading={emrLoading}
            offset={pending_offset}
            // filterOptions={filterOptionTable}
            limit={pending_limit}
            error={error}
            handleRefersh={handleRefershTable}
            totalPages={pendingCount}
            handleNextPage={handleNextPageEMR}
            handlePageChange={handlePageChangeEMR}
            handlePreviousPage={handlePreviousPageEMR}
            handlePageLimit={handlePageLimitEMR}
            // onFilterChange={handleSelectedFilterOption}
            renderRowSubComponent={renderRowSubComponent}
            title={"Work Permits:  Replacement Work Permit Applicants"}
            headerClassess="bg-primary"
            pageCount={0}
          />
        </>
      )}

      {/* Financial Modal */}
      {showModal && (
        <FinanceDocument
          handleReject={handleReject}
          handleVerify={handleVerify}
          data={modalData}
          handleClick={handleClose}
        />
      )}

      {openReplacementModal && (
        <EMRReplacementModal
          handleApplication={handleSubmitApplication}
          modalData={modalData}
          handleCancelModal={() => setOpenReplacementModal(false)}
        />
      )}
    </div>
  );
};

export default Placement;
