import React, { createRef, useEffect, useRef, useState } from "react";
import FormLabel from "../common/FormLabel";
import { BsBuilding, BsGlobe, BsPinFill } from "react-icons/bs";
import { FaFileUpload } from "react-icons/fa";
import { MdDashboard, MdOutlineDelete } from "react-icons/md";
import { FormProvider, CInputField, CSelectField } from "./Fields";
import CButton from "./CButton";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_ORGANIZATION_DATA_UPDATE,
  INSERT_ORGANIZATION_DOC,
  UPDATE_ORGANIZATION_DOC,
  UPLOAD_FILE,
} from "../../graph-query/mutation";
import { useLocalStore } from "../../store";
import {
  BASE_SECTORS,
  BASE_REGION,
  BASE_ORGANIZATIOIN_TYPE,
  BASE_DISTRICT,
  BASE_SUBCITY,
  BASE_ZONE,
  BASE_WOREDA,
} from "../../graph-query/queries";
import ChooseDocumentModal from "../utilities/ChooseDocumentModal";
import { VscFilePdf } from "react-icons/vsc";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { getBase64 } from "../../utils/utilityFunctions";
import { AddisID } from "../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateOrganizationProfileSchema } from "../../utils/SchemaValidation";
import { BASE_ORG_DOCUMENT_TYPES } from "../../graph-query/queries";
import { useForm } from "react-hook-form";
const OrganizationDataUpdate = ({ defaultValue }) => {
  // const titlesData = ["Hello Data", "Test Data"];
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [tempFiles, setTempFile] = useState([]);
  const [updateVal, setUpdateVal] = useState([]);

  const [formDisabled, setDisabledForm] = useState(false);

  const {
    sectors,
    regions,
    organizationType,
    profileFileType,
    subcities,
    districts,
    zones,
    woredas,
    setSectors,
    setRegions,
    setOrganizationType,
    setDistricts,
    setSubCities,
    setZones,
    setWoredas,
    userOrganizationData,
    orgDocumentTypes,
    setOrgDocumentTypes,

    filterDocumentTypes,
  } = useLocalStore();

  const navigator = useNavigate();
  const {
    register,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(CreateOrganizationProfileSchema),
    defaultValues: {
      ...userOrganizationData,
      organization_type_id: userOrganizationData?.organization_type?.id,
      zone_id: userOrganizationData?.zone?.id,
      sector_id: userOrganizationData?.sector?.id,
      region_id: userOrganizationData?.region?.id,
      district_id: userOrganizationData?.district?.id,
      subcity_id: userOrganizationData?.subcity?.id,
      woreda_id: userOrganizationData?.woreda?.id,
      phone_no:
        userOrganizationData?.phone_no &&
        userOrganizationData?.phone_no[0]
          ?.substring(3)
          ?.replace(/\\/g, "")
          .replace(/"/g, ""),
    },
  });

  useEffect(() => {
    if (userOrganizationData) {
      setValue(
        "organization_type_id",
        userOrganizationData?.organization_type?.id
      );
      setValue("zone_id", userOrganizationData?.zone?.id);
      setValue("sector_id", userOrganizationData?.sector?.id);
      setValue("region_id", userOrganizationData?.region?.id);
      setValue("district_id", userOrganizationData?.district?.id);
      setValue("subcity_id", userOrganizationData?.subcity?.id);
      setValue("woreda_id", userOrganizationData?.woreda?.id);
      setValue("name", userOrganizationData?.name);
      setValue("email", userOrganizationData?.email);
      setValue("fax", userOrganizationData?.fax);
      setValue("house_number", userOrganizationData?.house_number);
      setValue("pobox", userOrganizationData?.pobox);
      setValue(
        "local_employees_count",
        userOrganizationData?.local_employees_count
      );
      setValue("expatriates_count", userOrganizationData?.expatriates_count);
      setValue("expatriates_count", userOrganizationData?.expatriates_count);
      setValue(
        "business_license_number",
        userOrganizationData?.business_licence_number
      );
      setValue(
        "business_license_issue_date",
        userOrganizationData?.business_licence_issued_date
      );
      setValue(
        "business_license_expiry_date",
        userOrganizationData?.business_licence_expiry_date
      );
      setValue("tin_number", userOrganizationData?.tin_number);
      setValue(
        "phone_no",
        userOrganizationData?.phone_no &&
          userOrganizationData?.phone_no[0]
            ?.substring(3)
            ?.replace(/\\/g, "")
            .replace(/"/g, "")
      );
    }
  }, [userOrganizationData, setValue]);

  const { id } = useParams();

  const [fileErrors, setFileErrors] = useState({});
  const [documentFiles, setDocumentFiles] = useState([]);

  const fileRef = useRef();
  const fileRef2 = useRef();
  const fileRef3 = useRef();

  // watch region if aa is selected
  const selectedRegion = watch("region_id");
  const selectedSubCity = watch("subcity_id");
  const selectedZone = watch("zone_id");

  const [fileUpload, { loading: uploadLoading }] = useMutation(UPLOAD_FILE);

  const [getSectors, {}] = useLazyQuery(BASE_SECTORS, {
    onCompleted: (data) => {
      console.log("sector data:", data?.base_sectors);
      setSectors(data?.base_sectors);
    },
  });

  const [getRegions, {}] = useLazyQuery(BASE_REGION, {
    onCompleted: (data) => {
      console.log("regions data:", data?.base_regions);
      setRegions(data?.base_regions);
    },
  });

  const [getOrgType, {}] = useLazyQuery(BASE_ORGANIZATIOIN_TYPE, {
    onCompleted: (data) => {
      console.log("organization type data:", data?.base_organization_types);
      setOrganizationType(data?.base_organization_types);
    },
  });

  const [getDistrict, {}] = useLazyQuery(BASE_DISTRICT, {
    variables: {
      zone: selectedZone,
    },
    onCompleted: (data) => {
      console.log("districts data:", data?.base_districts);
      setDistricts(data?.base_districts);
    },
  });

  const [getSubCity, {}] = useLazyQuery(BASE_SUBCITY, {
    variables: {
      region: selectedRegion,
    },
    onCompleted: (data) => {
      console.log("subcities data:", data?.base_subcities);
      setSubCities(data?.base_subcities);
    },
  });

  const [getWoreda, {}] = useLazyQuery(BASE_WOREDA, {
    variables: {
      subcity: selectedSubCity,
    },
    onCompleted: (data) => {
      console.log("woreda data:", data?.base_woredas);
      setWoredas(data?.base_woredas);
    },
  });

  const [getZone, {}] = useLazyQuery(BASE_ZONE, {
    variables: { region: selectedRegion },
    onCompleted: (data) => {
      console.log("zones data:", data?.base_zones);
      setZones(data?.base_zones);
    },
  });

  useEffect(() => {
    if (sectors?.length === 0) {
      getSectors();
    }
  }, [sectors, setSectors]);

  useEffect(() => {
    if (regions?.length === 0) {
      getRegions();
    }
  }, [regions, setRegions]);

  useEffect(() => {
    if (organizationType?.length === 0) {
      getOrgType();
    }
  }, [organizationType, setOrganizationType]);

  useEffect(() => {
    if (selectedRegion !== "") {
      setDistricts([]);
      setZones([]);
      setSubCities([]);
      getDistrict(selectedZone);
      getSubCity(selectedRegion);
    }
  }, [selectedRegion]);

  useEffect(() => {
    if (selectedSubCity !== "") {
      getWoreda(selectedSubCity);
    }
  }, [selectedSubCity]);

  useEffect(() => {
    if (subcities?.length === 0) {
      // Load from server
      getSubCity();
    }
  }, [subcities, setSubCities]);

  useEffect(() => {
    if (zones?.length === 0) {
      getZone();
    }
  }, [zones, setZones]);

  useEffect(() => {
    if (orgDocumentTypes?.length === 0) {
      getDocType();
    }
  }, [orgDocumentTypes, setOrgDocumentTypes]);

  const [getDocType, {}] = useLazyQuery(BASE_ORG_DOCUMENT_TYPES, {
    variables: {},
    onCompleted: (d) => {
      setOrgDocumentTypes(d?.base_organization_document_types);
    },
  });

  // const fileRef = useRef();

  //handle file change
  const fileChange = (event) => {
    event.preventDefault();

    const files = event.target.files;
    const newFiles = Array.from(files).map((file) => ({
      file,
      title: file.name,
      path: URL.createObjectURL(file),
    }));
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemoveFile = (removeData) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file !== removeData)
    );
  };

  const [updateOrg, { error, loading: orgLoad }] = useMutation(
    CREATE_ORGANIZATION_DATA_UPDATE
  );

  const [updateOrgDOC, { error: docErr }] = useMutation(
    INSERT_ORGANIZATION_DOC,
    {
      onCompleted: (_res) => {
        navigator("/workspace");
      },
      onError: (er) => {
        console.log(er);
        // toast.error("Something happened while creating organization");
      },
    }
  );

  const onSubmit = (data) => {
    if (data.district_id === "") {
      data.district_id = null;
    }
    if (data.zone_id === "") {
      data.zone_id = null;
    }

    let finalData = {
      ...data,
      phone_no: ["251" + data.phone_no],
      // user_id: JSON.parse(localStorage.getItem('work-permit'))?.userID,
      // created_by: JSON.parse(localStorage.getItem('work-permit'))?.userID,
      // organization_documents: organizationDocuments,
    };
    console.log(finalData, selectedFiles);
    let r = orgDocumentTypes?.map((i) => i.name);
    console.log(r);
    console.log(r?.some((f) => selectedFiles.some((e) => e.title === f)));
    let fileD = [];
    if (selectedFiles?.length !== 0) {
      if (r?.some((f) => selectedFiles.some((e) => e.title === f))) {
        const uploadPromises = selectedFiles.map((i) => {
          return new Promise((resolve, reject) => {
            filesUpload(
              i?.file,
              (filePath) => {
                setTempFile((prevFiles) => [
                  ...prevFiles,
                  {
                    ...filePath,
                    document_type_id: i.document_type_id,
                    title: i.title,
                  },
                ]);
                fileD.push({
                  ...filePath,
                  document_type_id: i.document_type_id,
                  title: i.title,
                });
                resolve(filePath);
              },
              reject
            );
          });
        });

        console.log(finalData);
        Promise.all(uploadPromises)
          .then((uploadedFiles) => {
            updateOrg({
              variables: {
                ...finalData,
                id: id,
              },
              onCompleted: async (data) => {
                console.log(data);
                toast.success("Organization Update successfully");
                tempFiles.map(async (i) => {
                  console.log(i);
                  await updateOrgDOC({
                    variables: {
                      orgID: data?.update_organizations?.returning[0]?.id,
                      document_type: i?.document_type_id,
                      files: i,
                    },
                  });
                  navigator("/organization");
                });
                fileD.map(async (i) => {
                  console.log("_____upload data: ", i);
                  await updateOrgDOC({
                    variables: {
                      orgID: data?.update_organizations?.returning[0]?.id,
                      document_type: i?.document_type_id,
                      files: i,
                    },
                  });
                  navigator("/organization");
                });
              },
              onError: (err) => {
                console.log("error: ", err);
                toast.error("error updating organization");
              },
            });
          })
          .catch((error) => {
            console.log("error uploading files: ", error);
            toast.error("error uploading files");
          });
      } else {
        toast.info("Please Upload All Document Files");
      }
    } else {
      toast.warn("Select all Document");
    }
  };

  // Upload file
  const filesUpload = (uploadedFile, cb) => {
    getBase64(uploadedFile)
      .then((result) => {
        console.log(result);
        // extract the file type to have the folowing pdf,png ...
        let fileType = (uploadedFile?.type).split("/").length
          ? (uploadedFile?.type).split("/")[1]
          : "";
        // upload the file to the server
        trackPromise(
          fileUpload({
            variables: {
              extension: fileType,
              file: result,
            },
            onCompleted(data) {
              console.log(data);
              cb &&
                cb({ name: uploadedFile.name, path: data.uploadFile.info.Key });
              toast.success("Document Uploaded Successfully!");
            },
            onError(error) {
              toast.error("Document upload failed!");
            },
          })
        );
      })
      .catch((err) => {
        // handle error
      });
  };

  const handleFileUpload = async () => {
    console.log(files);
    // upload data selected one
    await files.map((i) => {
      filesUpload(
        i?._file,
        (filePath) =>
          setTempFile((prevFiles) => [
            ...prevFiles,
            { ...filePath, title: i?.title[0], document_type_id: i.typeID },
          ])
        // setTempFile([...tempFiles, { ...filePath, title: i?.title }])
      );
    });
    setUploadModal(false);
  };

  //Handle file upload
  const handleOrganizationDocument = async (id) => {
    await tempFiles.map(async (i) => {
      console.log(i);
      await updateOrgDOC({
        variables: {
          orgID: id,
          document_type: i?.document_type_id,
          files: i,
        },
      });
      navigator("/organization");
    });
  };

  const handleTinNo = (_file, e) => {
    console.log("tin", _file, e);
    const file = e.target.files[0];
    if (file) {
      if (file.size > "5MB") {
        setFileErrors({
          ...fileErrors,
          [_file?.id]: `File size should not exceed 5MB`,
        });
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          const fileData = reader.result;
          const existingFile = selectedFiles.find(
            (file) => file.document_type_id === _file?.id
          );
          if (existingFile) {
            const updatedFile = {
              document_type_id: _file?.id,
              title: _file?.name,
              file: file,
            };
            setSelectedFiles((prevFiles) => {
              const updatedFiles = prevFiles.map((file) =>
                file.document_type_id === _file?.id ? updatedFile : file
              );
              return updatedFiles;
            });
          } else {
            setSelectedFiles((prevFiles) => [
              ...prevFiles,
              {
                document_type_id: _file?.id,
                title: _file?.name,
                file: file,
              },
            ]);
          }
        };
        reader.readAsDataURL(file);
        setFileErrors({
          ...fileErrors,
          [_file?.id]: undefined,
        });
      }
    }
  };

  const handleBusiness = (_file, e) => {
    console.log("busin", _file, e);
    const file = e.target.files[0];
    if (file) {
      if (file.size > "5MB") {
        setFileErrors({
          ...fileErrors,
          [_file?.id]: `File size should not exceed 5MB`,
        });
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          const fileData = reader.result;
          const existingFile = selectedFiles.find(
            (file) => file.document_type_id === _file?.id
          );
          if (existingFile) {
            const updatedFile = {
              document_type_id: _file?.id,
              title: _file?.name,
              file: file,
            };
            setSelectedFiles((prevFiles) => {
              const updatedFiles = prevFiles.map((file) =>
                file.document_type_id === _file?.id ? updatedFile : file
              );
              return updatedFiles;
            });
          } else {
            setSelectedFiles((prevFiles) => [
              ...prevFiles,
              {
                document_type_id: _file?.id,
                title: _file?.name,
                file: file,
              },
            ]);
          }
        };
        reader.readAsDataURL(file);
        setFileErrors({
          ...fileErrors,
          [_file?.id]: undefined,
        });
      }
    }
  };

  const handleReg = (_file, e) => {
    console.log("regis", _file, e);
    const file = e.target.files[0];
    if (file) {
      if (file.size > "5MB") {
        setFileErrors({
          ...fileErrors,
          [_file?.id]: `File size should not exceed 5MB`,
        });
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          const fileData = reader.result;
          const existingFile = selectedFiles.find(
            (file) => file.document_type_id === _file?.id
          );
          if (existingFile) {
            const updatedFile = {
              document_type_id: _file?.id,
              title: _file?.name,
              file: file,
            };
            setSelectedFiles((prevFiles) => {
              const updatedFiles = prevFiles.map((file) =>
                file.document_type_id === _file?.id ? updatedFile : file
              );
              return updatedFiles;
            });
          } else {
            setSelectedFiles((prevFiles) => [
              ...prevFiles,
              {
                document_type_id: _file?.id,
                title: _file?.name,
                file: file,
              },
            ]);
          }
        };
        reader.readAsDataURL(file);
        setFileErrors({
          ...fileErrors,
          [_file?.id]: undefined,
        });
      }
    }
  };

  const handleCancelModal = () => {
    setUploadModal(false);
    setFiles([]);
  };

  /**
   * @description sort organization document while sorting the latest one
   * @param {*} documents
   * @param {*} title
   * @returns
   */
  const getLatestDocumentStatus = (documents, title) => {
    try {
      const filteredDocuments = documents?.filter(
        (doc) => doc?.files?.title === title
      );
      const sortedDocuments = filteredDocuments?.sort(
        (a, b) => new Date(b?.created_at) - new Date(a?.created_at)
      );

      if (sortedDocuments?.length === 0) {
        // console.log(`No document found with title "${title}"`);
        return;
      }

      const latestDocument = sortedDocuments[0];
      // console.log(
      //   `The latest document with title "${title}" has status "${latestDocument?.review_status}"`
      // );
      return latestDocument?.review_status;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userOrganizationData?.review_status === true) {
      setDisabledForm(false);
    }
  }, []);

  return (
    <>
      {/* Organization Data Form */}
      {uploadModal && (
        <ChooseDocumentModal
          optionData={orgDocumentTypes}
          files={files}
          setFiles={setFiles}
          onFileUpload={handleFileUpload}
          handleCancel={handleCancelModal}
        />
      )}

      <div className="flex flex-col gap-4 items-start h-full w-full">
        <div className="flex flex-col gap-1 items-start pb-10">
          <p className="text-3xl font-bold text-N40">Organization Data</p>
          <p className="text-xs font-light w-full md:w-2/3 text-N60">
            Organization must fulfill the Organization Data in order to create
            the work permit process.
          </p>
        </div>
        <div className="w-full">
          <FormProvider
            onSubmithandler={handleSubmit(onSubmit)}
            className="w-full flex flex-wrap"
          >
            <div className="flex w-full flex-wrap justify-between items-start">
              <div className="flex flex-col gap-4 flex-wrap items-start h-full w-full md:w-3/5">
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<BsBuilding />}
                    labelTitile="Organization Name"
                    labelDescription="Organization name"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CInputField
                        name="name"
                        register={register}
                        errors={errors}
                        // defaultValue={
                        //   userOrganizationData?.name
                        // }
                        disabled={formDisabled}
                        validation={{
                          required: "Organization Name is required",
                        }}
                        type="text"
                        label=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                {/* Type Of Organization  */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<MdDashboard />}
                    labelTitile="Type Of Organization"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CSelectField
                        name="organization_type_id"
                        register={register}
                        errors={errors}
                        disabled={formDisabled}
                        // defaultValue={
                        //   userOrganizationData?.organization_type?.id
                        // }
                        validation={{
                          required: "Type of Organization is required",
                        }}
                        options={organizationType?.map((types) => {
                          return {
                            ...types,
                            value: types?.id,
                          };
                        })}
                        label=""
                        placeholder="Select"
                      />
                    </div>
                  </div>
                </div>

                {/* Sector Of Organization  */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel labelIcon={<MdDashboard />} labelTitile="Sector" />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CSelectField
                        name="sector_id"
                        register={register}
                        errors={errors}
                        disabled={formDisabled}
                        // defaultValue={userOrganizationData?.sector?.id}
                        validation={{
                          required: "Sector is required",
                        }}
                        options={sectors?.map((types) => {
                          return {
                            ...types,
                            name: types?.name_json?.en,
                            value: types?.id,
                          };
                        })}
                        label=""
                        placeholder="Select Sector"
                      />
                    </div>
                  </div>
                </div>

                {/* Region  */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel labelIcon={<BsGlobe />} labelTitile="Region" />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CSelectField
                        name="region_id"
                        register={register}
                        errors={errors}
                        disabled={formDisabled}
                        // defaultValue={userOrganizationData?.region?.id}
                        validation={{ required: "Region is required" }}
                        options={regions?.map((reg) => {
                          return {
                            ...reg,
                            value: reg?.id,
                          };
                        })}
                        label=""
                        placeholder="Select Region"
                      />
                    </div>
                  </div>
                </div>

                {selectedRegion === AddisID && (
                  <>
                    <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                      <FormLabel
                        labelIcon={<MdDashboard />}
                        labelTitile="SubCity"
                        labelDescription="Sub City"
                      />
                      <div className="w-full flex gap-6 py-2">
                        <div className=" w-full">
                          <CSelectField
                            name="subcity_id"
                            register={register}
                            errors={errors}
                            disabled={formDisabled}
                            // defaultValue={userOrganizationData?.subcity?.id}
                            validation={{ required: "SubCity is required" }}
                            options={subcities?.map((sub) => {
                              return {
                                ...sub,
                                name: sub?.name_json?.en,
                                value: sub?.id,
                              };
                            })}
                            label=""
                            placeholder="Select SubCity"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {selectedSubCity && selectedRegion === AddisID && (
                  <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                    <FormLabel
                      labelIcon={<MdDashboard />}
                      labelTitile="Woreda"
                      labelDescription="Woreda"
                    />
                    <div className="w-full flex gap-6 py-2">
                      <div className=" w-full">
                        <CSelectField
                          name="woreda_id"
                          register={register}
                          errors={errors}
                          disabled={formDisabled}
                          // defaultValue={userOrganizationData?.woreda?.id}
                          validation={{ required: "Woreda is required" }}
                          options={woredas?.map((wor) => {
                            return {
                              ...wor,
                              name: wor?.name_json?.en,
                              value: wor?.id,
                            };
                          })}
                          label=""
                          placeholder="Select Woreda"
                        />
                      </div>
                    </div>
                  </div>
                )}

                {selectedRegion !== "" && selectedRegion !== AddisID && (
                  <>
                    <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                      <FormLabel
                        labelIcon={<MdDashboard />}
                        labelTitile="Zone"
                        labelDescription="zone"
                      />
                      <div className="w-full flex gap-6 py-2">
                        <div className=" w-full">
                          <CSelectField
                            name="zone_id"
                            register={register}
                            errors={errors}
                            disabled={formDisabled}
                            // defaultValue={userOrganizationData?.zone?.id}
                            options={zones?.map((dis) => {
                              return {
                                ...dis,
                                value: dis.id,
                                name: dis?.name_json?.en,
                              };
                            })}
                            label=""
                            placeholder="Select Zone"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                      <FormLabel
                        labelIcon={<MdDashboard />}
                        labelTitile="District"
                        labelDescription="district"
                      />
                      <div className="w-full flex gap-6 py-2">
                        <div className=" w-full">
                          <CSelectField
                            name="district_id"
                            register={register}
                            errors={errors}
                            disabled={formDisabled}
                            // defaultValue={userOrganizationData?.district?.id}
                            options={districts?.map((dis) => {
                              return {
                                ...dis,
                                name: dis.name_json?.en,
                                value: dis.id,
                              };
                            })}
                            label=""
                            placeholder="Select District"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* Fax */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<MdDashboard />}
                    labelTitile="Fax"
                    labelDescription="Fax"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CInputField
                        name="fax"
                        register={register}
                        errors={errors}
                        // defaultValue={
                        //   userOrganizationData?.fax
                        // }
                        disabled={formDisabled}
                        validation={{
                          required: true,
                        }}
                        type="text"
                        label=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                {/* PO. Box */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<MdDashboard />}
                    labelTitile="PO. Box"
                    labelDescription="PO. Box"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CInputField
                        name="pobox"
                        register={register}
                        errors={errors}
                        // defaultValue={
                        //   userOrganizationData?.pobox
                        // }
                        disabled={formDisabled}
                        validation={{
                          required: "Po.Box is required",
                        }}
                        type="text"
                        label=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                {/* Work Phone */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<MdDashboard />}
                    labelTitile="Work Phone"
                    labelDescription="Work Phone"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CInputField
                        name="phone_no"
                        register={register}
                        errors={errors}
                        disabled={formDisabled}
                        validation={{
                          required: "Work Phone is required",
                        }}
                        type="text"
                        label=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                {/* Email Address */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<MdDashboard />}
                    labelTitile="Email Address"
                    labelDescription="Email Address"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CInputField
                        name="email"
                        register={register}
                        errors={errors}
                        disabled={formDisabled}
                        validation={{
                          required: "Email is required",
                        }}
                        type="email"
                        label=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                {/* House Number */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<MdDashboard />}
                    labelTitile="House Number"
                    labelDescription="House Number"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CInputField
                        name="house_number"
                        register={register}
                        disabled={formDisabled}
                        errors={errors}
                        validation={{
                          required: "House Number is required",
                        }}
                        type="text"
                        label=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                {/* Number of Employees */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<MdDashboard />}
                    labelTitile="Number of Employees"
                    labelDescription="Number of Employees"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CInputField
                        name="local_employees_count"
                        register={register}
                        errors={errors}
                        disabled={formDisabled}
                        validation={{
                          required: "Number of Employee is required",
                        }}
                        type="number"
                        label=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                {/* Number of Expatriates */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<MdDashboard />}
                    labelTitile="Number of Expatriates"
                    labelDescription="Number of Expatriates"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CInputField
                        name="expatriates_count"
                        register={register}
                        errors={errors}
                        disabled={formDisabled}
                        validation={{
                          required: "Number of Expatriates is required",
                        }}
                        type="number"
                        label=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                {/* Business License Number */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<MdDashboard />}
                    labelTitile="Business License Number"
                    labelDescription="Business License Number"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CInputField
                        name="business_license_number"
                        register={register}
                        errors={errors}
                        disabled={formDisabled}
                        validation={{
                          required: "Business License Number is required",
                        }}
                        type="text"
                        label=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<MdDashboard />}
                    labelTitile="Business License Issued Date"
                    labelDescription="Enter Business License Issued Date"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CInputField
                        name="business_license_issue_date"
                        register={register}
                        errors={errors}
                        disabled={formDisabled}
                        validation={{
                          required:
                            "Issued Date of Business License is required",
                        }}
                        type="date"
                        label=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<MdDashboard />}
                    labelTitile="Business License Expiry Date"
                    labelDescription="Business License Expiry Date"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CInputField
                        name="business_license_expiry_date"
                        register={register}
                        errors={errors}
                        disabled={formDisabled}
                        validation={{
                          required: "Business License expiry date is required",
                        }}
                        type="date"
                        label=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                {/* Tin Number */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<MdDashboard />}
                    labelTitile="Tin Number"
                    labelDescription="Tin number"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CInputField
                        name="tin_number"
                        register={register}
                        disabled={formDisabled}
                        errors={errors}
                        validation={{
                          required: "Tin Number is required",
                        }}
                        type="text"
                        label=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-1/3 h-full border-dashed border-2 bg-Prime99/50 rounded-lg p-4">
                <h1 className="py-4 font-semibold">Upload File</h1>
                <div className="flex flex-col gap-1">
                  <p className="text-xs text-N70 font-light">
                    File types SHould be
                  </p>

                  <div className="flex gap-1 pl-4 items-center">
                    <BsPinFill className="text-N70 text-xs -rotate-45" />
                    <p className="text-xs text-N70">Pdf</p>
                  </div>
                  <div className="flex gap-1 pl-4 items-center">
                    <BsPinFill className="text-N70 text-xs -rotate-45" />
                    <p className="text-xs text-N70">jpeg | jpg | png</p>
                  </div>
                </div>

                {/* {userOrganizationData?.organization_documents
                  ?.filter(
                    (i) =>
                      i.files?.title === "TIN Certificate" &&
                      i?.review_status === false
                  )
                  ?.map((item) => ( */}
                {orgDocumentTypes.map((item) => (
                  <div
                    className={`${
                      getLatestDocumentStatus(
                        userOrganizationData?.organization_documents,
                        item?.name
                      ) === false
                        ? ""
                        : "hidden"
                    } w-full flex md:flex-row flex-col gap-4 md:gap-6 py-2`}
                  >
                    <div className=" w-full ">
                      <div className="flex flex-col gap-2 bg-white p-6 rounded-t-xl">
                        <p className="text-primary text-sm font-semibold">
                          Upload {item.name}
                        </p>
                        <div
                          className={`relative bg-primary/5 border-dashed border-2 cursor-pointer rounded-lg px-8 py-12 mt-4 gap-3 ${
                            fileErrors[orgDocumentTypes[0]?.id] &&
                            "border-red-500"
                          }`}
                        >
                          <input
                            onChange={(e) =>
                              item.name === "TIN Certificate"
                                ? handleTinNo(item, e)
                                : item.name === "Business License Certificate"
                                ? handleBusiness(item, e)
                                : item.name === "Certificate of Registration"
                                ? handleReg(item, e)
                                : ""
                            }
                            ref={
                              item.name === "TIN Certificate"
                                ? fileRef
                                : item.name === "Business License Certificate"
                                ? fileRef2
                                : item.name === "Certificate of Registration"
                                ? fileRef3
                                : ""
                            }
                            type="file"
                            className="hidden"
                          />
                          <div
                            onClick={() =>
                              item.name === "TIN Certificate"
                                ? fileRef.current.click()
                                : item.name === "Business License Certificate"
                                ? fileRef2.current.click()
                                : item.name === "Certificate of Registration"
                                ? fileRef3.current.click()
                                : ""
                            }
                            className="absolute top-0 left-0 right-0 flex flex-col items-center justify-center px-8 py-4 gap-3"
                          >
                            <FaFileUpload className="text-primary/90 text-4xl" />
                            <p className="text-N70 text-xs md:px-6 text-center">
                              Choose document type
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <h2 className="font-semibold text-lg my-2">
                  Uploaded Document Status
                </h2>
                {userOrganizationData?.organization_documents?.map(
                  (o, index) => (
                    <div
                      key={index}
                      className={`${
                        o?.review_status === true
                          ? "bg-green20/20"
                          : o?.review_status === false
                          ? "bg-red20/25"
                          : ""
                      } border-b mb-4 border-N99/50 rounded-md p-4 flex justify-between flex-col items-start w-full`}
                    >
                      <div className="flex gap-2 items-center">
                        <VscFilePdf />
                        <div>
                          <p className="text-xs text-N50">{o?.files?.title}</p>
                          <p className="text-[10px] text-N50">
                            {o?.files?.name}
                          </p>
                          <span
                            className={`${
                              o?.review_status === true
                                ? "bg-green text-white"
                                : o?.review_status === null
                                ? "bg-yellow30 text-white"
                                : "bg-red10 text-fuchsia-50"
                            } px-2 py-0.5 rounded text-xs bg-blue-100`}
                          >
                            {o?.review_status
                              ? "Approved"
                              : o?.review_status === false
                              ? "Rejected"
                              : o?.review_status === null
                              ? "Pending"
                              : ""}
                          </span>
                        </div>
                      </div>
                      <p className="text-sm  text-red10 mt-4">
                        {o?.review_note}
                      </p>
                    </div>
                  )
                )}
                {selectedFiles && (
                  <div className="mt-5">
                    {/* <h1>New Selected File</h1> */}
                    {selectedFiles &&
                      selectedFiles?.map((item, index) => (
                        <div
                          key={index}
                          className="border-b border-N99/50 p-4 flex justify-between items-center w-full"
                        >
                          <div className="flex gap-2 items-center">
                            <VscFilePdf />
                            <div>
                              <p className="text-xs text-N50">
                                {item.title}.pdf
                              </p>
                              <p className="text-[10px] text-N50">
                                {item?.size / 1000} KB
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>

            {/* Next Button */}
            <div className="w-full md:w-1/4 flex justify-end py-10">
              <CButton
                type="submit"
                loading={orgLoad}
                btnLabel={"Update"}
                classes=" w-1/3 py-2 flex justify-center items-center text-white bg-primary rounded-sm text-sm"
              />
            </div>
          </FormProvider>
        </div>
      </div>
    </>
  );
};

export default OrganizationDataUpdate;
