import React, { useState, useEffect } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { CgOrganisation } from "react-icons/cg";
import FormLabel from "../../common/FormLabel";
import { useLocalStore } from "../../../store";
import { CInputField, FormProvider } from "../Fields";
import { useForm } from "react-hook-form";
import { useLazyQuery } from "@apollo/client";
import {
  ExtractFileTypeAndNameFromBase64,
  getBase64,
} from "../../../utils/utilityFunctions";
import { useNavigate } from "react-router-dom";
import { GET_ORGANIZATION } from "../../../graph-query/queries";
import { WORK_PERMIT_TYPES, WorkPermitForms } from "../../../utils/constants";
import CButton from "../CButton";
import { MdWarning } from "react-icons/md";
import FileInput from "../Fields/FileInput";
import { toast } from "react-toastify";
import UploadSingleFile from "../../uploads/UploadFile";

const Finance = ({ changeState, handleFormSubmit, defaultValues }) => {
  const [files, setFiles] = useState([]);
  const [tempFiles, setTempFile] = useState([]);

  const router = useNavigate();

  const {
    setData,
    data,
    permitPrice,
    selectedCurrentOrg,
    userOrganizationData,
    setUserOrganizationData,
  } = useLocalStore();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
  });

  const [getUserData, { error: userErr }] = useLazyQuery(GET_ORGANIZATION, {
    variables: { eq: JSON.parse(localStorage.getItem("work-permit"))?.userID },
    context: {
      headers: {
        "Content-Type": "application/json",
      },
    },
    onCompleted: (data) => {
      setUserOrganizationData(data?.organizations[0]);
    },
  });

  const handleFinanceImage = (file) => {
    if (file) {
      getBase64(file).then((result) => {
        let fileType = (file?.type).split("/").length
          ? (file?.type).split("/")[1]
          : "";
        setTempFile(() => [
          // ...prevFiles,
          {
            file: result,
            fileType: fileType,
            name: file.name,
            document_type_id: "3aff76f9-f65a-4ee2-8a4e-9a551802ad26",
            catagory: "166d1c11-6ce1-41ce-87eb-b83bf8ade238",
            title: "Bank Slip",
          },
        ]);
      });
    }
  };

  const onSubmit = (d) => {
    // console.log("user",userOrganizationData);
    if (tempFiles.length >= 1) {
      let _final = {
        ...d,
        reference_number: d?.reference_number,
        expatriate_work_permit_documents: tempFiles,
      };
      console.log(_final);
      handleFormSubmit(WorkPermitForms.FINANCE, _final);
    } else {
      toast.error("Please attach bank slip!");
    }
  };

  useEffect(() => {
    if (defaultValues) {
      setTempFile(defaultValues?.expatriate_work_permit_documents);
    }
  }, []);

  // useEffect(() => {
  //   if (userOrganizationData.length === 0) {
  //     // Get Data from server
  //     console.log("getting data from server....");
  //     getUserData();
  //   } else {
  //     console.log("getting from store...");
  //   }
  // }, [userOrganizationData, setUserOrganizationData]);

  return (
    <>
      <div className="flex p-4 flex-col gap-4 items-start h-full w-full">
        <div className="flex flex-col gap-1 items-start pb-10">
          <h1 className="text-3xl font-semibold text-N40">
            Financial Document
          </h1>
          <p className="text-xs font-light w-full md:w-2/3 text-N60">
            Organization must fulfill the expatriates functional,
            self-management and special knowledge skill information
          </p>
        </div>
        {/* <div
          class="p-4 mb-4 w-full text-sm text-blue-400 rounded-lg border-2 border-blue-300"
          role="alert"
        >
          <span class="font-medium">Info!</span> Kindly input the transaction
          number for (
          {
            permitPrice
              ?.filter((i) => i?.work_permit_type?.name === "New")
              .map((i) => i?.price)[0]
          }
          ) in the <strong>reference number</strong>, and kindly attach the
          transaction screenshot to the bank slip
        </div> */}
        <div className="w-full">
          <FormProvider
            className="w-full"
            onSubmithandler={handleSubmit(onSubmit)}
          >
            {/* Reference Number */}
            <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
              <FormLabel
                labelIcon={<CgOrganisation />}
                labelTitile="Reference Number"
              />
              <div className="w-full flex md:flex-row flex-col gap-4 md:gap-6 py-2">
                <div className=" w-full">
                  <CInputField
                    name="reference_number"
                    register={register}
                    errors={errors}
                    validation={{ required: "Reference Number is required" }}
                    type="text"
                    label=""
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            {/* Reference Number */}
            <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
              <div className="flex flex-col items-start w-full md:w-[30%] gap-2 text-primary">
                <div className="flex gap-2 items-center">
                  <AiOutlineInfoCircle />
                  <p>Bank Slip</p>
                </div>
                <p className="text-N70 text-[10px] px-6">
                  File types should be Image format
                </p>
              </div>
              <div className="w-full md:w-[70%] flex flex-col">
                <div className="w-full flex md:flex-row flex-col gap-4 md:gap-6 py-2">
                  <div className=" w-full ">
                    <UploadSingleFile
                      type={"image"}
                      defaultValue={
                        defaultValues &&
                        defaultValues?.expatriate_work_permit_documents
                          ?.length > 0 &&
                        ExtractFileTypeAndNameFromBase64(
                          defaultValues?.expatriate_work_permit_documents[0]
                            ?.file
                        )
                      }
                      onChange={(e) => handleFinanceImage(e)}
                    />
                    {/* <FileInput
                      onChange={(e) => handleFinanceImage(e)}
                      enablePreview={true}
                      defaultValue={
                        defaultValues &&
                        defaultValues?.expatriate_work_permit_documents?.length >
                          0 &&
                        ExtractFileTypeAndNameFromBase64(
                          defaultValues?.expatriate_work_permit_documents[0]?.file
                        )
                      }
                      type={"image"}
                    /> */}
                  </div>
                </div>
              </div>
            </div>

            {/* Next Button */}
            <div className="w-full md:w-1/2 flex gap-4  py-10">
              <CButton
                handleClick={() => changeState("Skills")}
                classes=" w-full py-2 flex justify-center items-center text-white bg-gray-400 rounded-sm text-sm"
                btnLabel={"Back"}
                type={"button"}
              />
              <CButton
                classes=" w-full py-2 flex justify-center items-center text-white bg-primary rounded-sm text-sm"
                type={"submit"}
                btnLabel={"Submit"}
              />
            </div>
          </FormProvider>
        </div>
      </div>
    </>
  );
};

export default Finance;
