import React, { useState } from "react";
import {
  IoCloudUploadOutline,
  IoFileTraySharp,
  IoImageOutline,
} from "react-icons/io5";
import { FaFilePdf } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { toast } from "react-toastify";
import { useEffect } from "react";

const FileInput = ({
  onChange,
  type,
  enablePreview,
  previewAsIcon,
  selectedData,
  defaultValue,
}) => {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    console.log("file default:", defaultValue);
    if (defaultValue) {
      setFile(defaultValue);
      setPreview(URL.createObjectURL(defaultValue));
    }
  }, [defaultValue]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    // console.log("selected",file);
    if (file) {
      const allowedTypes =
        type === "image" ? ["image/png", "image/jpeg"] : ["application/pdf"];

      const maxSize = 1 * 1024 * 1024; // 1 MB in bytes

      if (file.size > maxSize) {
        toast.warn("File size exceeds the limit of 1 MB!");
        return;
      }
      if (allowedTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result);
        };
        reader.readAsDataURL(file);
        onChange(file);
      } else {
        toast.warn(
          "Only " +
            (type === "image" ? "PNG or JPEG images" : "PDF files") +
            " are allowed!"
        );
      }
    } else {
      setPreview(null);
      setPreview(null);
      onChange(null);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setPreview(null);
    onChange(null);
  };

  const openFileInNewTab = () => {
    window.open(preview, "_blank");
    return;
  };

  return (
    <div className="border border-dashed border-gray-300 cursor-pointer">
      <div className="relative py-6 px-20 flex flex-col items-center justify-center gap-1 ">
        <>
          {file ? (
            <IoCloudUploadOutline className="text-4xl text-gray-400 font-light" />
          ) : (
            <IoFileTraySharp />
          )}
          <p className="text-xs text-gray-400">
            Drag and drop a file here or click
          </p>
        </>
        {/* )} */}
        <input
          type="file"
          accept={`${
            type === "image" ? "image/png, image/jpeg" : "application/pdf"
          }`}
          // capture
          onChange={handleFileChange}
          className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
        />
        {selectedData && (
          <div className="mt-2 text-sm text-gray-600">
            <p className="truncate">{selectedData.name}</p>
            <p className="truncate">{selectedData.type}</p>
          </div>
        )}
      </div>
      <div className="m-3 border-2 rounded-md p-2">
        {preview && (
          <div className="relative flex gap-3 w-full h-auto mb-4">
            {enablePreview && (
              <>
                <>
                  {file.type === "application/pdf" ? (
                    <FaFilePdf
                      onClick={openFileInNewTab}
                      className="text-4xl text-gray-400"
                    />
                  ) : (
                    <img
                      src={preview}
                      alt="Preview"
                      className="w-24 h-24 object-cover rounded-md"
                      onClick={openFileInNewTab}
                    />
                  )}
                </>
                <div className="flex flex-col">
                  <span className="text-xs">{file?.name}</span>
                  <span className="text-xs">{file?.type}</span>
                </div>
              </>
            )}
            <button
              className="absolute top-2 right-2 rounded-full bg-gray-200 hover:bg-gray-300transition-colors duration-200 ease-in-out"
              onClick={handleRemoveFile}
            >
              <MdCancel />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

FileInput.defaultProps = {
  type: "image",
  enablePreview: false,
  previewAsIcon: false,
};

export default FileInput;
