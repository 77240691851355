function path(root, subpath) {
  return `${root}${subpath}`;
}

const ROOT_DASHBOARD = "/dashboard/";
const ORG_DASHBOARD = "/organization/";
const ROOT_AUTH = "/auth";

export const PATH_PAGE = {
  page404: "/404",
  page505: "/505",
};

// AUTH
export const PATH_AUTH = {
  root: ROOT_AUTH,
  login: path(ROOT_AUTH, "/login"),
  register: path(ROOT_AUTH, "/signup"),
  resetPassword: path(ROOT_AUTH, "/signup"),
  verify: path(ROOT_AUTH, "/signup"),
};

// HOME
export const PATH_DESK_DASHBOARD = {
  root: ROOT_DASHBOARD,
  mainDashboard: {
    root: path(ROOT_DASHBOARD, ""),
    listApplicant: path(ROOT_DASHBOARD, "applicant"),
    listVerifiedAccount: path(ROOT_DASHBOARD, "verified"),
    listFailedAccount: path(ROOT_DASHBOARD, "failed"),
    listCanceledReq: path(ROOT_DASHBOARD, "cancelled"),
    listRenewalReq: path(ROOT_DASHBOARD, "renewal"),
    listReplacementReq: path(ROOT_DASHBOARD, "replacement"),
    listSetting: path(ROOT_DASHBOARD, "setting"),
    listAddtionalInfo: path(ROOT_DASHBOARD, "additional"),
    listHistory: path(ROOT_DASHBOARD, "history"),
  },
  organization: {
    root: path(ORG_DASHBOARD, "home"),
  },
  systemAdmin: {
    root: path(ROOT_DASHBOARD, "/EMR/app"),
  },
  // registry: {
  //   root: path(ROOT_DASHBOARD, "/Registry/app"),
  // },
};

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN_DASH = PATH_DESK_DASHBOARD.mainDashboard.root; // as '/dashboard/dash'
export const PATH_AFTER_LOGIN_ORGANIZATION =
  PATH_DESK_DASHBOARD.organization.root; // as '/organization/index'
