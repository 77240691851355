import React from "react";
// import SearchInput from "./SearchInput";

const SearchApplicant = () => {
  return (
    <>
      <div className="py-6 px-8 rounded-xl flex md:flex-col gap-2 bg-white w-full md:w-[25%] overflow-auto noScrollBar">
        <p className="text-primary font-medium">EMR Number</p>
        {/* <SearchInput /> */}

        {/* applicant Resul Display Card  */}
        <div className="flex flex-col gap-4 p-4 items-start bg-Prime99/50 mt-4">
          <p className="text-N40 font-semibold">Jun un Yung</p>

          <div className="flex flex-col opacity-50">
            <p>Uploaded Files</p>
            <p className="text-xs text-primary">25 files uploaded</p>
          </div>

          <div className="flex flex-col opacity-50">
            <p>Accepted Files</p>
            <p className="text-xs text-primary">10 files uploaded</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchApplicant;
