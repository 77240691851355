import { useLazyQuery } from "@apollo/client";
import React, { useState } from "react";
import {
  SEARCH_ORGANIZATION,
  SEARCH_WORK_PERMIT,
} from "../../../graph-query/queries";
import { FormProvider } from "../../../components/Forms/Fields";
import { useForm } from "react-hook-form";
import { ApplicantColumns } from "../../../datasets/applicants/applicantColumns";
import { useLocalStore } from "../../../store";
import Table from "../../../components/Table/CTable";
import { useNavigate } from "react-router-dom";

const StatusShow = () => {
  const [currentTab, setCurrentTab] = useState("organization");
  const [filterOrganizations, setFilterOrganizations] = useState([]);

  const navigator = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    register: registerPermit,
    handleSubmit: handleSubmitPermit,
    formState: { errors: errorsPermit },
  } = useForm();

  const { limit, offset, setWorkPermits, workPermits, setOffset } = useLocalStore();
  /**
   * @description Search organization
   */
  const [filterOrganization, {}] = useLazyQuery(SEARCH_ORGANIZATION, {
    onCompleted: (data) => {
      console.log("==========SEARCH ORG================");
      console.log(data?.organizations);
      setFilterOrganizations(data?.organizations);
    },
  });

  /**
   * @description Search Work Permit
   */
  const [filterPermit, { loading, error }] = useLazyQuery(SEARCH_WORK_PERMIT, {
    onCompleted: (d) => {
      console.log("Filter permit", d?.expatriates);
      setWorkPermits(d?.expatriates);
    },
  });
  const searchOrganization = (e) => {
    console.log(e);
    filterOrganization({
      variables: {
        searchInput: e.searchOrganization,
      },
    });
  };

  const SearchPermit = (e) => {
    console.log("search work permit ...", e);
    filterPermit({
      variables: {
        searchInput: e?.searchPermit,
        limit: limit,
        offset: offset,
      },
    });
  };

  const handleNextPage = () => {
    setOffset(offset + 1); //the first offset
  };

  const handlePreviousPage = () => {
    setOffset(offset - 1); //the first offset
  };

  const handlePageChange = (newOffset) => {
    setOffset(newOffset - 1);
  };

  return (
    <div className="w-full">
      <div class="text-sm mb-5 w-fit font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
        <ul class="flex flex-wrap -mb-px">
          <li
            onClick={() => setCurrentTab("organization")}
            className="mr-2 cursor-pointer"
          >
            <a
              className={`${
                currentTab === "organization"
                  ? "text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                  : "border-transparent "
              } inline-block p-4 border-b-2 rounded-t-lg`}
            >
              Organization
            </a>
          </li>
          <li
            onClick={() => setCurrentTab("permit")}
            className="mr-2 cursor-pointer"
          >
            <a
              className={`${
                currentTab === "permit"
                  ? "text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                  : "border-transparent "
              } inline-block p-4 `}
              aria-current="page"
            >
              Work Permit
            </a>
          </li>
        </ul>
      </div>

      {/* SEARCH filter for ORGANIZATION */}
      {currentTab === "organization" && (
        <div>
          <FormProvider onSubmithandler={handleSubmit(searchOrganization)}>
            <label
              for="search"
              class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
            >
              Search
            </label>
            <div class="relative">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  class="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="search"
                name="searchPermit"
                {...register("searchOrganization")}
                className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search Organization..."
                required
              />
              <button
                type="submit"
                className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Search
              </button>
            </div>
          </FormProvider>

          <div className="mt-4">
            {filterOrganizations &&
              filterOrganizations.map((org) => (
                <div className="bg-white rounded-md w-full">
                  <div className="p-4 flex justify-start items-center gap-4">
                    <div class="relative inline-flex items-center justify-center w-24 h-24 overflow-hidden bg-gray-100 rounded-lg dark:bg-gray-600">
                      <span class="font-medium text-gray-600 dark:text-gray-300">
                        {org?.name?.substring(0, 2).toUpperCase()}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span>{org?.name}</span>
                      <small>{org?.sector?.name_json?.en}</small>
                      <small>{org?.organization_type?.name_json?.en}</small>
                      <span
                        className={` ${
                          org?.review_status === true
                            ? "bg-green40 text-white"
                            : org?.review_status === false
                            ? "bg-red20 text-white"
                            : "bg-blue-200 text-blue-800"
                        } text-xs font-medium px-3 py-1 rounded`}
                      >
                        {org?.review_status === true
                          ? "Active"
                          : org?.review_status === false
                          ? "Rejected"
                          : "Pending"}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}

      {/* SEARCH filter for Work Permit */}
      {currentTab === "permit" && (
        <>
          <p className="flex items-center gap-2 my-4">
            <strong>Search using</strong>{" "}
            <span class="flex w-3 h-3 bg-blue-600 rounded-full"></span>Passport
            Number, <span class="flex w-3 h-3 bg-blue-600 rounded-full"></span>{" "}
            First Name,{" "}
            <span class="flex w-3 h-3 bg-blue-600 rounded-full"></span> EMR
            Number
          </p>
          <FormProvider onSubmithandler={handleSubmitPermit(SearchPermit)}>
            <label
              for="search"
              class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
            >
              Search
            </label>
            <div class="relative">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  class="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="search"
                name="searchPermit"
                {...registerPermit("searchPermit")}
                className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search Work Permit..."
                required
              />
              <button
                type="submit"
                className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Search
              </button>
            </div>
          </FormProvider>
          <div className="mt-5"></div>
          {workPermits?.length > 0 && (
            <Table
              columns={ApplicantColumns.systemAdminApplicantColumn(navigator)}
              tableData={workPermits}
              loading={loading}
              error={error}
              offset={offset}
              limit={limit}
              // filterOptions={filterOptionTable}
              // totalPages={totalExpatList}
              handleNextPage={handleNextPage}
              handlePageChange={handlePageChange}
              handlePreviousPage={handlePreviousPage}
              // handleRefersh={handleRefershTable}
              // onSearchChange={handleSearch}
              // onFilterChange={handleSelectedFilterOption}
              title={"Applicant:  Work Permit Applcants"}
              headerClassess="bg-primary"
              // pageCount={pageCount}
            />
          )}
        </>
      )}
    </div>
  );
};

export default StatusShow;
