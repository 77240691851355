import React, { useState } from "react";
import ApexChart from "react-apexcharts";

const AreaChart = ({ areaData, areaCatagory, disableDashboard }) => {
  const [areaState, setAreaSatte] = useState({
    series: areaData,
    options: {
      chart: {
        height: 350,
        type: "area",
        pan: {
          enabled: disableDashboard ? disableDashboard : true,
        },
        zoom: {
          enabled: disableDashboard ? disableDashboard : true,
        },
        toolbar: disableDashboard ? disableDashboard : true,
        opacity: disableDashboard ? 0.5 : 1,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: areaCatagory,
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  });
  return (
    <div
      className={`bg-white p-3 h-96 ${
        disableDashboard ? "select-none opacity-50" : ""
      }`}
    >
      <ApexChart
        options={areaState.options}
        series={areaState.series}
        type="area"
        height={350}
      />
    </div>
  );
};

export default AreaChart;
