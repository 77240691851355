import React, { useState } from "react";
import Nav from "../../components/Navbar/Navbar";
import OrganizationListCard from "../../components/cards/OrganizationListCard";
import { useLocalStore } from "../../store";
import { useLazyQuery } from "@apollo/client";
import { GET_ORGANIZATION } from "../../graph-query/queries";
import { useEffect } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Avatar from "../../assets/images/avatar.jpg";
import { updateRole } from "../../permission/Ability";
import { usePromiseTracker } from "react-promise-tracker";

const SwitchOrganization = () => {
  const [notification, setNotification] = useState(false);
  const [userOrganization, setUserOrganization] = useState([]);
  const { promiseInProgress } = usePromiseTracker();

  const router = useNavigate();
  const { setCurrentRole,  } = useLocalStore();

  const getuserRole = () => {
    // let currentAuth;
    let _local = localStorage.getItem("work-permit");
    _local = JSON.parse(_local)?.roles;
    console.log(_local);
    setCurrentRole(_local);

    updateRole(_local);
  };

  const [getData, {}] = useLazyQuery(GET_ORGANIZATION, {
    variables: {
      eq: JSON.parse(localStorage.getItem("work-permit"))?.userID,
    },
    onCompleted: async (d) => {
      await setUserOrganization(d?.organizations);
      if(d?.organizations?.length === 0){
        router('/organization')
      }
    },
    onError: (er) => console.log(er),
  });

  useEffect(() => {
    getData();
    getuserRole();
  }, [userOrganization, setUserOrganization]);

  // useEffect(() => {
  //   if(userOrganization?.length === 0){
  //     router('/organization')
  //   }
  //   // new Promise((resolve) => setTimeout(resolve, 2000));
  // },[])

  return (
    <div className="">
      <Nav
        Avatar={Avatar}
        userType={JSON.parse(localStorage.getItem("work-permit")).roles}
        name={userOrganization}
      />
      <div className="container h-screen max-w-8xl mx-auto md:pt-16">
        <div className="my-8 px-12 rounded-md pb-8 bg-white">
          <div className="my-10 pt-8 flex justify-between items-center flex-wrap">
            <h2 className="font-bold text-3xl decoration-slice">
              {userOrganization[0]?.user_info?.name}'s
              <span class="ml-3 mr-3 before:block before:absolute before:-inset-1 before:-skew-y-3 before:bg-indigo-400 relative inline-block">
                <span class="relative text-white">Organization</span>
              </span>
              list{" "}
              <small className="font-normal text-lg">
                ({userOrganization?.length})
              </small>
            </h2>
            <button
              onClick={() => router("/create-organization-profile")}
              type="button"
              className="border-2 border-gray-300 rounded-md flex items-center text-black px-6 py-2 active:shadow-md hover:shadow-md"
            >
              <AiOutlinePlus />
              Add New Organization{" "}
            </button>
          </div>
          <div className="mt-8 mb-7">
            <p>
              Those organization was created by the{" "}
              {userOrganization[0]?.user_info?.name}, and all of its members are
              listed here. The members with the verified icon have already been
              accepted and verified, while those without the verified icon are
              currently under review.
            </p>
          </div>
          <div className="grid grid-flow-row grid-cols-1 md:grid-cols-4 gap-4 ">
            {userOrganization?.map((i) => (
              <OrganizationListCard
                data={i}
                notification={notification}
                key={i}
              />
            ))}
          </div>
        </div>
      </div>
      {promiseInProgress && <p>Loading...</p>}
    </div>
  );
};

export default SwitchOrganization;
