import React from "react";


const Sidebar = ({ children,disabled }) => {
  return (
    <div className={`${disabled ? 'select-none pointer-events-none opacity-60': ''} md:w-1/4 w-full bg-white p-4 md:sticky md:top-44 capitalize rounded-md shadow text-N40 flex flex-col gap-3`}>
      {children}
    </div>
  );
};

export default Sidebar;
