import React, { useEffect, useState } from "react";
import { VscVerifiedFilled } from "react-icons/vsc";
import { FcOrganization } from "react-icons/fc";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { useLocalStore } from "../../store";

const OrganizationListCard = ({ data, notification }) => {
  const [showPopover, setShowPopover] = useState(false);

  const { setData, setSelectedCurrentOrg } = useLocalStore();
  const router = useNavigate();

  const handleButtonClick = () => {
    setShowPopover(!showPopover);
  };

  const handleSwitchOrg = (e) => {
    if (data?.review_status) {
      let currentOrganization = {
        id: e?.id,
        name: e?.name,
        exp_work_permits_json: e?.exp_work_permits_json,
      };
      setData("currentOrg", currentOrganization);
      setSelectedCurrentOrg(e);
      router("/organization");
    } else {
      handleButtonClick();
    }
  };

  useEffect(() => {
    console.log("====================================");
    console.log(data);
    console.log("====================================");
  }, []);

  return (
    <>
      <button
        className={`border-2 w-[100%] relative rounded-lg min-h-[48px] max-w-full ${
          showPopover ? "relative" : ""
        }`}
      >
        <div
          onClick={() => handleSwitchOrg(data)}
          className="group p-8 w-full h-full relative rounded-lg text-start border border-gray-200 px-5 py-4 transition-colors hover:border-gray-300 hover:bg-gray-100 hover:dark:border-neutral-700 hover:dark:bg-neutral-800/30"
        >
          <div className="absolute right-2 top-2 w-5 h-5">
            {data?.review_status && (
              <VscVerifiedFilled size={22} color="green" />
            )}
          </div>
          {notification && (
            <div className="absolute -left-2 -top-2 w-5 h-5 bg-yellow20 rounded-full"></div>
          )}
          <FcOrganization size={34} />
          <h2 className={`mb-3 text-2xl font-semibold`}>
            {data && data?.name}
            <span className="inline-block transition-transform group-hover:translate-x-1 motion-reduce:transform-none">
              -&gt;
            </span>
          </h2>
          <span
            className={`${
              data.review_status ? "bg-green40" : "bg-red30/40"
            } rounded-md px-4 py-1`}
          >
            {data?.review_status ? "Active" : "Waiting Verification"}
          </span>
          <div className="flex justify-between">
            <div>
              <p className={`m-0 max-w-[30ch] mt-4 text-sm opacity-50`}>
                expatriate {data && data?.expatriates_count}
              </p>
              <p className={`m-0 max-w-[30ch] text-sm opacity-50`}>
                created at:{" "}
                <Moment format="YYYY-MM-DD">{data && data?.created_at}</Moment>
              </p>
            </div>

            {data?.review_status !== true && (
              <button
                onClick={() =>
                  router(`/update-organization-profile/update/${data?.id}`)
                }
                className="border-2 text-sm border-blue-200 self-center px-3 py-1 rounded-md"
              >
                Detail
              </button>
            )}
          </div>
        </div>
        {data?.review_status === true &&
          data?.organization_type?.name !== "Government office" && (
            <button
              onClick={() => router(`/organization/renewal/${data?.id}`)}
              className="border-2 z-[9989] absolute right-4 bottom-4 text-sm border-blue-200 self-center px-3 py-1 rounded-md hover:bg-primary hover:text-white"
            >
              Renew
            </button>
          )}
        {showPopover && (
          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 w-56 mt-2">
            <div className="relative bg-white shadow-md rounded-lg">
              <div className="arrow absolute top-full left-1/2 transform -translate-x-1/2">
                <svg
                  className="text-white h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 14l-7 7m0 0l-7-7m7 7V3"
                  />
                </svg>
              </div>
              <div className="px-4 py-3">
                <p className="text-sm text-gray-700">
                  Verification for this organization is still pending
                </p>
              </div>
            </div>
          </div>
        )}
      </button>
    </>
  );
};

export default OrganizationListCard;
