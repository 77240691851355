import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SideNav from "../../../components/Navbar/SideNav";
import ApplicantStepper from "../../../components/common/ApplicantStepper";
import { WORK_PERMIT_TYPES, WorkPermitForms } from "../../../utils/constants";
import { BsFileEarmarkPlay } from "react-icons/bs";
import { MdArrowUpward, MdHomeWork, MdModelTraining } from "react-icons/md";
import { FaMoneyCheckAlt } from "react-icons/fa";
import Nav from "../../../components/Navbar/Navbar";
import avatar from "../../../assets/images/avatar.jpg";
import { useLocalStore } from "../../../store";
import useStepperFormStore from "../../../store/formStore";
import { deleteDB, openDB } from "idb";
import { toast } from "react-toastify";
import {
  INSERT_EXPATERIATE_WORK_PLACE,
  INSERT_EXPATRIATE_DOC,
  INSERT_WORK_COUNTER_PART,
  SWAP_EXPATRIATE_PERMIT,
  UPLOAD_FILE,
} from "../../../graph-query/mutation";
import { useLazyQuery, useMutation } from "@apollo/client";
import { trackPromise } from "react-promise-tracker";
import FormAreaSwap from "../../../components/common/FormAreaSwap";
import {
  BASE_OCCUPATION_CATEGORIES,
  BASE_PROFESSIONAL_TITLE,
  BASE_REGION,
  BASE_SERVICE_TYPE,
} from "../../../graph-query/queries";

const steps = [
  {
    icon: <BsFileEarmarkPlay />,
    label: "Employement Information",
  },
  {
    icon: <MdHomeWork />,
    label: "Work Place",
  },
  {
    icon: <MdModelTraining />,
    label: "Counterpart",
  },
  {
    icon: <FaMoneyCheckAlt />,
    label: "Finance",
  },
];

const SwapExpatriateApplication = () => {
  const [activeStep, setActiveStep] = useState(
    WorkPermitForms.EMPLOYEE_INFORMATION
  );

  const { id } = useParams();

  const {
    userOrganizationData,
    occupationCatagory,
    serviceType,
    setServiceType,
    setOccupationCatagory,
    professionalTitles,
    setProfessionalTitles,
    setRegions,
    regions,
  } = useLocalStore();

  const {
    setLoading,
    data,
    fetchDataFromIndexedDB,
    recreateNew,
    clearIndexedDB,
    saveFormData,
  } = useStepperFormStore();

  const router = useNavigate();

  const [getOccupation, {}] = useLazyQuery(BASE_OCCUPATION_CATEGORIES, {
    onCompleted: (data) => {
      setOccupationCatagory(data?.base_occupation_categories);
    },
  });
  const [getTypeOfService, {}] = useLazyQuery(BASE_SERVICE_TYPE, {
    onCompleted: (d) => {
      setServiceType(d?.base_service_types);
    },
  });
  const [getProfessionalTitles, {}] = useLazyQuery(BASE_PROFESSIONAL_TITLE, {
    onCompleted: (data) => {
      console.log("professional title data:", data?.base_profession_titles);
      setProfessionalTitles(data?.base_profession_titles);
    },
  });
  const [getRegions, {}] = useLazyQuery(BASE_REGION, {
    onCompleted: (data) => {
      console.log("regions data:", data?.base_regions);
      setRegions(data?.base_regions);
    },
  });

  useEffect(() => {
    if (occupationCatagory?.length === 0) {
      getOccupation();
    }
  }, [occupationCatagory, setOccupationCatagory]);

  useEffect(() => {
    if (serviceType?.length === 0) {
      getTypeOfService();
    }
  }, [serviceType, setServiceType]);

  useEffect(() => {
    if (professionalTitles?.length === 0) {
      getProfessionalTitles();
    }
  }, [professionalTitles, setProfessionalTitles]);

  useEffect(() => {
    if (regions?.length === 0) {
      getRegions();
    }
  }, [regions, setRegions]);
  /**
   * @description create permit data
   */
  const [createPermit, {}] = useMutation(SWAP_EXPATRIATE_PERMIT, {
    onCompleted(data) {
      console.log(data);
    },
    onError(er) {
      console.log(er);
      // window.location.reload();
      er.graphQLErrors.forEach((item) => {
        toast.error(item.message);
      });
    },
  });

  const moveToNext = () => {
    switch (activeStep) {
      case WorkPermitForms.EMPLOYEE_INFORMATION:
        setActiveStep(WorkPermitForms.WORK_PLACE);
        break;
      case WorkPermitForms.WORK_PLACE:
        setActiveStep(WorkPermitForms.COUNTER_PART);
        break;
      case WorkPermitForms.COUNTER_PART:
        setActiveStep(WorkPermitForms.FINANCE);
        break;
      case WorkPermitForms.FINANCE:
        setActiveStep(WorkPermitForms.FINAL);
        break;
      default:
        return;
    }
  };

  const recreateIndexedDB = async () => {
    try {
      const DB_NAME = "new_permit_transfer";
      // Manually delete the existing database
      await deleteDB(DB_NAME);

      // Increment the database version
      const dbVersion = 1;

      console.log("db recreate....");
      const getStoreNames = () => {
        return [
          // "general_information",
          "employement_information",
          // "educational_information",
          // "work_experience",
          "work_place",
          "counterpart",
          // "skills",
          "finance",
        ];
      };

      const storeNames = getStoreNames();

      const db = await openDB(DB_NAME, dbVersion, {
        upgrade(db) {
          for (const name of storeNames) {
            if (!db.objectStoreNames.contains(name)) {
              db.createObjectStore(name);
            }
          }
        },
      });

      console.log("IndexedDB database recreated:", db);
    } catch (error) {
      console.error("Error recreating IndexedDB:", error);
      // Handle the error appropriately
    }
  };

  const handleExpatSubmitForm = async (name, e) => {
    console.log("application", e);
    if (name === "final") {
      // fetchDataFromIndexedDB("new_permit");
      handleNewApplication();
      return;
    } else {
      await saveFormData("new_permit_transfer", { [name]: e })
        .then((re) => {
          console.log("Ex", re);
          moveToNext();
        })
        .catch(async (er) => {
          setLoading(false);
          recreateIndexedDB();
          window.location.reload();
          console.log("error____", er);
          // deleteDB("new_permit");
          // indexedDB.deleteDB("new_permit");
        });
    }
  };

  const [fileUpload, { loading: uploadLoading }] = useMutation(UPLOAD_FILE);
  const [create_expatriate_document, {}] = useMutation(INSERT_EXPATRIATE_DOC, {
    onError: (err) => console.log(err),
  });
  const [createCounterPart, {}] = useMutation(INSERT_WORK_COUNTER_PART);
  const [create_expatriate_work_place, {}] = useMutation(
    INSERT_EXPATERIATE_WORK_PLACE,
    {
      onError: (er) => toast.error("problem occurs while creating work place"),
    }
  );

  const uploadFiles = async (files) => {
    console.log("uploadFiles:", files);
    const uploadPromises = files.map(async (item) => {
      try {
        const { fileType, file } = item;
        const { data } = await fileUpload({
          variables: { extension: fileType, file },
        });
        return {
          path: data.uploadFile.info.Key,
          document_type_id: item?.document_type_id,
          catagory: item?.catagory,
          title: item?.title,
          name: item?.name,
        };
      } catch (error) {
        console.error("Document upload failed:", error);
        return null;
      }
    });

    const uploadedFiles = await Promise.all(uploadPromises);
    return uploadedFiles.filter((file) => file !== null);
  };

  const handleNewApplication = () => {
    console.log("click!");
    fetchDataFromIndexedDB("new_permit_transfer");
    const finance = data?.finance;
    const counterP = data?.counterpart;
    const employment = data?.employement_information;
    const workP = data?.work_place;

    if (employment && Object?.keys(employment)?.length === 0) {
      return toast.error("Employment Information should be filled!");
    }
    if (finance && Object?.keys(finance)?.length === 0) {
      return toast.error("FInance Information should be filled!");
    }
    if (workP && Object?.keys(workP)?.length === 0) {
      return toast.error("Work place Information should be filled!");
    } else {
      console.log("application_data: ", {
        finance,
        counterP,
        employment,
        workP,
      });

      var _UploadedFiles = [];
      const uploadFileData = async () => {
        const _filesToUpload = [
          ...employment?.contract,
          ...(finance?.expatriate_work_permit_documents || []),
        ];

        try {
          const uploadedFiles = await uploadFiles(_filesToUpload);

          //   setFileUploadedFile((prevFiles) => [...prevFiles, ...uploadedFiles]);
          _UploadedFiles.push([...uploadedFiles]);
        } catch (error) {
          console.error("File upload failed:", error);
        }
      };

      trackPromise(
        uploadFileData().then(() => {
          createPermit({
            variables: {
              ...employment,
              //   profession_title_id: employment?.profession_title_id,
              start_date: employment?.employment_start_date,
              end_date: employment?.employment_end_date,
              salary: employment?.salary,
              arrival_date: employment?.arrival_date,
              allowance: employment?.allowance,
              occupation_category_id: employment?.occupation_category_id,
              passport_issued_date: employment?.passport_issued_date,
              passport_expiry_date: employment?.passport_expiry_date,
              visa_issued_date: employment?.passport_issued_date,
              visa_expiry_date: employment?.passport_expiry_date,
              reference_number: finance?.reference_number,
              permit_type: WORK_PERMIT_TYPES.NEW,
              expatriate_id: id,
              organization_id: JSON.parse(localStorage.getItem("currentOrg"))
                ?.currentOrg?.id,
            },
            onCompleted: async (D) => {
              console.log("all files: ", _UploadedFiles);
              await _UploadedFiles[0]?.map(async (item) => {
                await create_expatriate_document({
                  variables: {
                    documentId: item?.document_type_id,
                    expatriate_work_permit_id:
                      D?.insert_expatriate_work_permits?.returning[0]?.id,
                    files: item?.path,
                    properties: item,
                    title: item?.title,
                  },
                });
              });
              setTimeout(() => {
                counterP?.counterparts?.map(async (item) => {
                  let _counter = {
                    ...item,
                    assigned_date: item?.assignment_date,
                    first_name: item?.counterpart_firstName,
                    last_name: item?.counterpart_lastName,
                    position: item?.position,
                    work_permit_id:
                      D?.insert_expatriate_work_permits?.returning[0]?.id,
                  };
                  await createCounterPart({
                    variables: { ..._counter },
                  });
                });
              }, 1000);

              await create_expatriate_work_place({
                variables: {
                  phone_number: [workP?.phone_number],
                  email: workP?.email, //add
                  house_number: workP?.house_number,
                  kebele: workP?.kebele,
                  project_name: workP?.project_name,
                  // created_by: workP?.created_by,
                  district_id: workP?.district_id,
                  expatriate_work_permit_id:
                    D?.insert_expatriate_work_permits?.returning[0]?.id,
                  region_id: workP?.region_id !== "" ? workP?.region_id : null,
                  subcity_id:
                    workP?.subcity_id !== "" ? workP?.subcity_id : null,
                  woreda_id: workP?.woreda_id !== "" ? workP?.woreda_id : null,
                  zone_id: workP?.zone_id !== "" ? workP?.zone_id : null,
                },
              }).then(() => clearIndexedDB("new_permit_transfer"));
            },
          }).then((_res) => {
            saveFormData("new_permit_transfer", {});
            if (_res.data) {
              localStorage.removeItem("new_permit_transfer");
              router("/organization");
              toast.success("New Expatriate requested successfully");
            }
          });
        })
      );
    }
  };
  return (
    <>
      <Nav Avatar={avatar} name={userOrganizationData} />
      <div className="flex flex-col md:flex-row gap-8 2xl:gap-12 mt-10 md:mt-20 layout items-start">
        <SideNav>
          <ApplicantStepper steps={steps} activeStep={activeStep} />
        </SideNav>
        <div className="w-full">
          <button
            onClick={() => router("/organization")}
            className="flex gap-2 items-center border-2 rounded-md border-gray-300 text-gray-600 mb-2 px-4 py-1 text-capitalize"
          >
            <MdArrowUpward /> Home
          </button>
          {/* {!acceptNotice && (
          <div className="my-5">
            <NoticeCard
              amount={
                permitPrice
                  ?.filter((i) => i?.work_permit_type?.name === "New")
                  .map((i) => i?.price)[0]
              }
              handleHandle={() => setAcceptNotice(!acceptNotice)}
            >
              <ul className="list-disc">
                <li className="md:ml-6">
                  To obtain a new work permit, an upfront payment of 2000 ETB is
                  required
                </li>
                <li className="md:ml-6">
                  The visa file should consist of the front page of the visa,
                  arrival document, and, if applicable, the e-visa
                </li>
                <li className="md:ml-6">
                  A valid passport document is required.
                </li>
                <li className="md:ml-6">
                  An employment contract agreement with the company is
                  necessary.
                </li>
              </ul>
              <p className="text-N70 text-sm">
                Please use this CBE Back Account Number :{" "}
                <strong className="text-black">1000461142331 ,</strong>{" "}
                <span className="text-error30 font-bold text-xl">
                  {
                    permitPrice
                      ?.filter((i) => i?.work_permit_type?.name === "New")
                      .map((i) => i?.price)[0]
                  }{" "}
                  ETB
                </span>
              </p>
            </NoticeCard>
          </div>
        )} */}
          {/* {acceptNotice && ( */}
          <FormAreaSwap
            moveToNext={moveToNext}
            activeStep={activeStep}
            //   initialValues={data}
            title={"New Work Permit"}
            setActiveStep={setActiveStep}
            handleSubmit={handleExpatSubmitForm}
            handleClearCache={() => {}}
          />
          {/* )} */}
        </div>
      </div>
    </>
  );
};

export default SwapExpatriateApplication;
