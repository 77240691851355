import React, { useEffect } from "react";
import SideNav from "../../components/Navbar/SideNav";
import StepperList from "../../components/utilities/StepperList";
import Nav from "../../components/Navbar/Navbar";
import Avatar from "../../assets/images/avatar.jpg";
import { useLocalStore } from "../../store";
import { HiBuildingLibrary } from "react-icons/hi2";
import OrganizationData from "../../components/Forms/OrganizationData";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { GET_ORGANIZATION } from "../../graph-query/queries";
import { updateRole } from "../../permission/Ability";

const CreateOrganizationProfile = () => {
  const { currentRole, user, userOrganizationData,setCurrentRole, setUserOrganizationData } =
    useLocalStore();
  const { type } = useParams();

  const router = useNavigate();

  /**
   * @description get logged user data with assigned organization if there is any
   */
  const [getUserData, { error: userErr }] = useLazyQuery(GET_ORGANIZATION, {
    variables: { eq: JSON.parse(localStorage.getItem("work-permit"))?.userID },
    context: {
      headers: {
        "Content-Type": "application/json",
      },
    },
    onCompleted: (data) => {
      // setUser(data?.credentials[0]);
      console.log("getUserData: ", data.organizations);
      setUserOrganizationData(data?.organizations);
    },
  });

  const getuserRole = () => {
    // let currentAuth;
    let _local = localStorage.getItem("work-permit");
    _local = JSON.parse(_local)?.roles;
    console.log(_local);
    setCurrentRole(_local);

    updateRole(_local);
  };

  //request new data if not found
  useEffect(() => {
    if (userOrganizationData?.length === 0) {
      // Get Data from server
      getUserData();
    }
  }, [userOrganizationData, setUserOrganizationData]);

  useEffect(() => {
    let r = localStorage.getItem("work-permit");
    if (!r) {
      router("/login");
    }
    getuserRole();
  }, []);

  return (
    <>
      <Nav Avatar={Avatar} userType={currentRole} name={userOrganizationData} />
      <div className="flex flex-col md:flex-row gap-8 2xl:gap-12 pb-10 mt-10 md:mt-20 layout items-start">
        <SideNav>
          {/* <Can I="view" a="newRequest">
            {() => ( */}
          <StepperList
            _link={"/create-organization-profile"}
            icon={<HiBuildingLibrary className="text-xl" />}
            stepperText={"Organization Data "}
            activeClassName="bg-primary text-white"
          />
          {/* )}
          </Can> */}
        </SideNav>
        <div className="w-full">
          {/* Form here */}
          <button
            onClick={() => router(-1)}
            className="border-2 rounded-md border-gray-300 text-gray-600 mb-2 px-4 py-1"
          >
            back
          </button>
          <div className="bg-white shadow-sm p-8">
            <OrganizationData type="new" />
          </div>
        </div>
      </div>
      <div className="mt-16">{/* Footer */}</div>
    </>
  );
};

CreateOrganizationProfile.defaultProps = {
  type: "new",
};
export default CreateOrganizationProfile;
