import React, { useEffect, useState } from "react";
import CTable from "../../components/Table/CTable";
import { OrganizationColumns } from "../../datasets/organizations/organizationColumns";
import { GET_ALL_ORGANIZATION } from "../../graph-query/queries";
import { useLazyQuery } from "@apollo/client";
import {useNavigate} from 'react-router-dom';

const RenewalOrganizationList = () => {
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [loadingRows, setLoadingRows] = useState([]);
    const [data, setData] = useState([]);

    const router = useNavigate();

  const [getDataFromServer, { loading, error }] = useLazyQuery(
    GET_ALL_ORGANIZATION,
    {
      variables: {
        limit: limit,
        offset: offset * limit,
        is_renewed: true
      },
      onCompleted: (data) => {
        console.log("___", data);
        setData(data?.organizations);
      },
    }
  );

  useEffect(() => {
    getDataFromServer();
  },[])

  return (
    <div>
      <CTable
        columns={OrganizationColumns.deskHandlerOrganizationColumn(
          router,
          loadingRows
        )}
        tableData={data}
        pageSize={0}
        loading={loading}
        error={error}
        offset={offset}
        limit={limit}
        title={"Renewal Incoming Organization"}
        headerClassess="bg-primary"
        //handleRefersh={handleRefershTable}
        //handlePageLimit={handlePageLimit}
        //totalPages={data?.organizations_aggregate?.aggregate?.count}
        //handleNextPage={handleNextPage}
        //handlePageChange={handlePageChange}
        //handlePreviousPage={handlePreviousPage}
        //searchQuery={handleSearch}
        pageCount={Math.ceil(
          data?.organizations_aggregate?.aggregate?.count / limit
        )}
        // fetchData={fetchData}
      />
    </div>
  );
};

export default RenewalOrganizationList;
