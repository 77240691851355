import React from "react";
import FederalLogo from "../assets/icons/Emblem_of_Ethiopia.svg.png";
import MolsLogo from "../assets/images/mols.jpg";
import { RxPaperPlane } from "react-icons/rx";
const License = () => {
  return (
    <div className=" bg-primary/80 w-full min-h-screen">
      <div className="w-[70%] max-w-[25cm] bg-white flex flex-col gap-4 mx-auto p-4 px-6">
        <div className="flex items-center justify-between gap-4 text-2xl">
          <div className="flex items-center gap-4">
            <img src={FederalLogo} alt="federa;l logo" className="w-28" />
            <h2 className="w-1/2">
              የኢትዮጵያ ፌዴራላዊ ዴሞክራሲያዊ ሪፐብሊክ የሥራ እና ክህሎት ሚኒስቴር
            </h2>
          </div>
          <img src={MolsLogo} alt="mols logo" className="w-52" />
        </div>
        <div className="flex items-center justify-between">
          <div className="w-36 h-36 border border-N80 "></div>
          <div className=" capitalize space-y-1 w-[33%] text-sm">
            <div className="flex items-center gap-4">
              <h3 className="w-1/2">የመዝገብ ቁ</h3>
              <span className=" underline">5.2/0/አ306/1</span>
            </div>
            <div className="flex items-center gap-4">
              <h3 className="w-1/2">file number</h3>
              <span className=" underline">5.2/0/አ306/1</span>
            </div>
            <div className="flex items-center gap-4">
              <h3 className="w-1/2">የፈቃድ ቁ.የግል ሥ/ሠ/አ/ኤ </h3>
              <span className=" underline">340/2019</span>
            </div>
            <div className="flex items-center gap-4">
              <h3 className="w-1/2">license no.</h3>
              <span className=" underline">340/2019</span>
            </div>
            <div className="flex items-center gap-4">
              <h3 className="w-1/2">የተሰጠበት ቀን</h3>
              <span className=" underline">08/11/2022</span>
            </div>
            <div className="flex items-center gap-4">
              <h3 className="w-1/2">date of issuance</h3>
              <span className=" underline">08/11/2022</span>
            </div>
          </div>
        </div>
        <div className="text-center space-y-4 text-2xl my-8 text-slate-800 w-4/5 font-bold mx-auto ">
          <h1>
            በኢትዮጵያ የውጪ አገር ሦራ ሦምሪት አዋጅ ቁጥር 923/2008 እና 1246/2013 መሰረት የተሰጠ የብቃት
            ማረጋገጫ ፈቃድ
          </h1>
          <h1 className="text-xl">
            License Compitency Enabling a Private Employment Agency to Provide
            Employment Service as per the Ethiopia's Overseas Employment
            Proclamation no. 923/2016 and 1246/2021
          </h1>
        </div>
        <div className="flex justify-between">
          <div className="w-[45%] capitalize space-y-2">
            <div className="flex items-center gap-4">
              <h3 className="w-1/2">1. የኤጀንሲው ስም</h3>
              <span className=" underline font-medium">
                agency name in amharic
              </span>
            </div>
            <div className="flex items-center gap-4">
              <h3 className="w-1/2">2. የባለቤት/ስራ አስኪያጅ ስም </h3>
              <span className=" underline font-medium">
                manager name in amharic
              </span>
            </div>
            <div className="flex items-center gap-4">
              <h3 className="w-1/2">3. ዜግነት</h3>
              <span className=" underline font-medium">ኢትዮጵያዊ</span>
            </div>
            <div className="flex items-start flex-col gap-1">
              <h3 className="w-1/2">4. አድራሻ</h3>
              <div className="flex items-center gap-4 ml-4">
                <h3 className="">ክልል: </h3>
                <span className=" underline font-medium">አ.አ</span>
                <h3 className="">ክ/ከተማ</h3>
                <span className=" underline font-medium">ቂርቆስ</span>
              </div>
              <div className="flex items-center gap-4 ml-4">
                <h3 className="">ወረዳ: </h3>
                <span className=" underline font-medium">02</span>
                <h3 className="">ቀበሌ</h3>
                <span className=" underline font-medium">__</span>
                <h3 className="">የቤ.ቁ</h3>
                <span className=" underline font-medium">G1,766</span>
              </div>
              <div className="flex items-center gap-4 ml-4">
                <h3 className="">ስ.ቁ</h3>
                <span className=" underline font-medium">0934918827</span>
              </div>
              <div className="flex items-center gap-4">
                <h3 className="">5. የስራ ስምሪት የሚሰጥበት አገር</h3>
                <span className=" underline font-medium">ሳዑዲ ኣረብ</span>
              </div>
              <div className="flex items-center gap-4">
                <h3 className="">6. ፈቃዱ የሚያበቃበት ቀን</h3>
                <span className=" underline font-medium">07/11/2023</span>
              </div>
            </div>
          </div>
          <div className="w-[45%] capitalize space-y-2">
            <div className="flex items-center gap-4">
              <h3 className="w-1/2">1. agency name</h3>
              <span className=" underline font-medium">
                agency name in english
              </span>
            </div>
            <div className="flex items-center gap-4">
              <h3 className="w-1/2">2. owner/manager/ </h3>
              <span className=" underline font-medium">
                manager name in english
              </span>
            </div>
            <div className="flex items-center gap-4">
              <h3 className="w-1/2">3. nationality</h3>
              <span className=" underline font-medium">ethiopian</span>
            </div>
            <div className="flex items-start flex-col gap-1">
              <h3 className="w-1/2">4. address</h3>
              <div className="flex items-center gap-4 ml-4">
                <h3 className="">region: </h3>
                <span className=" underline font-medium">A.A</span>
                <h3 className="">S.city</h3>
                <span className=" underline font-medium">kirkos</span>
              </div>
              <div className="flex items-center gap-4 ml-4">
                <h3 className="">woreda: </h3>
                <span className=" underline font-medium">02</span>
                <h3 className="">house.no</h3>
                <span className=" underline font-medium">G1,766</span>
              </div>
              <div className="flex items-center gap-4 ml-4">
                <h3 className="">phone no</h3>
                <span className=" underline font-medium">0934918827</span>
              </div>
              <div className="flex items-center gap-4">
                <h3 className="">5. country of destination</h3>
                <span className=" underline font-medium">K.S.A</span>
              </div>
              <div className="flex items-center gap-4">
                <h3 className="">6. the license is valid until</h3>
                <span className=" underline font-medium">07/11/2023</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-start justify-between my-8">
          <div className="w-[45%] space-y-2">
            <h3 className="underline font-medium mb-4">ማሳሰቢያ</h3>
            <div className="flex items-center gap-4">
              <RxPaperPlane />
              <p className="w-3/4">ፈቃዱ ለሌላ ሰው የሚተላለፍ አይሆንም</p>
            </div>
            <div className="flex items-center gap-4">
              <RxPaperPlane />
              <p className="w-3/4">
                ፈቃዱ ስራው በሚከናወንበት ቢሮው ውስጥ ለሌሎች ሰዎች ግልጽ ሆኖ በሚታይበት ስፍራ መደረግ አለበት።
              </p>
            </div>
            <div className="flex items-center gap-4">
              <RxPaperPlane />
              <p className="w-3/4">ይህ የብቃት ማረጋገጫ ፈቃድ በዋስትና /በዕዳ/ መያዝ አይችልም</p>
            </div>
            <div className="flex items-center gap-4">
              <RxPaperPlane />
              <p className="w-3/4">
                ባለፈቃዱ ሰራተኛን መላክ የሚችለው ፈቃዱ በተሰጠበት አገር ብቻ ነው።
              </p>
            </div>
            <div className="flex items-center gap-4">
              <p>የባለስልጣኑ ፊርማ</p>
              _______
            </div>
          </div>
          <div className="w-[45%] space-y-2">
            <h3 className="underline font-medium mb-4">Notification</h3>
            <div className="flex items-start gap-4">
              <RxPaperPlane />
              <p className="w-3/4">The license shall not be transferable</p>
            </div>
            <div className="flex items-start gap-4">
              <RxPaperPlane />
              <p className="w-3/4">
                The license shall be prominnettly displayed at a conspicous
                place in the office premises for conducting the service
              </p>
            </div>
            <div className="flex items-start gap-4">
              <RxPaperPlane />
              <p className="w-3/4">
                The license shall not be used as a guarentee or debt.
              </p>
            </div>
            <div className="flex items-start gap-4">
              <RxPaperPlane />
              <p className="w-3/4">
                The holder of the license sends a worker only to the country
                indicated in the license.
              </p>
            </div>
            <div className="flex items-center gap-4">
              <p>Signature of authorized</p>
              _______
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default License;
