import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ExpatriateCard from "../../../components/cards/ExpatraitCard";
import { BsFillBuildingFill } from "react-icons/bs";
import { MdArrowBack, MdEmail, MdFlag, MdHouse, MdPhone } from "react-icons/md";
import InfoCard from "../../../components/cards/InfoCard";
import CreatorCard from "../../../components/cards/CreatorCard";
import { useLazyQuery } from "@apollo/client";
import { ORGANIZATION_BY_ID } from "../../../graph-query/queries";

const IndividualDocument = () => {
  const router = useNavigate();
  const [orgData, setOrgData] = useState([]);
  const { id } = useParams();

  console.log("org id: ", id);
  const [getData, { error, loading }] = useLazyQuery(ORGANIZATION_BY_ID, {
    variables: {
      OrgID: id,
    },
    onCompleted: (data) => {
      console.log(data);
      setOrgData(data?.organizations[0]);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  useEffect(() => {
    if (id !== "undefined") {
      getData();
    } else {
      router(-1);
    }
  }, []);

  return (
    <div className="flex flex-wrap md:flex-nowrap gap-6">
      <div className="w-full md:w-2/3 space-y-6">
        <div className="bg-white shadow-sm p-4 rounded-md space-y-5">
          <button
            onClick={() => router(-1)}
            className="bg-gray px-3 py-2 border-2 rounded-md flex gap-2 items-center"
          >
            <MdArrowBack /> Back
          </button>
          <h2 className="text-N70 capitalize font-medium">
            organization Details
          </h2>
          <div className="flex flex-wrap md:flex-nowrap items-center gap-4">
            <div className="rounded bg-N99/30 p-3 w-full md:w-1/4 space-y-1">
              <p className="text-sm text-N70 capitalize">tin number</p>
              <h3 className="2xl:text-lg text-primary font-semibold tracking-wider">
                {orgData?.tin_number}
              </h3>
            </div>
            <div className="rounded bg-N99/30 p-3 w-full md:w-2/5 space-y-1">
              <p className="text-sm text-N70 capitalize">
                Business License Number
              </p>
              <h3 className="text-lg text-primary font-semibold tracking-wider">
                {orgData?.business_licence_number}
              </h3>
            </div>
            <div className="rounded bg-N99/30 p-3 w-full md:w-1/3 space-y-1">
              <p className="text-sm text-N70 capitalize">Local Activity Code</p>
              <h3 className="text-lg text-primary font-semibold tracking-wider">
                ---
              </h3>
            </div>
          </div>
          <div className=" space-y-1">
            <h4 className="text-sm text-N70">Sector</h4>
            <h4 className="text-primary font-medium">
              {orgData?.sector?.name}
            </h4>
          </div>
          {/* <div className="text-sm space-y-1">
            <h4 className=" text-N70">Major Activity</h4>
            <p className="text-N40">
              Major Activity of the organizaiton Major Activity of the
              organizaitonMajor Activity of the organizaitonMajor Activity of
              the organizaitonMajor Activity of the organizaitonMajor Activity
              of the organizaiton
            </p>
          </div> */}
          <div className="flex items-center gap-2">
            <p className="text-N70">Expatriates Count -</p>
            <h3 className="fon-medium text-primary font-semibold text-xl">
              {orgData?.expatriates_count}
            </h3>
          </div>
          <div className="flex items-center gap-2">
            <p className="text-N70">Employees Count -</p>
            <h3 className="fon-medium text-primary font-semibold text-xl">
              {orgData?.local_employees_count}
            </h3>
          </div>
        </div>
        <div className="bg-white shadow-sm p-4 rounded-md space-y-5">
          <h2 className="text-N70 capitalize font-medium">
            List of Expatriates ({orgData?.expatriate_work_permit?.length})
          </h2>
          <div className="divide-y">
            {orgData?.expatriate_work_permit?.map((item, index) => (
              <ExpatriateCard
                data={item?.expatriate}
                emr={item?.emr_number}
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/3 space-y-6">
        <div className="relative flex flex-col items-center rounded-[20px] w-full mx-auto p-4 bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:!shadow-none">
          <div className="relative flex h-32 w-full justify-center rounded-xl bg-cover">
            <img
              src="https://horizon-tailwind-react-git-tailwind-components-horizon-ui.vercel.app/static/media/banner.ef572d78f29b0fee0a09.png"
              className="absolute flex h-32 w-full justify-center rounded-xl bg-cover"
            />
            <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-start rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
              <BsFillBuildingFill className="h-full w-full object-contain rounded-full text-primary text-3xl" />
            </div>
          </div>
          <div className="mt-16 flex flex-row text-center items-center">
            {/* <p className="text-base font-normal text-gray-600">Owner</p> */}
            <h4 className="text-xl font-bold text-slate-700">
              {orgData?.name}
            </h4>
          </div>
          <div className="w-full mt-6 mb-3 flex flex-col gap-4 md:!gap-4">
            <div className="flex flex-row items-center justify-between">
              <p className="flex items-center gap-2 text-sm md:text-lg font-semibold text-slate-700">
                <MdEmail />
                Type
              </p>
              <p className="ml-4 md:ml-0 text-sm font-normal text-gray-600">
                {orgData?.organization_type?.name}
              </p>
            </div>
            <div className="flex flex-row items-center justify-between">
              <p className="flex items-center gap-2 text-sm md:text-lg font-semibold text-slate-700">
                <MdPhone /> Phone
              </p>
              <p className="mr-4 md:ml-0 text-sm font-normal text-gray-600">
                {orgData?.phone_no || "--"}
              </p>
            </div>
            <div className="flex flex-row items-center justify-between">
              <p className="flex items-center gap-2 text-sm md:text-lg font-semibold text-slate-700">
                <MdHouse />
                House Number
              </p>
              <p className="mr-4 md:ml-0 text-sm font-normal text-gray-600">
                {orgData?.house_number}
              </p>
            </div>
            <div className="flex flex-row items-center justify-between">
              <p className="flex items-center gap-2 text-sm md:text-lg font-semibold text-slate-700">
                <MdFlag /> Region
              </p>
              <p className="mr-4 md:ml-0 text-sm font-normal text-gray-600">
                {orgData?.region?.name}
              </p>
            </div>
            <div className="flex flex-row items-center justify-between">
              <p className="flex items-center gap-2 text-sm md:text-lg font-semibold text-slate-700">
                <MdEmail /> Town
              </p>
              <p className="mr-4 md:ml-0 text-sm font-normal text-gray-600">
                {orgData?.region?.name}
              </p>
            </div>
          </div>
        </div>

        {/* <div className="bg-white shadow-sm p-4 rounded-md space-y-5">
          <div className="flex flex-col gap-3 items-center">
            <div className="p-4 rounded-full bg-N99/60 w-20 h-20 flex items-center justify-center">
              <BsFillBuildingFill className="text-primary text-3xl" />
            </div>
            <div className=" space-y-1">
              <h3 className="font-semibold text-N20">{orgData?.name}</h3>
              <p className="text-N50 text-sm text-center">
                {orgData?.sector?.name}
              </p>
            </div>
          </div>
          <div className=" space-y-2">
            <InfoCard title="Type" email={orgData?.organization_type?.name}>
              <MdEmail />
            </InfoCard>
            <InfoCard title="phone number" email={orgData?.phone_no}>
              <MdPhone />
            </InfoCard>
            <InfoCard title="house number" email={orgData?.house_number}>
              <MdHouse />
            </InfoCard>
            <InfoCard title="region" email={orgData?.region?.name}>
              <MdFlag />
            </InfoCard>
            <InfoCard title="town" email="Addis Abeba">
              <MdEmail />
            </InfoCard>
          </div>
        </div> */}
        <CreatorCard data={orgData.user_info} />
      </div>
    </div>
  );
};

export default IndividualDocument;
