import NotAuthorized from "../pages/NotAuthorized";
import { defineRulesFor } from "../permission/Ability";
import { AbilityContext, Can } from "../permission/Can";
import { ability } from "../permission/Ability";
import { useContext } from "react";

// const withAuthorization = (permission, Component) => {
//   console.log("==================Permission===================");
//   console.log(permission, "clicked");
//   const WrappedComponent = (props) => {
//     console.log(props);
//     // Generate rules for the current user's role
//     // const ability = defineRulesFor(props?.currentUser?.role);

//     return (
//       <Can I={permission} ability={propsability}>
//         {(allowed) => (allowed ? <Component {...props} /> : <NotAuthorized />)}
//       </Can>
//     );
//   };

//   return WrappedComponent;
// };

// export default withAuthorization;
const authorize = (permission) => (Component) => {
  const ProtectedComponent = (props) => {
    const ability = useContext(AbilityContext);

    return !ability.can(permission, "all") ? (
      <Component {...props} />
    ) : (
      <NotAuthorized />
    );
  };
  return ProtectedComponent;
};

export default authorize;
