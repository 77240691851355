import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import { client } from "./graph-query/client";
import { AbilityContext } from "./permission/Can";
import { ability } from "./permission/Ability";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loader from "./components/Loader";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>
    <AbilityContext.Provider value={ability}>
      <BrowserRouter>
        <ToastContainer />
        <Loader />
        <App />
      </BrowserRouter>
    </AbilityContext.Provider>
  </ApolloProvider>
);
