import React from "react";
import { NavLink } from "react-router-dom";

const StepperList = ({ icon, stepperText, custom, _link, disabled }) => {
  return (
    <NavLink className={disabled ? 'select-none pointer-events-none cursor-not-allowed opacity-60' : ''} to={_link}>
      <div
        className={`flex gap-2 items-center px-6 py-2  cursor-pointer rounded hover:bg-primary text-N50 hover:text-white text-sm transition-all duration-300 bg-N99/50 ${custom}`}
      >
        {icon}
        <p className="w-max ">{stepperText}</p>
      </div>
    </NavLink>
  );
};

export default StepperList;
