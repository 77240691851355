import React from "react";
import { MdEmail, MdPhone } from "react-icons/md";

const CreatorCard = ({ data, custom }) => {
  return (
    <div className="relative flex flex-col items-center rounded-[20px] w-full mx-auto p-4 bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:!shadow-none">
      <div className="relative flex h-32 w-full justify-center rounded-xl bg-cover">
        <img
          src="https://horizon-tailwind-react-git-tailwind-components-horizon-ui.vercel.app/static/media/banner.ef572d78f29b0fee0a09.png"
          className="absolute flex h-32 w-full justify-center rounded-xl bg-cover"
        />
        <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
          <img
            className="h-full w-full rounded-full"
            src="https://cdn-icons-png.flaticon.com/512/147/147142.png"
            alt=""
          />
        </div>
      </div>
      <div className="mt-16 flex flex-col items-center">
        <h4 className="text-xl font-bold text-slate-700">
          {data?.name}
        </h4>
        <p className="text-base font-normal text-gray-600">Owner</p>
      </div>
      <div className="mt-6 mb-3 flex gap-14 md:!gap-14">
        <div className="flex flex-col items-center justify-center">
          <p className="text-lg md:text-2xl font-semibold text-slate-700">Email</p>
          <p className="ml-4 md:ml-0 text-sm font-normal text-gray-600">{data?.email}</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="text-lg md:text-2xl font-semibold text-slate-700">Phone</p>
          <p className="mr-4 md:ml-0 text-sm font-normal text-gray-600">
            {data?.phone_number || "--"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CreatorCard;
