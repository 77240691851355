import React from "react";
import MainModal from "./MainModal";
import { CTextArea, FormProvider } from "../Forms/Fields";
import { useForm } from "react-hook-form";
import { MdCancel } from "react-icons/md";

const RejectModal = ({ title, handleRejectDocument, setShowDocumentModal }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const reviewTextDoc = watch("review_note_doc");

  return (
    <MainModal>
      <div className="relative w-1/3 my-6 mx-auto max-w-3xl">
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
            <h3 className="text-xl font-semibold">{title}</h3>
            <button
              className="bg-transparent border-0 text-black float-right flex justify-center items-center"
              onClick={() => setShowDocumentModal(false)}
            >
              <MdCancel />
            </button>
          </div>
          <FormProvider onSubmithandler={handleSubmit(handleRejectDocument)}>
            <div className="relative p-6 flex-auto">
              <div className="rounded px-8 pt-2 pb-2 w-full">
                <CTextArea
                  name="review_note_doc"
                  register={register}
                  errors={errors}
                  validation={{ required: true }}
                  type="text"
                  label="Review note"
                />
              </div>
            </div>
            <div className="flex items-center justify-end p-6 mr-7 border-t border-solid border-blueGray-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                type="button"
                onClick={() => setShowDocumentModal(false)}
              >
                Close
              </button>
              <button
                className={`${!reviewTextDoc ? 'cursor-not-allowed' : ''} text-white bg-red50 active:bg-red70 font-normal uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1`}
                type="submit"
                disabled={!reviewTextDoc}
              >
                Submit
              </button>
            </div>
          </FormProvider>
        </div>
      </div>
    </MainModal>
  );
};

export default RejectModal;
