import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { GET_ORGANIZATION_PERMITS } from "../../../graph-query/queries";
import { useLocalStore } from "../../../store";
import Table from "../../../components/CustomTable";
import Moment from "react-moment";
import StatusTags from "../../../components/utilities/StatusTags";
import { useNavigate } from "react-router-dom";
import { WORK_PERMIT_TYPES } from "../../../utils/constants";

const RequestedOrganizationWorkPermit = () => {
  const [currentTab, setCurrentTab] = useState("new_applicant");
  const { userData, organizationWorkPermits, setOrganizationWorkPermits } =
    useLocalStore();
  const [pageCount, setPageCount] = useState(0);

  const router = useNavigate();

  const [getRequstedWorkPermit, { loading, error }] = useLazyQuery(
    GET_ORGANIZATION_PERMITS,
    {
      variables: {
        orgID:
          userData?.id ||
          JSON.parse(localStorage.getItem("currentOrg"))?.currentOrg?.id, //userOrganizationData?.id ||
      },
      onCompleted: (data) => {
        setOrganizationWorkPermits(data?.expatriate_work_permits);
      },
    }
  );

  useEffect(() => {
    getRequstedWorkPermit();
  }, []);

  const columns = [
    {
      Header: "Name",
      accessor: (c) => (
        <div className="flex items-center justify-start gap-2">
          <button
            type="button"
            className="relative"
            data-model-target="#defaultModal"
            data-modal-toggle="#defaultModal"
          >
            <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
              <span className="font-medium text-gray-600 dark:text-gray-300">
                {c?.expatriate?.first_name?.substring(0, 1) +
                  c?.expatriate?.father_name?.substring(0, 1)}
              </span>
            </div>
          </button>
          {c?.expatriate?.first_name} {c?.expatriate?.father_name}
        </div>
      ),
    },
    {
      Header: "Applied date",
      accessor: (c) => <Moment format="MMMM Do YYYY">{c?.created_at}</Moment>,
    },
    {
      Header: "Permit Type",
      accessor: (c) => <span>{c?.expatriate_work_permit_type?.name}</span>,
    },
    {
      Header: "Permit Status",
      accessor: (c) =>
        c?.review_status ? (
          <StatusTags status={"active"} />
        ) : c?.review_status === false ? (
          <StatusTags status={"rejected"} />
        ) : (
          <StatusTags status={"pending"} />
        ),
    },
    {
      Header: "Document Status",
      accessor: (c) => (
        <div className="text-center bg-gray-200 p-2 rounded-lg">
          {c?.document_status}
        </div>
      ),
    },
    {
      Header: "Nationality",
      accessor: (c) => c?.expatriate?.nationality?.name,
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ cell }) => (
        <button
          // onClick={() => console.log(cell?.row.original)}
          onClick={() =>
            router(
              `/organization/requested-work-permit/detail/${cell?.row.original?.id}`
            )
          }
          className="border-2 border-blue-400 text-blue-400 px-3 py-1 rounded-md hover:bg-blue-400 hover:text-white"
        >
          Detail
        </button>
      ),
    },
  ];

  return (
    <div>
      <div className="text-sm mb-5 w-full font-medium text-center text-gray-500 border-b border-gray-200">
        <ul className="customer-scroll mb-5 truncate flex bg-gray-100 md:overflow-hidden overflow-x-scroll rounded-2xl flex-nowrap md:flex-wrap -mb-px">
          <li onClick={() => setCurrentTab("new_applicant")} className="mr-2">
            <a
              href="#"
              className={`${
                currentTab === "new_applicant"
                  ? "text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                  : ""
              } inline-block p-4 border-b-2 border-transparent rounded-t-lg`}
            >
              New Permit
            </a>
          </li>
          <li
            onClick={() => setCurrentTab("renewal_applicant")}
            className="mr-2"
          >
            <a
              href="#"
              className={`${
                currentTab === "renewal_applicant"
                  ? "text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                  : ""
              } inline-block p-4 border-b-2 border-transparent rounded-t-lg`}
              aria-current="page"
            >
              Renewal
            </a>
          </li>
          <li
            onClick={() => setCurrentTab("cancellation_applicant")}
            className="mr-2"
          >
            <a
              href="#"
              className={`${
                currentTab === "cancellation_applicant"
                  ? "text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                  : ""
              } inline-block p-4 border-b-2 border-transparent rounded-t-lg`}
            >
              Cancellation
            </a>
          </li>
          <li
            onClick={() => setCurrentTab("replacement_applicant")}
            className="mr-2"
          >
            <a
              href="#"
              className={`${
                currentTab === "replacement_applicant"
                  ? "text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                  : ""
              } inline-block p-4 border-b-2 border-transparent rounded-t-lg`}
            >
              Replacement
            </a>
          </li>
        </ul>
        {currentTab === "new_applicant" && (
          <Table
            columns={columns}
            data={organizationWorkPermits?.filter(i => i?.expatriate_work_permit_type?.id === WORK_PERMIT_TYPES.NEW).map(i => i)}
            loading={loading}
            error={error}
            title={`New Work Permit Requested Application (${organizationWorkPermits?.filter(i => i?.expatriate_work_permit_type?.id === WORK_PERMIT_TYPES.NEW)?.length})`}
            headerClassess="bg-primary w-full"
            pageCount={pageCount}
          />
        )}
        {currentTab === "renewal_applicant" && (
          <Table
            columns={columns}
            data={organizationWorkPermits?.filter(i => i?.expatriate_work_permit_type?.id === WORK_PERMIT_TYPES.RENEWAL).map(i => i)}
            loading={loading}
            error={error}
            title={`Renewal Requested Work Permit Application (${organizationWorkPermits?.filter(i => i?.expatriate_work_permit_type?.id === WORK_PERMIT_TYPES.RENEWAL)?.length})`}
            headerClassess="bg-primary w-full"
            pageCount={pageCount}
          />
        )}
        {currentTab === "cancellation_applicant" && (
          <Table
            columns={columns}
            data={organizationWorkPermits?.filter(i => i?.expatriate_work_permit_type?.id === WORK_PERMIT_TYPES.CANCELLATION).map(i => i)}
            loading={loading}
            error={error}
            title={`Cancellation Requested Work Permit Application (${organizationWorkPermits?.filter(i => i?.expatriate_work_permit_type?.id === WORK_PERMIT_TYPES.CANCELLATION)?.length})`}
            headerClassess="bg-primary w-full"
            pageCount={pageCount}
          />
        )}
        {currentTab === "replacement_applicant" && (
          <Table
            columns={columns}
            data={organizationWorkPermits?.filter(i => i?.expatriate_work_permit_type?.id === WORK_PERMIT_TYPES.REPLACEMENT).map(i => i)}
            loading={loading}
            error={error}
            title={`Replacement Requested Work Permit Application (${organizationWorkPermits?.filter(i => i?.expatriate_work_permit_type?.id === WORK_PERMIT_TYPES.CANCELLATION)?.length})`}
            headerClassess="bg-primary w-full"
            pageCount={pageCount}
          />
        )}
      
      </div>

    </div>
  );
};

export default RequestedOrganizationWorkPermit;
