import React, { useEffect, useState } from "react";
import { useLocalStore } from "../../../store";
import { useLazyQuery } from "@apollo/client";
import { GET_EMPLOYEES } from "../../../graph-query/queries";
import { useNavigate } from "react-router-dom";
import { OrganizaEmployeeByRole } from "../../../utils/utilityFunctions";
import { roles } from "../../../utils/roles";

const UserList = () => {
  const [currentTab, setCurrentTab] = useState(roles.WP_DOC_CHECKER);
  const [employeeData, setEmployeeData] = useState([]);
  // const [filterEmployeeRole, setFilterEmployeeRole] = useState();

  const router = useNavigate();
  const filterEmployeeRole = OrganizaEmployeeByRole(employeeData);

  const [getEmplyee, { loading, error }] = useLazyQuery(GET_EMPLOYEES, {
    onCompleted: (data) => {
      setEmployeeData(data?.employees);
      console.log(OrganizaEmployeeByRole(data?.employees));
      // setFilterEmployeeRole(OrganizaEmployeeByRole(data?.employees));
    },
  });

  useEffect(() => {
    getEmplyee();
  }, [employeeData, setEmployeeData]);

  return (
    <div className="flex flex-col gap-5">
      <div class="text-sm font-medium text-center text-gray-500 border-b border-gray-100">
        <ul class="flex flex-wrap -mb-px">
          {filterEmployeeRole &&
            Object?.keys(filterEmployeeRole)
              ?.filter((i) =>
                [roles.WP_DOC_CHECKER, roles.WP_EMR_GENERATOR, roles.WP_FINANCE].includes(
                  filterEmployeeRole[i][0]?.user_info?.allowed_roles[0]
                    ?.role_information?.value
                )
              )
              .map((i, index) => (
                <li
                  key={index}
                  onClick={() =>
                    setCurrentTab(
                      filterEmployeeRole[i][0]?.user_info?.allowed_roles[0]
                        ?.role_information?.value
                    )
                  }
                  className="mr-2"
                >
                  <a
                    href="#"
                    className={`${
                      currentTab ===
                      filterEmployeeRole[i][0]?.user_info?.allowed_roles[0]
                        ?.role_information?.value
                        ? "text-blue-600 border-b-2 border-blue-600 active"
                        : ""
                    } inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600`}
                  >
                    {
                      filterEmployeeRole[i][0]?.user_info?.allowed_roles[0]
                        ?.role_information?.value
                    }
                  </a>
                </li>
              ))}
        </ul>
      </div>

      {/* card */}
      <h1 className="text-black font-semibold">
        {currentTab.toUpperCase()} Employee
      </h1>
      <div className="grid grid-cols-3 gap-4">
        {filterEmployeeRole &&
          Object?.keys(filterEmployeeRole)?.map((item, index) => {
            if (
              filterEmployeeRole[item][0]?.user_info.allowed_roles[0]
                ?.role_information?.value === currentTab
            ) {
              return filterEmployeeRole[item]?.filter(i => i?.user_info?.name !== "Test").map((item, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg overflow-hidden shadow-md"
                >
                  <div className="flex justify-between mx-6 my-4">
                    <div class="relative inline-flex items-center justify-center w-16 h-16 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                      <span class="font-semibold text-xl text-gray-600 dark:text-gray-300">
                        {item?.user_info?.name?.substring(0, 2)}
                      </span>
                    </div>
                  </div>
                  <div className="px-6 py-2">
                    <div className="font-bold text-xl mb-2">
                      {item?.user_info?.name}
                    </div>
                    <p className="text-gray-700 text-base">
                      Total Assigned:{" "}
                      <span className="font-extrabold underline text-lg">
                        {
                          item?.assigned_workpermit_reviewers_aggregate
                            ?.aggregate?.count
                        }
                      </span>
                    </p>
                    <p className="text-gray-700 text-base">
                      Phone:{" "}
                      <a
                        href={`tel:${item?.user_info?.phone_number}`}
                        className="font-semibold underline"
                      >
                        {item?.user_info?.phone_number}
                      </a>
                    </p>
                  </div>
                  <button
                    type="button"
                    onClick={() =>
                      router(
                        `/dashboard/user-list/detail/${item?.user_info?.id}`
                      )
                    }
                    className="ml-5 border-2 text-sm border-gray-500 hover:bg-primary hover:text-white rounded-md px-4 py-0.5 mb-4"
                  >
                    View Detail
                  </button>
                </div>
              ));
            }
          })}
      </div>
    </div>
  );
};

export default UserList;
