import React from "react";
import ReportCard from "../../components/cards/report/ReportCard";
import DonutChart from "../../components/chart/DountChart";
import BarChart from "../../components/chart/BarChart";
import { useReportStore } from "../../store/reportStore";

const ReportDashboard = () => {
  const {
    reportCount,
    chartData,
  } = useReportStore();

  return (
    <div className="w-full mt-10">
      <div className="grid gap-4 grid-cols-2 md:grid-cols-6">
        <div className="col-span-2">
          <ReportCard label="Total Expatriates" data={reportCount?.total_permit?.aggregate?.count} />
        </div>
        <div className="col-span-2">
          <ReportCard
            label="Total Document Checker Approved"
            data={reportCount?.document_checker_approved?.aggregate?.count}
          />
        </div>
        <div className="col-span-2">
          <ReportCard
            label="Total EMR Generator Approved(Finalized)"
            data={reportCount?.emr_approved_permits?.aggregate?.count}
          />
        </div>
      </div>

      {chartData && (
        <div className="mt-8 grid gap-4 grid-cols-1 md:grid-cols-3 w-full">
          <div className="col-span-1 bg-red95 h-[400px]">
            <DonutChart
              legend={false}
              data={[
                chartData?.new_permit?.aggregate?.count,
                chartData?.renewal_permit?.aggregate?.count,
                chartData?.cancellation_permit?.aggregate?.count,
              ]}
            />
          </div>
          <div className="col-span-2 bg-blue-100 h-[400px]">
            <BarChart />
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportDashboard;
