import { create } from "zustand";
import { set as idbSet, get as idbGet } from "idb-keyval";

export const useLocalStore = create((set) => ({
  fontSize: 0.8,
  setFontSize: (size) => set({ fontSize: size }),

  limitOrg: 10,
  offsetOrg: 0,
  limit: 10,
  offset: 0,
  totalAssignedPermit: 0,
  setOffset: (offset) => set({ offset }),
  setLimit: (limit) => set({ limit }),
  setOffsetOrg: (offsetOrg) => set({ offsetOrg }),
  setLimitOrg: (limitOrg) => set({ limitOrg }),
  setTotalAssignedPermit: (totalAssignedPermit) => set({ totalAssignedPermit }),
  userData: {},
  permitPrice: [],
  setPermitPrice: (permitPrice) => set({ permitPrice }),
  selectedCurrentOrg: null,
  isAuthenticated: false,
  loading: false,
  error: null,
  defaultLimit: 20,
  defaultOffset: 0,
  userOrganizationData: [],
  baseRoles: [],
  organizationDashboardData: null,

  currentRole: null,
  workPermits: [],
  notAssignedWorkPermits: [],

  assginedWorkPermits: [],
  verifiedWorkPermits: [],
  renewalWorkPermits: [],
  failedWorkPermits: [],
  canceledWorkPermits: [],
  renewalWorkPermits: [],

  // report
  totalReport: null, 
  dailyTotalReport: null,

  // Sector Data
  sectors: [],
  regions: [],
  serviceType: [],
  qualificationLevels: [],
  qualificationTypes: [],
  professionalTitles: [],
  nationality: [],
  occupationCatagory: [],
  documentTypes: [],
  districts: [],
  subcities: [],
  visaTypes: [],
  martialTypes: [],
  genders: [],
  orgDocumentTypes: [],

  expatriateWayEntered: [],

  businessDocuuments: [],

  documentTypeCategories: [],

  setTotalReport: (data) => set({ totalReport: data }),
  setDailyTotalReport: (data) => set({ dailyTotalReport: data }),

  setDocumentTypeCategories: (documentTypeCategories) =>
    set({ documentTypeCategories }),

  filterOptionTable: [
    { label: "Tin Number", value: "tin_number" },
    { label: "Passport Number", value: "passport_number" },
    { label: "Phone Number", value: "phone_number" },
    { label: "Emr Number", value: "emr_number" },
    { label: "Reference NUmber", value: "reference_number" },
  ],
  fileUploadAttributes: [
    {
      name: "Letter of Application",
      value: "letter_of_application",
    },
    {
      name: "Letter of Support",
      value: "letter_of_support",
    },
    {
      name: "Travel Document",
      value: "letter_of_application",
    },
    {
      name: "Contract Employment",
      value: "contract_employment",
    },
    {
      name: "Attachemnt of Arrival Date",
      value: "arrival_date_attachement",
    },
  ],
  educationalFileUploadAttributes: [
    {
      name: "Certificate",
      value: "certificate",
    },
    {
      name: "Diploma",
      value: "diploma",
    },
    {
      name: "BA/BSC",
      value: "ba/bsc",
    },
    {
      name: "MA/MSC",
      value: "ma/msc",
    },
    {
      name: "PHD",
      value: "phd",
    },
    {
      name: "Other",
      value: "other",
    },
  ],

  singleWorkPermit: null,

  organizationType: [],
  zones: [],
  woredas: [],
  incomingOrganizations: [],

  currentOrganization: [],
  setCurrentOrganization: (currentOrganization) => set({ currentOrganization }),

  setOrgDocumentTypes: (orgDocumentTypes) => set({ orgDocumentTypes }),

  // DASHBORD
  totalExpatrates: null,
  totalNewPermits: null,
  totalRenwalPermits: null,
  totalCancelledPermits: null,
  setTotalNewPermits: (totalNewPermits) => set({ totalNewPermits }),
  setTotalRenwalPermits: (renewalWorkPermits) => set({ renewalWorkPermits }),
  setTotalCancelledPermits: (canceledWorkPermits) =>
    set({ canceledWorkPermits }),
  setTotalExpatrates: (totalExpatrates) => set({ totalExpatrates }),
  //

  setSelectedCurrentOrg: (selectedCurrentOrg) => set({ selectedCurrentOrg }),

  setBaseRoles: (baseRoles) => set({ baseRoles }),

  setServiceType: (serviceType) => set({ serviceType }),

  setGenders: (genders) => set({ genders }),

  setAssignedWorkPermits: (assginedWorkPermits) => set({ assginedWorkPermits }),

  setIncomingOrganization: (incomingOrganizations) =>
    set({ incomingOrganizations }),

  data: null,

  getData: (key) => {
    const savedData = JSON.parse(localStorage.getItem(key)) || {};

    set({ data: savedData });
  },
  // setData: (db, value) => {
  //   console.log(db,value);
  //   console.log(Object.values(value)[0]);
  //   try {
  //     const request = window.indexedDB.open(db, 1);

  //     request.onerror = function (event) {
  //       console.log("Error opening database");
  //     };

  //     request.onsuccess = function (event) {
  //       const db = event.target.result;
  //       const transaction = db.transaction(Object.keys(value)[0], "readwrite");
  //       const store = transaction.objectStore("workPermit");

  //       const getRequest = store.get(db);
  //     }
  //   } catch (error) {
  //     console.log("Error occurs", error);
  //   }
  // },

  setData: (key, value) => {
    try {
      // Retrieve the saved data from localStorage or initialize it to an empty object
      const savedData = JSON.parse(localStorage.getItem(key)) || {};
      // Merge the new value with the saved data
      // const updatedData = { ...savedData, ...value };
      const updatedData = {
        ...savedData,
        [key]: { ...savedData[key], ...value },
      };
      // Merge the new value with the saved data using a recursive function
      // const updatedData = mergeNestedObjects(savedData, value);

      console.log("store: ", { [key]: { ...savedData[key] } });
      console.log("store: ", updatedData);
      // console.log('store: ', JSON.stringify(updatedData));
      // Save the updated data to localStorage
      localStorage.setItem(key, JSON.stringify(updatedData));

      // Set the data in the store
      set({ data: updatedData });
    } catch (Error) {
      console.log("error occurs", Error);
    }
  },

  setUserData: (key, value) => {
    // Retrieve the saved data from localStorage or initialize it to an empty object
    const savedData = JSON.parse(localStorage.getItem(key)) || {};
    // Merge the new value with the saved data
    const _org = { ...savedData, ...value };

    // Save the updated data to localStorage
    localStorage.setItem(key, JSON.stringify(_org));

    // Set the data in the store
    set({ userData: _org });
  },

  organizationWorkPermits: [],

  setOrganizationDashboardData: (organizationDashboardData) =>
    set({ organizationDashboardData }),

  setOrganizationWorkPermits: (organizationWorkPermits) =>
    set({ organizationWorkPermits }),

  setExpatriateWayEntered: (expatriateWayEntered) =>
    set({ expatriateWayEntered }),

  setVisaTypes: (visaTypes) => set({ visaTypes }),

  setMartialTypes: (martialTypes) => set({ martialTypes }),

  setWoredas: (woredas) => set({ woredas }),

  setZones: (zones) => set({ zones }),

  setDistricts: (districts) => set({ districts }),

  setSubCities: (subcities) => set({ subcities }),

  // when login get user credential and organization that registered
  setUserOrganizationData: (userOrganizationData) =>
    set({ userOrganizationData }),

  setDocumentTypes: (documentTypes) => set({ documentTypes }),

  filterDocumentTypes: (filterName) =>
    set((state) => ({
      businessDocuuments: state.documentTypes.filter(
        (i) => i?.document_type_category === filterName
      ),
    })),

  setOccupationCatagory: (occupationCatagory) => set({ occupationCatagory }),

  setNationality: (nationality) => set({ nationality }),

  setOrganizationType: (organizationType) => set({ organizationType }),

  setSectors: (sectors) => set({ sectors }),

  setRegions: (regions) => set({ regions }),

  setQualificationLevels: (qualificationLevels) => set({ qualificationLevels }),

  setQualificationTypes: (qualificationTypes) => set({ qualificationTypes }),

  setProfessionalTitles: (professionalTitles) => set({ professionalTitles }),

  setUser: (user) => set({ user }),
  // ====================WORK PERMIT LIST===========================
  setWorkPermits: (workPermits) => set({ workPermits }),
  setNotAssignedWorkPermits: (notAssignedWorkPermits) =>
    set({ notAssignedWorkPermits }),
  setVerifiedWorkPermits: (verifiedWorkPermits) => set({ verifiedWorkPermits }),
  setFailedWorkPermits: (failedWorkPermits) => set({ failedWorkPermits }),
  setCanceledWorkPermits: (canceledWorkPermits) => set({ canceledWorkPermits }),
  setRenewalWorkPermits: (renewalWorkPermits) => set({ renewalWorkPermits }),
  setCurrentRole: (currentRole) => set({ currentRole }),

  // ===================SINGLE WORK PERMIT==========================
  setSingleWorkPermit: (singleWorkPermit) => set({ singleWorkPermit }),
  // getSingleWorkPermit: () => set({ singleWorkPermit }),

  // =========================HANDLE MUTATION=======================
  verifyDoc: () => {},
  assignReviewer: () => {},
  assignReviewer: () => {},
}));

const mergeNestedObjects = (target, source) => {
  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      if (source[key] && typeof source[key] === "object") {
        // If the value is a nested object, recursively merge it
        target[key] = mergeNestedObjects(target[key] || {}, source[key]);
      } else {
        // Otherwise, assign the value directly
        target[key] = source[key];
      }
    }
  }
  return target;
};
