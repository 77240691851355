import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/common/Navbar";
// import Input from "../components/utilities/Input";

const VerifyEmail = () => {
  return (
    <>
      <div className=" relative">
        <Navbar>
          <div className="flex gap-4 2xl:gap-8 text-primary">
            <Link to="/">about us</Link>
            <Link to="/">log in</Link>
          </div>
        </Navbar>

        <div className="w-full flex justify-center items-center h-[75vh]">
          <div className="md:w-1/2 w-full flex items-center justify-center flex-col gap-6 2xl:gap-8">
            <div className="flex justify-center items-center flex-col">
              <h1 className=" text-primary text-3xl w-max text-center font-semibold pb-2">
                We have sent you a verification <br /> code to your E-Mail
                Address
              </h1>
              <p className="text-xs font-light text-primary">
                Please, Enter the code you received below.
              </p>
            </div>
            <div className=" bg-white w-11/12 md:w-3/5 2xl:w-1/2 p-4 2xl:p-8 rounded-md shadow-md space-y-4">
              {/* <Input type={"email"} placeholder="Email Address" /> */}
              <button className="bg-primary hover:bg-prime50 w-full text-white py-3 rounded">
                Verify
              </button>
              <button className="bg-white w-full text-primary shadow-none py-1 rounded">
                Resend Code
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
