import React from "react";
import PropTypes from "prop-types";

FormProvider.propTypes = {
  children: PropTypes.node.isRequired,
  // onSubmithandler: PropTypes.func.isRequired,
};

export default function FormProvider({ children, onSubmithandler }) {
  return <form onSubmit={onSubmithandler}>{children}</form>;
}
