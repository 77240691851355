import React from "react";
import Building from "../../assets/images/Building.png";
import { Link, useNavigate } from "react-router-dom";
import { CInputField, FormProvider } from "../../components/Forms/Fields";
import CButton from "../../components/Forms/CButton";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { SIGN_UP } from "../../graph-query/mutation";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { RegitrationSchema } from "../../utils/SchemaValidation";
import {
  handleOrganizationError,
  phoneValidation,
} from "../../utils/utilityFunctions";

const Signup = () => {
  const router = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(RegitrationSchema),
  });

  const [signIn, { loading }] = useMutation(SIGN_UP);

  const handleSignUp = (data) => {
    // let _phone = phoneValidation(data.phone_number);
    // console.log(_phone)
    signIn({
      variables: {
        ...data,
        phone_number: "251" + data?.phone_number,
      },
      onCompleted: (res) => {
        console.log("res", res);
        if (res?.signup.message !== "" && res.signup.message !== null) {
          toast.success(res?.signup?.message);
          localStorage.setItem(
            "temp",
            JSON.stringify({ phone: "251" + data?.phone_number })
          );
          // router("/verify-account");
          router("/login");
        } else {
          if (
            res?.signup?.error?.includes(
              'Uniqueness violation. duplicate key value violates unique constraint "users_phone_number_key"'
            )
          ) {
            toast.error(
              "Sorry, the phone number you entered is already in use"
            );
          } else if (
            res?.signup?.error?.includes(
              'Check constraint violation. new row for relation "users" violates check constraint "name_check"'
            )
          ) {
            toast.error("User already Exist, try to Login");
          } else {
            toast.error(res?.signup?.error);
          }
        }
      },
      onError: (er) => {
        let _err = handleOrganizationError(er);
        toast.error(_err);
      },
    });
  };
  return (
    <>
      <div className=" relative">
        <div className="fixed flex top-0 left-0 w-full h-screen">
          <div className="w-1/2 hidden bg-white md:flex items-center justify-center flex-col">
            <img src={Building} alt="permit" className="w-3/5" />
            <h2 className="text-xl text-N80 capitalize w-1/2 text-center font-medium">
              We work for our country, our country lives for us
            </h2>
          </div>
          <div className="md:w-1/2 w-full bg-gradient-to-b from-prime20 via-prime50 to-Prime95 flex items-center justify-center flex-col gap-6 2xl:gap-8">
            <h1 className=" text-white text-2xl w-3/4 xl:w-2/5 text-center font-medium">
              Welcome to foreign work permit account
            </h1>

            <div className=" bg-white w-11/12 md:w-3/5 2xl:w-1/2 p-4 2xl:p-8 rounded-md shadow-md space-y-4">
              <FormProvider onSubmithandler={handleSubmit(handleSignUp)}>
                {/* <Input placeholder="Organization Name" /> */}
                <CInputField
                  name="name"
                  register={register}
                  errors={errors}
                  validation={{ required: true }}
                  type="text"
                  label="Name"
                />

                <CInputField
                  name="email"
                  register={register}
                  errors={errors}
                  validation={{ required: true }}
                  type="email"
                  label="Email"
                />

                <CInputField
                  name="phone_number"
                  register={register}
                  errors={errors}
                  validation={{ required: true }}
                  type="tel"
                  placeholder={"9******** | 7********"}
                  label="Phone Number"
                />

                <CInputField
                  name="password"
                  register={register}
                  errors={errors}
                  validation={{ required: true }}
                  type="password"
                  label="Password"
                />
                <CInputField
                  name="confirm_password"
                  register={register}
                  errors={errors}
                  validation={{ required: true }}
                  type="password"
                  label="Confirm Password"
                />
                <CButton
                  btnLabel={"SignUp"}
                  loading={loading}
                  type={"submit"}
                  classes="bg-primary hover:bg-prime50 w-full text-white py-3 rounded"
                />

                <p className="text-xs w-full mt-4 text-center">
                  Already have an account?{" "}
                  <Link to="/login">
                    <span className="text-primary font-medium">Sign In</span>
                  </Link>
                </p>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
