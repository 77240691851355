import React from "react";

const Pagination = ({
  limit,
  offset,
  totalPages,
  handlePreviousPage,
  handleNextPage,
  renderPageButtons,
  handlePageLimit
}) => {
  return (
    <nav
      class="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0"
      aria-label="Table navigation"
    >
      <span class="text-sm font-normal text-gray-500 dark:text-gray-400">
        Showing
        <span class="font-semibold text-gray-900">{offset + 1}</span>
        of
        <span class="font-semibold text-gray-900">{totalPages}</span>
        <select
          onChange={(e) => handlePageLimit(e?.target?.value)}
          className="ml-3 px-4 py-0.5 rounded-md"
          name=""
          id=""
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </span>
      <ul class="inline-flex items-stretch -space-x-px">
        <li>
          <a
            onClick={handlePreviousPage}
            className={`${
              offset === 0 ? "opacity-25 pointer-events-none" : ""
            } flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 `}
          >
            <span class="sr-only">Previous</span>
            <svg
              class="w-5 h-5"
              aria-hidden="true"
              fill="currentColor"
              viewbox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
        </li>
        {renderPageButtons}
        <li>
          <a
            onClick={handleNextPage}
            className={`${
              offset === totalPages - 1 ? "opacity-25 pointer-events-none" : ""
            } flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
          >
            <span class="sr-only">Next</span>
            <svg
              class="w-5 h-5"
              aria-hidden="true"
              fill="currentColor"
              viewbox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
