import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/common/Navbar";
import ConcentIllustration from "../assets/images/tip-illustrations/Consent.png";
import TaskIllustration from "../assets/images/tip-illustrations/Task.png";
import CancelledIllustration from "../assets/images/tip-illustrations/Cancelled.png";
import NewMessageIllustration from "../assets/images/tip-illustrations/NewMessage.png";
import { BsFillFlagFill } from "react-icons/bs";

const Tips = () => {
  const [currentPage, setCurrentPage] = useState("renewal");

  return (
    <>
      <div className="w-full relative">
        <Navbar custom={"bg-white"}>
          <div className="flex gap-4">
            <Link to="/" className="text-primary">
              about us
            </Link>
            <Link to="/" className="text-primary">
              log in
            </Link>
          </div>
        </Navbar>

        {currentPage === "replacement" && (
          <div className="h-screen top-0 absolute w-full flex">
            <div className="w-1/2 h-full flex justify-end bg-white items-end">
              <div className="flex flex-col gap-4 w-4/5">
                <p className="text-3xl font-semibold text-primary">
                  Replacement Form Requirement
                </p>

                {/* lists container */}
                <div className="w-full flex flex-col gap-2 items-start h-[70vh] overflow-auto">
                  {/* Lists */}
                  <div className="flex gap-4 text-primary py-4 border-b border-N99 items-center w-full">
                    <BsFillFlagFill />
                    <p className="text-N90 text-xs w-4/5">
                      Letter of application of the employer.
                    </p>
                  </div>

                  {/* Lists */}
                  <div className="flex gap-4 text-primary py-4 border-b border-N99 items-center w-full">
                    <BsFillFlagFill />
                    <p className="text-N90 text-xs w-4/5">
                      Letter of support from the immediate supervising
                      government body
                    </p>
                  </div>

                  {/* Lists */}
                  <div className="flex gap-4 text-primary py-4 border-b border-N99 items-center w-full">
                    <BsFillFlagFill />
                    <p className="text-N90 text-xs w-4/5">
                      Attach evidence from Police or Damage Id
                    </p>
                  </div>

                  {/* Lists */}
                  <div className="flex gap-4 text-primary py-4 border-b border-N99 items-center w-full">
                    <BsFillFlagFill />
                    <p className="text-N90 text-xs w-4/5">
                      Attach residential permit
                    </p>
                  </div>

                  {/* Lists */}
                  <div className="flex gap-4 text-primary py-4 border-b border-N99 items-center w-full">
                    <BsFillFlagFill />
                    <p className="text-N90 text-xs w-4/5">
                      Letter of delegation of the liaison officer accompanied
                      with his/her valid IDs of the organization and kebele
                      (i.e. resident ID); or Power of attorney as appropriate. *
                    </p>
                  </div>

                  {/* Lists */}
                  <div className="flex gap-4 text-primary py-4 border-b border-N99 items-center w-full">
                    <BsFillFlagFill />
                    <p className="text-N90 text-xs w-4/5">
                      1200 ETB - bank payment slip (Non Refundable )
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/2 h-full flex justify-center items-center bg-white relative">
              <img
                src={ConcentIllustration}
                alt="TaskIllustration"
                className="h-2/3 z-10"
              />
              <div className="h-3/5 absolute top-0 left-1/2 -translate-x-1/2 w-4/5 z-0 bg-N99"></div>
            </div>
          </div>
        )}

        {currentPage === "newApplication" && (
          <div className="h-screen top-0 absolute w-full flex">
            <div className="w-1/2 h-full flex justify-end bg-white items-end">
              <div className="flex flex-col gap-4 w-4/5">
                <p className="text-3xl font-semibold text-primary">
                  New Application Form Requirement
                </p>

                {/* lists container */}
                <div className="w-full flex flex-col gap-2 items-start h-[70vh] overflow-auto">
                  {/* Lists */}
                  <div className="flex gap-4 text-primary py-4 border-b border-N99 items-center w-full">
                    <BsFillFlagFill />
                    <p className="text-N90 text-xs w-4/5">
                      Letter of application of the employer.
                    </p>
                  </div>

                  {/* Lists */}
                  <div className="flex gap-4 text-primary py-4 border-b border-N99 items-center w-full">
                    <BsFillFlagFill />
                    <p className="text-N90 text-xs w-4/5">
                      Letter of support from the immediate supervising
                      government body
                    </p>
                  </div>

                  {/* Lists */}
                  <div className="flex gap-4 text-primary py-4 border-b border-N99 items-center w-full">
                    <BsFillFlagFill />
                    <p className="text-N90 text-xs w-4/5">
                      Attach evidence from Police or Damage Id
                    </p>
                  </div>

                  {/* Lists */}
                  <div className="flex gap-4 text-primary py-4 border-b border-N99 items-center w-full">
                    <BsFillFlagFill />
                    <p className="text-N90 text-xs w-4/5">
                      Attach residential permit
                    </p>
                  </div>

                  {/* Lists */}
                  <div className="flex gap-4 text-primary py-4 border-b border-N99 items-center w-full">
                    <BsFillFlagFill />
                    <p className="text-N90 text-xs w-4/5">
                      Letter of delegation of the liaison officer accompanied
                      with his/her valid IDs of the organization and kebele
                      (i.e. resident ID); or Power of attorney as appropriate. *
                    </p>
                  </div>

                  {/* Lists */}
                  <div className="flex gap-4 text-primary py-4 border-b border-N99 items-center w-full">
                    <BsFillFlagFill />
                    <p className="text-N90 text-xs w-4/5">
                      1200 ETB - bank payment slip (Non Refundable )
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/2 h-full flex justify-center items-center bg-white relative">
              <img
                src={TaskIllustration}
                alt="TaskIllustration"
                className="h-2/3 z-10"
              />
              <div className="h-3/5 absolute top-0 left-1/2 -translate-x-1/2 w-4/5 z-0 bg-N99"></div>
            </div>
          </div>
        )}

        {currentPage === "cancelled" && (
          <div className="h-screen top-0 absolute w-full flex">
            <div className="w-1/2 h-full flex justify-end bg-white items-end">
              <div className="flex flex-col gap-4 w-4/5">
                <p className="text-3xl font-semibold text-primary">
                  Cancelation Form Requirement
                </p>

                {/* lists container */}
                <div className="w-full flex flex-col gap-2 items-start h-[70vh] overflow-auto">
                  {/* Lists */}
                  <div className="flex gap-4 text-primary py-4 border-b border-N99 items-center w-full">
                    <BsFillFlagFill />
                    <p className="text-N90 text-xs w-4/5">
                      Letter of application of the employer.
                    </p>
                  </div>

                  {/* Lists */}
                  <div className="flex gap-4 text-primary py-4 border-b border-N99 items-center w-full">
                    <BsFillFlagFill />
                    <p className="text-N90 text-xs w-4/5">
                      Letter of support from the immediate supervising
                      government body
                    </p>
                  </div>

                  {/* Lists */}
                  <div className="flex gap-4 text-primary py-4 border-b border-N99 items-center w-full">
                    <BsFillFlagFill />
                    <p className="text-N90 text-xs w-4/5">
                      Attach evidence from Police or Damage Id
                    </p>
                  </div>

                  {/* Lists */}
                  <div className="flex gap-4 text-primary py-4 border-b border-N99 items-center w-full">
                    <BsFillFlagFill />
                    <p className="text-N90 text-xs w-4/5">
                      Attach residential permit
                    </p>
                  </div>

                  {/* Lists */}
                  <div className="flex gap-4 text-primary py-4 border-b border-N99 items-center w-full">
                    <BsFillFlagFill />
                    <p className="text-N90 text-xs w-4/5">
                      Letter of delegation of the liaison officer accompanied
                      with his/her valid IDs of the organization and kebele
                      (i.e. resident ID); or Power of attorney as appropriate. *
                    </p>
                  </div>

                  {/* Lists */}
                  <div className="flex gap-4 text-primary py-4 border-b border-N99 items-center w-full">
                    <BsFillFlagFill />
                    <p className="text-N90 text-xs w-4/5">
                      1200 ETB - bank payment slip (Non Refundable )
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/2 h-full flex justify-center items-center bg-white relative">
              <img
                src={CancelledIllustration}
                alt="TaskIllustration"
                className="h-2/3 z-10"
              />
              <div className="h-3/5 absolute top-0 left-1/2 -translate-x-1/2 w-4/5 z-0 bg-N99"></div>
            </div>
          </div>
        )}

        {currentPage === "renewal" && (
          <div className="h-screen top-0 absolute w-full flex">
            <div className="w-1/2 h-full flex justify-end bg-white items-end">
              <div className="flex flex-col gap-4 w-4/5">
                <p className="text-3xl font-semibold text-primary">
                  Renewal Form Requirement
                </p>

                {/* lists container */}
                <div className="w-full flex flex-col gap-2 items-start h-[70vh] overflow-auto">
                  {/* Lists */}
                  <div className="flex gap-4 text-primary py-4 border-b border-N99 items-center w-full">
                    <BsFillFlagFill />
                    <p className="text-N90 text-xs w-4/5">
                      Letter of application of the employer.
                    </p>
                  </div>

                  {/* Lists */}
                  <div className="flex gap-4 text-primary py-4 border-b border-N99 items-center w-full">
                    <BsFillFlagFill />
                    <p className="text-N90 text-xs w-4/5">
                      Letter of support from the immediate supervising
                      government body
                    </p>
                  </div>

                  {/* Lists */}
                  <div className="flex gap-4 text-primary py-4 border-b border-N99 items-center w-full">
                    <BsFillFlagFill />
                    <p className="text-N90 text-xs w-4/5">
                      Attach evidence from Police or Damage Id
                    </p>
                  </div>

                  {/* Lists */}
                  <div className="flex gap-4 text-primary py-4 border-b border-N99 items-center w-full">
                    <BsFillFlagFill />
                    <p className="text-N90 text-xs w-4/5">
                      Attach residential permit
                    </p>
                  </div>

                  {/* Lists */}
                  <div className="flex gap-4 text-primary py-4 border-b border-N99 items-center w-full">
                    <BsFillFlagFill />
                    <p className="text-N90 text-xs w-4/5">
                      Letter of delegation of the liaison officer accompanied
                      with his/her valid IDs of the organization and kebele
                      (i.e. resident ID); or Power of attorney as appropriate. *
                    </p>
                  </div>

                  {/* Lists */}
                  <div className="flex gap-4 text-primary py-4 border-b border-N99 items-center w-full">
                    <BsFillFlagFill />
                    <p className="text-N90 text-xs w-4/5">
                      1200 ETB - bank payment slip (Non Refundable )
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/2 h-full flex justify-center items-center bg-white relative">
              <img
                src={NewMessageIllustration}
                alt="TaskIllustration"
                className="h-2/3 z-10"
              />
              <div className="h-3/5 absolute top-0 left-1/2 -translate-x-1/2 w-4/5 z-0 bg-N99"></div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Tips;
