import React from "react";
import { useLocalStore } from "../../store";

const Settings = () => {
  const { fontSize, setFontSize } = useLocalStore();

  const handleFontSizeChange = (event) => {
    const size = parseInt(event.target.value);
    setFontSize(size);
  };

  const handleBgColorChange = (event) => {
    // setBgColor(event.target.value);
  };

  return (
    <div className="bg-white">
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Settings</h1>

        <div className="mb-4">
          <label htmlFor="fontSize" className="mr-2">
            Font Size:
          </label>
          <select
            id="fontSize"
            value={fontSize}
            onChange={handleFontSizeChange}
            className="border px-2 py-1 rounded"
          >
            <option value={14}>Small</option>
            <option value={16}>Medium</option>
            <option value={18}>Large</option>
          </select>
        </div>

        <div className="mb-4">
          <label htmlFor="bgColor" className="mr-2">
            Background Color:
          </label>
          <input
            type="color"
            id="bgColor"
            // value={bgColor}
            onChange={handleBgColorChange}
            className="border px-2 py-1 rounded"
          />
        </div>

        <div
          className="p-4 my-4"
          style={{ fontSize: `${fontSize}px` }}
        >
          Sample Text
        </div>
      </div>
    </div>
  );
};

export default Settings;
