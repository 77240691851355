import { Navigate, useLocation, useRoutes } from "react-router-dom";
import GuestGuard from "../guards/GuestGuard";
import { Suspense, lazy } from "react";
import Dashboard from "../pages/Dashboard/Dashboard";
import DashboardLayout from "../layouts/DashboardLayout";
import {
  PATH_AFTER_LOGIN,
  PATH_AFTER_LOGIN_DASH,
  PATH_AFTER_LOGIN_ORGANIZATION,
  PATH_AUTH,
} from "./paths";
import OrganizationLayout from "../layouts/OrganizationLayout";

{
  /* <Route
      {...rest}
      render={(props) =>
        ability.can("read", "all") ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
     */
}

//Lazy loading
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  return (
    <Suspense
      fallback={<Login isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          element: <Navigate to={PATH_AUTH} replace />,
          index: true,
        },
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: "*",
          element: <Login />,
        },
      ],
    },
    // Dashboard
    {
      path: "dashboard",
      element: <DashboardLayout />,
      children: [
        {
          element: <Navigate to={PATH_AFTER_LOGIN_DASH} replace />,
          index: true,
        },
        { path: "applicant", element: <DashboardApplicant /> },
        { path: "verified", element: <DashboardVerified /> },
      ],
    },
    {
      path: "organization",
      element: <OrganizationLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          element: <Navigate to={PATH_AFTER_LOGIN_ORGANIZATION} replace />,
          index: true,
        },
        { path: "home", element: <OrganizationDashboard /> },
        { path: "create-profile", element: <OrganizationCreateProfile /> },
        { path: "expatriate-list", element: <OrganizationWorkPermits /> },
        { path: "renewal", element: <OrganizationRenewal /> },
        { path: "cancellation", element: <OrganizationCancellation /> },
        { path: "verified", element: <OrganizationVerifiedPermits /> },
      ],
    },
  ]);
}

function ErrorPage() {
  return (
    <div>
      <h1>Error</h1>
      <p>Sorry, an error occurred. Please try again later.</p>
    </div>
  );
}

// Import components
const Login = Loadable(lazy(() => import("../pages/Auth/Login")));
const Register = Loadable(lazy(() => import("../pages/Auth/signup")));
// Dashboard
const generalDashboard = Loadable(
  lazy(() => import("../pages/Dashboard/Dashboard"))
);
const DashboardApplicant = Loadable(
  lazy(() => import("../pages/Applicant/Applicant"))
);
const DashboardVerified = Loadable(
  lazy(() => import("../pages/Dashboard/Verified"))
);

// =============================================================================
const OrganizationDashboard = Loadable(
  lazy(() => import("../pages/Organization/index"))
);
const OrganizationRenewal = Loadable(
  lazy(() => import("../pages/Organization/RenewOrganizationWorkPermit"))
);
const OrganizationWorkPermits = Loadable(
  lazy(() =>
    import("../pages/Organization/RequestedPermits/RequestedOrganizationWorkPermit")
  )
);
const OrganizationCancellation = Loadable(
  lazy(() => import("../pages/Organization/CancellationOrganizationWorkPermit"))
);
const OrganizationCreateProfile = Loadable(
  lazy(() => import("../pages/Organization/CreateOrganizationProfile"))
);
const OrganizationVerifiedPermits = Loadable(
  lazy(() => import("../pages/Organization/VerifiedOrganizationWorkPermit.jsx"))
);
