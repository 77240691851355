import React from "react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

const DropdownAutocomplete = ({
  name,
  label,
  options,
  register,
  validation,
  errors,
  inputErrors,
  classes,
  defaultValue,
  disabled
}) => {
  const { message } = errors[name] || [];

  return (
    <div>
      {label && (
        <label
          for={name}
          className="block mb-2 text-sm font-medium text-gray-900"
        >
          Select {label}
        </label>
      )}
      <select
        id={name}
        {...register(name, { ...validation })}
        value={defaultValue}
        disabled={disabled}
        className={`bg-gray-50 border border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-300 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
          message ? "border-red50" : "border-gray-200"
        }`}
      >
        <option value="" selected disabled>
          Choose {label && `a ${label}`}
        </option>
        {options?.map((item) => (
          <option disabled={item?.disabled} value={item?.value}>{item?.name}</option>
        ))}
      </select>
      {message && (
        <p className="text-start mt-2 text-xs text-red60 dark:text-red40">
          {message}
        </p>
      )}
    </div>
  );
};

const CSelectField = ({
  type,
  name,
  label,
  disabled,
  register,
  validation,
  errors,
  icon,
  classes,
  placeholder,
  options, // array of options for dropdown
  autoComplete,
  defaultValue,
}) => {
  return (
    <DropdownAutocomplete
      type={type}
      register={register}
      validation={validation}
      name={name}
      placeholder={placeholder}
      errors={errors}
      label={label}
      classes={classes}
      icon={icon}
      options={options}
      defaultValue={defaultValue}
      autoComplete={autoComplete}
      disabled={disabled}
    />
  );
};

export default CSelectField;
