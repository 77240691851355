import React from "react";
import Nav from "../../components/Navbar/Navbar";
import SideNav from "../../components/Navbar/SideNav";
import StepperList from "../../components/utilities/StepperList";
import { HiBuildingLibrary } from "react-icons/hi2";
import { useLocalStore } from "../../store";
import Avatar from "../../assets/images/avatar.jpg";
import OrganizationProfile from "../../components/OrganizationProfile";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { GET_ORGANIZATION } from "../../graph-query/queries";
import { useEffect } from "react";
import { useState } from "react";

const Profile = () => {
  const [userOrganization, setUserOrganization] = useState([]);

  const { currentRole, user } = useLocalStore();

  const router = useNavigate();

  const [getData, {}] = useLazyQuery(GET_ORGANIZATION, {
    variables: {
      eq: JSON.parse(localStorage.getItem("work-permit")).userID,
    },
    onCompleted: async (d) => {
      await setUserOrganization(d?.organizations);
    },
    onError: (er) => console.log(er),
  });

  useEffect(() => {
    getData();
  }, [userOrganization, setUserOrganization]);

  return (
    <>
      <>
        <Nav
          Avatar={Avatar}
          userType={JSON.parse(localStorage.getItem("work-permit"))?.roles}
          name={userOrganization}
        />
        <div className="flex flex-col md:flex-row gap-8 2xl:gap-12 mt-10 md:mt-20 layout items-start">
          <SideNav>
            <StepperList
              _link={"/profile"}
              icon={<HiBuildingLibrary className="text-xl" />}
              stepperText={"Organization Data "}
              activeClassName="bg-primary text-white"
            />
          </SideNav>
          <div className="w-full">
            <button
              onClick={() => router(-1)}
              className="flex gap-2 items-center border-2 rounded-md border-gray-300 px-4 py-1"
            >
              <BsArrowLeft /> Back
            </button>
            <OrganizationProfile data={userOrganization[0]} />
          </div>
        </div>
        <div className="mt-16">{/* Footer */}</div>
      </>
    </>
  );
};

export default Profile;
