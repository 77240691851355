import HeroImage from "../../assets/images/hero-image.png";
import { useNavigate } from "react-router-dom";

export const HeroSection = () => {
  const navigate = useNavigate();

  return (
    <div className="hero-section relative min-h-[89vh] flex justify-center items-center">
      <div className="hero-container h-full layout flex justify-between items-center flex-col md:flex-row">
        <div className="text-container 2xl:w-[45%] md:w-3/5 sm:w-4/5 w-full space-y-2 py-8 md:py-0">
          <h1
            className="md:text-[5em] text-3xl font-bold leading-tight text-center md:text-left"
          >
            Welcome to Expatriate Work Permit System
          </h1>
          <p className="mt-4 sm:w-11/12 md:w-4/5 mx-auto md:mx-0 text-center md:text-left text-sm text-primary animate-fade-in-up">
            An expatriate work permit is a legal document that allows a foreign
            national to work in a foreign country for a specific period of time.
          </p>
          <button className="sm:hidden bg-primary text-white py-2 px-4 rounded-lg mt-6 hover:bg-primary-light focus:outline-none focus:ring-2 focus:ring-primary-light transition-colors duration-300 ease-in-out">
            Learn More
          </button>
        </div>
        <div className="image-container overflow-hidden md:w-1/3 w-4/5">
          <img
            src={HeroImage}
            alt="hero"
            className="w-full h-full object-cover object-center transform scale-125 md:scale-100 transition-transform duration-1000 ease-out"
          />
          <div className="gradient-overlay absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50"></div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
