import React from "react";
const FormLabel = ({ labelIcon, labelTitile, labelDescription }) => {
  return (
    <>
      <div className="md:w-[45%] flex  gap-2 items-center justify-start text-primary w-max">
        {labelIcon}
        <div className="flex flex-col">
          <p className="text-N40 font-semibold md:text-md text-sm">{labelTitile}</p>
          <p className="text-N40 text-wrap text-[10px]">{labelDescription}</p>
        </div>
      </div>
    </>
  );
};

export default FormLabel;
