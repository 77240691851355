import React from "react";
import { useController, useForm } from "react-hook-form";
import FormLabel from "../../../components/common/FormLabel";
import { BsBuilding } from "react-icons/bs";
import { useLocalStore } from "../../../store";
import {
  CSelectField,
  CInputField,
  FormProvider,
} from "../../../components/Forms/Fields";
import { useMutation } from "@apollo/client";
import {
  INSERT_EMPLOYEE,
  REGISTER_EMPLOYEE_USER,
} from "../../../graph-query/mutation";
import { toast } from "react-toastify";
import { decodeJWT } from "../../../utils/utilityFunctions";
import { useNavigate } from "react-router-dom";
import {yupResolver} from '@hookform/resolvers/yup'
import { NewEmployeeSchema } from "../../../utils/SchemaValidation";

const AddNewEmployee = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(NewEmployeeSchema)
  });

  const {
    field: { value, onChange },
  } = useController({
    name: "status",
    control,
    defaultValue: false, // Set the default value here
  });

  const navigator = useNavigate();

  const { baseRoles } = useLocalStore();

  const [registerEmployee, {}] = useMutation(REGISTER_EMPLOYEE_USER, {
    onError: (er) => {
      console.log(er);
      toast.error("Error registering employee");
    },
  });

  const [addEmplyee, {}] = useMutation(INSERT_EMPLOYEE, {
    onError: (er) => {
      console.log(er);
      toast.error("Error adding user to employee");
    },
  });

  const handleNewEmplyee = async (e) => {
    // console.log(e);
    await registerEmployee({
      variables: {
        ...e,
        role: [e?.role],
        phone: `251${e?.phone}`
      },
      onCompleted: async (res) => {
        console.log("employee", res);
        if (res?.registerEmployee?.token !== "" && res?.registerEmployee?.token !== null) {
          const { metadata } = decodeJWT(res?.registerEmployee?.token);
          await addEmplyee({
            variables: {
              user_id: metadata["x-hasura-user-id"],
              // role: baseRoles
              //   .filter((i) => i.value === metadata["x-hasura-default-role"])
              //   .map((j) => j?.id)[0],
            },
          });
          navigator("/dashboard/employee-list");
        }
      },
    });
  };
  return (
    <div className="bg-white h-auto p-4">
      <h2 className="capitalize font-semibold text-2xl mt-4 ml-2">
        Add New employee
      </h2>
      <FormProvider onSubmithandler={handleSubmit(handleNewEmplyee)}>
        <div className="flex flex-col p-8">
          <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
            <FormLabel
              labelIcon={<BsBuilding />}
              labelTitile="Employee Name"
              labelDescription="Employee Full name"
            />
            <div className="w-full flex gap-6 py-2">
              <div className=" w-full">
                <CInputField
                  name="name"
                  register={register}
                  errors={errors}
                  validation={{
                    required: true,
                  }}
                  type="text"
                  label=""
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
            <FormLabel
              labelIcon={<BsBuilding />}
              labelTitile="Employee Email"
              labelDescription="Employee Email Address"
            />
            <div className="w-full flex gap-6 py-2">
              <div className=" w-full">
                <CInputField
                  name="email"
                  register={register}
                  errors={errors}
                  validation={{
                    required: true,
                  }}
                  type="email"
                  label=""
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
            <FormLabel
              labelIcon={<BsBuilding />}
              labelTitile="Employee Phone Number"
              labelDescription="Employee Phone Number"
            />
            <div className="w-full flex gap-6 py-2">
              <div className=" w-full">
                <CInputField
                  name="phone"
                  register={register}
                  errors={errors}
                  validation={{
                    required: true,
                  }}
                  type="tel"
                  label=""
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
            <FormLabel
              labelIcon={<BsBuilding />}
              labelTitile="Role"
              labelDescription="Employee Email Address"
            />
            <div className="w-full flex gap-6 py-2">
              <div className=" w-full">
                <CSelectField
                  name="role"
                  register={register}
                  errors={errors}
                  validation={{
                    required: true,
                  }}
                  options={baseRoles?.map((types) => {
                    return {
                      ...types,
                      value: types?.value,
                      name: types?.value,
                    };
                  })}
                  label=""
                  placeholder="Select"
                />
              </div>
            </div>
          </div>
          <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
            <FormLabel
              labelIcon={<BsBuilding />}
              labelTitile="Password"
              labelDescription="Employee Password"
            />
            <div className="w-full flex gap-6 py-2">
              <div className=" w-full">
                <CInputField
                  name="password"
                  register={register}
                  errors={errors}
                  validation={{
                    required: true,
                  }}
                />
              </div>
            </div>
          </div>

          {/* <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
            <FormLabel
              labelIcon={<BsBuilding />}
              labelTitile="Employee Status"
              labelDescription="Employee Status(by default it is active)"
            />
            <div className="w-full flex gap-6 py-2">
              <label class="relative inline-flex self-start items-center cursor-pointer">
                <input
                  type="checkbox"
                  onChange={onChange}
                  checked={value}
                  register={register}
                  errors={errors}
                  class="sr-only peer"
                />
                <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-900">
                  {value ? "Active" : "Not Active"}
                </span>
              </label>
            </div>
          </div> */}

          <div className="flex mt-8 md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
            <button
              type="submit"
              className="border-2 bg-primary text-white px-6 py-2 rounded-md"
            >
              Add Employee
            </button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default AddNewEmployee;
