import React, { useEffect } from "react";
import Building from "../../assets/images/Building.png";
import { Link, useNavigate } from "react-router-dom";
import { CInputField, FormProvider } from "../../components/Forms/Fields";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CButton from "../../components/Forms/CButton";
import { WORK_PERMIT_LOGIN } from "../../graph-query/mutation";
import { useMutation } from "@apollo/client";
import { defineRulesFor } from "../../permission/Ability";
import { useToaster } from "../../hooks/useToaster";
import { decodeJWT } from "../../utils/utilityFunctions";
import { useLocalStore } from "../../store";
import { toast } from "react-toastify";
import { roles } from "../../utils/roles";

const loginSchema = yup
  .object({
    username: yup.string().required(),
    password: yup.string().min(4).max(15).required(),
  })
  .required();

const Login = () => {
  const router = useNavigate();
  const roast = useToaster();

  useEffect(() => {
    let _user = JSON.parse(localStorage.getItem("work-permit"));
    if (_user?.userID && _user?.roles) {
      if (_user?.roles === roles?.WP_ORGANIZATION) {
        router("/workspace");
      } else {
        router("/dashboard");
      }
    }
  }, []);

  const { setUserData, userData } = useLocalStore();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const [signIn, { data, loading }] = useMutation(WORK_PERMIT_LOGIN, {
    context: {
      headers: { "x-hasura-role": "user" },
    },
    onError(err) {
      const error = `${err}`.split(":").reverse()[0];
      console.log(error.trim());
    },
    onCompleted(d) {
      // login(d);
    },
  });

  const addToken = (data) => {
    let _decodedData = decodeJWT(data);
    // console.log(_decodedData);
    defineRulesFor(_decodedData?.metadata["x-hasura-default-role"]);
    if (_decodedData.metadata["x-hasura-default-role"]) {
      setUserData("work-permit", {
        roles: _decodedData.metadata["x-hasura-default-role"],
        userID: _decodedData?.metadata["x-hasura-user-id"],
        token: data,
      });
      sessionStorage.setItem("accesstoken", data);
      switch (_decodedData.metadata["x-hasura-default-role"]) {
        case roles.WP_ORGANIZATION:
          router("/workspace/");
          break;
        case roles.WP_OPERATION:
          router("/report");
          break;
        case roles.WP_DESK_HANDLER:
          router("/dashboard");
          break;
        case roles.WP_DOC_CHECKER:
          router("/dashboard");
          break;
        case roles.WP_FINANCE:
          router("/dashboard");
          break;
        case roles.WP_SYS_ADMIN:
          router("/dashboard");
          break;
        case roles.WP_DESK_MANAGER:
          router("/dashboard");
          break;
        case roles.WP_EMR_GENERATOR:
          router("/dashboard");
          break;
        default:
          break;
      }
      // if (
      //   _decodedData.metadata["x-hasura-default-role"] === "wp:organization" ||
      //   _decodedData.metadata["x-hasura-default-role"] === "organization"
      // ) {
      //   router("/workspace/");
      //   // return;
      // }
      // if (
      //   _decodedData.metadata["x-hasura-default-role"] === "wp:operation"
      // ) {

      //   // return;
      // } else {
      //   router("/dashboard");
      //   // return;
      // }
    }
  };

  const handleLoginForm = async (data) => {
    await signIn({
      variables: {
        username: data.username,
        password: data.password,
      },
      onCompleted(data) {
        console.log(data);
        if (data?.signin?.token !== "" && data?.signin?.token !== null) {
          roast.SuccessToast("Login Successful!");
          addToken(data?.signin.token);
        }
        if (data?.signin?.error === "User is not verified") {
          // router("/verify-account");
          toast.error("User Not Verified");
        } else {
          toast.error(data?.signin?.error);
        }
      },
      onError(e) {
        console.log(e);
        if (e?.signin?.error === "User is not verified") {
          toast.error("User Not Verified");
          // router("/verify-account");
        } else {
          roast.ErrorToast("Something is wrong try again!");
        }
        // console.log("err: ", e);
      },
    });
  };

  return (
    <div className=" relative">
      <div className="fixed flex top-0 left-0 w-full h-screen">
        <div className="w-1/2 hidden bg-white md:flex items-center justify-center flex-col">
          <img src={Building} alt="permit" className="w-3/5" />
          <h2 className="text-xl text-N80 capitalize w-1/2 text-center font-medium">
            We work for our country, our country lives for us
          </h2>
        </div>
        <div className="md:w-1/2 py-5 w-full bg-gradient-to-b from-prime20 via-prime50 to-Prime95 flex items-center justify-center flex-col gap-6 2xl:gap-8">
          <h1 className=" text-white text-2xl w-3/4 xl:w-2/5 text-center font-medium">
            Welcome to foreign work permit account
          </h1>
          <div className="bg-white w-11/12 md:w-3/5 2xl:w-1/2 p-4 2xl:p-8 rounded-md shadow-md space-y-4">
            <FormProvider onSubmithandler={handleSubmit(handleLoginForm)}>
              <CInputField
                name="username"
                register={register}
                errors={errors}
                validation={{ required: true }}
                type="email"
                label="Email"
              />
              <div className="mt-5"></div>
              <CInputField
                name="password"
                register={register}
                errors={errors}
                validation={{ required: true }}
                type="password"
                label="Password"
              />
              <CButton
                type="submit"
                loading={loading}
                btnLabel="Login"
                classes="bg-primary block w-full"
              />
            </FormProvider>
            <div className="w-full flex justify-end items-center pb-2">
              <p className=" text-primary cursor-pointer font-medium text-sm w-max text-end">
                <Link to={"/forgot-password"}>Forgot Password?</Link>
              </p>
            </div>
            <p className="text-xs w-full text-center">
              Don't have an account yet?{" "}
              <Link to="/signup">
                <span className="text-primary font-medium">Sign Up Here</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
