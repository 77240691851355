import React from "react";
import Table from "../../../components/CustomTable";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_EMPLOYEES } from "../../../graph-query/queries";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BiDotsVertical } from "react-icons/bi";
import { MdEdit } from "react-icons/md";
import { BsPersonAdd } from "react-icons/bs";
import { UPDATE_EMPLOYEE_STATUS } from "../../../graph-query/mutation";
import { toast } from "react-toastify";
import CTable from "../../../components/Table/CTable";
import { roles } from "../../../utils/roles";

const EmployeePage = () => {
  const [employeeData, setEmployeeData] = useState([]);

  const [getEmployee, { loading, error }] = useLazyQuery(GET_EMPLOYEES, {
    onCompleted: (data) => {
      setEmployeeData(data?.employees);
    },
  });

  const [updateEmployee, {}] = useMutation(UPDATE_EMPLOYEE_STATUS);

  const router = useNavigate();

  useEffect(() => {
    getEmployee();
  }, []);

  const handleStatusChange = (data, event) => {
    console.log("status:", data, event.target.checked);
    const newStatus = event.target.checked;
    updateEmployee({
      variables: {
        userID: data?.user_info?.id,
        status: newStatus,
      },
      onCompleted: () => {
        toast.success("Status Updated");
        getEmployee({ fetchPolicy: "network-only" });
      },
    });
  };

  const columns = [
    {
      Header: "Name",
      footer: (props) => props.column.id,
      id: "name",
      accessor: (c) => (
        <div className="flex justify-start gap-2">
          <button
            type="button"
            className="relative"
            data-model-target="#defaultModal"
            data-modal-toggle="#defaultModal"
          >
            {c?.user_info?.name}
          </button>
        </div>
      ),
    },
    {
      Header: "Email",
      accessor: (c) => c?.user_info?.email,
    },
    {
      Header: "Phone Number",
      accessor: (c) => (
        <p className="text-center">{c?.user_info?.phone_number}</p>
      ),
    },
    {
      Header: "Role",
      accessor: (c) => (
        <span className="text-center px-2 py-0.5 rounded text-xs bg-blue-100">
          {c?.user_info?.allowed_roles[0]?.role_information?.value}
        </span>
      ),
    },
    {
      Header: "Status",
      accessor: (c) => (
        <label className="relative inline-flex items-center mr-5 cursor-pointer">
          <input
            type="checkbox"
            onChange={(e) => handleStatusChange(c, e)}
            value={c?.active_status}
            className="sr-only peer"
            checked={c?.active_status}
          />
          <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark :border-gray-600 peer-checked:bg-green60"></div>
          <span
            className={`${
              c?.active_status ? "text-green" : "text-red"
            } ml-3 text-sm font-medium text-gray-900`}
          >
            {c?.active_status ? "Online" : "Offline"}
          </span>
        </label>
      ),
    },
    
  ];

  return (
    <div className="h-screen">
      <h1 className="px-4 py-4 text-3xl">Employees List</h1>
      <div className="mx-5">
        <CTable
          columns={columns}
          tableData={employeeData.filter((i) =>
            [roles.WP_DOC_CHECKER, roles.WP_EMR_GENERATOR, roles.WP_FINANCE].includes(
              i.user_info?.allowed_roles[0]?.role_information?.value
            )
          ).filter(i => i?.user_info?.name !== "Test")}
          loading={loading}
          error={error}
          handlePageLimit={() => {}}
          handleNextPage={() => {}}
          handlePageChange={() => {}}
          handlePreviousPage={() => {}}
          handleRefersh={() => {}}
          title={"Employees List"}
          headerClassess="bg-primary"
          //   pageCount={pageCount}
        />
      </div>
    </div>
  );
};

export default EmployeePage;
