import React, { useEffect, useRef, useState } from "react";
import { BsCalendarEvent, BsGlobe } from "react-icons/bs";
import { BiMoney } from "react-icons/bi";
import { AiOutlineNumber } from "react-icons/ai";
import FormLabel from "../../common/FormLabel";
// import { trackPromise } from "react-promise-tracker";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { UPLOAD_FILE } from "../../../graph-query/mutation";
import { CInputField, CSelectField, FormProvider } from "../Fields";
import CButton from "../CButton";
import { useLocalStore } from "../../../store";
import {
  ExtractFileTypeAndNameFromBase64,
  formatDate,
  getBase64,
} from "../../../utils/utilityFunctions";
import { toast } from "react-toastify";
import FileInput from "../Fields/FileInput";
import { WorkPermitForms } from "../../../utils/constants";
import { EmploymentSchema } from "../../../utils/SchemaValidation";
import UploadSingleFile from "../../uploads/UploadFile";

const EmploymentInformation = ({
  changeState,
  handleFormSubmit,
  defaultValues,
}) => {
  const [tempFiles, setTempFile] = useState({
    file: null,
    fileType: "",
    name: "",
    document_type_id: "47cb2539-128a-4d4e-8c92-02d4ad2c6c46",
    catagory: "c289dfc7-b4a1-4cfd-af67-2e71a4c2c829",
    title: "Employment Contract",
  });

  const { professionalTitles, occupationCatagory, data } = useLocalStore();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(EmploymentSchema),
    defaultValues: {
      ...defaultValues,
      employment_end_date: formatDate(defaultValues?.employment_end_date),
      employment_start_date: formatDate(defaultValues?.employment_start_date),
      support_letter_date: formatDate(defaultValues?.support_letter_date),
      // tempFiles: defaultValues?.contract,
    },
  });

  useEffect(() => {
    if (defaultValues?.contract) {
      setTempFile(defaultValues?.contract);
      let fileInfo = ExtractFileTypeAndNameFromBase64(
        defaultValues?.contract?.file
      );
      if (fileInfo) {
        const { fileType, fileName } = fileInfo;
        console.log(fileInfo);
      } else {
        console.log("Invalid Base64 string", fileInfo);
      }
    }
  }, []);
  // ===========================================================================================

  const handleContract = (file) => {
    console.log("file:", file);
    if (file) {
      getBase64(file).then((result) => {
        let fileType = (file?.type).split("/").length
          ? (file?.type).split("/")[1]
          : "";
        setTempFile({
          ...tempFiles,
          file: result,
          fileType: fileType,
          name: file.name,
          document_type_id: "47cb2539-128a-4d4e-8c92-02d4ad2c6c46",
          catagory: "c289dfc7-b4a1-4cfd-af67-2e71a4c2c829",
          title: "Employment Contract",
        });
      });
    }
  };

  /**
   * @description submit form
   * @param {*} data
   */
  const onSubmit = async (d) => {
    // console.log(d);
    // if (defaultValues.expatriate_work_permit_documents?.length > 0) {
    //   setTempFile();
    // }
    if (tempFiles?.file !== null) {
      let _res = {
        allowance: d?.allowance !== "" ? d?.allowance : 0,
        salary: d?.salary !== "" ? d?.salary : 0,
        contract: tempFiles && tempFiles,
        education_level: d?.education_level,
        employment_start_date: d?.employment_start_date,
        employment_end_date: d?.employment_end_date,
        field: d?.field,
        occupation_category_id: d?.occupation_category_id,
        profession_title_id: d?.profession_title_id,
        support_letter_date: d?.support_letter_date,
        support_letter_number: d?.support_letter_number,
        intended_position: d?.intended_position,
        year_of_experience: d?.year_of_experience,
      };
      console.log(_res);
      handleFormSubmit(WorkPermitForms.EMPLOYEE_INFORMATION, _res);
    } else {
      toast.warn("Please Upload Employee Contract");
    }
  };

  return (
    <>
      <div className="flex flex-col gap-4 p-4 items-start h-full w-full">
        <div className="flex flex-col gap-1 items-start pb-10">
          <h1 className="text-3xl font-bold text-N40">
            Employment Information
          </h1>
          <p className="text-xs font-light w-full lg:w-2/3 text-N60">
            Organization must fulfill the expatriates employment information in
            order to create the work permit process.
          </p>
        </div>
        <FormProvider
          onSubmithandler={handleSubmit(onSubmit)}
          className="w-full"
        >
          <hr className="border-b-2 border-gray-400 my-5 border-opacity-10" />

          {/* <h1 className="font-bold text-xl">Employment Information</h1> */}
          <div className="flex lg:flex-row flex-col mt-5 gap-4 items-start lg:items-center">
            <FormLabel
              labelIcon={<BiMoney />}
              labelTitile="Date of Employment starting Date*"
              labelDescription=""
            />
            <div className=" w-full lg:w-2/3">
              <CInputField
                name="employment_start_date"
                register={register}
                errors={errors}
                defaultValue={data?.employment_start_date}
                // validation={{ required: "Date of employment is required" }}
                type="date"
                label=""
                placeholder=""
              />
            </div>

            <FormLabel
              labelIcon={<BiMoney />}
              labelTitile="Duration of Employment end Date*"
              labelDescription=""
            />
            <div className=" w-full lg:w-2/3">
              <CInputField
                name="employment_end_date"
                register={register}
                errors={errors}
                defaultValue={data?.employment_end_date}
                // validation={{ required: "End of employement date is required" }}
                type="date"
                label=""
                placeholder=""
              />
            </div>
          </div>
          {/* Gross Salary  */}
          <div className="flex lg:flex-row flex-col gap-4 items-start lg:items-center mt-5">
            <FormLabel
              labelIcon={<BiMoney />}
              labelTitile="Monthly Allowance"
              labelDescription="Expatriate allowance In BIRR"
            />
            <div className=" w-full lg:w-2/3">
              <CInputField
                name="allowance"
                register={register}
                errors={errors}
                defaultValue={data?.allowance}
                type="number"
                label=""
                placeholder=""
              />
            </div>

            <FormLabel
              labelIcon={<BiMoney />}
              labelTitile="Gross Salary"
              labelDescription="In BIRR"
            />
            <div className=" w-full lg:w-2/3">
              <CInputField
                name="salary"
                register={register}
                errors={errors}
                defaultValue={data?.salary}
                type="number"
                label=""
                placeholder="Gross Salary"
              />
            </div>
          </div>

          <div className="flex lg:flex-row flex-col gap-4 items-start lg:items-center mt-5">
            <FormLabel
              labelIcon={<BiMoney />}
              labelTitile="Level Of Education*"
              labelDescription=""
            />
            <div className=" w-full lg:w-2/3">
              <CInputField
                name="education_level"
                register={register}
                errors={errors}
                defaultValue={data?.education_level}
                // validation={{ required: "Education level is required" }}
                type="text"
                label=""
                placeholder=""
              />
            </div>

            <FormLabel
              labelIcon={<BiMoney />}
              labelTitile="Field of Study"
              labelDescription="type expatriate field study"
            />
            <div className=" w-full lg:w-2/3">
              <CInputField
                name="field"
                register={register}
                errors={errors}
                defaultValue={data?.field}
                // validation={{ required: "Field of study is required" }}
                type="text"
                label=""
                placeholder=""
              />
            </div>
          </div>

          <div className="flex lg:flex-row flex-col gap-4 items-start lg:items-center mt-5">
            <FormLabel
              labelIcon={<BiMoney />}
              labelTitile="Professional Skills*"
              labelDescription="Expatriate Professional skills"
            />
            <div className=" w-full lg:w-2/3">
              <CSelectField
                name="profession_title_id"
                register={register}
                errors={errors}
                // validation={{
                //   required: "Professional title is required",
                // }}
                options={professionalTitles?.map((item) => {
                  return {
                    ...item,
                    value: item.id,
                    name: item.name_json?.en,
                  };
                })}
                label=""
                placeholder="Professional Title"
              />
            </div>

            <FormLabel
              labelIcon={<BiMoney />}
              labelTitile="Year of Experience*"
              labelDescription="Year of experience of the expatriate"
            />
            <div className=" w-full lg:w-2/3">
              <CInputField
                name="year_of_experience"
                register={register}
                errors={errors}
                // validation={{
                //   required: "YEar of experience of study is required",
                // }}
                type="number"
                label=""
                placeholder=""
              />
            </div>
          </div>

          {/* Support Leter Date */}
          <div className="flex lg:flex-row flex-col gap-4 items-start lg:items-center mt-5">
            <FormLabel
              labelIcon={<BsCalendarEvent />}
              labelTitile="Support Letter Date"
              labelDescription="support letter date"
            />
            <div className=" w-full lg:w-2/3">
              <CInputField
                name="support_letter_date"
                register={register}
                errors={errors}
                defaultValue={data?.support_letter_date}
                // validation={{ required: "Support letter date is required" }}
                type="date"
                label=""
                placeholder="Support Letter Date"
              />
            </div>

            <FormLabel
              labelIcon={<AiOutlineNumber />}
              labelTitile="Support letter number"
              labelDescription="Specify the support letter number of the expatriate"
            />
            <div className=" w-full lg:w-2/3">
              <CInputField
                name="support_letter_number"
                register={register}
                errors={errors}
                defaultValue={data?.support_letter_number}
                // validation={{ required: "support letter number is required" }}
                type="text"
                label=""
                placeholder="Letter Number"
              />
            </div>
          </div>

          <div className="flex lg:flex-row flex-col gap-4 items-start lg:items-center mt-4">
            <FormLabel
              labelIcon={<AiOutlineNumber />}
              labelTitile="Work Position of the expatriate"
              labelDescription="Specify the support letter number of the expatriate"
            />
            <div className="w-full">
              <CInputField
                name="intended_position"
                register={register}
                errors={errors}
                defaultValue={data?.intended_position}
                // validation={{ required: "work position is required" }}
                type="text"
                label=""
                placeholder=""
              />
            </div>

            <FormLabel
              labelIcon={<BsGlobe />}
              labelTitile="Occupation Category"
              labelDescription="Specify the occupation category of the expatriate"
            />
            <div className="w-full">
              <CSelectField
                name="occupation_category_id"
                register={register}
                errors={errors}
                defaultValue={data?.occupation_category_id}
                // validation={{ required: "Occupation Category is required" }}
                options={occupationCatagory?.map((item) => {
                  return {
                    ...item,
                    value: item.id,
                  };
                })}
                label=""
                placeholder="Occupation Category"
              />
            </div>
          </div>

          <div className="mt-8"></div>
          <h3 className="font-bold">Upload Employee Contract</h3>
          <UploadSingleFile
            type={"file"}
            defaultValue={
              defaultValues &&
              defaultValues?.contract &&
              ExtractFileTypeAndNameFromBase64(defaultValues?.contract?.file)
            }
            onChange={(e) => handleContract(e)}
          />

          {/* nav */}
          <div className="w-fit gap-2 flex justify-end py-10">
            <CButton
              type="button"
              btnLabel="Back"
              handleClick={() =>
                changeState(WorkPermitForms.GENERAL_INFORMATION)
              }
              classes="bg-gray-300 text-black block w-full"
            />
            <CButton
              type="submit"
              // loading={loading}
              btnLabel="Continue"
              classes="bg-primary block w-full"
            />
          </div>
        </FormProvider>
      </div>
    </>
  );
};

export default EmploymentInformation;
