import React from "react";

const ApplicantStepper = ({ steps, activeStep }) => {
  return (
    <>
      <div className="py-6 px-2 bg-transparent rounded-xl flex md:flex-col gap-2 w-full overflow-hidden noScrollBar">
        <ol className="p-5 ml-6 relative text-gray-500 border-l border-gray-200 dark:border-gray-300 dark:text-gray-400">
          {steps &&
            steps?.map((step) => (
              <li
                key={step}
                className={`${
                  step.label === activeStep ? "bg-primary/10 text-white" : ""
                } flex justify-center rounded-3xl flex-col h-14 mb-1 ml-2 pl-3 cursor-pointer`}
              >
                <span
                  className={`${
                    step.label === activeStep
                      ? "bg-primary border-white text-white"
                      : "ring-white border-gray-200 bg-transparent"
                  } absolute flex border-2 items-center z-[800] justify-center w-8 h-8 rounded-full -left-4 ring-4 transition-colors duration-300 ${
                    step.label === activeStep ? "scale-110" : ""
                  }`}
                >
                  {step.icon}
                </span>
                <div>
                  <h3
                    className={`${
                      step.label === activeStep ? "text-primary" : ""
                    } text-sm font-medium leading-tight transition-colors duration-300`}
                  >
                    {step.label}
                  </h3>
                  <p className="text-sm">{step?.description}</p>
                </div>
              </li>
            ))}
        </ol>
      </div>
    </>
  );
};

export default ApplicantStepper;
