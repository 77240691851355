import React from "react";

const Input = ({ type, name, label, register, validation, inputErrors, disabled }) => {
  return (
    <div>
        <div className="mb-2">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor={name}
        >
          {label}
        </label>
        <textarea
            type="text"
            disabled={disabled}
            className="h-28 w-full p-2 border-N99 rounded-sm focus:ring-0 focus:outline-none"
            placeholder="Here the reason for the rejection"
            id={name}
            {...register(name, { ...validation })}
            aria-invalid={inputErrors?.name ? "true" : "false"}
        ></textarea>
      </div>
      {inputErrors.name && inputErrors.name?.message}
      <p
        id="filled_success_help"
        className={
            inputErrors.name && inputErrors.name
            ? "text-start mt-2 text-xs text-green-600 dark:text-green-400"
            : "text-start mt-2 text-xs text-red-600 dark:text-red-400"
        }
      >
        {inputErrors?.name && <p>{inputErrors.name?.message}</p>}
      </p>
    </div>
  );
};

const CTextAreaField = ({ type, name, label, register, validation, errors, disabled }) => {
  return (
    <div>
      <div>
        <Input
          type={type}
          register={register}
          validation={validation}
          name={name}
          inputErrors={errors}
          label={label}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default CTextAreaField;
