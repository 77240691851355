import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/common/Navbar";
import { CInputField, FormProvider } from "../../components/Forms/Fields";
import { useForm } from "react-hook-form";
import CButton from "../../components/Forms/CButton";
import { useMutation } from "@apollo/client";
import { RESEND_VERIFY_CODE, VERIFY_CODE } from "../../graph-query/mutation";
import { toast } from "react-toastify";

const VerifyAccount = () => {
  const [phone, setPhone] = useState(null);

  const router = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    const _phone = JSON.parse(localStorage.getItem("v-phone"));
    setPhone(_phone);
  }, []);

  const [verifyCode, { loading }] = useMutation(VERIFY_CODE);
  const [resendverifyCode, {}] = useMutation(RESEND_VERIFY_CODE, {
    onCompleted: (d) => {
      toast.success("OTP send Successfully");
    },
    onError: (e) => toast.error("Error whiling sending OTP"),
  });

  const handleVerifiyPhone = (data) => {
    console.log(data);
    verifyCode({
      variables: {
        otp: data?.otp,
        phone_number: JSON.parse(localStorage.getItem("temp"))?.phone,
      },
      onCompleted: () => {
        router("/login");
        toast.success("Account Verified Successfully");
        localStorage.removeItem("temp");
      },
      onError: (er) => {
        toast.error("OTP is not correct");
      },
    });
  };

  const handleResendOTP = async () => {
    console.log("clicked");
    await resendverifyCode({
      variables: {
        phone_number: JSON.parse(localStorage.getItem("temp"))?.phone,
      },
    });
  };
  return (
    <>
      <div className=" relative">
        <Navbar>
          <div className="flex gap-4 2xl:gap-8 text-primary">
            <Link to="/">about us</Link>
            <Link to="/">log in</Link>
          </div>
        </Navbar>

        <div className="w-full flex justify-center items-center h-[75vh]">
          <div className="md:w-1/2 w-full flex items-center justify-center flex-col gap-6 2xl:gap-8">
            <div className="flex justify-center items-center flex-col">
              <h1 className=" text-primary text-xl md:text-3xl w-max text-center font-semibold pb-2">
                We have sent you a verification <br /> code to your Phone Number
              </h1>
              <p className="text-xs font-light text-primary">
                Please, Enter the code you recived below.
              </p>
            </div>
            <div className=" bg-white w-11/12 md:w-3/5 2xl:w-1/2 p-4 2xl:p-8 rounded-md shadow-md space-y-4">
              <FormProvider onSubmithandler={handleSubmit(handleVerifiyPhone)}>
                {/* {!phone && (
                  <CInputField
                    name="phone_number"
                    register={register}
                    errors={errors}
                    defaultValue={phone}
                    validation={{ required: true }}
                    type="text"
                    label="Phone Number"
                  />
                )} */}
                <CInputField
                  name="otp"
                  register={register}
                  errors={errors}
                  validation={{ required: true }}
                  type="text"
                  label="Verify Code"
                />

                <span
                  onClick={handleResendOTP}
                  className="w-full cursor-pointer flex justify-end text-blue-600 text-xs"
                >
                  Resend Code
                </span>

                {/* <Input type={"email"} placeholder="Email Address" /> */}
                <CButton
                  btnLabel={"Verify"}
                  type={"submit"}
                  loading={loading}
                  classes="bg-primary hover:bg-prime50 w-full text-white py-3 rounded"
                />
                {/* <CButton
                  type={"button"}
                  btnLabel={"Resend Code"}
                  classes="bg-gray w-full text-primary shadow-none py-1 rounded"
                /> */}
              </FormProvider>
            </div>

            <h1 className="cursor-pointer" onClick={() => router("/login")}>
              Login Here
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyAccount;
