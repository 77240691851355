import { create } from "zustand";

export const useReportStore = create((set) => ({
  // limit default values
  limit: 10,
  //offset default value
  offset: 0,
  loading: false,

  chartData: null,
  reportData: [],
  reportCount: {
    total_permit: {
      aggregate: {
        count: 0,
      },
    },
    document_checker_approved: {
      aggregate: {
        count: 0,
      },
    },
    emr_approved_permits: {
      aggregate: {
        count: 0,
      },
    },
  },

  setLoading: (loading) => set({ loading: loading }),
  
  setReportCount: (count) => set({ reportCount: count }),

  setReportData: (count) => set({ reportData: count }),

  setChartData: (count) => set({ chartData: count }),
}));
