import React from "react";

const joinClasses = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

const Input = ({
  type,
  name,
  label,
  register,
  validation,
  errors,
  classes,
  placeholder,
  icon,
  defaultValue,
  disabled,
  helperText,
}) => {
  const { message } = errors[name] || {};

  const inputClasses = joinClasses(classes, {
    "pl-[1.8rem]": icon,
    "border-red50": message,
    "border-gray10": !message,
  });

  return (
    <div className="w-full">
      <label
        className="block text-sm font-medium text-gray-700 mb-1"
        htmlFor={name}
      >
        {label}
      </label>
      <div className="relative w-full">
        <div class="relative h-10 w-full min-w-[200px] ">
          {/* <div class="absolute grid w-5 h-5 place-items-center text-blue-gray-500 top-2/4 right-3 -translate-y-2/4">
            {icon && <div className="mr-2">{icon}</div>}
          </div> */}
          <input
            className={`${inputClasses} ${
              message ? "border-red50" : "!border-gray-300"
            } peer h-full w-full rounded-[7px] !border bg-transparent bg-white px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 shadow-sm shadow-gray-900/5 outline outline-0 ring-4 ring-transparent transition-all placeholder:text-gray-500 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2  focus:!border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 focus:ring-gray-900/10 disabled:border-0 disabled:bg-blue-gray-50`}
            id={name}
            type={type}
            name={name}
            value={defaultValue}
            defaultValue={defaultValue}
            disabled={disabled}
            placeholder={placeholder}
            {...register(name, validation)}
          />
          {/* <label class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5  hidden h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"></label> */}
        </div>
        {helperText && (
          <p class="flex items-center gap-1 mt-2 font-sans text-sm antialiased font-normal leading-normal text-gray-700">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-4 h-4 -mt-px"
            >
              <path
                fill-rule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                clip-rule="evenodd"
              ></path>
            </svg>
            {helperText}
          </p>
        )}
        {message && <div className="text-xs text-red50 mt-1">{message}</div>}
        {/* 
        <div className="flex items-center border border-gray-300 rounded-lg">
          {icon && <div className="mr-2">{icon}</div>}
          <input
            className={`w-full rounded-lg active:rounded-lg placeholder:text-sm ${inputClasses} ${
              message ? "border-red50" : "border-transparent"
            }`}
            id={name}
            type={type}
            name={name}
            value={defaultValue}
            defaultValue={defaultValue}
            disabled={disabled}
            placeholder={placeholder}
            {...register(name, validation)}
          />
        </div> */}
      </div>
    </div>
  );
};

const CInputField = ({
  type = "text",
  name,
  label,
  register,
  validation,
  errors,
  icon,
  classes,
  placeholder,
  defaultValue,
  disabled,
}) => {
  return (
    // <div className="w-full">
    <Input
      type={type}
      name={name}
      label={label}
      register={register}
      validation={validation}
      errors={errors}
      classes={classes}
      placeholder={placeholder}
      icon={icon}
      defaultValue={defaultValue}
      disabled={disabled}
    />
    // </div>
  );
};

export default CInputField;
