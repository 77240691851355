import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/mols-logo.png";
import { trackPromise } from "react-promise-tracker";
import { useLocalStore } from "../../store";
const Navbar = ({ children, custom, dropDown, orgList }) => {
  const { userData, setUserData } = useLocalStore();

  const router = useNavigate();

  const handleOrganizationSelect = (w) => {
    let _data = orgList?.filter((i) => i?.id === w)?.map((item) => item)[0];
    const currentOrganization = {
      id: _data?.id,
      name: _data?.name,
    };
    setUserData("currentOrg", { id: _data?.id, name: _data?.name });
    router("/organization");
    window.location.reload();
  };

  return (
    <div className={`${custom} py-4 shadow-md bg-white sticky top-0 z-40 `}>
       <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex layout items-center justify-between">
            <div className="flex gap-4">
              <img src={Logo} alt="logo" className="h-16" />
              {dropDown && (
                <select
                  className="rounded-md self-center border-gray-300"
                  name="org"
                  value={userData?.id || JSON.parse(localStorage.getItem('currentOrg'))?.id}
                  onChange={(e) => handleOrganizationSelect(e.target.value)}
                  id=""
                >
                  {orgList?.map((i) => (
                    <option value={i?.id}>{i?.name}</option>
                  ))}
                </select>
              )}
            </div>
            <div className="flex items-center gap-4 capitalize relative">{children}</div>
          </div>
       </div>
    </div>
  );
};

Navbar.defaultProp = {
  dropDown: false,
};

export default Navbar;
