import React from "react";

// local components/assets imports
import HelpImage1 from "../../assets/images/HelpImage3.png";
import HelpImage2 from "../../assets/images/HelpImage4.png";
import HelpImage3 from "../../assets/images/HelpImage2.png";
import HelpImage4 from "../../assets/images/HelpImage1.png";

export const HelpingGuides = () => {
  // state variables
  const helpingGuides = [
    {
      name: "New Application Process",
      image: HelpImage1,
      desciption: `Information on the new application, including instructions on how to complete each step
       of the process, a list of required documents, and payment information, can be found here.`,
      link: "",
      class: "bg-green90/20",
    },
    {
      name: "Renewal process",
      image: HelpImage2,
      desciption: ` Information on the new application, including instructions on how to
          complete each step of the process, a list of required documents, and
          payment information, can be found here.`,
      link: "",
      class: "bg-yellow/20",
    },
    {
      name: "Cancelation process",
      image: HelpImage3,
      desciption: ` Information on the new application, including instructions on how to
          complete each step of the process, a list of required documents, and
          payment information, can be found here.`,
      link: "",
      class: "bg-red/20",
    },
    {
      name: "Replacement process",
      image: HelpImage4,
      desciption: ` Information on the new application, including instructions on how to
          complete each step of the process, a list of required documents, and
          payment information, can be found here.`,
      link: "",
      class: "bg-primary/20",
    },
  ];

  return (
    <div className="flex flex-col gap-6 layout pb-8">
      <h1 className="text-2xl text-green30">Helping Guides</h1>

      <div
        className={`grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 `}
      >
        {helpingGuides.map((guide, index) => (
          <div
            className={`flex ${guide.class} flex-col gap-2 items-start h-72 p-4 rounded-xl`}
            key={"guide- " + index}
          >
            <h1 className="text-4xl text-white font-semibold">0{index + 1}</h1>
            <h3 className="text-lg font-medium">{guide.name}</h3>
            <p className="text-sm text-N30 w-11/12 leading-relaxed">
              {guide.desciption}
            </p>
          </div>
        ))}
        {/* help cards  */}
      </div>
    </div>
  );
};

export default HelpingGuides;
