import { Ability, AbilityBuilder } from "@casl/ability";

const roleRules = (can, role, cannot) => {
  switch (role) {
    case "wp:finance":
      can("view", "dashboard");
      can("view", "applicant");
      can("view", "renewal");
      can("view", "replacement");
      can("view", "cancelled");

      can("action", "reference");
      can("action", "documentCheck");
      can("accept", "application");
      can("reject", "application");
      can("action", "filterTab");
      break;

    case "wp:doc:checker":
      can("view", "dashboard");
      can("view", "organization");
      can("view", "applicant");
      can("view", "viewApplicant");
      can("view", "renewal");
      can("view", "cancelled");
      can("view", "settings");
      can("view", "additional");
      can("view", "replacement");
      can("view", "history");

      can("action", "documentCheck");
      can("action", "rejectPermit");
      can("action", "filterTab");

      can("accept", "application");
      can("reject", "application");
      break;

    case "wp:desk:handler":
      can("view", "dashboard");
      can("view", "applicant");
      can("view", "verified");
      can("view", "failed");
      can("view", "cancelled");
      can("view", "renewal");
      can("view", "replacement");
      can("view", "settings");
      can("view", "blacklist");
      can("view", "organization");
      can("view", "manualAssign");
      can('view', 'employee');
      can("view", "organization_renewal");

      can("action", "documentCheck");
      can("accept", "application");
      can("reject", "application");
      break;

    case "wp:emr:generator":
      can("view", "dashboard");
      can("view", "verified");
      can("view", "cancelled");
      can("view", "renewal");
      can("view", "replacement");
      can("view", "additional");
      can("view", "history");
      can("view", "settings");
      // Filter bar on verified
      can("view", "viewApplicant");
      can("view", "showVerifiedTab");
      can("view", "verifiedFilter");
      can("action", "rejectPermit");
      can("action", "documentCheckCancellation");
      break;

    case "wp:reg:admin":
      break;

    case "wp:sys:admin":
      can("view", "dashboard");
      can("view", "employees");
      can("view", "file-upload");
      can("view", "file-change");

      break;

    case "wp:desk:manager":
      can("view", "dashboard");
      can("view", "userList");
      can('view', 'employee');
      break;

    case "wp:arc:operator":
      can("view", "dashboard");
      can("view", "settings");
      can("view", "archive");
      can("view", "history");
      break;

    // case "wp:dep:minister":
    //   break;

    case "REGISTRY":
      can("view", "dashboard");
      can("view", "verified");
      can("view", "settings");
      can("view", "archive");
      can("view", "history");

      can("view", "verifiedFilter");
      break;

    case "wp:organization":
      can("view", "dashboard");
      can("view", "newRequest");
      can("view", "orgVerfiedPermit");
      can("view", "orgFailedPermit");
      can("view", "orgCanceledPermit");
      can("view", "orgRenewPermit");
      can("view", "orgReplacmentPermit");
      can("view", "orgBlackPermit");
      can("view", "orgRequestedPermit");

      can("view", "orgProfile");
      can("view", "createProfile");
      can("view", "workspace");

      // can("action", "notification");
      break;

    case "wp:dep:minister":
      can('view', 'report');
      can('view', 'userList');
      // can('view', 'employee');
      break;

    default:
      can("view", "registration");
  }
};

export const defineRulesFor = (role) => {
  const { can, rules, cannot } = new AbilityBuilder(Ability);

  roleRules(can, role, cannot);
  return rules;
};

export const buildAbilityFor = (role) => {
  const { can, rules, cannot } = new AbilityBuilder(Ability);

  roleRules(can, role, cannot);

  return new Ability(rules);
};

// Update the ability with new rules based on the user's role
export const updateRole = (role) => {
  const newAbility = buildAbilityFor(role);
  ability.update(newAbility.rules);
};

let defaultRole = null;

export const ability = buildAbilityFor(defaultRole);
