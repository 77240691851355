import React from "react";
import circle from "../../assets/images/ellipse.png";
import molsLogo from "../../assets/images/mols-logo.png";
import { useNavigate } from "react-router-dom";
const Navbar = () => {
  const navigate = useNavigate();
  return (
    <div className="relative">
      <img src={circle} alt="ellipse" className="absolute top-0 left-0 w-1/3" />
      <div className="layout flex items-center justify-between border-b border-b-N90 border-dashed">
        <img src={molsLogo} alt="mols logo" className="w-48" />
        <div className="flex items-center gap-6 2xl:gap-10 py-4 2xl:py-6 relative z-50">
          <p className="font-medium text-gray-800">About</p>
          <button
            className="bg-gradient-to-r from-primary to-blue-800 text-white py-2 px-4 rounded-md shadow-lg hover:shadow-2xl transition-all duration-500 ease-in-out"
            onClick={() => navigate("/login")}
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
