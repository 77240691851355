import React, { useState } from "react";
import AddSkills from "../../utilities/AddSkills";
import { useMutation } from "@apollo/client";
import { ADD_SKILLS } from "../../../graph-query/mutation";
import { trackPromise } from "react-promise-tracker";
import { useParams } from "react-router-dom";
import { useLocalStore } from "../../../store";
import CButton from "../CButton";
import { WorkPermitForms } from "../../../utils/constants";
import { useEffect } from "react";
// import withPermit from "../../context/withPermit";

const Skills = ({ changeState, handleFormSubmit, defaultValues }) => {
  const { permitId } = useParams();

  console.log("skills default: ", defaultValues);
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    if (
      typeof defaultValues === "object" &&
      Object.keys(defaultValues)?.length > 0
    ) {
      setSkills([...defaultValues?.skills]);
    }
  }, []);
  const handleChange = (value) => {
    setSkills([...value, ...skills]);
  };

  const handleRemove = (index) => {
    let temp = [...skills];

    temp = temp.filter((val, i) => index !== i);

    setSkills([...temp]);
  };

  const handleSubmit = () => {
    // setData("expatriate", { skills: skills });
    handleFormSubmit(WorkPermitForms.SKILLS, { skills: skills });
    // moveToNext();
  };
  return (
    <>
      <div className="flex flex-col w-full gap-6">
        <div className="mt-5 ml-5 flex flex-col gap-1 items-start ">
          <p className="text-2xl font-bold text-N0">Skills</p>
          <p className="text-xs font-light w-full md:w-2/3 text-N60">
            Organization must fulfill the expatriates work place information in
            order to proceed the work permit process.
          </p>
        </div>
        <div className="w-full md:w-3/5">
          <AddSkills
            skills={skills}
            handleChange={handleChange}
            handleRemove={handleRemove}
          />
        </div>
      </div>
      {/* Next Button */}
      <div className="w-full md:w-1/2 flex gap-4 justify-end py-10">
        <CButton
          handleClick={() => changeState("Counterpart")}
          classes=" w-1/3 py-2 flex justify-center items-center text-white bg-gray-400 rounded-sm text-sm"
          btnLabel={"Back"}
          type={"button"}
        />

        <CButton
          classes=" w-1/4 py-2 flex justify-center items-center text-white bg-primary rounded-sm text-sm"
          handleClick={handleSubmit}
          btnLabel={"Submit"}
          type={"button"}
        />
      </div>
    </>
  );
};

export default Skills;
