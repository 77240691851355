import React, { useState, useEffect } from "react";
import { VERIFIED_WORK_PERMITS } from "../../graph-query/queries";
import { useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import StatusTags from "../../components/utilities/StatusTags";
import Moment from "react-moment";
import { BiFile, BiMessageAltDetail } from "react-icons/bi";
import { AiFillExclamationCircle } from "react-icons/ai";
import Table from "../../components/Table/CTable";
import SendMessageModal from "../../components/utilities/SendMessageModal";
import { useLocalStore } from "../../store";
import { FailedColumns } from "../../datasets/failed/FailedColumns";

const Failed = () => {
  const [modalData, setModalData] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  //==========================================================
  const { limit, offset, setOffset, setWorkPermits, workPermits } =
    useLocalStore();

  const [getDataFromServer, { loading, error }] = useLazyQuery(
    VERIFIED_WORK_PERMITS,
    {
      variables: { status: false },
      onCompleted: (data) => {
        setWorkPermits(
          data?.expatriate_work_permits
        );
      },
    }
  );

  //request new data if not found
  useEffect(() => {
    if (workPermits?.length === 0) {
      // Get Data from server
      console.log("getting data from server....");
      getDataFromServer();
    } else {
      console.log("getting from store...");
    }
  }, [workPermits, setWorkPermits]);
  // =========================================================================

  const handleOpenModal = (w) => {
    console.log("modal open failed: ", w);
    setModalData(w?.row?.original);
    setShow((prev) => !prev);
  };

  const handlePreviousPage = () => {
    if (offset > 0) {
      setOffset(offset - 1);
    }
  };

  const handleNextPage = () => {
    setOffset(offset + 1); //the first offset
  };

  const handlePageChange = (newOffset) => {
    setOffset(newOffset - 1);
  };

  return (
    <div>
      {/* {load ? ( */}
      <Table
        columns={FailedColumns.failedDeskhandlerColumn(handleOpenModal)}
        tableData={workPermits ? workPermits : []}
        loading={loading}
        error={error}
        limit={limit}
        offset={offset}
        handleNextPage={handleNextPage}
        handlePageChange={handlePageChange}
        handlePreviousPage={handlePreviousPage}
        title={"Failed:  Work Permit Applcants"}
        headerClassess="bg-primary"
        pageCount={pageCount}
        // fetchData={fetchData}
      />

      <div className="fixed top-0 left-0 right-0 overflow-hidden">
        {show ? (
          <SendMessageModal
            data={modalData && modalData}
            handleClick={handleOpenModal}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Failed;
