import React, { useRef, useState } from "react";
import {
  BsBuilding,
  BsCalendarEvent,
  BsGlobe,
  BsPinFill,
} from "react-icons/bs";
import { BiFemale, BiMale, BiCategory, BiMoney } from "react-icons/bi";
import { FaReadme, FaFileUpload } from "react-icons/fa";
import FormLabel from "../../common/FormLabel";
import { MdDelete, MdPlusOne } from "react-icons/md";
import { useFieldArray, useForm } from "react-hook-form";
import CButton from "../CButton";
import { CInputField, CSelectField, FormProvider } from "../Fields";
import UploadFileModal from "../../utilities/UploadFileModal";
import { useLocalStore } from "../../../store";
import {
  ExtractFileTypeAndNameFromBase64,
  getBase64,
} from "../../../utils/utilityFunctions";
import { WorkExperienceSchema } from "../../../utils/SchemaValidation";
import { WorkPermitForms } from "../../../utils/constants";
import UploadSingleFile from "../../uploads/UploadFile";

const WorkExperience = ({
  moveToNext,
  changeState,
  handleFormSubmit,
  defaultValues,
}) => {
  const [files, setFiles] = useState([]);
  const [tempFile, setTempFile] = useState([]);

  const { setData, data, serviceType } = useLocalStore();

  const {
    handleSubmit,
    register,
    control,
    getValues,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(WorkExperienceSchema),
    defaultValues: {
      work_experiences: defaultValues?.work_experiences,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "work_experiences",
  });

  const handleFileUpload = async (e) => {
    let file = e;
    // console.log("file Upload modal clickup", file);
    getBase64(file).then((result) => {
      let fileType = (file?.type).split("/").length
        ? (file?.type).split("/")[1]
        : "";
      setTempFile((prevFiles) => [
        ...prevFiles,
        {
          file: result,
          fileType: fileType,
          name: file.name,
          document_type_id: "a61485f9-62d3-480f-b475-53fa30e86582",
          catagory: "a2e1515e-6c21-4825-97fe-6e624f3ae7f2",
          title: "Work Experience Certificate",
        },
      ]);
    });
    // );
    // });
  };

  const onSubmit = (d) => {
    let _final = [];
    d?.work_experiences?.forEach((s, index) => {
      let _res = {
        ...s,
      };
      if (tempFile && tempFile[index] && tempFile[index].path) {
        _res.attachement = tempFile[index].path;
      } else {
        _res.attachement = ""; // Set attachement to an empty string if no file is selected
      }
      _final.push(_res);
      // attachement: tempFile && tempFile[index]?.path, _final.push(_res);
      // console.log(_res);
    });

    const work_experiences = { work_experiences: _final };
    let k = {
      ...work_experiences,
      expatriate_work_permit_documents: data?.expatriate_work_permit_documents
        ? data?.expatriate_work_permit_documents.concat(tempFile)
        : tempFile,
    };
    // console.log(_final);
    // setData("expatriate", work_experiences);
    // setData("expatriate", k);
    // handleFormSubmit(WorkPermitForms.WORK_EXPREIENCE, work_experiences);
    handleFormSubmit(WorkPermitForms.WORK_EXPREIENCE, k);
    // reset();
    // setExperienceCount(0);
    // moveToNext();
  };

  const toggleCurrentlyWorking = (index, value) => {
    setValue(`work_experiences[${index}].currently_working`, value);
    if (value) {
      setValue(`work_experiences[${index}].end_date`, null);
    }
  };

  const calculateExperienceYears = (startDate, endDate) => {
    const startYear = new Date(startDate).getFullYear();
    const endYear = new Date(endDate).getFullYear();
    const diff = endYear - startYear;

    return diff;
  };

  return (
    <>
      {/* {uploadModal && (
        <UploadFileModal
          setUploadModal={setUploadModal}
          setFiles={setFiles}
          files={files ? files : []}
          onUpload={(incoming) => {
            setFiles([...files, ...incoming]);
          }}
          removeFiles={(index) => {
            files.splice(index, 1);
            setFiles([...files]);

            files.splice(index, 1);
            setFiles([...files]);
          }}
        />
      )} */}
      <div className="flex p-4 flex-col gap-4 items-start h-full w-full">
        <div className="w-full flex flex-row justify-between items-center gap-1 items-start pb-10">
          <div>
            <h1 className="text-3xl font-semibold text-N40 capitalize">
              Work Experience
            </h1>
            <p className="text-xs font-light w-full md:w-2/3 text-N60">
              Organization must fulfill the expatriates work data information in
              order to proceed the work permit process.
            </p>
          </div>
        </div>
        <div className="w-full">
          <FormProvider
            onSubmithandler={handleSubmit(onSubmit)}
            className="w-full"
          >
            {fields.map((field, index) => (
              <div
                key={field.id}
                className="flex w-full flex-wrap md:flex-nowrap justify-between items-start"
              >
                <div className="flex flex-col gap-4 items-start h-full w-full md:w-3/5">
                  <>
                    <div className="flex flex-col gap-4 items-start h-full w-full mb-5">
                      <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                        <FormLabel
                          labelIcon={<BsBuilding />}
                          labelTitile="Occupation"
                          labelDescription="Enter Applicant job title"
                        />

                        <div className=" w-full md:w-3/4">
                          <CInputField
                            name={`work_experiences[${index}].institution_name`}
                            register={register}
                            errors={errors}
                            type="text"
                            label=""
                            placeholder=""
                          />
                        </div>
                      </div>

                      {/* Name of Service */}
                      <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                        <FormLabel
                          labelIcon={<FaReadme />}
                          labelTitile="Name of Service"
                          labelDescription="Insert applicants job service type"
                        />

                        <div className=" w-full md:w-3/4">
                          <CInputField
                            name={`work_experiences[${index}].name_of_service`}
                            register={register}
                            errors={errors}
                            type="text"
                            label=""
                            placeholder=""
                          />
                        </div>
                      </div>

                      {/* Year of experirnce */}
                      <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                        <FormLabel
                          labelIcon={<FaReadme />}
                          labelTitile="Year of Experirnce"
                          labelDescription="eg. 1 year"
                        />

                        <div className=" w-full md:w-3/4">
                          <CInputField
                            name={`work_experiences[${index}].years_of_work_experience`}
                            register={register}
                            errors={errors}
                            type="number"
                            label=""
                            placeholder=""
                          />
                        </div>
                      </div>

                      {/* Position */}
                      <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                        <FormLabel
                          labelIcon={<BsGlobe />}
                          labelTitile="Position in the Firm"
                          labelDescription="Select applicants level of qualification from the dropdown"
                        />

                        <div className=" w-full md:w-3/4">
                          <CInputField
                            name={`work_experiences[${index}].position`}
                            register={register}
                            errors={errors}
                            type="text"
                            label=""
                            placeholder=""
                          />
                        </div>
                      </div>

                      {/* Nature of Service */}
                      <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                        <FormLabel
                          labelIcon={<BsGlobe />}
                          labelTitile="Nature of Service"
                          labelDescription="Select service type"
                        />

                        <div className=" w-full md:w-3/4">
                          <CSelectField
                            name={`work_experiences[${index}].service_type_id`}
                            register={register}
                            errors={errors}
                            options={serviceType.map((item) => {
                              return {
                                ...item,
                                name: item?.name_json?.en,
                                value: item.id,
                              };
                            })}
                            label=""
                            placeholder=""
                          />
                        </div>
                      </div>

                      {/* Starting date */}
                      <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                        <FormLabel
                          labelIcon={<BsCalendarEvent />}
                          labelTitile="Starting Date"
                        />

                        <div className=" w-full md:w-3/4">
                          <CInputField
                            name={`work_experiences[${index}].start_date`}
                            register={register}
                            errors={errors}
                            type="date"
                            label=""
                            placeholder=""
                          />
                        </div>
                      </div>

                      <div>
                        <div className="flex gap-4 items-center w-full">
                          <input
                            type="checkbox"
                            className="cursor-pointer"
                            name={`work_experiences[${index}].currently_working`}
                            {...register(
                              `work_experiences[${index}].currently_working`
                            )}
                            // checked={watchFields[index]?.currentlyWorking}
                            defaultChecked={false}
                            // onChange={(e) =>
                            //   toggleCurrentlyWorking(index, e.target.checked)
                            // }
                          />
                          <p className="text-primary font-medium">
                            I still work here
                          </p>
                        </div>
                      </div>

                      {/* End  date */}
                      {!getValues(
                        `work_experiences[${index}].currently_working`
                      ) && (
                        <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                          <FormLabel
                            labelIcon={<BsCalendarEvent />}
                            labelTitile="End Date"
                          />

                          <div className=" w-full md:w-3/4">
                            <CInputField
                              name={`work_experiences[${index}].end_date`}
                              register={register}
                              errors={errors}
                              type="date"
                              label=""
                              placeholder=""
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <hr className="mb-5 w-full" />
                  </>
                  {/* ))} */}
                </div>
                <div className="md:w-1/3 w-full h-full  bg-Prime99/50 rounded-lg p-4">
                  <div className="w-full flex md:flex-row flex-col gap-4 md:gap-6 py-2">
                    <div className=" w-full ">
                      <div className="flex flex-col gap-2 bg-white p-6 rounded-t-xl">
                        <p className="text-primary text-sm font-semibold">
                          Upload Work Experience Information
                        </p>

                        <div className="flex flex-col gap-1">
                          <p className="text-xs text-N70 font-light">
                            File types
                          </p>

                          <div className="flex gap-1 pl-4 items-center">
                            <BsPinFill className="text-N70 text-xs -rotate-45" />
                            <p className="text-xs text-N70">Pdf</p>
                          </div>
                        </div>
                        <UploadSingleFile
                          onChange={handleFileUpload}
                          defaultValue={
                            defaultValues &&
                            ExtractFileTypeAndNameFromBase64(
                              defaultValues?.expatriate_work_permit_documents
                                ?.filter(
                                  (i) =>
                                    i?.title === "Work Experience Certificate"
                                )
                                ?.map((i) => i)[0]?.file,
                              "Work Experience Certificate",
                              ""
                            )
                          }
                        />
                        {/* <input
                          type="file"
                          id={`work_experiences.${index}.attachment`}
                          className="hidden"
                          accept="application/pdf"
                          onChange={(e) => handleFileUpload(e)}
                          ref={attachRef}
                        />
                        <div
                          onClick={() => {
                            const input = document.getElementById(
                              `work_experiences.${index}.attachment`
                            );
                            if (input) input.click();
                          }}
                          className=" bg-primary/10 cursor-pointer rounded-lg px-8 py-4 mt-4 gap-3 flex flex-col items-center justify-center"
                        >
                          <FaFileUpload className="text-primary/90 text-4xl" />
                          <p className="text-N70 text-xs px-6">
                            Choose document type
                          </p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* {files &&
                    files?.map((file) => (
                      <div className="w-full rounded-md bg-white px-4 py-3 flex justify-between items-center">
                        <div className="flex flex-col gap-1">
                          <p className="text-sm text-N50">{file.name}</p>
                          <p className="text-[10px] text-N70">
                            Added Date jan 2 2022
                          </p>
                        </div>
                        <MdDelete className="text-2xl text-red p-1 bg-Prime95/50" />
                      </div>
                    ))} */}
                </div>
              </div>
            ))}
            <div className="mt-4 flex gap-4 flex-wrap">
              <button
                type="button"
                onClick={() => append({})}
                className="w-full md:w-1/4 py-2 flex justify-center items-center text-blue-500 border-2 border-primary hover:bg-primary hover:text-white transition-all duration-200 ease-in bg-white rounded-sm text-sm cursor-pointer"
              >
                <MdPlusOne size={22} /> Add Experience
              </button>
              <button
                type="button"
                onClick={() => remove(-1)}
                className="w-full md:w-1/4 py-2 flex justify-center items-center text-red50 border-2 border-red hover:bg-red hover:text-white transition-all duration-200 ease-in bg-white rounded-sm text-sm cursor-pointer"
              >
                <MdPlusOne size={22} /> Remove Experience
              </button>
            </div>

            {/* Next Button */}
            <div className="w-full mt-6 md:w-1/2 flex justify-end py-10 gap-6">
              <CButton
                handleClick={() => changeState("Educational Information")}
                classes=" w-1/3 py-2 flex justify-center items-center text-white bg-gray-400 rounded-sm text-sm"
                btnLabel={"Back"}
                type={"button"}
              />
              <CButton
                type="submit"
                btnLabel="Submit"
                classes=" w-1/4 py-2 flex justify-center items-center text-white bg-primary rounded-sm text-sm"
                // onClick={() => moveToNext()}
              >
                next
              </CButton>
            </div>
          </FormProvider>
        </div>
      </div>
    </>
  );
};

export default WorkExperience;
