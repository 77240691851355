import React from "react";
import { BiCopy } from "react-icons/bi";

const ReasonCard = ({ title, description }) => {
  return (
    <div className="bg-error40/20 p-3 rounded flex gap-4 items-start">
      <div>
        <h3 className=" font-medium capitalize text-error50">{title}</h3>
        <p className="text-sm text-N30">{description}</p>
      </div>
      <div className="bg-error40/20 text-error40 p-2 rounded-sm text-xl cursor-pointer">
        <BiCopy />
      </div>
    </div>
  );
};

export default ReasonCard;
