import React, { useState } from "react";

function AddSkills({ skills, handleChange, handleRemove }) {
  const [skill, setSkill] = useState("");

  return (
    <div className="flex flex-col space-y-4 items-center mx-2 md:mx-4 sm:mx-0 w-full">
      <div className="py-8 px-8 items-center overflow-hidden w-full bg-white">
        <form
          className="mt-8"
          onSubmit={(e) => {
            e.preventDefault();

            const vals = skill.split(",").map((val) => val.trim());

            handleChange([...vals]);
            setSkill("");
          }}
        >
          <div className="flex  p-1 items-center w-full space-x-2 sm:space-x- rounded border border-gray-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 opacity-50 ml-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
              />
            </svg>
            <input
              required
              value={skill}
              className=" outline-none text-sm sm:text-base w-full border-transparent focus:border-transparent focus:ring-0"
              type="text"
              onChange={(e) => setSkill(e.target.value)}
              placeholder="Add Skills"
            />
            <input type="submit" className="invisible w-0 h-0" />
          </div>
        </form>

        <div className="my-3 flex flex-wrap p-4 rounded-xl bg-Prime99/50">
          {skills.map((skill, index) => (
            <span
              key={`${skill}${index}`}
              className="m-1 flex flex-wrap justify-between items-center text-xs sm:text-sm border border-primary text-primary bg-white rounded px-4 py-2 font-bold leading-loose cursor-pointer"
            >
              {skill}
              <button
                onClick={() => handleRemove(index)}
                className="cursor-pionter"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 h-3 sm:h-4 sm:w-4 ml-4 text-primary"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AddSkills;
