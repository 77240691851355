import { create } from "zustand";

export const useReplacementStore = create((set) => ({
  isLoading: false,

  pending_offset: 0,
  approved_offset: 0,
  rejected_offset: 0,

  pending_limit: 10,
  approved_limit: 10,
  rejected_limit: 10,

  //count
  pendingCount: 0,
  approvalCount: 0,
  rejectedCount: 0,

  // store fetched Data  here
  replacementPendingList: [],
  replacementApprovedList: [],
  replacementRejectedList: [],

  setIsLoading: (loading) => set({ isLoading: loading }),

  /**
   * @description count total number of list
   * @param {*} pendingCount 
   * @returns 
   */
  setPendingCount: (pendingCount) => set({ pendingCount }),
  setApprovalCount: (approvalCount) => set({ approvalCount }),
  setRejectedCount: (rejectedCount) => set({ rejectedCount }),

  /**
   * @description set page offset
   * @param {*} pendingOffset
   * @returns
   */
  setPendingOffset: (pendingOffset) => set({ pending_offset: pendingOffset }),
  setApprovedOffset: (approvedOffset) =>
    set({ approved_offset: approvedOffset }),
  setRejectedOffset: (rejectedOffset) =>
    set({ rejected_offset: rejectedOffset }),

  /**
   * @description set page limit to display
   * @param {*} limit
   * @returns
   */
  setPendingLimit: (limit) => set({ pending_limit: limit }),
  setApprovedLimit: (limit) => set({ approved_limit: limit }),
  setRejectedLimit: (limit) => set({ rejected_limit: limit }),

  /**
   * @description set fetched data to state
   * @param {*} pendingList
   * @returns
   */
  setReplacementPendingList: (pendingList) =>
    set({ replacementPendingList: pendingList }),
  setReplacementApprovedList: (approvedList) =>
    set({ replacementApprovedList: approvedList }),
  setReplacementRejectedList: (rejectedList) =>
    set({ replacementRejectedList: rejectedList }),
}));
