import { makeVar } from "@apollo/client";

// Initializes to true if local storage includes a 'token' key, false otherwise
export const isLoggedInVar = makeVar(!!localStorage.getItem("work-permit"));

// initialize access token
export const accessTokenInVar = makeVar(null);

// initialize refersh token
export const refreshTokenInVar = makeVar(
  sessionStorage.getItem("accesstoken")
    ? sessionStorage.getItem("accesstoken")
    : null
);

// intialize user data
export const userDatavar = makeVar({
  id: "",
  email: "",
  phoneNumber: "",
  password: "",
});

