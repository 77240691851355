import React from "react";
import { AiOutlineCheckCircle, AiOutlineClose } from "react-icons/ai";

const ConfirmationModal = ({ handleCancel }) => {
  return (
    <>
      <div
        className="z-50 fixed top-0 left-0 h-screen w-full flex items-center justify-center"
        style={{ background: "rgba(0,0,0,0.8)" }}
      >
        <div className="w-1/3 h-2/5 rounded-xl bg-transparent flex justify-center items-center relative">
          <AiOutlineClose
            onClick={handleCancel}
            className="text-lg cursor-pointer hover:scale-110 transition-all duration-200 ease-in-out text-primary top-6 right-6 absolute"
          />
          <div className="h-2/3 w-4/5 shadow-xl border-2 bg-[#F2F9FF] flex flex-col gap-6 items-center justify-center relative rounded-lg">
            <div className="flex justify-center items-center rounded-full bg-white border-8 border-white absolute left-1/2 -translate-x-1/2 -top-14">
              <div className="flex bg-[#F2F9FF] p-4 rounded-full">
                <AiOutlineCheckCircle className="text-6xl text-green" />
              </div>
            </div>

            <p className="text-center text-xl tracking-wider mt-8">
              You have successfully submitted <br /> your application
            </p>
            <button
              type="button"
              handleClick={() => handleCancel}
              className={
                "text-white bg-primary text-sm hover:bg-prime30 transition-all duration-300 ease-in-out py-1.5 rounded w-1/3"
              }
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmationModal;
