import React from "react";
import NotFound from "../assets/icons/Not-Found.svg";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="h-screen w-full flex flex-col items-center justify-center">
        <p className="text-primary text-4xl font-semibold">Page Not Found</p>
        <img src={NotFound} alt="" className="h-4/5 w-full" />
        <p
          onClick={goBack}
          className="text-primary text-xl font-semibold cursor-pointer"
        >
          Go Back
        </p>
      </div>
    </>
  );
};

export default PageNotFound;
