import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { MdArrowBack } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import {
  EMPLOYEE_STATUS,
  EMPLOYEE_WEEKLY_STAT,
} from "../../../graph-query/queries";
import { CInputField, FormProvider } from "../../../components/Forms/Fields";
import { useForm } from "react-hook-form";
import CButton from "../../../components/Forms/CButton";
import PieChart from "../../../components/chart/PieChart";
import DonutChart from "../../../components/chart/DountChart";

const EmployeeDetail = () => {
  const router = useNavigate();
  const { id } = useParams();

  const [tab, setTab] = useState();
  const [displayAccordance, setDisplayAccordance] = useState("new_permit");
  const [employeeData, setEmployeeData] = useState([]);
  const [weeklyStat, setWeeklyStat] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  /**
   * @description get employee stat
   */
  const [GetEmployeeState, {}] = useLazyQuery(EMPLOYEE_STATUS, {
    variables: {
      userID: id,
    },
    onCompleted: (data) => {
      setEmployeeData(data?.employees[0]);
    },
    onError: (err) => {
      console.log("error", err);
    },
  });

  const [getWeeklyState, {}] = useLazyQuery(EMPLOYEE_WEEKLY_STAT, {
    onCompleted: (data) => {
      console.log(data);
      setWeeklyStat(data?.employees[0]);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /**
   * @description date filter
   * @param {*} e
   */
  const handleDateFilter = (e) => {
    let modifiedDate = {
      start_date: `${e?.start_date}T00:00:00.000Z`,
      end_date: `${e?.end_date}T23:59:59.999Z`,
    };
    getWeeklyState({
      variables: {
        userID: id,
        weekStartDate: modifiedDate.start_date,
        weekEndDate: modifiedDate.end_date,
      },
    }).then(() => {
      setTab("detail");
    });
    // console.log("date filter", modifiedDate);
  };

  const handleClearFilter = () => {
    reset();
    setWeeklyStat([]);
    setTab("");
  };

  const handleGenerateReport = () => {
    //TODO Generate Report
  };

  useEffect(() => {
    GetEmployeeState();
  }, [id]);

  return (
    <>
      <div className="bg-white rounded-md">
        <div className="p-5">
          <button
            type="button"
            onClick={() => router(-1)}
            className="flex gap-2 items-center border-2 px-2 py-1 rounded-md"
          >
            <MdArrowBack /> Back
          </button>
          <div className="mt-5">
            <h2>Total Report</h2>
            <div>
              <div className="flex flex-col justify-between h-full">
                <div className="grid grid-cols-1 md:grid-cols-2 justify-between ">
                  <div className="pb-6">
                    <span className="text-gray-400 text-xs font-semibold uppercase tracking-wide">
                      Report Permit Assigned
                    </span>
                    <h1 className="text-5xl font-bold leading-tight text-gray-900 mt-2">
                      {employeeData?.total_assigned_permits?.aggregate?.count}
                    </h1>
                    <div className="mt-4">
                      <span className="text-lg font-semibold text-gray-700 tracking-wide">
                        Detail Stat
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div class="relative inline-flex items-center justify-center w-16 h-16 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                      <span class="font-medium text-gray-600 dark:text-gray-300">
                        {employeeData?.user_info?.name?.substring(0, 2)}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <p>
                        Name:{" "}
                        <span className="font-bold">
                          {employeeData?.user_info?.name}
                        </span>
                      </p>
                      <p>
                        position:{" "}
                        <span className="bg-primary px-3 py-0.5 rounded-3xl text-white">
                          {
                            employeeData?.user_info?.allowed_roles[0]
                              ?.role_information?.value
                          }
                        </span>
                      </p>
                      <p>
                        email:{" "}
                        <a href={`mailto:${employeeData?.user_info?.email}`}>
                          {employeeData?.user_info?.email}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="border-t pt-6">
                  <div className="grid grid-cols-2 gap-2">
                    <div>
                      <span className="text-sm text-gray-600 font-semibold tracking-wide">
                        New Applicant
                      </span>
                      <p className="text-xl font-bold text-gray-900 mt-1">
                        {employeeData?.new?.aggregate?.count}
                      </p>
                    </div>
                    <div>
                      <span className="text-sm text-gray-600 font-semibold tracking-wide">
                        Replacement
                      </span>
                      <p className="text-xl font-bold text-gray-900 mt-1">
                        {employeeData?.replacement?.aggregate?.count}
                      </p>
                    </div>
                    <div>
                      <span className="text-sm text-gray-600 font-semibold tracking-wide">
                        Canceled
                      </span>
                      <p className="text-xl font-bold text-gray-900 mt-1">
                        {employeeData?.cancellation?.aggregate?.count}
                      </p>
                    </div>
                    <div>
                      <span className="text-sm text-gray-600 font-semibold tracking-wide">
                        Renewal
                      </span>
                      <p className="text-xl font-bold text-gray-900 mt-1">
                        {employeeData?.renewal?.aggregate?.count}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 p-4 bg-white w-full">
        <div className="">
          <FormProvider onSubmithandler={handleSubmit(handleDateFilter)}>
            <div className="grid gap-4 items-center grid-cols-3">
              <CInputField
                type="date"
                label={"Start Date"}
                name={"start_date"}
                register={register}
                errors={errors}
                placeholder={"Start Date"}
              />
              <CInputField
                type="date"
                label={"End Date"}
                name={"end_date"}
                register={register}
                errors={errors}
                placeholder={"Start Date"}
              />
              <div className="grid grid-cols-3 items-center justify-center gap-2">
                <CButton
                  btnLabel={"Apply Filter"}
                  type={"submit"}
                  classes={"bg-primary scale-90 col-span-2"}
                />
                <CButton
                  btnLabel={"Clear"}
                  type={"button"}
                  handleClick={handleClearFilter}
                  classes="scale-90 border text-[#000] dark:text-black border-gray-600 px-3 py-2 self-center rounded-md"
                />
              </div>
            </div>
          </FormProvider>
        </div>
        <div className="pt-4">
          <div className="">
            {weeklyStat && (
              <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 ">
                <li onClick={() => setTab("detail")} className="me-2">
                  <a
                    href="#"
                    aria-current="page"
                    className={`inline-block p-4 ${
                      tab === "detail" ? "text-blue-600 bg-gray-100" : ""
                    } rounded-t-lg active`}
                  >
                    Detail Stat
                  </a>
                </li>
                <li onClick={() => setTab("chart")} className="me-2">
                  <a
                    href="#"
                    className={`${
                      tab === "chart" ? "text-blue-600 bg-gray-100" : ""
                    } inline-block p-4 rounded-t-lg hover:text-gray-600 `}
                  >
                    Chart
                  </a>
                </li>
              </ul>
            )}

            {weeklyStat && (
              <div className="mt-5">
                {tab === "detail" && (
                  <>
                    <div class="bg-white py-6 sm:py-8 lg:py-12">
                      <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
                        <div class="mb-4 flex flex-col items-center md:mb-8 lg:flex-row lg:justify-between">
                          <div>
                            <h2 class="mb-2 text-center text-2xl font-bold text-gray-800 lg:mb-0 lg:text-3xl">
                              Stat Report
                            </h2>
                            <button
                              onClick={() => handleGenerateReport}
                              className="px-3 border rounded-lg text-sm"
                            >
                              Generate Report
                            </button>
                          </div>

                          <p class="max-w-md text-center text-gray-400 lg:text-right">
                            The below data is based on filter data
                          </p>
                        </div>

                        <div id="accordion-open" data-accordion="open">
                          <h2 id="accordion-open-heading-1">
                            <button
                              type="button"
                              onClick={() => setDisplayAccordance("new_permit")}
                              class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200  gap-3"
                              data-accordion-target="#accordion-open-body-1"
                              aria-expanded="true"
                              aria-controls="accordion-open-body-1"
                            >
                              <span class="flex items-center">
                                <svg
                                  class="w-5 h-5 me-2 shrink-0"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                    clip-rule="evenodd"
                                  ></path>
                                </svg>{" "}
                                Total New Work Permits (
                                {weeklyStat?.new_applicant_pending?.aggregate
                                  ?.count +
                                  weeklyStat?.new_applicant_approved?.aggregate
                                    ?.count +
                                  weeklyStat?.new_applicant_rejected?.aggregate
                                    ?.count}
                                )
                              </span>
                              <svg
                                data-accordion-icon
                                class="w-3 h-3 rotate-180 shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 10 6"
                              >
                                <path
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M9 5 5 1 1 5"
                                />
                              </svg>
                            </button>
                          </h2>
                          {displayAccordance === "new_permit" && (
                            <div
                              id="accordion-open-body-1"
                              class=""
                              aria-labelledby="accordion-open-heading-1"
                            >
                              <div class="p-5 border border-b-0 border-gray-200 ">
                                <p class="mb-2 font-bold text-gray-500 dark:text-gray-400">
                                  Total:{" "}
                                  {weeklyStat?.new_applicant_pending?.aggregate
                                    ?.count +
                                    weeklyStat?.new_applicant_approved
                                      ?.aggregate?.count +
                                    weeklyStat?.new_applicant_rejected
                                      ?.aggregate?.count}
                                </p>
                                <ul class="ps-5 text-gray-500 list-disc dark:text-gray-400">
                                  <li>
                                    <span class="text-blue-600 dark:text-blue-500 hover:underline">
                                      Pending New Applicant (
                                      {
                                        weeklyStat?.new_applicant_pending
                                          ?.aggregate?.count
                                      }
                                      )
                                    </span>
                                  </li>
                                  <li>
                                    <span
                                      rel="nofollow"
                                      class="text-blue-600 dark:text-blue-500 hover:underline"
                                    >
                                      Approved New Applicant (
                                      {
                                        weeklyStat?.new_applicant_approved
                                          ?.aggregate?.count
                                      }
                                      )
                                    </span>
                                  </li>
                                  <li>
                                    <span
                                      rel="nofollow"
                                      class="text-blue-600 dark:text-blue-500 hover:underline"
                                    >
                                      Rejected New Applicant (
                                      {
                                        weeklyStat?.new_applicant_rejected
                                          ?.aggregate?.count
                                      }
                                      )
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )}

                          <h2 id="accordion-open-heading-2">
                            <button
                              type="button"
                              onClick={() =>
                                setDisplayAccordance("renewal_permit")
                              }
                              class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200  gap-3"
                              data-accordion-target="#accordion-open-body-2"
                              aria-expanded="false"
                              aria-controls="accordion-open-body-2"
                            >
                              <span class="flex items-center">
                                <svg
                                  class="w-5 h-5 me-2 shrink-0"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                    clip-rule="evenodd"
                                  ></path>
                                </svg>
                                Renewal Work Permits (
                                {weeklyStat?.renewal_pending?.aggregate?.count +
                                  weeklyStat?.renewal_approved?.aggregate
                                    ?.count +
                                  weeklyStat?.renewal_rejected?.aggregate
                                    ?.count}
                                )
                              </span>
                              <svg
                                data-accordion-icon
                                class="w-3 h-3 rotate-180 shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 10 6"
                              >
                                <path
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M9 5 5 1 1 5"
                                />
                              </svg>
                            </button>
                          </h2>
                          {displayAccordance === "renewal_permit" && (
                            <div
                              id="accordion-open-body-2"
                              class=""
                              aria-labelledby="accordion-open-heading-2"
                            >
                              <div class="p-5 border border-b-0 border-gray-200">
                                <p class="mb-2 font-bold text-gray-500 dark:text-gray-400">
                                  Total:{" "}
                                  {weeklyStat?.renewal_pending?.aggregate
                                    ?.count +
                                    weeklyStat?.renewal_approved?.aggregate
                                      ?.count +
                                    weeklyStat?.renewal_rejected?.aggregate
                                      ?.count}
                                </p>

                                <ul class="ps-5 text-gray-500 list-disc dark:text-gray-400">
                                  <li>
                                    <span class="text-blue-600 dark:text-blue-500 hover:underline">
                                      Pending Renewal Permit (
                                      {
                                        weeklyStat?.renewal_pending?.aggregate
                                          ?.count
                                      }
                                      )
                                    </span>
                                  </li>
                                  <li>
                                    <span
                                      rel="nofollow"
                                      class="text-blue-600 dark:text-blue-500 hover:underline"
                                    >
                                      Approved Renewal Permit (
                                      {
                                        weeklyStat?.renewal_approved?.aggregate
                                          ?.count
                                      }
                                      )
                                    </span>
                                  </li>
                                  <li>
                                    <span
                                      rel="nofollow"
                                      class="text-blue-600 dark:text-blue-500 hover:underline"
                                    >
                                      Rejected Renewal Permit (
                                      {
                                        weeklyStat?.renewal_rejected?.aggregate
                                          ?.count
                                      }
                                      )
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )}
                          <h2 id="accordion-open-heading-3">
                            <button
                              type="button"
                              onClick={() =>
                                setDisplayAccordance("cancelled_permit")
                              }
                              class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 gap-3"
                              data-accordion-target="#accordion-open-body-3"
                              aria-expanded="false"
                              aria-controls="accordion-open-body-3"
                            >
                              <span class="flex items-center">
                                <svg
                                  class="w-5 h-5 me-2 shrink-0"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                    clip-rule="evenodd"
                                  ></path>
                                </svg>{" "}
                                Cancellation Work Permits (
                                {weeklyStat?.cancellation_pending?.aggregate
                                  ?.count +
                                  weeklyStat?.cancellation_approved?.aggregate
                                    ?.count +
                                  weeklyStat?.cancellation_rejected?.aggregate
                                    ?.count}
                                )
                              </span>
                              <svg
                                data-accordion-icon
                                class="w-3 h-3 rotate-180 shrink-0"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 10 6"
                              >
                                <path
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M9 5 5 1 1 5"
                                />
                              </svg>
                            </button>
                          </h2>
                          {displayAccordance === "cancelled_permit" && (
                            <div
                              id="accordion-open-body-3"
                              class=""
                              aria-labelledby="accordion-open-heading-3"
                            >
                              <div class="p-5 border border-t-0 border-gray-200">
                                <p class="mb-2 font-bold text-gray-500 font-bold dark:text-gray-400">
                                  Total:{" "}
                                  {weeklyStat?.cancellation_pending?.aggregate
                                    ?.count +
                                    weeklyStat?.cancellation_approved?.aggregate
                                      ?.count +
                                    weeklyStat?.cancellation_rejected?.aggregate
                                      ?.count}
                                </p>
                                <ul class="ps-5 text-gray-500 list-disc dark:text-gray-400">
                                  <li>
                                    <span class="text-blue-600 dark:text-blue-500 hover:underline">
                                      Pending Cancellation (
                                      {
                                        weeklyStat?.cancellation_pending
                                          ?.aggregate?.count
                                      }
                                      )
                                    </span>
                                  </li>
                                  <li>
                                    <span
                                      rel="nofollow"
                                      class="text-blue-600 dark:text-blue-500 hover:underline"
                                    >
                                      Approved Cancellation (
                                      {
                                        weeklyStat?.cancellation_approved
                                          ?.aggregate?.count
                                      }
                                      )
                                    </span>
                                  </li>
                                  <li>
                                    <span
                                      rel="nofollow"
                                      class="text-blue-600 dark:text-blue-500 hover:underline"
                                    >
                                      Rejected Cancellation (
                                      {
                                        weeklyStat?.cancellation_rejected
                                          ?.aggregate?.count
                                      }
                                      )
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>

                        {/* <div class="grid grid-cols-2 gap-4 rounded-lg md:grid-cols-3 lg:gap-6">
                        <div class="flex flex-col h-16 items-center justify-center rounded-lg bg-gray-100 p-4 text-gray-400 sm:h-32">
                          <h2 className="font-bold">New Work Permit</h2>
                          <span className="text-3xl font-bold text-black">
                            {weeklyStat?.new_applicant_pending?.aggregate
                              ?.count +
                              weeklyStat?.new_applicant_approved?.aggregate
                                ?.count +
                              weeklyStat?.new_applicant_rejected?.aggregate
                                ?.count}
                          </span>
                        </div>

                        <div class="flex flex-col h-16 items-center justify-center rounded-lg bg-gray-100 p-4 text-gray-400 sm:h-32">
                          <h2 className="font-bold">Renewal Permit</h2>
                          <span className="text-3xl font-bold text-black">
                            {weeklyStat?.renewal_pending?.aggregate?.count +
                              weeklyStat?.renewal_approved?.aggregate?.count +
                              weeklyStat?.renewal_rejected?.aggregate?.count}
                          </span>
                        </div>
                        <div class="flex flex-col h-16 items-center justify-center rounded-lg bg-gray-100 p-4 text-gray-400 sm:h-32">
                          <h2 className="font-bold">Cancellation Permit</h2>
                          <span className="text-3xl font-bold text-black">
                            {weeklyStat?.cancellation_pending?.aggregate
                              ?.count +
                              weeklyStat?.cancellation_approved?.aggregate
                                ?.count +
                              weeklyStat?.cancellation_rejected?.aggregate
                                ?.count}
                          </span>
                        </div>
                        <div class="flex flex-col h-16 items-center justify-center rounded-lg bg-gray-100 p-4 text-gray-400 sm:h-32">
                          <h2 className="font-bold">Replacement Permit</h2>
                          <span className="text-3xl text-black font-bold">
                            {weeklyStat?.replacement?.aggregate?.count}
                          </span>
                        </div>
                      </div> */}
                      </div>
                    </div>
                  </>
                )}

                {tab === "chart" && (
                  <>
                    <div className="w-full bg-blue-200 h-full">
                      <DonutChart
                        data={[
                          // weeklyStat.assigned_organization_reviewers_aggregate
                          //   .aggregate.count,
                          weeklyStat.new_applicant_approved.aggregate.count,
                          weeklyStat.renewal_approved.aggregate.count,
                          weeklyStat.cancellation_approved.aggregate.count,
                          weeklyStat.replacement.aggregate.count,
                          // weeklyStat.total_assigned_permits.aggregate.count
                        ]}
                        pieLabel={[
                          "Assigned Organization Reviewers",
                          "New",
                          "Renewal",
                          "Cancellation",
                          "Replacement",
                        ]}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeDetail;
