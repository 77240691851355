import React from "react";
import Avatar from "../../assets/images/avatar.jpg";
import BlueLogo from "../../assets/images/blue-logo.png";
import {
  BsTelephoneFill,
  BsSignpostFill,
} from "react-icons/bs";
import { useQuery } from "@apollo/client";
import logo from "../../assets/images/federal-logo.png";

const IdModal = ({ IDData, handleClick }) => {
  console.log(IDData);
  const functionPrint = () => {
    console.log("print clicked");
    document.getElementById("Idprint").className += "noPrint";
    window.print();
  };

  return (
    <div
      id={IDData?.id}
      tabIndex="1"
      aria-hidden="true"
      className=" h-screen w-full z-[60] bg-white/10 backdrop-blur-sm fixed flex items-center justify-center top-0"
    >
      <div
        onClick={handleClick}
        className="absolute h-full w-full top-0 left-0 z-10"
      ></div>

      <div className="bg-gradient-to-br z-20  from-[#090979] to-[#00d4ff] p-8 rounded-md shadow-lg">
        <div id="Idprint" className="flex w-[650px] gap-8 items-center">
          <div className="w-1/2 mx-auto min-h-[500px] p-4 shadow-lg rounded-md relative bg-white">
            <div className="absolute top-1/2 opacity-70 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <img src={logo} alt="logo" />
            </div>
            <h3 className="bg-[#090979] bg-opacity-10 text-[#090979] text-ms font-normal capitalize mx-auto py-2 mb-4 p-4 w-fit rounded">
              New Applied Person
            </h3>

            <div class="relative z-50 object-cover rounded-md mx-auto mb-2 border-2 shadow border-[#090979] mx-auto inline-flex items-center justify-center w-36 h-36 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
              <span class="font-medium text-gray-600 dark:text-gray-300">
                JL
              </span>
            </div>

            {/* <img
              src={Avatar}
              alt="applied person"
              className="w-36 h-36 relative z-50 object-cover rounded-md mx-auto mb-2 border-2 shadow border-[#090979]"
            /> */}
            <h4 className="text-center text-lg capitalize text-gray-800 font-medium">
              {IDData?.first_name + " " + IDData?.father_name}
            </h4>
            <p className="text-center capitalize text-sm text-gray-500 mb-2">
              {IDData?.nationality?.name}
            </p>
            <p className="text-center uppercase font-semibold mb-6 text-gray-800">
              {IDData?.gender.name}
            </p>
            <div className="flex flex-col gap-3 2xl:w-11/12 mx-auto">
              <div className="flex items-center justify-between">
                <h3 className="text-[#090979]">Occupation</h3>
                <p className="text-sm text-gray-500">
                  {IDData?.occupation_category?.name_json?.en}
                </p>
              </div>
              <div className="flex items-center justify-between">
                <h3 className="text-[#090979]">Organization</h3>
                <p className="text-sm text-gray-500">
                  {IDData?.orgnization?.name}
                </p>
              </div>
              <div className="flex items-center justify-between">
                <BsTelephoneFill className="text-[#090979]" />
                <p className="text-sm text-gray-500">
                  {IDData?.phone_number || "--"}
                </p>
              </div>
              <div className="flex items-center justify-between">
                <BsSignpostFill className="text-[#090979]" />
                <p className="text-sm text-gray-500">
                  {IDData?.passport_number}
                </p>
              </div>
            </div>
          </div>
          <div className="w-1/2 h-full">
            <div className="flex flex-col min-h-[500px] gap-8 justify-around rounded-md shadow-lg items-center p-4 bg-white">
              <div className="flex flex-col gap-3 2xl:w-4/5 mx-auto">
                <div className="flex items-center justify-between">
                  <h3 className="text-[#090979]">Valid upto-</h3>
                  <p className="text-sm text-gray-500">June 12, 2022</p>
                </div>
                <div className="flex items-center justify-between">
                  <h3 className="text-[#090979]">Date of Issue</h3>
                  <p className="text-sm text-gray-500">25 Sep 2022</p>
                </div>
              </div>
              <img src={BlueLogo} alt="blue logo" className="w-2/5 mx-auto" />
              <p className="pt-2 text-[#090979] border-t border-gray-500 w-4/5 text-center">
                Signature of Issuing Authority
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <button
            onClick={() => functionPrint()}
            className="bg-[#090979] hover:bg-[#020024] hover:shadow-sm text-white font-medium rounded py-2 px-4"
          >
            Download
          </button>
        </div>
      </div>
      {/* <div className=" bg-white p-4 2xl:p-8 z-20 rounded-md shadow w-3/5">
        <div id="Idprint" className="flex gap-8 items-center">
          <div className="w-1/2 mx-auto p-4 shadow rounded-md relative">
            <div className="absolute top-1/2 opacity-70 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <img src={logo} alt="logo" />
            </div>
            <h3 className=" bg-prime40/10 text-primary capitalize mx-auto py-2 mb-4 p-4 w-fit rounded">
              new applied person
            </h3>
            <img
              src={Avatar}
              alt=" applied person"
              className="w-36 h-36 relative z-50 object-cover rounded-md mx-auto mb-2 border-2 shadow border-prime70"
            />
            <h4 className=" text-center relative z-50 text-lg capitalize text-primary font-medium">
              {IDData?.first_name + " " + IDData?.father_name}
            </h4>
            <p className=" text-center relative z-50 capitalize text-sm text-N50 mb-2">
              {IDData?.nationality?.name}
            </p>
            <p className=" text-center relative z-50 uppercase font-semibold mb-6 text-N10">
            {IDData?.gender}
            </p>
            <div className=" flex flex-col gap-3 capitalize 2xl:w-11/12 mx-auto">
              <div className="flex items-center justify-between">
                <h3 className=" text-primary">occupation</h3>
                <p className=" text-sm text-N50">
                {IDData?.occupation_category?.name}
                </p>
              </div>
              <div className="flex items-center justify-between">
                <h3 className=" text-primary">Organization</h3>
                <p className=" text-sm text-N50">{IDData?.orgnization?.name}</p>
              </div>
              <div className="flex items-center justify-between">
                <BsTelephoneFill className=" text-primary" />
                <p className=" text-sm text-N50">{IDData?.phone_number || "--"}</p>
              </div>
              <div className="flex items-center justify-between">
                <BsSignpostFill className=" text-primary" />
                <p className=" text-sm text-N50">{IDData?.passport_number}</p>
              </div>
            </div>
          </div>
          <div className="w-1/2 h-full">
            <div className="flex flex-col h-full gap-8 justify-around rounded-md shadow items-center p-4">
              <div className=" flex flex-col gap-3 capitalize 2xl:w-4/5 mx-auto">
                <div className="flex items-center justify-between">
                  <h3 className=" text-primary">Valid upto-</h3>
                  <p className=" text-sm text-N50">
                    June 12, 2022
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <h3 className=" text-primary">Date of Issue</h3>
                  <p className=" text-sm text-N50">25 Sep 2022</p>
                </div>
              </div>
              <img src={BlueLogo} alt="blue logo" className="w-2/5 mx-auto" />
              <p className="pt-2 text-primary border-t border-N80 w-4/5 text-center">
                Signature of Issuing authority
              </p>
            </div>
            <div className="flex justify-end">
              <button onClick={() => functionPrint()} className="bg-prime40/20 py-2 text-primary font-medium rounded w-2/5 mt-4">
                print
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default IdModal;
