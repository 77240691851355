import React, { useEffect, useRef, useState } from "react";
import FormLabel from "../common/FormLabel";
import { BsBuilding, BsGlobe, BsPinFill } from "react-icons/bs";
import { FaFileUpload } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { FormProvider, CInputField, CSelectField } from "./Fields";
import { useForm } from "react-hook-form";
import CButton from "./CButton";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_ORGANIZATION_DATA,
  INSERT_ORGANIZATION_DOC,
  UPLOAD_FILE,
} from "../../graph-query/mutation";
import { useLocalStore } from "../../store";
import {
  BASE_SECTORS,
  BASE_REGION,
  BASE_ORGANIZATIOIN_TYPE,
  BASE_DISTRICT,
  BASE_SUBCITY,
  BASE_ZONE,
  BASE_WOREDA,
} from "../../graph-query/queries";
import ChooseDocumentModal from "../utilities/ChooseDocumentModal";
import { VscFilePdf } from "react-icons/vsc";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import {
  getBase64,
  handleOrganizationError,
} from "../../utils/utilityFunctions";
import { AddisID, DireDawaID } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateOrganizationProfileSchema } from "../../utils/SchemaValidation";
import { BASE_ORG_DOCUMENT_TYPES } from "../../graph-query/queries";

const OrganizationData = ({ defaultValue, type }) => {
  // const titlesData = ["Hello Data", "Test Data"];
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [tempFiles, setTempFile] = useState([]);
  const [updateVal, setUpdateVal] = useState([]);

  // console.log("type", type);
  const navigator = useNavigate();
  const {
    register,
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(CreateOrganizationProfileSchema),
  });

  const [fileErrors, setFileErrors] = useState({});

  const {
    sectors,
    regions,
    organizationType,
    profileFileType,
    subcities,
    districts,
    zones,
    woredas,
    setSectors,
    setRegions,
    setOrganizationType,
    setDistricts,
    setSubCities,
    setZones,
    setWoredas,
    userOrganizationData,
    orgDocumentTypes,
    setOrgDocumentTypes,

    filterDocumentTypes,
  } = useLocalStore();

  const fileRef = useRef();
  const fileRef2 = useRef();
  const fileRef3 = useRef();
  //form ref
  const formRef = useRef(null);

  // watch regioin if aa is selected
  const selectedRegion = watch("region_id");
  const selectedSubCity = watch("subcity_id");
  const selectedZone = watch("zone_id");

  const [fileUpload, { loading: uploadLoading }] = useMutation(UPLOAD_FILE);

  const [getSectors, {}] = useLazyQuery(BASE_SECTORS, {
    onCompleted: (data) => {
      console.log("sector data:", data?.base_sectors);
      setSectors(data?.base_sectors);
    },
  });

  const [getRegions, {}] = useLazyQuery(BASE_REGION, {
    onCompleted: (data) => {
      console.log("regions data:", data?.base_regions);
      setRegions(data?.base_regions);
    },
  });

  const [getOrgType, {}] = useLazyQuery(BASE_ORGANIZATIOIN_TYPE, {
    onCompleted: (data) => {
      console.log("organization type data:", data?.base_organization_types);
      setOrganizationType(data?.base_organization_types);
    },
  });

  const [getDistrict, {}] = useLazyQuery(BASE_DISTRICT, {
    variables: {
      zone: selectedZone,
    },
    onCompleted: (data) => {
      console.log("districts data:", data?.base_districts);
      setDistricts(data?.base_districts);
    },
  });

  const [getSubCity, {}] = useLazyQuery(BASE_SUBCITY, {
    variables: {
      region: selectedRegion,
    },
    onCompleted: (data) => {
      console.log("subcities data:", data?.base_subcities);
      setSubCities(data?.base_subcities);
    },
  });

  const [getWoreda, {}] = useLazyQuery(BASE_WOREDA, {
    variables: {
      subcity: selectedSubCity,
    },
    onCompleted: (data) => {
      console.log("woreda data:", data?.base_woredas);
      setWoredas(data?.base_woredas);
    },
  });

  const [getZone, {}] = useLazyQuery(BASE_ZONE, {
    variables: { region: selectedRegion },
    onCompleted: (data) => {
      console.log("zones data:", data?.base_zones);
      setZones(data?.base_zones);
    },
  });

  useEffect(() => {
    if (sectors?.length === 0) {
      getSectors();
    }
  }, [sectors, setSectors]);

  useEffect(() => {
    if (regions?.length === 0) {
      getRegions();
    }
  }, [regions, setRegions]);

  useEffect(() => {
    if (organizationType?.length === 0) {
      getOrgType();
    }
  }, [organizationType, setOrganizationType]);

  useEffect(() => {
    if (selectedRegion !== "") {
      setDistricts([]);
      setZones([]);
      setSubCities([]);
      getDistrict(selectedZone);
      getSubCity(selectedRegion);
    }
  }, [selectedRegion]);

  useEffect(() => {
    if (selectedSubCity !== "") {
      getWoreda(selectedSubCity);
    }
  }, [selectedSubCity]);

  useEffect(() => {
    if (subcities?.length === 0) {
      // Load from server
      if (selectedRegion) getSubCity();
    }
  }, [subcities, setSubCities]);

  useEffect(() => {
    if (zones?.length === 0) {
      getZone();
    }
  }, [zones, setZones]);

  useEffect(() => {
    if (orgDocumentTypes?.length === 0) {
      getDocType();
    }
  }, [orgDocumentTypes, setOrgDocumentTypes]);

  const [getDocType, {}] = useLazyQuery(BASE_ORG_DOCUMENT_TYPES, {
    variables: {},
    onCompleted: (d) => {
      setOrgDocumentTypes(d?.base_organization_document_types);
    },
  });

  // const fileRef = useRef();

  //handle file change
  const fileChange = (event) => {
    event.preventDefault();

    const files = event.target.files;
    const newFiles = Array.from(files).map((file) => ({
      file,
      title: file.name,
      path: URL.createObjectURL(file),
    }));
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemoveFile = (removeData) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file !== removeData)
    );
  };

  const [createOrg, { error, loading }] = useMutation(CREATE_ORGANIZATION_DATA);

  const [createOrgDOC, { error: docErr }] = useMutation(
    INSERT_ORGANIZATION_DOC,
    {
      onCompleted: (_res) => {
        // navigator("/organization");
        window.location.href = "/organization";
      },
      onError: (er) => {
        console.log(er);
        // toast.error("Something happend while creating organization");
      },
    }
  );

  useEffect(() => {
    if (selectedRegion === AddisID) {
      setValue("district_id", null);
      setValue("zone_id", null);
    } else {
      setValue("woreda_id", null);
      setValue("subcity_id", null);
    }
  }, [selectedRegion, setValue]);

  useEffect(() => {
    //tempfile update
    console.log(tempFiles);
  }, [tempFiles]);

  const onSubmit = (data) => {
    if (data.district_id === "") {
      data.district_id = null;
    }
    if (data.zone_id === "") {
      data.zone_id = null;
    }

    console.log('====================================');
    console.log(data);
    console.log('====================================');

    let finalData = {
      ...data,
      phone_no: ["251" + data.phone_no],
    };
    console.log(tempFiles, data);
    let r = orgDocumentTypes?.map((i) => i.name);
    const foundName = selectedFiles.some(
      (item) => item.title === "TIN Certificate"
    );
    const foundName2 = selectedFiles.some(
      (item) => item.title === "Business License Certificate"
    );
    const foundName3 = selectedFiles.some(
      (item) => item.title === "Certificate of Registration"
    );
    if (!foundName) {
      toast.warn("TIN Certificate document required!");
      return;
    }
    if (!foundName2) {
      toast.warn("Business License Certificate document required!");
      return;
    }
    if (!foundName3) {
      toast.warn("Certificate of Registration document required!");
      return;
    }
    if (selectedFiles.length >= 3) {
      if (r?.some((f) => selectedFiles.some((e) => e.title === f))) {
        const updatedTempFiles = [];
        const uploadPromises = selectedFiles.map((i) => {
          return new Promise((resolve, reject) => {
            filesUpload(
              i?.file,
              (filePath) => {
                updatedTempFiles.push({
                  ...filePath,
                  document_type_id: i.document_type_id,
                  title: i.title,
                });
                resolve(filePath);
              },
              reject
            );
          });
        });

        console.log(finalData);
        Promise.all(uploadPromises)
          .then((uploadedFiles) => {
            setTempFile(updatedTempFiles);
            createOrg({
              variables: {
                ...finalData,
              },
              onCompleted: async (data) => {
                console.log(data);
                toast.success("Organization create successfully");
                // handleOrganizationDocument(data?.insert_organizations_one?.id);
                await updatedTempFiles.map(async (i) => {
                  console.log(i);
                  await createOrgDOC({
                    variables: {
                      orgID: data?.insert_organizations_one?.id,
                      document_type: i?.document_type_id,
                      files: i,
                    },
                  });
                });
                // );
              },
              onError: (err) => {
                console.log("error: ", err);
                const errorMessage = handleOrganizationError(err);
                toast.error(errorMessage);
                // Scroll to the top of the form
                formRef.current.scrollIntoView({ behavior: "smooth" });
              },
            });
          })
          .catch((error) => {
            console.log("error uploading files: ", error);
            toast.error("error uploading files");
            // Scroll to the top of the form
            formRef.current.scrollIntoView({ behavior: "smooth" });
          });
      } else {
        toast.info("Please Upload All Document Files");
      }
    } else {
      toast.warn("Missing Files, Please check again!");
    }
  };

  // Upload file
  const filesUpload = (uploadedFile, cb) => {
    getBase64(uploadedFile)
      .then((result) => {
        // console.log(result);
        // extract the file type to have the folowing pdf,png ...
        let fileType = (uploadedFile?.type).split("/").length
          ? (uploadedFile?.type).split("/")[1]
          : "";
        // upload the file to the server
        trackPromise(
          fileUpload({
            variables: {
              extension: fileType,
              file: result,
            },
            onCompleted(data) {
              // console.log(data);
              cb &&
                cb({ name: uploadedFile.name, path: data.uploadFile.info.Key });
              // toast.success("Document Uploaded Successfully!");
            },
            onError(error) {
              // toast.error("Document upload failed!");
            },
          })
        );
      })
      .catch((err) => {
        // handle error
      });
  };

  const handleFileUpload = async () => {
    console.log(files);
    // upload data selected one
    await files.map((i) => {
      filesUpload(
        i?._file,
        (filePath) =>
          setTempFile((prevFiles) => [
            ...prevFiles,
            { ...filePath, title: i?.title, document_type_id: i.typeID },
          ])
        // setTempFile([...tempFiles, { ...filePath, title: i?.title }])
      );
    });
    setUploadModal(false);
  };

  const handleTinNo = (_file, e) => {
    console.log("tin", _file.name, e);
    const file = e.target.files[0];
    if (file) {
      if (file.size > "5MB") {
        setFileErrors({
          ...fileErrors,
          [_file?.id]: `File size should not exceed 5MB`,
        });
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          const fileData = reader.result;
          const existingFile = selectedFiles.find(
            (file) => file.document_type_id === _file?.id
          );
          if (existingFile) {
            const updatedFile = {
              document_type_id: _file?.id,
              title: _file?.name,
              file: file,
            };
            setSelectedFiles((prevFiles) => {
              const updatedFiles = prevFiles.map((file) =>
                file.document_type_id === _file?.id ? updatedFile : file
              );
              return updatedFiles;
            });
          } else {
            setSelectedFiles((prevFiles) => [
              ...prevFiles,
              {
                document_type_id: _file?.id,
                title: _file?.name,
                file: file,
              },
            ]);
          }
        };
        reader.readAsDataURL(file);
        setFileErrors({
          ...fileErrors,
          [_file?.id]: undefined,
        });
      }
    }
  };
  const handleBusiness = (_file, e) => {
    console.log("busin", _file.name, e);
    const file = e.target.files[0];
    if (file) {
      if (file.size > "5MB") {
        setFileErrors({
          ...fileErrors,
          [_file?.id]: `File size should not exceed 5MB`,
        });
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          const fileData = reader.result;
          const existingFile = selectedFiles.find(
            (file) => file.document_type_id === _file?.id
          );
          if (existingFile) {
            const updatedFile = {
              document_type_id: _file?.id,
              title: _file?.name,
              file: file,
            };
            setSelectedFiles((prevFiles) => {
              const updatedFiles = prevFiles.map((file) =>
                file.document_type_id === _file?.id ? updatedFile : file
              );
              return updatedFiles;
            });
          } else {
            setSelectedFiles((prevFiles) => [
              ...prevFiles,
              {
                document_type_id: _file?.id,
                title: _file?.name,
                file: file,
              },
            ]);
          }
        };
        reader.readAsDataURL(file);
        setFileErrors({
          ...fileErrors,
          [_file?.id]: undefined,
        });
      }
    }
  };
  const handleReg = (_file, e) => {
    console.log("regis", _file.name, e);
    const file = e.target.files[0];
    if (file) {
      if (file.size > "5MB") {
        setFileErrors({
          ...fileErrors,
          [_file?.id]: `File size should not exceed 5MB`,
        });
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          const fileData = reader.result;
          const existingFile = selectedFiles.find(
            (file) => file.document_type_id === _file?.id
          );
          if (existingFile) {
            const updatedFile = {
              document_type_id: _file?.id,
              title: _file?.name,
              file: file,
            };
            setSelectedFiles((prevFiles) => {
              const updatedFiles = prevFiles.map((file) =>
                file.document_type_id === _file?.id ? updatedFile : file
              );
              return updatedFiles;
            });
          } else {
            setSelectedFiles((prevFiles) => [
              ...prevFiles,
              {
                document_type_id: _file?.id,
                title: _file?.name,
                file: file,
              },
            ]);
          }
        };
        reader.readAsDataURL(file);
        setFileErrors({
          ...fileErrors,
          [_file?.id]: undefined,
        });
      }
    }
  };

  const handleCancelModal = () => {
    setUploadModal(false);
    setFiles([]);
  };

  return (
    <>
      {/* Organization Data Form */}
      {uploadModal && (
        <ChooseDocumentModal
          optionData={orgDocumentTypes}
          files={files}
          setFiles={setFiles}
          onFileUpload={handleFileUpload}
          handleCancel={handleCancelModal}
        />
      )}

      <div className="flex flex-col gap-4 items-start h-full w-full">
        <div className="flex flex-col gap-1 items-start pb-10">
          <p className="text-3xl font-bold text-N40">Organization Data</p>
          <p className="text-xs font-light w-full md:w-2/3 text-N60">
            Organization must fulfill the Organization Data in order to create
            the work permit process.
          </p>
        </div>
        <div ref={formRef} className="w-full">
          <FormProvider
            onSubmithandler={handleSubmit(onSubmit)}
            className="w-full flex flex-wrap"
          >
            <div className="flex w-full flex-wrap justify-between items-start">
              <div className="flex flex-col gap-4 flex-wrap items-start h-full w-full md:w-3/5">
                {/* Organization Name  */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<BsBuilding />}
                    labelTitile="Organization Name*"
                    labelDescription="Organization name"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CInputField
                        name="name"
                        register={register}
                        errors={errors}
                        // defaultValue={defaultValue?.name}
                        validation={{
                          required: "Organization Name is required",
                        }}
                        type="text"
                        label=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                {/* Type Of Organization  */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<MdDashboard />}
                    labelTitile="Type Of Organization*"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CSelectField
                        name="organization_type_id"
                        register={register}
                        errors={errors}
                        // defaultValue={
                        //   userOrganizationData?.organization_type?.id
                        // }
                        validation={{
                          required: "Type of Organization is required",
                        }}
                        options={organizationType?.map((types) => {
                          return {
                            ...types,
                            value: types?.id,
                          };
                        })}
                        label=""
                        placeholder="Select"
                      />
                    </div>
                  </div>
                </div>

                {/* Sector Of Organization  */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<MdDashboard />}
                    labelTitile="Sector*"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CSelectField
                        name="sector_id"
                        register={register}
                        errors={errors}
                        // defaultValue={userOrganizationData?.sector?.id}
                        validation={{
                          required: "Sector is required",
                        }}
                        options={sectors?.map((types) => {
                          return {
                            ...types,
                            name: types?.name_json?.en,
                            value: types?.id,
                          };
                        })}
                        label=""
                        placeholder="Select Sector"
                      />
                    </div>
                  </div>
                </div>

                {/* Region  */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel labelIcon={<BsGlobe />} labelTitile="Region*" />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CSelectField
                        name="region_id"
                        register={register}
                        errors={errors}
                        validation={{ required: "Region is required" }}
                        options={regions?.map((reg) => {
                          return {
                            ...reg,
                            value: reg?.id,
                          };
                        })}
                        label=""
                        placeholder="Select Region"
                      />
                    </div>
                  </div>
                </div>

                {(selectedRegion === AddisID ||
                  selectedRegion === DireDawaID) && (
                  <>
                    <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                      <FormLabel
                        labelIcon={<MdDashboard />}
                        labelTitile="SubCity"
                        labelDescription="Sub City"
                      />
                      <div className="w-full flex gap-6 py-2">
                        <div className=" w-full">
                          <CSelectField
                            name="subcity_id"
                            register={register}
                            errors={errors}
                            options={subcities?.map((sub) => {
                              return {
                                ...sub,
                                name: sub?.name_json?.en,
                                value: sub?.id,
                              };
                            })}
                            label=""
                            placeholder="Select SubCity"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {selectedSubCity && (
                  <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                    <FormLabel
                      labelIcon={<MdDashboard />}
                      labelTitile="Woreda"
                      labelDescription="Woreda"
                    />
                    <div className="w-full flex gap-6 py-2">
                      <div className=" w-full">
                        <CSelectField
                          name="woreda_id"
                          register={register}
                          errors={errors}
                          options={woredas?.map((wor) => {
                            return {
                              ...wor,
                              name: wor?.name_json?.en,
                              value: wor?.id,
                            };
                          })}
                          label=""
                          placeholder="Select Woreda"
                        />
                      </div>
                    </div>
                  </div>
                )}

                {selectedRegion !== "" &&
                  selectedRegion !== AddisID &&
                  selectedRegion !== DireDawaID && (
                    <>
                      <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                        <FormLabel
                          labelIcon={<MdDashboard />}
                          labelTitile="Zone"
                          labelDescription="zone"
                        />
                        <div className="w-full flex gap-6 py-2">
                          <div className=" w-full">
                            <CSelectField
                              name="zone_id"
                              register={register}
                              errors={errors}
                              options={zones?.map((dis) => {
                                return {
                                  ...dis,
                                  value: dis.id,
                                  name: dis?.name_json?.en,
                                };
                              })}
                              label=""
                              placeholder="Select Zone"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                {selectedZone && (
                  <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                    <FormLabel
                      labelIcon={<MdDashboard />}
                      labelTitile="District"
                      labelDescription="district"
                    />
                    <div className="w-full flex gap-6 py-2">
                      <div className=" w-full">
                        <CSelectField
                          name="district_id"
                          register={register}
                          errors={errors}
                          options={districts?.map((dis) => {
                            return {
                              ...dis,
                              name: dis.name_json?.en,
                              value: dis.id,
                            };
                          })}
                          label=""
                          placeholder="Select District"
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* Fax */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<MdDashboard />}
                    labelTitile="Fax"
                    labelDescription="Fax"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CInputField
                        name="fax"
                        register={register}
                        errors={errors}
                        // defaultValue={userOrganizationData?.fax}
                        validation={{
                          required: true,
                        }}
                        type="text"
                        label=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                {/* PO. Box */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<MdDashboard />}
                    labelTitile="PO. Box"
                    labelDescription="PO. Box"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CInputField
                        name="pobox"
                        register={register}
                        errors={errors}
                        // defaultValue={userOrganizationData?.pobox}
                        validation={{
                          required: "Po.Box is required",
                        }}
                        type="text"
                        label=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                {/* Work Phone */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<MdDashboard />}
                    labelTitile="Work Phone*"
                    labelDescription="Work Phone"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CInputField
                        name="phone_no"
                        register={register}
                        errors={errors}
                        // defaultValue={userOrganizationData?.phone_no}
                        validation={{
                          required: "Work Phone is required",
                        }}
                        type="text"
                        label=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                {/* Email Address */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<MdDashboard />}
                    labelTitile="Email Address*"
                    labelDescription="Email Address"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CInputField
                        name="email"
                        register={register}
                        errors={errors}
                        // defaultValue={userOrganizationData?.email}
                        validation={{
                          required: "Email is required",
                        }}
                        type="email"
                        label=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                {/* House Number */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<MdDashboard />}
                    labelTitile="House Number*"
                    labelDescription="House Number"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CInputField
                        name="house_number"
                        register={register}
                        // defaultValue={userOrganizationData?.house_number}
                        errors={errors}
                        validation={{
                          required: "House Number is required",
                        }}
                        type="text"
                        label=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                {/* Number of Employees */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<MdDashboard />}
                    labelTitile="Number of Employees*"
                    labelDescription="Number of Employees"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CInputField
                        name="local_employees_count"
                        register={register}
                        errors={errors}
                        // defaultValue={
                        //   userOrganizationData?.local_employees_count
                        // }
                        validation={{
                          required: "Number of Employee is required",
                        }}
                        type="number"
                        label=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                {/* Number of Expatriates */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<MdDashboard />}
                    labelTitile="Number of Expatriates*"
                    labelDescription="Number of Expatriates"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CInputField
                        name="expatriates_count"
                        register={register}
                        errors={errors}
                        // defaultValue={userOrganizationData?.expatriates_count}
                        validation={{
                          required: "Number of Expatriates is required",
                        }}
                        type="number"
                        label=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                {/* Business License Number */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<MdDashboard />}
                    labelTitile="Business License Number*"
                    labelDescription="Business License Number"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CInputField
                        name="business_license_number"
                        register={register}
                        errors={errors}
                        // defaultValue={
                        //   userOrganizationData?.business_licence_number
                        // }
                        validation={{
                          required: "Business License Number is required",
                        }}
                        type="text"
                        label=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<MdDashboard />}
                    labelTitile="Business License Issue Date*"
                    labelDescription="Enter Business License Issued date"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CInputField
                        name="business_license_issue_date"
                        register={register}
                        errors={errors}
                        // defaultValue={
                        //   userOrganizationData?.business_licence_number
                        // }
                        validation={{
                          required: "Business License Issue date is required",
                        }}
                        type="date"
                        label=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<MdDashboard />}
                    labelTitile="Business License Expiry Date*"
                    labelDescription="Enter Business License Expiry date"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CInputField
                        name="business_license_expiry_date"
                        register={register}
                        errors={errors}
                        // defaultValue={
                        //   userOrganizationData?.business_licence_number
                        // }
                        validation={{
                          required: "Business License Expiry date is required",
                        }}
                        type="date"
                        label=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                {/* Tin Number */}
                <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                  <FormLabel
                    labelIcon={<MdDashboard />}
                    labelTitile="Tin Number*"
                    labelDescription="Tin number"
                  />
                  <div className="w-full flex gap-6 py-2">
                    <div className=" w-full">
                      <CInputField
                        name="tin_number"
                        register={register}
                        // defaultValue={userOrganizationData?.tin_number}
                        errors={errors}
                        validation={{
                          required: "Tin Number is required",
                        }}
                        type="text"
                        label=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                {/* <Controller
                  name="otp1"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="text"
                      maxLength={1}
                      className="w-12 h-12 text-center border rounded"
                    />
                  )}
                /> */}
              </div>

              <div className="w-full md:w-1/3 h-full border-dashed border-2 bg-Prime99/50 rounded-lg p-4">
                <h1 className="py-4 font-semibold">Upload File</h1>
                <div className="flex flex-col gap-1">
                  <p className="text-xs text-N70 font-light">File types</p>

                  <div className="flex gap-1 pl-4 items-center">
                    <BsPinFill className="text-N70 text-xs -rotate-45" />
                    <p className="text-xs text-N70">Pdf</p>
                  </div>
                  <div className="flex gap-1 pl-4 items-center">
                    <BsPinFill className="text-N70 text-xs -rotate-45" />
                    <p className="text-xs text-N70">jpeg | jpg | png</p>
                  </div>
                </div>

                <div className="w-full flex md:flex-row flex-col gap-4 md:gap-6 py-2">
                  <div className=" w-full ">
                    <div className="flex flex-col gap-2 bg-white p-6 rounded-t-xl">
                      <p className="text-primary text-sm font-semibold">
                        Upload {orgDocumentTypes[0]?.name}
                      </p>
                      <div
                        className={`relative bg-primary/5 border-dashed border-2 cursor-pointer rounded-lg px-8 py-12 mt-4 gap-3 ${
                          fileErrors[orgDocumentTypes[0]?.id] &&
                          "border-red-500"
                        }`}
                      >
                        <input
                          onChange={(e) => handleTinNo(orgDocumentTypes[0], e)}
                          ref={fileRef}
                          type="file"
                          className="hidden"
                        />
                        <div
                          onClick={() => fileRef?.current?.click()}
                          className="absolute top-0 left-0 right-0 flex flex-col items-center justify-center px-8 py-4 gap-3"
                        >
                          <FaFileUpload className="text-primary/90 text-4xl" />
                          <p className="text-N70 text-xs md:px-6 text-center">
                            Choose document type
                          </p>
                        </div>
                      </div>
                      {selectedFiles
                        .filter((i) => i.title === orgDocumentTypes[0]?.name)
                        .map((item, index) => (
                          <div
                            key={index}
                            className="border-b border-N99/50 p-4 flex justify-between items-center w-full"
                          >
                            <div className="flex gap-2 items-center">
                              <VscFilePdf />
                              <div>
                                <p className="text-xs text-N50">
                                  {item.title}.pdf
                                </p>
                                <p className="text-[10px] text-N50">
                                  {/* {item?.size / 1000} KB */}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="w-full flex md:flex-row flex-col gap-4 md:gap-6 py-2">
                  <div className=" w-full ">
                    <div className="flex flex-col gap-2 bg-white p-6 rounded-t-xl">
                      <p className="text-primary text-sm font-semibold">
                        Upload {orgDocumentTypes[1]?.name}
                      </p>
                      <div
                        className={`relative bg-primary/5 border-dashed border-2 cursor-pointer rounded-lg px-8 py-12 mt-4 gap-3 ${
                          fileErrors[orgDocumentTypes[1]?.id] &&
                          "border-red-500"
                        }`}
                      >
                        <input
                          onChange={(e) =>
                            handleBusiness(orgDocumentTypes[1], e)
                          }
                          ref={fileRef2}
                          type="file"
                          className="hidden"
                        />
                        <div
                          onClick={() => fileRef2?.current?.click()}
                          className="absolute top-0 left-0 right-0 flex flex-col items-center justify-center px-8 py-4 gap-3"
                        >
                          <FaFileUpload className="text-primary/90 text-4xl" />
                          <p className="text-N70 text-xs md:px-6 text-center">
                            Choose document type
                          </p>
                        </div>
                      </div>
                      {selectedFiles
                        .filter((i) => i.title === orgDocumentTypes[1]?.name)
                        .map((item, index) => (
                          <div
                            key={index}
                            className="border-b border-N99/50 p-4 flex justify-between items-center w-full"
                          >
                            <div className="flex gap-2 items-center">
                              <VscFilePdf />
                              <div>
                                <p className="text-xs text-N50">
                                  {item.title}.pdf
                                </p>
                                <p className="text-[10px] text-N50">
                                  {item?.name}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="w-full flex md:flex-row flex-col gap-4 md:gap-6 py-2">
                  <div className=" w-full ">
                    <div className="flex flex-col gap-2 bg-white p-6 rounded-t-xl">
                      <p className="text-primary text-sm font-semibold">
                        Upload {orgDocumentTypes[2]?.name}
                      </p>
                      <div
                        className={`relative bg-primary/5 border-dashed border-2 cursor-pointer rounded-lg px-8 py-12 mt-4 gap-3 ${
                          fileErrors[orgDocumentTypes[2]?.id] &&
                          "border-red-500"
                        }`}
                      >
                        <input
                          onChange={(e) => handleReg(orgDocumentTypes[2], e)}
                          ref={fileRef3}
                          type="file"
                          className="hidden"
                        />
                        <div
                          onClick={() => fileRef3?.current?.click()}
                          className="absolute top-0 left-0 right-0 flex flex-col items-center justify-center px-8 py-4 gap-3"
                        >
                          <FaFileUpload className="text-primary/90 text-4xl" />
                          <p className="text-N70 text-xs md:px-6 text-center">
                            Choose document type
                          </p>
                        </div>
                      </div>
                      {selectedFiles
                        .filter((i) => i.title === orgDocumentTypes[2]?.name)
                        .map((item, index) => (
                          <div
                            key={index}
                            className="border-b border-N99/50 p-4 flex justify-between items-center w-full"
                          >
                            <div className="flex gap-2 items-center">
                              <VscFilePdf />
                              <div>
                                <p className="text-xs text-N50">
                                  {item.title}.pdf
                                </p>
                                <p className="text-[10px] text-N50">
                                  {/* {item?.size / 1000} KB */}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

                {/* {selectedFiles &&
                  selectedFiles?.map((item, index) => (
                    <div
                      key={index}
                      className="border-b border-N99/50 p-4 flex justify-between items-center w-full"
                    >
                      <div className="flex gap-2 items-center">
                        <VscFilePdf />
                        <div>
                          <p className="text-xs text-N50">{item.title}.pdf</p>
                          <p className="text-[10px] text-N50">
                            {item?.size / 1000} KB
                          </p>
                        </div>
                      </div>
                    </div>
                  ))} */}
              </div>
            </div>

            {/* Next Button */}
            <div className="md:w-1/4 w-full flex justify-end py-10">
              <CButton
                type="submit"
                loading={loading}
                btnLabel={type === "new" ? "Submit" : "Update"}
                classes=" w-1/3 py-2 flex justify-center items-center text-white bg-primary rounded-sm text-sm"
              />
            </div>
          </FormProvider>
        </div>
      </div>
    </>
  );
};

export default OrganizationData;
