import * as yup from "yup";
import { AddisID } from "./constants";
import { toUpper } from "lodash";

const EMR_VALIDATE = /^EMR\d{1,10}$/;
const PATTERN_VALIDATE = /^[a-zA-Z0-9]{3,20}$/;
const EMR_NUMBER_VALIDATE = /^[A-Z]{3}\d{1,6}$/;

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const PASSPORT_REG_EXP = /^[A-Za-z]{2}[0-9]{7}$/;
// const PASSPORT_REG_EXP = /^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/;
const VISA_REG_EXP = "^4[0-9]{12}(?:[0-9]{3})?$";
// const NAME_REG_EXP = "^[a-zA-Z0-9]*$";

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

const LoginSchema = yup
  .object({
    username: yup.string().required(),
    password: yup.string().min(4).max(15).required(),
  })
  .required();

const RegitrationSchema = yup
  .object({
    name: yup.string().required("Name is required"), //.matches(NAME_REG_EXP, "Name should only contain alphanumeric characters"),
    email: yup.string().email().required(),
    phone_number: yup
      .string()
      .matches(/^(9|7)\d{8}$/, "Phone Number should be 9******* or 7*******"),
    password: yup.string().min(4).max(15).required(),
    confirm_password: yup
      .string()
      .required()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required();

const ChangePasswordSchema = yup.object().shape({
  current_password: yup.string().required("Current password is required"),
  new_password: yup
    .string()
    .required("New password is required")
    .min(6, "Password must be at least 6 characters"),
  confirm_new_password: yup
    .string()
    .required("Confirm new password is required")
    .oneOf([yup.ref("new_password"), null], "Passwords must match"),
});

const ForgotPasswordSchema = yup
  .object()
  .shape({
    phone_number: yup.string().matches(/^(9|7)\d{8}$/, "Invalid phone number"),
  })
  .required();

const OrganizationGeneralInfoSchema = yup
  .object({
    date_of_birth: yup
      .date()
      .max(new Date(), "You Must be at least 18 years old") //Date.now() - 18 * 365 * 24 * 60 * 60 * 1000
      .test(
        "valid-age",
        "You must be at least 18 years old and not greater than 110 years",
        function (value) {
          const currentDate = new Date();
          const dob = new Date(value);
          const ageInYears = currentDate.getFullYear() - dob.getFullYear();

          return ageInYears >= 18 && ageInYears <= 110;
        }
      )
      .required(),
    entry_date: yup.date("entry date is required!"),
    father_name: yup.string().required("father name is required!"),
    first_name: yup.string().required("first name is required!"),
    gender: yup.string().uuid().required("gender is required!"),
    marital_status: yup.string().required("martial status is required!"),
    grand_father_name: yup.string().nullable(),
    nationality_id: yup.string().required("nationality is required!"),
    passport_expiry_date: yup
      .date()
      .required("passport Expiry date is required!"),
    passport_issued_date: yup
      .date()
      .required("passport issued date is required!"),
    // expiry_date: yup.date().required(),
    // issued_date: yup.date().required(),
    passport_number: yup
      .string()
      .matches(PATTERN_VALIDATE, "passport number should be valid!")
      .required("passport number is required"),
    visa_expiry_date: yup.date(),
    visa_issued_date: yup.date(),
    visa_number: yup
      .string()
      .matches(PATTERN_VALIDATE, "visa number should be valid!")
      .required("visa number is required"),
    visa_type: yup.string().required("visa type is required"),
    way_of_entry: yup.string().uuid().required("way of enter is required"),
  })
  .required();

const WorkPlaceSchema = yup
  .object({
    project_name: yup.string().required("Project Name is required"),
    region_id: yup.string().uuid().required("Region is required"),
    woreda: yup.string(),
    kebele: yup.string(),
    town: yup.string(),
    phone_number: yup.string(),
    house_number: yup.string(),
    email: yup.string().email(),
  })
  .required();

const EmploymentSchema = yup
  .object({
    employment_start_date: yup
      .date()
      .required("Employment start date is required"),
    employment_end_date: yup.date().required("Employment end date is required"),
    allowance: yup.number(),
    salary: yup.number(),
    education_level: yup.string(),
    field: yup.string(),
    profession_title_id: yup.string().uuid().required(),
    year_of_experience: yup
      .number()
      .max(50, "Experience Should not be greater than 50"),
    support_letter_date: yup.date().required(),
    support_letter_number: yup.string().required(),
    intended_position: yup.string(),
    occupation_category_id: yup.string().uuid().required(),
  })
  .required();

const EmploymentSchemaSWap = yup
  .object({
    employment_start_date: yup
      .date()
      .required("Employment start date is required"),
    employment_end_date: yup.date().required("Employment end date is required"),
    allowance: yup.number(),
    salary: yup.number(),
    education_level: yup.string(),
    field: yup.string(),
    profession_title_id: yup.string().uuid().required(),
    arrival_date: yup.date().required(),
    passport_expiry_date: yup.date().required(),
    passport_issued_date: yup.date().required(),
    visa_issued_date: yup.date().required(),
    visa_expiry_date: yup.date().required(),
    intended_position: yup.string(),
    occupation_category_id: yup.string().uuid().required(),
  })
  .required();

const WorkExperienceSchema = yup
  .array()
  .of(
    yup.object({
      institution_name: yup.string(),
      name_of_service: yup.string(),
      years_of_work_experience: yup.string(),
      position: yup.string().required("Position is required"),
      service_type_id: yup.string(),
      start_date: yup.date().required("Start date is required"),
      end_date: yup
        .date()
        .nullable()
        .when("start_date", (startDate, schema) =>
          schema.min(startDate, "End date cannot be before start date")
        ),
      // currently_working: yup.boolean().default(false),
    })
  )
  .required("Work experience is required");

const CounterpartSchema = yup
  .object()
  .shape({
    counterparts: yup.array().of(
      yup.object().shape({
        counterpart_firstName: yup.string().required(),
        counterpart_lastName: yup.string().required(),
        position: yup.string().required(),
        assignment_date: yup.date(),
      })
    ),
  })
  .required();

const CreateOrganizationProfileSchema = yup
  .object({
    name: yup.string().required(),
    organization_type_id: yup.string().uuid().required(),
    sector_id: yup.string().uuid().required(),
    region_id: yup.string().uuid().required(),
    subcity_id: yup.string().nullable(),
    woreda_id: yup.string().nullable(),
    zone_id: yup.string().nullable(),
    district_id: yup.string().nullable(),
    // subcity_id: yup
    //   .string()
    //   .uuid()
    //   .when("region_id", {
    //     is: (val) => val === AddisID,
    //     then: yup.string(),
    //     otherwise: yup.string().nullable(),
    //   })
    //   .required("Subcity is required"),
    // woreda_id: yup
    //   .string()
    //   .uuid()
    //   .when("region_id", {
    //     is: (val) => val === AddisID,
    //     then: yup.string(),
    //     otherwise: yup.string().nullable(),
    //   })
    //   .required("Woreda is required"),
    // zone_id: yup
    //   .string()
    //   .uuid()
    //   .when("region_id", {
    //     is: (val) => val !== AddisID,
    //     then: yup.string().required("Zone is required"),
    //     otherwise: yup.string().nullable(),
    //   }),
    // district_id: yup
    //   .string()
    //   .uuid()
    //   .when("region_id", {
    //     is: (val) => val !== AddisID,
    //     then: yup.string().required("District is required"),
    //     otherwise: yup.string().nullable(),
    //   }),
    fax: yup.string(),
    pobox: yup.string(),
    phone_no: yup
      .string()
      .matches(/^(9|7)\d{8}$/, "phone number should be 9******* or 7*******"),
    email: yup.string().email().required(),
    house_number: yup.string().required(),
    local_employees_count: yup.number().min(1).required(),
    expatriates_count: yup.number().min(1).required(),
    business_license_number: yup.string().required(),
    business_license_issue_date: yup
      .date()
      .min(
        new Date(currentYear - 1, currentDate.getMonth(), currentDate.getDate())
      )
      .max(currentDate)
      .required(),
    business_license_expiry_date: yup
      .date()
      .min(currentDate)
      .max(
        new Date(currentYear + 1, currentDate.getMonth(), currentDate.getDate())
      )
      .required(),
    tin_number: yup
      .string()
      .length(10, "Tin Number must be exactly 10 digit long")
      .required(),
  })
  .required();

const OrganizationRenewalSchema = yup.object({
  orgID: yup.string().uuid(),
  business_license_issued_date: yup
    .date()
    .min(
      new Date(currentYear - 1, currentDate.getMonth(), currentDate.getDate())
    )
    .max(currentDate)
    .required(),
  business_license_expiry_date: yup
    .date()
    .min(currentDate)
    .max(
      new Date(currentYear + 1, currentDate.getMonth(), currentDate.getDate())
    )
    .required(),
});
/**
 * @description Validation for organizatioin expatrates' renewal request
 */
const RenewalRequestSchema = yup
  .object({
    passport_number: yup
      .string()
      .matches(PASSPORT_REG_EXP)
      .required("Passport Number is required"),
    visa_number: yup
      .string()
      .matches(VISA_REG_EXP)
      .required("Visa Number is required"),
    visa_type: yup.string().required("Visa type is required"),
    issue_date: yup.string().required("Issue Date is required"),
    expired_date: yup.string().required("Expired Date is required"),
  })
  .required();

const NewEmployeeSchema = yup
  .object({
    name: yup.string().required("Name is required"),
    email: yup.string().email().required(),
    phone: yup.string().matches(/^(9|7)\d{8}$/, "Invalid phone number"),
    role: yup.string().required("Role is required"),
    password: yup.string().required(),
  })
  .required();

const LetterEMRGenerateShema = yup.object({
  emr_number: yup
    .string()
    .matches(
      EMR_NUMBER_VALIDATE,
      "Invalid format. Must be 3 uppercase letters followed by a number up to 4 digits."
    ),
  arrivalDate: yup.string().required(),
  expiryDate: yup.string().required(),
  requestedDate: yup.string().required(),
  job: yup.string(),
});

const EmrFormSchema = yup
  .object({
    emr_number: yup
      .string()
      .matches(
        EMR_VALIDATE,
        "Enter valid EMR number including the word 'EMR' without space"
      ),
  })
  .required();

export {
  LoginSchema,
  RegitrationSchema,
  OrganizationRenewalSchema,
  ForgotPasswordSchema,
  OrganizationGeneralInfoSchema,
  WorkPlaceSchema,
  EmploymentSchema,
  WorkExperienceSchema,
  CounterpartSchema,
  ChangePasswordSchema,
  CreateOrganizationProfileSchema,
  RenewalRequestSchema,
  NewEmployeeSchema,
  LetterEMRGenerateShema,
  EmrFormSchema,
  EmploymentSchemaSWap,
};
