import React from "react";
import { useDropzone } from "react-dropzone";
import { fData } from "../../utils/constants";
import { isString } from "lodash";
import PropTypes from "prop-types";
import { RiFilePptFill } from "react-icons/ri";
import { BsUpload } from "react-icons/bs";

const UploadAvatar = ({ error, file, caption, sx, ...other }) => {
  const {
    getInputProps,
    getRootProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    multiple: false,
    ...other,
  });

  const showRejectionItems = () => {
    return (
      <div>
        {fileRejections.map(({ file, errors }) => {
          const { path, size } = file;

          return (
            <div key={path} className="mt-1">
              <p>
                {path} = {fData(size)}
              </p>
              {errors.map((e) => (
                <p key={e.code}>{e.message}</p>
              ))}
            </div>
          );
        })}
      </div>
    );
  };

//   console.log("file",file);
  return (
    <>
      <div className="w-[144px] h-[144px] rounded-full border m-auto">
        <div
          {...getRootProps()}
          className={`${isDragActive ? "opacity-72" : ""} ${
            isDragReject || error
              ? "text-error-main border-error-light bg-error-lighter"
              : ""
          } w-full h-full ring-2 ring-offset-2 ring-primary outline-none flex overflow-hidden rounded-full relative items-center justify-center cursor-pointer`}
        >
          <input {...getInputProps()} />
          {file && (
            <img
              alt="avatar"
              src={isString(file) ? file : file.preview}
              className="object-cover w-full h-full"
            />
          )}
          <div
            className={`flex flex-col w-full h-full items-center justify-center absolute ease-in-out hover:opacity-70 ${
              file ? "opacity-0 text-white bg-gray-900" : ""
            } `}
          >
            <BsUpload width={34} height={34} className="mb-1" />
            <span>{file ? "Update photo" : "Upload photo"}</span>
          </div>
        </div>
      </div>
      {caption}

      {fileRejections.length > 0 && <showRejectionItems />}
    </>
  );
};

UploadAvatar.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  caption: PropTypes.node,
  sx: PropTypes.object,
};

export default UploadAvatar;
