import React from "react";
import molsLogo from "../../assets/images/mols-logo.png";
import Building from "../../assets/images/Building.png";
import {
  FormProvider,
  CInputField,
  NewCustomButton,
} from "../../components/Forms/Fields";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CButton from "../../components/Forms/CButton";
import NewInputField from "../../components/Forms/Fields/NewInputField";
import { MdArrowBack } from "react-icons/md";

const loginSchema = yup
  .object({
    username: yup.string().required(),
    password: yup.string().min(5).max(15).required(),
  })
  .required();

const registerSchema = yup
  .object({
    organizationName: yup.string().required(),
    organizationEmail: yup.string().email().required(),
    phoneNumber: yup.string().required(),
    password: yup.string().min(5).max(15).required(),
    confirmPassword: yup.string().min(5).max(15).required(),
  })
  .required();

const Auth = () => {
  const [selected, setSelected] = React.useState("login");
  const router = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });
  // const {
  //   register: registreReg,
  //   handleSubmit: handleSubmitReg,
  //   formState: { errors: errorsReg },
  // } = useForm({
  //   resolver: yupResolver(registerSchema),
  // });

  const handleLogin = (s) => {
    console.log(s);
  };

  const handleRegistration = (s) => {
    console.log(s);
  };
  return (
    <div className="bg-white h-screen relative lg:py-20">
      <div
        className="flex flex-col items-center justify-between pt-0 pr-10 pb-0 pl-10 mt-0 mr-auto mb-0 ml-auto max-w-7xl
      xl:px-5 lg:flex-row"
      >
        <div className="flex flex-col items-center w-full pt-5 md:pr-10 pb-20 md:pl-10 lg:pt-20 lg:flex-row">
          <div className="md:visible hidden  w-full bg-cover relative max-w-md lg:max-w-2xl lg:w-7/12">
            <div className="flex flex-col items-center justify-center w-full h-full relative lg:pr-10">
              <img src={Building} alt="permit" className="h-full w-full" />
            </div>
          </div>
          <div className="w-full mt-20 mr-0 mb-0 ml-0 relative z-10 max-w-2xl lg:mt-0 lg:w-5/12">
            {selected === "login" && (
              <FormProvider onSubmithandler={handleSubmit(handleLogin)}>
                <div
                  className="flex flex-col items-start justify-start pt-10 pr-10 pb-10 pl-10 bg-white shadow-2xl rounded-xl
                relative z-10"
                >
                  <button
                    onClick={() => router(-1)}
                    type="button"
                    className="w-7 h-7 flex justify-center items-center bg-gray-200 px-2 py-0.5 rounded-full"
                  >
                    <MdArrowBack />
                  </button>
                  <img src={molsLogo} alt="permit" className="h-16" />
                  <p className="w-full text-4xl font-medium text-start leading-snug font-serif">
                    Sign In
                  </p>
                  <div className="w-full mt-6 mr-0 mb-0 ml-0 relative space-y-8">
                    <NewInputField
                      label={"Email"}
                      name={"username"}
                      validation={{ required: true }}
                      register={register}
                      errors={errors}
                      placeholder={"Please Enter your name"}
                      type={"text"}
                    />
      
                    <NewInputField
                      label={"Password"}
                      name={"password"}
                      register={register}
                      validation={{ required: true }}
                      errors={errors}
                      placeholder={"******"}
                      type={"password"}
                    />

                    <div className="flex justify-end text-indigo-700 -mt-4">
                      <button onClick={() => setSelected("forgot")} type="button" className="text-sm">
                        Forgot Password?
                      </button>
                    </div>
                    <NewCustomButton type={"submit"} label={"Submit"} />
                    <NewCustomButton
                      handleClick={() => setSelected("new")}
                      classes={"!bg-gray-200 !text-black !text-sm"}
                      type={"button"}
                      label={"Create new account?"}
                    />
                    {/* <button type="button" className="text-sm hover:bg-gray-200 w-full px-3 py-2">Create new account?</button> */}
                  </div>
                </div>
              </FormProvider>
            )}
            {selected === "forgot" && (
              <FormProvider onSubmithandler={handleSubmit(handleLogin)}>
                <div
                  className="flex flex-col items-start justify-start pt-10 pr-10 pb-10 pl-10 bg-white shadow-2xl rounded-xl
                relative z-10"
                >
                  <button
                    onClick={() => router(-1)}
                    type="button"
                    className="w-7 h-7 flex justify-center items-center bg-gray-200 px-2 py-0.5 rounded-full"
                  >
                    <MdArrowBack />
                  </button>
                  <img src={molsLogo} alt="permit" className="h-16" />
                  <p className="w-full text-4xl font-medium text-start leading-snug font-serif">
                    Sign In
                  </p>
                  <div className="w-full mt-6 mr-0 mb-0 ml-0 relative space-y-8">
                    <NewInputField
                      label={"Phone Number"}
                      name={"phone"}
                      validation={{ required: true }}
                      register={register}
                      errors={errors}
                      placeholder={"Please Enter your phone number"}
                      type={"text"}
                    />
                    <NewInputField
                      label={"OTP Code"}
                      name={"otp"}
                      maxLength="6"
                      register={register}
                      validation={{ required: true }}
                      errors={errors}
                      placeholder={""}
                      type={"text"}
                    />

                    <div className="flex justify-end text-indigo-700 -mt-4">
                      <button onClick={() => {}} type="button" className="text-sm">
                        Resend OTP?
                      </button>
                    </div>
                    <NewCustomButton type={"submit"} label={"Submit"} />
                  </div>
                </div>
              </FormProvider>
            )}
            {selected === "new" && (
              <FormProvider onSubmithandler={handleSubmit(handleLogin)}>
                <div
                  className="flex flex-col items-start justify-start pt-10 pr-1 md:pr-10 pb-10 pl-1 md:pl-10 bg-white shadow-2xl rounded-xl
                relative z-10"
                >
                  <button
                    onClick={() => router(-1)}
                    type="button"
                    className="w-7 h-7 flex justify-center items-center bg-gray-200 px-2 py-0.5 rounded-full"
                  >
                    <MdArrowBack />
                  </button>
                  <img src={molsLogo} alt="permit" className="h-16" />
                  <p className="w-full text-4xl font-medium text-start leading-snug font-serif">
                    Sign Up
                  </p>
                  <div className="w-full mt-6 mr-0 mb-0 ml-0 relative space-y-8">
                    <NewInputField
                      label={"Name"}
                      name={"name"}
                      validation={{ required: true }}
                      register={register}
                      errors={errors}
                      placeholder={"Please Enter your name"}
                      helperText={"this is not organization name just a person managing it"}
                      type={"text"}
                    />
                    <NewInputField
                      label={"Email"}
                      name={"email"}
                      register={register}
                      validation={{ required: true }}
                      errors={errors}
                      placeholder={""}
                      type={"text"}
                    />
                    <NewInputField
                      label={"Phone Number"}
                      name={"phone_number"}
                      register={register}
                      validation={{ required: true }}
                      errors={errors}
                      placeholder={""}
                      type={"text"}
                    />
                    <NewInputField
                      label={"Password"}
                      name={"password"}
                      register={register}
                      validation={{ required: true }}
                      errors={errors}
                      placeholder={""}
                      type={"password"}
                    />
                    <NewInputField
                      label={"Confirm Password"}
                      name={"confirm-password"}
                      register={register}
                      validation={{ required: true }}
                      errors={errors}
                      placeholder={""}
                      type={"password"}
                    />

                    <div className="flex justify-end text-indigo-700 -mt-4">
                      <button onClick={() => {}} type="button" className="text-sm">
                        Resend OTP?
                      </button>
                    </div>
                    <NewCustomButton type={"submit"} label={"Submit"} />
                  </div>
                </div>
              </FormProvider>
            )}
            <svg
              viewBox="0 0 91 91"
              className="absolute top-0 left-0 z-0 w-32 h-32 -mt-12 -ml-12 text-yellow-300
            fill-current"
            >
              <g stroke="none" strokeWidth="1" fillRule="evenodd">
                <g fillRule="nonzero">
                  <g>
                    <g>
                      <circle cx="3.261" cy="3.445" r="2.72" />
                      <circle cx="15.296" cy="3.445" r="2.719" />
                      <circle cx="27.333" cy="3.445" r="2.72" />
                      <circle cx="39.369" cy="3.445" r="2.72" />
                      <circle cx="51.405" cy="3.445" r="2.72" />
                      <circle cx="63.441" cy="3.445" r="2.72" />
                      <circle cx="75.479" cy="3.445" r="2.72" />
                      <circle cx="87.514" cy="3.445" r="2.719" />
                    </g>
                    <g transform="translate(0 12)">
                      <circle cx="3.261" cy="3.525" r="2.72" />
                      <circle cx="15.296" cy="3.525" r="2.719" />
                      <circle cx="27.333" cy="3.525" r="2.72" />
                      <circle cx="39.369" cy="3.525" r="2.72" />
                      <circle cx="51.405" cy="3.525" r="2.72" />
                      <circle cx="63.441" cy="3.525" r="2.72" />
                      <circle cx="75.479" cy="3.525" r="2.72" />
                      <circle cx="87.514" cy="3.525" r="2.719" />
                    </g>
                    <g transform="translate(0 24)">
                      <circle cx="3.261" cy="3.605" r="2.72" />
                      <circle cx="15.296" cy="3.605" r="2.719" />
                      <circle cx="27.333" cy="3.605" r="2.72" />
                      <circle cx="39.369" cy="3.605" r="2.72" />
                      <circle cx="51.405" cy="3.605" r="2.72" />
                      <circle cx="63.441" cy="3.605" r="2.72" />
                      <circle cx="75.479" cy="3.605" r="2.72" />
                      <circle cx="87.514" cy="3.605" r="2.719" />
                    </g>
                    <g transform="translate(0 36)">
                      <circle cx="3.261" cy="3.686" r="2.72" />
                      <circle cx="15.296" cy="3.686" r="2.719" />
                      <circle cx="27.333" cy="3.686" r="2.72" />
                      <circle cx="39.369" cy="3.686" r="2.72" />
                      <circle cx="51.405" cy="3.686" r="2.72" />
                      <circle cx="63.441" cy="3.686" r="2.72" />
                      <circle cx="75.479" cy="3.686" r="2.72" />
                      <circle cx="87.514" cy="3.686" r="2.719" />
                    </g>
                    <g transform="translate(0 49)">
                      <circle cx="3.261" cy="2.767" r="2.72" />
                      <circle cx="15.296" cy="2.767" r="2.719" />
                      <circle cx="27.333" cy="2.767" r="2.72" />
                      <circle cx="39.369" cy="2.767" r="2.72" />
                      <circle cx="51.405" cy="2.767" r="2.72" />
                      <circle cx="63.441" cy="2.767" r="2.72" />
                      <circle cx="75.479" cy="2.767" r="2.72" />
                      <circle cx="87.514" cy="2.767" r="2.719" />
                    </g>
                    <g transform="translate(0 61)">
                      <circle cx="3.261" cy="2.846" r="2.72" />
                      <circle cx="15.296" cy="2.846" r="2.719" />
                      <circle cx="27.333" cy="2.846" r="2.72" />
                      <circle cx="39.369" cy="2.846" r="2.72" />
                      <circle cx="51.405" cy="2.846" r="2.72" />
                      <circle cx="63.441" cy="2.846" r="2.72" />
                      <circle cx="75.479" cy="2.846" r="2.72" />
                      <circle cx="87.514" cy="2.846" r="2.719" />
                    </g>
                    <g transform="translate(0 73)">
                      <circle cx="3.261" cy="2.926" r="2.72" />
                      <circle cx="15.296" cy="2.926" r="2.719" />
                      <circle cx="27.333" cy="2.926" r="2.72" />
                      <circle cx="39.369" cy="2.926" r="2.72" />
                      <circle cx="51.405" cy="2.926" r="2.72" />
                      <circle cx="63.441" cy="2.926" r="2.72" />
                      <circle cx="75.479" cy="2.926" r="2.72" />
                      <circle cx="87.514" cy="2.926" r="2.719" />
                    </g>
                    <g transform="translate(0 85)">
                      <circle cx="3.261" cy="3.006" r="2.72" />
                      <circle cx="15.296" cy="3.006" r="2.719" />
                      <circle cx="27.333" cy="3.006" r="2.72" />
                      <circle cx="39.369" cy="3.006" r="2.72" />
                      <circle cx="51.405" cy="3.006" r="2.72" />
                      <circle cx="63.441" cy="3.006" r="2.72" />
                      <circle cx="75.479" cy="3.006" r="2.72" />
                      <circle cx="87.514" cy="3.006" r="2.719" />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <svg
              viewBox="0 0 91 91"
              className="absolute bottom-0 right-0 z-0 w-32 h-32 -mb-12 -mr-12 text-indigo-500
            fill-current"
            >
              <g stroke="none" strokeWidth="1" fillRule="evenodd">
                <g fillRule="nonzero">
                  <g>
                    <g>
                      <circle cx="3.261" cy="3.445" r="2.72" />
                      <circle cx="15.296" cy="3.445" r="2.719" />
                      <circle cx="27.333" cy="3.445" r="2.72" />
                      <circle cx="39.369" cy="3.445" r="2.72" />
                      <circle cx="51.405" cy="3.445" r="2.72" />
                      <circle cx="63.441" cy="3.445" r="2.72" />
                      <circle cx="75.479" cy="3.445" r="2.72" />
                      <circle cx="87.514" cy="3.445" r="2.719" />
                    </g>
                    <g transform="translate(0 12)">
                      <circle cx="3.261" cy="3.525" r="2.72" />
                      <circle cx="15.296" cy="3.525" r="2.719" />
                      <circle cx="27.333" cy="3.525" r="2.72" />
                      <circle cx="39.369" cy="3.525" r="2.72" />
                      <circle cx="51.405" cy="3.525" r="2.72" />
                      <circle cx="63.441" cy="3.525" r="2.72" />
                      <circle cx="75.479" cy="3.525" r="2.72" />
                      <circle cx="87.514" cy="3.525" r="2.719" />
                    </g>
                    <g transform="translate(0 24)">
                      <circle cx="3.261" cy="3.605" r="2.72" />
                      <circle cx="15.296" cy="3.605" r="2.719" />
                      <circle cx="27.333" cy="3.605" r="2.72" />
                      <circle cx="39.369" cy="3.605" r="2.72" />
                      <circle cx="51.405" cy="3.605" r="2.72" />
                      <circle cx="63.441" cy="3.605" r="2.72" />
                      <circle cx="75.479" cy="3.605" r="2.72" />
                      <circle cx="87.514" cy="3.605" r="2.719" />
                    </g>
                    <g transform="translate(0 36)">
                      <circle cx="3.261" cy="3.686" r="2.72" />
                      <circle cx="15.296" cy="3.686" r="2.719" />
                      <circle cx="27.333" cy="3.686" r="2.72" />
                      <circle cx="39.369" cy="3.686" r="2.72" />
                      <circle cx="51.405" cy="3.686" r="2.72" />
                      <circle cx="63.441" cy="3.686" r="2.72" />
                      <circle cx="75.479" cy="3.686" r="2.72" />
                      <circle cx="87.514" cy="3.686" r="2.719" />
                    </g>
                    <g transform="translate(0 49)">
                      <circle cx="3.261" cy="2.767" r="2.72" />
                      <circle cx="15.296" cy="2.767" r="2.719" />
                      <circle cx="27.333" cy="2.767" r="2.72" />
                      <circle cx="39.369" cy="2.767" r="2.72" />
                      <circle cx="51.405" cy="2.767" r="2.72" />
                      <circle cx="63.441" cy="2.767" r="2.72" />
                      <circle cx="75.479" cy="2.767" r="2.72" />
                      <circle cx="87.514" cy="2.767" r="2.719" />
                    </g>
                    <g transform="translate(0 61)">
                      <circle cx="3.261" cy="2.846" r="2.72" />
                      <circle cx="15.296" cy="2.846" r="2.719" />
                      <circle cx="27.333" cy="2.846" r="2.72" />
                      <circle cx="39.369" cy="2.846" r="2.72" />
                      <circle cx="51.405" cy="2.846" r="2.72" />
                      <circle cx="63.441" cy="2.846" r="2.72" />
                      <circle cx="75.479" cy="2.846" r="2.72" />
                      <circle cx="87.514" cy="2.846" r="2.719" />
                    </g>
                    <g transform="translate(0 73)">
                      <circle cx="3.261" cy="2.926" r="2.72" />
                      <circle cx="15.296" cy="2.926" r="2.719" />
                      <circle cx="27.333" cy="2.926" r="2.72" />
                      <circle cx="39.369" cy="2.926" r="2.72" />
                      <circle cx="51.405" cy="2.926" r="2.72" />
                      <circle cx="63.441" cy="2.926" r="2.72" />
                      <circle cx="75.479" cy="2.926" r="2.72" />
                      <circle cx="87.514" cy="2.926" r="2.719" />
                    </g>
                    <g transform="translate(0 85)">
                      <circle cx="3.261" cy="3.006" r="2.72" />
                      <circle cx="15.296" cy="3.006" r="2.719" />
                      <circle cx="27.333" cy="3.006" r="2.72" />
                      <circle cx="39.369" cy="3.006" r="2.72" />
                      <circle cx="51.405" cy="3.006" r="2.72" />
                      <circle cx="63.441" cy="3.006" r="2.72" />
                      <circle cx="75.479" cy="3.006" r="2.72" />
                      <circle cx="87.514" cy="3.006" r="2.719" />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
