import React from "react";

const missingFiles = [
    {
        id:1,
        title: "Profile Image",
        description: "Profile image is needed"
    },
    {
        id:2,
        title: "Business License",
        description: "Business licsence needed"
    },
]
const PermitWarningCard = ({handleRoute}) => {
  return (
    <div className="border-2 rounded border-primary">
      <div className="p-4">
        {/* <h3 className="font-semibold">Permit Document Detail</h3> */}
        <div className="md:flex items-center">
          <div className="md:flex-shrink-0">
            <img
              className="h-24 w-full object-contain md:w-24"
              src="https://s2.svgbox.net/illlustrations.svg?ic=open-vault"
              alt="Missing File"
            />
          </div>
          <div className="p-8">
            <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
              Missing File
            </div>
            <h2 className="mt-2 text-lg leading-7 font-bold text-gray-900">
              Please upload the following documents:
            </h2>
            <p className="mt-4 text-red60">{missingFiles[0].description}</p>
            <ul className="mt-4 list-disc list-inside text-gray-600">
              {missingFiles.map((file, index) => (
                <li className="" key={index}><span>{file.title}</span></li>
              ))}
            </ul>

            <button onClick={handleRoute} className="mt-5 border-2 rounded border-primary px-4 py-1">Upload Document</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PermitWarningCard;
