const Button = ({ children, custom, handleClick, disabled }) => {
  return (
    <button
      disabled={disabled}
      className={`${custom} ${
        disabled ? "opacity-40 pointer-events-none cursor-not-allowed" : ""
      } px-4 sm:px-6 shadow-sm capitalize transition-all duration-200 ease-in-out`}
      onClick={(e) => handleClick(e)}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  disabled: false,
};

export default Button;
