import { toast } from "react-toastify";

const TOASTER_POSITION = "top-right";
const CLOSE_TIME = 3000;

export const useToaster = () => {
  /**
   * @description toast alert/toaster for success only
   */
  const SuccessToast = (msg) => {
    return toast.success(msg, {
      position: TOASTER_POSITION,
      autoClose: CLOSE_TIME,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  /**
   * @description toast alert/toaster for info purpose
   */
  const InfoToast = (msg) => {
    return toast.info(msg, {
      position: TOASTER_POSITION,
      autoClose: CLOSE_TIME,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  /**
   * @description toast alert/toaster for error purpose only
   */
  const ErrorToast = (msg) => {
    return toast.error(msg, {
      position: TOASTER_POSITION,
      autoClose: CLOSE_TIME,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  /**
   * @description toast alert/toaster for info warning
   */
  const WarnToast = (msg) => {
    return toast.warn(msg, {
      position: TOASTER_POSITION,
      autoClose: CLOSE_TIME,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  return { SuccessToast, InfoToast, ErrorToast, WarnToast };
};
