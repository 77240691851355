import React from "react";
import {CInputField, FormProvider} from "../../components/Forms/Fields";
import { useForm } from "react-hook-form";
import CButton from "../../components/Forms/CButton";
import { yupResolver } from "@hookform/resolvers/yup";
import { ChangePasswordSchema } from "../../utils/SchemaValidation";
import FormLabel from "../../components/common/FormLabel";
import { CgPassword } from "react-icons/cg";
import { useMutation } from "@apollo/client";
import { CHANGE_PASSWORD } from "../../graph-query/mutation";
import { toast } from "react-toastify";

const ChanagePassword = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(ChangePasswordSchema),
  });

  const [changePassword,{loading}] = useMutation(CHANGE_PASSWORD, {
    onCompleted: (d) => {
      if (
        d?.changePassword?.error !== "" &&
        d?.changePassword?.message === null
      ) {
        toast.error(d?.changePassword?.error);
      }
      if (
        d?.changePassword?.message !== "" &&
        d?.changePassword?.error === null
      ) {
        toast.success("Password Changed Successfully");
        reset();
      }
    },
    onError: (er) => {
      toast.error("Something is wrong, try again");
    },
  });

  const handleChangePassword = (e) => {
    console.log(e);
    changePassword({
      variables: {
        newPassword: e?.new_password,
        oldPassword: e?.current_password,
      },
    });
  };

  return (
    <FormProvider onSubmithandler={handleSubmit(handleChangePassword)}>
      <div className="w-full bg-white flex justify-center h-auto">
        <div className="p-7 flex flex-col gap-4 w-full">
          {/* <button className="w-auto border-2"><MdArrowBack /> Back</button> */}
          <h1 className="text-2xl font-bold">Change Password</h1>
          <div className="grid items-center grid-cols-1 md:grid-cols-3">
            <div className="col-span-1 justify-start items-center w-full">
              <FormLabel
                labelIcon={<CgPassword />}
                labelTitile="Old Password"
                labelDescription="enter your old password"
              />
            </div>
            <div className="col-span-2 w-full mx-auto py-4">
              <div className=" w-full">
                <CInputField
                  name="current_password"
                  type="password"
                  register={register}
                  errors={errors}
                  label=""
                  classes="w-1/2 pr-2"
                />
              </div>
            </div>
          </div>
          <div className="grid items-center grid-cols-1 md:grid-cols-3">
            <div className="col-span-1 justify-start items-center w-full">
              <FormLabel
                labelIcon={<CgPassword />}
                labelTitile="New Password"
                labelDescription="enter your new password"
              />
            </div>
            <div className="col-span-2 w-full mx-auto py-4">
              <div className=" w-full">
                <CInputField
                  name="new_password"
                  type="password"
                  register={register}
                  errors={errors}
                  label=""
                  classes="w-1/2 pr-2"
                />
              </div>
            </div>
          </div>
          <div className="grid items-center grid-cols-1 md:grid-cols-3">
            <div className="col-span-1 justify-start items-center w-full">
              <FormLabel
                labelIcon={<CgPassword />}
                labelTitile="Confirm Password"
                labelDescription="confirm your new password"
              />
            </div>
            <div className="col-span-2 w-full mx-auto py-4">
              <div className=" w-full">
                <CInputField
                  name="confirm_new_password"
                  type="password"
                  register={register}
                  errors={errors}
                  label=""
                  classes="w-1/2 pr-2"
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2">
            <CButton
              type="submit"
              loading={loading}
              btnLabel="Save Changes"
              classes="bg-primary place-end text-white hover:bg-primary-light py-3 px-4 rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
            />
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default ChanagePassword;
