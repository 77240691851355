import React from "react";
import organizationIcon from "../../assets/images/OrganizatioIcon.svg";
import { AiFillExclamationCircle } from "react-icons/ai";
import { MdPendingActions } from "react-icons/md";

const CreateOrganizationCard = ({ handleBtn, status }) => {
  return (
    <div
      className={`${
        status === "pending"
          ? "border-yellow-400"
          : status === "rejected"
          ? "border-red-500"
          : "border-gray-200"
      } border-2 w-full row-span-2 p-5 flex flex-col bg-white shadow-md rounded-md px-6 justify-center items-center transition-all duration-300 transform hover:-translate-y-1 hover:shadow-lg`}
    >
      {!status ? (
        <img
          src={organizationIcon}
          alt="organizationIcon"
          className="w-24 h-24 object-contain mb-4"
        />
      ) : status === "pending" ? (
        <MdPendingActions className="w-24 h-24 animate-pulse text-yellow-400 mb-4" />
      ) : status === "rejected" ? (
        <AiFillExclamationCircle className="w-24 h-24 text-red-500 mb-4" />
      ) : (
        ""
      )}
      {!status && (
        <h1 className="text-primary text-center font-semibold text-2xl mt-2">
          Create your organization profile
        </h1>
      )}
      {!status ? (
        <p className="text-N70 mt-3 text-sm text-center">
          To create expatriates work permit you need to fill all <br /> needed
          forms about your organization.
        </p>
      ) : status === "pending" ? (
        <h2 className="text-N70 mt-3 text-lg text-center">
          Your Organization profile is under review
          <br /> please wait...
        </h2>
      ) : status === "rejected" ? (
        <h2 className="text-red-500 font-bold mt-3 text-xl text-center">
          Please review and amend your organization profile if it has been
          rejected
        </h2>
      ) : (
        ""
      )}
      <button
        type="button"
        onClick={() => handleBtn(status)}
        className={`${
          status === "pending"
            ? "border-yellow-400 text-black"
            : status === "rejected"
            ? "border-red-500 text-black"
            : "text-black"
        } border-2 px-3 py-1.5 text-sm rounded mt-5 transition-all duration-300 hover:bg-primary hover:text-white`}
      >
        {status === "pending"
          ? "Update"
          : status === "rejected"
          ? "View Reason"
          : "Create"}
      </button>
    </div>
  );
};

export default CreateOrganizationCard;
