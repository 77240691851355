import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { PATH_DESK_DASHBOARD } from "../routes/paths";
import PropTypes from "prop-types";

GuestGuard.proprTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  //   const { isAuthenticated } = useAuth();
  const { isAuthenticated } = useState(true);

  if (isAuthenticated) {
    return <Navigate to={PATH_DESK_DASHBOARD.root} />;
  }
  return <>{children}</>;
}
