import React, { useState } from "react";
import { FaFileUpload, FaReadme } from "react-icons/fa";
import FormLabel from "../../common/FormLabel";
import { MdCancel, MdDelete, MdPlusOne } from "react-icons/md";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { UPLOAD_FILE } from "../../../graph-query/mutation";
import { trackPromise } from "react-promise-tracker";
// import withPermit from "../../context/withPermit";
import { useParams } from "react-router-dom";
import {
  BsCalendarEvent,
  BsGlobe,
  BsPinFill,
  BsBuilding,
} from "react-icons/bs";
import { CInputField, CSelectField, FormProvider } from "../Fields";
import CButton from "../CButton";
import { useLocalStore } from "../../../store";
import useLocalStorageHash from "../../../hooks/useLocalStorage";
import ChooseDocumentModal from "../../utilities/ChooseDocumentModal";
import {
  ExtractFileTypeAndNameFromBase64,
  getBase64,
} from "../../../utils/utilityFunctions";
import { toast } from "react-toastify";
import FileInput from "../Fields/FileInput";
import { WorkPermitForms } from "../../../utils/constants";

const EducationalInformation = ({
  changeState,
  handleFormSubmit,
  defaultValues,
}) => {
  const [files, setFiles] = useState([]);
  const [tempFiles, setTempFile] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);
  const [educationCount, setEducationCount] = useState(1);

  const [fileUpload, { loading: uploadLoading }] = useMutation(UPLOAD_FILE);

  const {
    sectors,
    organizationType,
    professionalTitles,
    qualificationLevels,
    qualificationTypes,
    educationalFileUploadAttributes,
    data,
    setData,
  } = useLocalStore();

  const schema = yup.object().shape({
    institution_name: yup.string().required(),
    field_of_study: yup.string().required(),
    // profession_title_id: yup.string().required(),
    qualification_level_id: yup.string().required(),
    received_date: yup.string(),
  });

  console.log("education: ", defaultValues);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { work_educations: defaultValues?.work_educations },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "work_educations",
  });

  /**
   * @description file Upload to server
   * @param {*} uploadedFile
   * @param {*} cb
   */
  const filesUpload = (uploadedFile, cb) => {
    getBase64(uploadedFile)
      .then((result) => {
        // extract the file type to have the folowing pdf,png ...
        let fileType = (uploadedFile?.type).split("/").length
          ? (uploadedFile?.type).split("/")[1]
          : "";
        // upload the file to the server
        trackPromise(
          fileUpload({
            variables: {
              extension: fileType,
              file: result,
            },
            onCompleted(data) {
              cb &&
                cb({ name: uploadedFile.name, path: data.uploadFile.info.Key });
              toast.success("Document Uploaded Successfully!");
            },
            onError(error) {
              toast.error("Document upload failed!");
            },
          })
        );
      })
      .catch((err) => {
        // handle error
      });
  };

  const onSubmit = async (d) => {
    console.log(data, tempFiles);
    let _res = {
      ...d,
      expatriate_work_permit_documents: tempFiles,
      // expatriate_work_permit_documents:
      // data?.expatriate_work_permit_documents ? data?.expatriate_work_permit_documents.concat(tempFiles) : tempFiles,
    };
    console.log("educational: ", _res);
    handleFormSubmit(WorkPermitForms.EDUCATIONAL_INFORMATION, _res);
    // await setData("expatriate", _res);
    // moveToNext();
  };

  /**
   * @description handle modal file upload
   */
  const handleFileUpload = async (file) => {
    console.log("file Upload modal click", file);
    // await files.map((i) => {
    // filesUpload(file, (filePath) =>
    if (file) {
      getBase64(file).then((result) => {
        let fileType = (file?.type).split("/").length
          ? (file?.type).split("/")[1]
          : "";
        setTempFile((prevFiles) => [
          ...prevFiles,
          {
            file: result,
            fileType: fileType,
            name: file.name,
            document_type_id: "6b190f6a-a27b-4058-ae9e-8272db7def46",
            catagory: "ffae9910-1f5b-403e-9262-ea8ae0dcb4d6",
            title: "Educational Certificate",
          },
        ]);
      });
    }
    // );
    // });
    // setUploadModal(false);
  };

  const checkArray = (value) => {
    if (Array.isArray(value)) {
      value = value.join("");
    }
    return value;
  };

  return (
    <>
      {uploadModal && (
        <ChooseDocumentModal
          optionData={educationalFileUploadAttributes}
          files={files}
          setFiles={setFiles}
          onFileUpload={handleFileUpload}
          handleCancel={() => setUploadModal(false)}
        />
      )}
      <div className="flex flex-col gap-4 p-4 items-start h-full w-full">
        <div className="flex flex-col gap-1 items-start pb-10">
          <div>
            <h1 className="text-3xl font-semibold text-N40">
              Educational Information
            </h1>
            <p className="text-xs font-light w-full md:w-2/3 text-N60">
              Organization must fulfill the expatriates educational information
              in order to create the work permit process.
            </p>
          </div>
        </div>
        <div className="w-full">
          <FormProvider
            onSubmithandler={handleSubmit(onSubmit)}
            className="w-full"
          >
            {fields.map((field, index) => (
              <div
                key={field.id}
                className="flex w-full flex-wrap md:flex-nowrap relative justify-between items-start"
              >
                <div
                  key={index}
                  className="flex flex-col gap-4 items-start h-full w-full md:w-3/5 flex-wrap md:flex-nowrap"
                >
                  {/* Institution Name  */}
                  <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                    <FormLabel
                      labelIcon={<BsBuilding />}
                      labelTitile="Institution Name"
                      labelDescription="Institution name"
                    />
                    <div className="w-full flex gap-6 py-2">
                      <div className=" w-full md:w-3/4">
                        <CInputField
                          name={`work_educations.${index}.institute_name`}
                          register={register}
                          errors={errors}
                          validation={{
                            required: "Inistitue Name is required",
                          }}
                          type="text"
                          label=""
                          placeholder="Institute Name"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Field Of Study  */}
                  <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                    <FormLabel
                      labelIcon={<FaReadme />}
                      labelTitile="Field Of Study"
                    />
                    <div className="w-full flex gap-6 py-2">
                      <div className=" w-full md:w-3/4">
                        <CInputField
                          name={`work_educations.${index}.field_of_study`}
                          register={register}
                          errors={errors}
                          validation={{
                            required: "Field of study is required",
                          }}
                          type="text"
                          label=""
                          placeholder="Field of Study"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Type of Qualification */}
                  <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                    <FormLabel
                      labelIcon={<BsGlobe />}
                      labelTitile="Qualification type"
                    />
                    <div className="w-full flex gap-6 py-2">
                      <div className=" w-full md:w-3/4">
                        <CSelectField
                          name={`work_educations.${index}.occupation_category_id`}
                          register={register}
                          errors={errors}
                          validation={{
                            required: "Qualification type is required",
                          }}
                          options={qualificationTypes?.map((item) => {
                            return {
                              ...item,
                              value: item.id,
                              name: item.name_json?.en,
                            };
                          })}
                          label=""
                          placeholder="Qualification Type"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Level of Qualification */}
                  <div className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full">
                    <FormLabel
                      labelIcon={<BsGlobe />}
                      labelTitile="Level of Qualification"
                    />
                    <div className="w-full flex gap-6 py-2">
                      <div className=" w-full md:w-3/4">
                        <CSelectField
                          name={`work_educations.${index}.qualification_level_id`}
                          register={register}
                          errors={errors}
                          validation={{
                            required: "Qualification level is required",
                          }}
                          options={qualificationLevels?.map((item) => {
                            return {
                              ...item,
                              value: item.id,
                            };
                          })}
                          label=""
                          placeholder="Qualification Level"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Recived date */}
                  <div
                    key={index}
                    className="flex md:flex-row flex-col gap-4 md:gap-8 items-start md:items-center w-full"
                  >
                    <FormLabel
                      labelIcon={<BsCalendarEvent />}
                      labelTitile="Recived Date"
                    />
                    <div className="w-full flex gap-6 py-2">
                      <div className=" w-full md:w-3/4">
                        <CInputField
                          name={`work_educations.${index}.received_date`}
                          register={register}
                          errors={errors}
                          validation={{ required: "Received date is required" }}
                          type="date"
                          label=""
                          placeholder="Received Date"
                        />
                      </div>
                    </div>
                  </div>
                  <hr className="my-8" />
                </div>
                <div
                  key={field.id}
                  className="md:w-1/3 w-full h-full  bg-Prime99/50 rounded-lg p-4"
                >
                  <div className="w-full flex md:flex-row flex-col gap-4 md:gap-6 py-2">
                    <div className=" w-full ">
                      <div className="flex flex-col gap-2 bg-white p-6 rounded-t-xl">
                        <p className="text-primary text-sm font-semibold">
                          Upload Educational Information
                        </p>

                        <div className="flex flex-col gap-1">
                          <p className="text-xs text-N70 font-light">
                            File types
                          </p>

                          <div className="flex gap-1 pl-4 items-center">
                            <BsPinFill className="text-N70 text-xs -rotate-45" />
                            <p className="text-xs text-N70">Pdf</p>
                          </div>
                        </div>
                        <FileInput
                          onChange={(e) => handleFileUpload(e)}
                          enablePreview={true}
                          defaultValue={
                            defaultValues &&
                            defaultValues?.expatriate_work_permit_documents
                              ?.length > 0 &&
                            ExtractFileTypeAndNameFromBase64(
                              defaultValues?.expatriate_work_permit_documents[0]
                                ?.file
                            )
                          }
                          type={"file"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className="mt-4 flex gap-4 flex-wrap">
              <button
                type="button"
                onClick={() => append({})}
                className="w-full md:w-1/4 py-2 flex justify-center items-center text-blue-500 border-2 border-primary hover:bg-primary hover:text-white transition-all duration-200 ease-in bg-white rounded-sm text-sm cursor-pointer"
              >
                <MdPlusOne size={22} /> Add Education
              </button>
              <button
                type="button"
                onClick={() => remove(-1)}
                className="w-full md:w-1/4 py-2 flex justify-center items-center text-red50 border-2 border-red hover:bg-red hover:text-white transition-all duration-200 ease-in bg-white rounded-sm text-sm cursor-pointer"
              >
                <MdPlusOne size={22} /> Remove Education
              </button>
            </div>
            {/*  */}
            <hr className="my-7" />
            {/* Next Button */}
            <div className="w-full mt-8 gap-4 flex justify-start py-10">
              <button
                onClick={() =>
                  changeState(WorkPermitForms.EMPLOYEE_INFORMATION)
                }
                className=" w-[128px] py-0 flex justify-center items-center text-white bg-gray-400 rounded-sm text-sm"
              >
                back
              </button>
              <button
                type="submit"
                className=" w-[128px] py-2 flex justify-center items-center text-white bg-primary rounded-sm text-sm"
              >
                Continue
              </button>
            </div>
          </FormProvider>
        </div>
      </div>
    </>
  );
};

export default EducationalInformation;
