import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { OrganizationField, PermitField } from "../../../utils/baseDataField";
import DropdownSelect from "../../../components/Forms/DropdownSelect";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  APPROVED_EXPATRATES_PERMITS,
  APPROVED_EXPATRATES_PERMITS_FILTER_SEARCH,
  SEARCH_SYS_EXPAT,
} from "../../../graph-query/queries";
import { CInputField, FormProvider } from "../../../components/Forms/Fields";
import CButton from "../../../components/Forms/CButton";
import { SYS_ADMIN_EXPAT_UPDATE } from "../../../graph-query/mutation";
import { toast } from "react-toastify";

const WORK_PERMIT = {
  permit: "PERMIT",
  organization: "ORGANIZATION",
};

const DataChangePage = () => {
  const [currentTab, setCurrentTab] = useState(WORK_PERMIT.permit);
  const [dropFile, setDropFile] = useState();
  const [expatData, setExpatData] = useState();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const searchWatch = watch('searchValue');

  useEffect(()=>{
    setExpatData();
  },[searchWatch])

  const {
    register: registerForm,
    handleSubmit: handleSubmitForm,
    setValue,
    formState: { errors: errorForm },
  } = useForm({
    defaultValues: { ...expatData },
  });

  const [getData, {}] = useLazyQuery(SEARCH_SYS_EXPAT, {
    onCompleted: (data) => {
      console.log(data);
      setExpatData(data.expatriates[0]);
      setValue("first_name", data.expatriates[0].first_name);
      setValue("father_name", data.expatriates[0].father_name);
      setValue("grand_father_name", data.expatriates[0].grand_father_name);
      setValue("passport_number", data.expatriates[0].passport_number);
      setValue("date_of_birth", data.expatriates[0].date_of_birth);
      // setValue('passport_issued_date', data.expatriates[0].expatriate_work_permits[0].passport_issued_date);
      // setValue('passport_issued_date', data.expatriates[0].expatriate_work_permits[0].passport_issued_date);
      // setValue('start_date', data.expatriates[0].expatriate_work_permits[0].start_date);
      // setValue('end_date', data.expatriates[0].expatriate_work_permits[0].end_date);
    },
    onError: (er) => console.log(er),
  });

  const [updateExpat, { loading }] = useMutation(SYS_ADMIN_EXPAT_UPDATE);

  const handleTabChange = (tab) => {
    console.log("tab change");
    setCurrentTab(tab);
    setDropFile({});
    if (currentTab === WORK_PERMIT.organization) {
      setDropOptions("organization");
    }
    if (currentTab === WORK_PERMIT.permit) {
      setDropOptions("permit");
    }
  };

  const setDropOptions = (data) => {
    switch (data) {
      case "organization":
        setDropFile(OrganizationField);
        break;

      case "permit":
        setDropFile(PermitField);
        break;

      default:
        break;
    }
  };

  /**
   * @description search expat
   * @param {*} e
   */
  const handleSearch = (e) => {
    console.log("clicked", e);
    getData({
      variables: {
        pass: e.searchValue,
      },
    });
  };

  const handleExpatForm = (data) => {
    console.log("form submit:", data);
    updateExpat({
      variables: {
        ...data,
        id: expatData.id,
      },
      onCompleted: (d) => {
        toast.success("Information updated");
      },
      onError: (e) => console.log(e),
    });
  };

  return (
    <div className="w-full bg-white p-4 rounded">
      <span className="font-semibold text-lg">Data Change</span>
      <div className="mt-5">
        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-100 dark:text-gray-400 dark:border-gray-200">
          <ul className="flex flex-wrap -mb-px">
            <li className="mr-2">
              <button
                onClick={() => handleTabChange(WORK_PERMIT.permit)}
                className={`${
                  currentTab === WORK_PERMIT.permit
                    ? "text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                    : ""
                } inline-block p-4`}
              >
                Work Permit
              </button>
            </li>
            <li className="mr-2">
              <button
                onClick={() => handleTabChange(WORK_PERMIT.organization)}
                className={`${
                  currentTab === WORK_PERMIT.organization
                    ? "text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                    : ""
                } inline-block p-4`}
                aria-current="page"
              >
                Organization
              </button>
            </li>
          </ul>
        </div>

        {currentTab === WORK_PERMIT.permit && (
          <div className="mt-8">
            <h2 class="mb-2 text-lg font-semibold text-gray-900">
              Work Permit:
            </h2>
            <ul class="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
              {/* <li>Search using EMR Number</li> */}
              <li>Search Passport Number</li>
              {/* <li>Search Visa Number</li>
              <li>Search using First Name</li> */}
            </ul>
          </div>
        )}
        {currentTab === WORK_PERMIT.organization && (
          <div className="mt-8">
            <h2 class="mb-2 text-lg font-semibold text-gray-900">
              Organization:
            </h2>
            <ul class="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
              <li>Search tin number</li>
              <li>Search organization name</li>
              <li>Search email address</li>
            </ul>
          </div>
        )}
        <FormProvider onSubmithandler={handleSubmit(handleSearch)}>
          <div className="mt-8 border-2 border-[#eee] relative flex items-center w-full h-16 rounded-lg focus-within:shadow-lg hover:shadow-sm bg-white overflow-hidden">
            <div className="grid place-items-center h-full w-12 text-gray-300">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>

            <input
              name="searchValue"
              className="peer h-full w-full outline-none text-sm border-none text-gray-700 pr-2"
              type="search"
              {...register("searchValue", { validate: true })}
              id="search"
              placeholder="Search something.."
            />
            <button
              type="submit"
              className="bg-primary rounded-lg text-teal-50 px-4 py-1.5 mr-4"
            >
              Search
            </button>
          </div>
        </FormProvider>

        {expatData && (
          <>
            <div className="my-4"></div>
            <span className="font-semibold">Document you want to change</span>
            <div>
              <DropdownSelect disabled options={dropFile} />
            </div>

            <div className="mt-5">
              <FormProvider onSubmithandler={handleSubmitForm(handleExpatForm)}>
                <div className=" grid grid-cols-3 gap-4">
                  <CInputField
                    name="first_name"
                    register={registerForm}
                    errors={errorForm}
                    validation={{ required: true }}
                    type="text"
                    label="First Name"
                  />
                  <CInputField
                    name="father_name"
                    register={registerForm}
                    errors={errorForm}
                    validation={{ required: true }}
                    type="text"
                    label="Middle Name"
                  />
                  <CInputField
                    name="grand_father_name"
                    register={registerForm}
                    errors={errorForm}
                    validation={{ required: false }}
                    type="text"
                    label="Grand Father Name"
                  />
                  <CInputField
                    name="passport_number"
                    register={registerForm}
                    errors={errorForm}
                    validation={{ required: true }}
                    type="text"
                    label="Passport Number"
                  />
                  <CInputField
                    name="date_of_birth"
                    register={registerForm}
                    errors={errorForm}
                    validation={{ required: true }}
                    type="date"
                    label="Birth Date"
                  />
                  {/* <CInputField
                    name="passport_issued_date"
                    register={registerForm}
                    errors={errorForm}
                    validation={{ required: true }}
                    type="date"
                    label="Passport Issue Date"
                  />
                  <CInputField
                    name="passport_expiry_date"
                    register={registerForm}
                    errors={errorForm}
                    validation={{ required: true }}
                    type="date"
                    label="Passport Expire Date"
                  />
                  <CInputField
                    name="start_date"
                    register={registerForm}
                    errors={errorForm}
                    validation={{ required: true }}
                    type="date"
                    label="Contract Start Date"
                  />
                  <CInputField
                    name="end_date"
                    register={registerForm}
                    errors={errorForm}
                    validation={{ required: true }}
                    type="date"
                    label="Contract End Date"
                  /> */}
                </div>
                <CButton
                  type={"submit"}
                  loading={loading}
                  classes={"bg-primary"}
                  btnLabel={"Submit"}
                />
              </FormProvider>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DataChangePage;
