import React, { useState } from "react";
import { CInputField, FormProvider } from "./Fields";
import { useForm } from "react-hook-form";
import { AiFillFilter } from "react-icons/ai";
import { BsFilterRight } from "react-icons/bs";


const FilterBox = ({ placeholder = '', handleFilterData, handleReset }) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const watchValue = watch("filterValue");

  const handleAccordionToggle = () => {
    setIsOpen(!isOpen);
  };

  const resetSearch = () => {
    reset();
    handleReset();
  };

  return (
    <div className="w-full rounded-md mb-4 mx-auto overflow-hidden">
      <div className="border border-gray-200 rounded-lg shadow-sm">
        <div
          className="flex items-center justify-between px-4 py-3 bg-gray-100 cursor-pointer"
          onClick={handleAccordionToggle}
        >
          <h3 className="text-lg font-medium text-gray-900 flex items-center gap-2"><BsFilterRight size={24}/>Apply Filter</h3>
          <svg
            className={`w-6 h-6 transition-transform ${
              isOpen ? "transform rotate-180" : ""
            }`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>
        {isOpen && (
          <div className="p-4 bg-white">
            <FormProvider onSubmithandler={handleSubmit(handleFilterData)}>
              <div className="flex gap-4 items-center">
                <div className="w-[73%]">
                  <CInputField
                    register={register}
                    errors={errors}
                    name={"filterValue"}
                    label={""}
                    placeholder={
                      `Filter by ${placeholder}`
                    }
                    classes={""}
                  />
                </div>
                <div className="">
                  <button
                    type="submit"
                    disabled={watchValue < 2}
                    className="disabled:opacity-70 disabled:pointer-events-none flex gap-3 items-center px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <AiFillFilter /> Apply Filter
                  </button>
                </div>
                <div className="flex items-center">
                  <button
                    type="button"
                    onClick={resetSearch}
                    className="flex gap-3 items-center px-4 py-2 text-sm font-medium text-white bg-red50 rounded-md hover:bg-red60 focus:outline-none focus:ring-2 focus:ring-red50"
                  >
                    Reset Filter
                  </button>
                </div>
              </div>
            </FormProvider>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterBox;
