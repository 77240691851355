import { BiDownArrow, BiUpArrow } from "react-icons/bi";
import Moment from "react-moment";
import { BANK_SLIP_ID, PROFILE_IMAGE_ID } from "../../utils/constants";
import { GetLatestProfilePic } from "../../utils/utilityFunctions";
import { MdPreview, MdRemoveRedEye } from "react-icons/md";
import { IoOpenOutline } from "react-icons/io5";

export const ReportEmployee = {
  name: "Report",

  reportColumns(handleRoute) {
    return [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "expander", // It needs an ID
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <BiUpArrow /> : <BiDownArrow />}
          </span>
        ),
      },
      {
        Header: "Name",
        id: "name",
        accessor: (c) => (
          <div className="flex items-center justify-start gap-2">
            <div className="relative inline-flex items-center justify-center w-10 h-10  bg-gray-100 rounded-full dark:bg-gray-600">
              <span className="font-medium text-gray-600 dark:text-gray-300">
                {c?.user_info?.name?.substring(0, 2)}
              </span>

              <div className={`absolute -top-0 right-1 w-2 h-2 rounded-full ring-1 ring-offset-1 ring-white ${c?.active_status ? 'bg-green' : 'bg-red'}`}></div>
            </div>
            {c?.user_info?.name}{" "}
          </div>
        ),
      },
      {
        Header: "Role",
        accessor: (c) => (
          <span className="rounded-2xl bg-primary px-3 py-1 text-white">
            {c?.user_info?.allowed_roles[0]?.role_information?.value}
          </span>
        ),
      },
      {
        Header: "Total Permit Assigned",
        accessor: (c) => c?.total_assigned_permits?.aggregate?.count,
      },
      {
        Header: "Total Organization Assigned",
        accessor: (c) => c?.total_assigned_org?.aggregate?.count,
      },
      {
        Header: "Total Approved",
        accessor: (c) => c?.approved_permit?.aggregate?.count,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ cell }) => (
          <div>
            <button onClick={()=>handleRoute(cell?.row?.original?.user_info?.id)} className="rounded-full w-8 h-8 flex justify-center items-center hover:bg-blue-10l0">
              <MdRemoveRedEye />{" "}
            </button>
          </div>
        ),
      },
    ];
  },
};
