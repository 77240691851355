import React from "react";

const OrganizationRejectModal = ({ data, handleCancel }) => {
  console.log("r modal");
  return (
    <div className=" h-screen w-full z-[90] bg-black/40 backdrop-blur-sm fixed flex items-center justify-center top-0 left-0">
      <div
        onClick={handleCancel}
        className="absolute h-full w-full top-0 left-0 z-10"
      ></div>
      <div className="bg-white overflow-hidden rounded-md shadow w-3/5 2xl:w-1/2 z-20">
        <div className="px-7 py-8 bg-red">
          <h2 className="text-white text-xl mb-4">You Profile is Rejected</h2>
          <p className="text-gray-200">{data && data}</p>
        </div>
        <div className="bg-white p-8 flex justify-end">
          <button
            onClick={handleCancel}
            type="button"
            className="px-5 py-2 border-2 rounded-md border-gray-400 text-black"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrganizationRejectModal;
