import {
  BsArchive,
  BsBuildingFillCheck,
  BsFillInfoCircleFill,
  BsFolderX,
  BsGearFill,
  BsShieldFillCheck,
} from "react-icons/bs";
import { FaBlackTie, FaWindowClose } from "react-icons/fa";
import {
  MdAutorenew,
  MdBugReport,
  MdCancelPresentation,
  MdCheck,
  MdDashboard,
  MdFiberNew,
  MdHistory,
  MdOutlineAutorenew,
  MdOutlineFiberNew,
  MdOutlineFileCopy,
  MdPeopleAlt,
  MdReport,
  MdVerified,
  MdVerifiedUser,
} from "react-icons/md";
import { RiMailCloseFill } from "react-icons/ri";
import { VscNewFolder } from "react-icons/vsc";

export const DashboardSidebarLink = [
  {
    path: "/dashboard/",
    icon: <MdDashboard />,
    title: "Dashboard",
    permission: "dashboard",
    disabled: false,
  },
  {
    path: "/dashboard/user-list",
    icon: <MdVerifiedUser />,
    title: "User list",
    permission: "userList",
    disabled: false,
  },
  {
    path: "/dashboard/employees",
    icon: <MdPeopleAlt />,
    title: "Employees",
    permission: "employee",
    disabled: false,
  },
  {
    path: "/dashboard/report",
    icon: <MdReport />,
    title: "Report",
    permission: "userList",
    disabled: false,
  },
  {
    path: "/dashboard/employee-list",
    icon: <MdVerifiedUser />,
    title: "Employees",
    permission: "employees",
    disabled: false,
  },
  {
    path: "/dashboard/status-check",
    icon: <MdCheck />,
    title: "Status Check",
    permission: "file-upload",
    disabled: false,
  },
  {
    path: "/dashboard/file-upload",
    icon: <MdVerifiedUser />,
    title: "File Upload",
    permission: "file-upload",
    disabled: false,
  },
  {
    path: "/dashboard/data-change",
    icon: <MdOutlineFileCopy />,
    title: "Data Change",
    permission: "file-change",
    disabled: false,
  },
  {
    path: "/dashboard/applicant",
    icon: <MdFiberNew />,
    title: "New Applicants list",
    permission: "applicant",
    disabled: false,
  },
  {
    path: "/dashboard/organization",
    icon: <BsBuildingFillCheck />,
    title: "New Organization List",
    permission: "organization",
    disabled: false,
  },
  {
    path: "/dashboard/organization/p/renewal",
    icon: <BsBuildingFillCheck />,
    title: "Renewal Organization List",
    permission: "organization_renewal",
    disabled: false,
  },
  {
    path: "/dashboard/verified",
    icon: <BsShieldFillCheck />,
    title: "Verified Account",
    permission: "verified",
    disabled: false,
  },
  {
    path: "/dashboard/failed",
    icon: <FaWindowClose />,
    title: "Failed Account",
    permission: "failed",
    disabled: true,
  },
  {
    path: "/dashboard/cancelled",
    icon: <RiMailCloseFill />,
    title: "cancelation request",
    permission: "cancelled",
    disabled: false,
  },
  {
    path: "/dashboard/renewal",
    icon: <MdOutlineAutorenew />,
    title: "renewal request",
    permission: "renewal",
    disabled: false,
  },
  {
    path: "/dashboard/replacement",
    icon: <RiMailCloseFill />,
    title: "Replacement request",
    permission: "replacement",
    disabled: false,
  },
  {
    path: "/dashboard/blacklist",
    icon: <FaBlackTie />,
    title: "BlackList",
    permission: "blacklist",
    disabled: true,
  },
  {
    path: "/dashboard/settings",
    icon: <BsGearFill />,
    title: "Settings",
    permission: "settings",
    disabled: true,
  },
  {
    path: "/dashboard/additional-info",
    icon: <BsFillInfoCircleFill />,
    title: "Additional Information",
    permission: "additional",
    disabled: true,
  },
  {
    path: "/archive",
    icon: <BsArchive />,
    title: "Add to Archive",
    permission: "archive",
    disabled: false,
  },
  {
    path: "/dashboard/history",
    icon: <MdHistory />,
    title: "History",
    permission: "history",
    disabled: true,
  },
];

export const OrganizationSidebarLink = [
  {
    path: "/organization/",
    icon: <MdOutlineFiberNew />,
    title: "Dashboard",
    permission: "dashboard",
    disabled: false,
  },
  {
    path: "/organization/new-work-request",
    icon: <MdOutlineFiberNew />,
    title: "Request New Permit",
    permission: "newRequest",
    disabled: false,
  },
  {
    path: "/organization/sw/new-work-request",
    icon: <MdOutlineFiberNew />,
    title: "Transfer Expatriate",
    permission: "newRequest",
    disabled: false,
  },

  // {
  //   path: "/organization/verified-work-permit",
  //   icon: <MdCheck />,
  //   title: "Verified Work Permits",
  //   permission: "orgVerfiedPermit",
  //   disabled: true,
  // },
  // {
  //   path: "/organization/failed-work-permit",
  //   icon: <BsFolderX />,
  //   title: "Failed Work Permits",
  //   permission: "orgFailedPermit",
  //   disabled: true,
  // },
  {
    path: "/organization/canceled-work-permit",
    icon: <MdCancelPresentation />,
    title: "Cancelation Request",
    permission: "orgCanceledPermit",
    disabled: false,
  },
  {
    path: "/organization/renew-work-permit",
    icon: <MdAutorenew />,
    title: "Request Renewal",
    permission: "orgRenewPermit",
    disabled: false,
  },
  {
    path: "/organization/replacement-work-permit",
    icon: <VscNewFolder />,
    title: "Request Replacement",
    permission: "orgReplacmentPermit",
    disabled: false,
  },
  {
    path: "/organization/requested-work-permit",
    icon: <MdVerified />,
    title: "Requested Work Permits",
    permission: "orgRequestedPermit",
    disabled: false,
  },
  // {
  //   path: "/organization/blacklist-request",
  //   icon: <VscNewFolder />,
  //   title: "Black Lists",
  //   permission: "orgBlackPermit",
  //   disabled: true,
  // },
];

export const ReportDashboardNavLink = [
  {
    path: "/report/",
    icon: <MdReport />,
    title: "Report",
    permission: "report",
    disabled: false,
  },
  {
    path: "/report/dashboard",
    icon: <MdBugReport />,
    title: "Report Detail",
    permission: "userList",
    disabled: false,
  },
];
