import React, { useState, useRef } from "react";
import CustomTable from "../../components/Table/Table";

const Blacklist = () => {
  const [openTab, setOpenTab] = React.useState(1);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(true);
  const [show, setShow] = useState(false);
  const [rowdata, setRowData] = useState([]);

  const color = "primary";

  const columns = [
    {
      Header: "Name",
      footer: (props) => props.column.id,
      id: "name",
      accessor: (c) => (
        <div className="flex justify-start gap-2">
          <button
            type="button"
            className="relative"
            data-model-target="#defaultModal"
            data-modal-toggle="#defaultModal"
          >
            <div class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
              <span class="font-medium text-gray-600 dark:text-gray-300">
                {c.first_name.substring(0, 1) + c.father_name.substring(0, 1)}
              </span>
            </div>
            {c?.nationality?.work_permit_requests.length > 0 && (
              <div class="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-normal text-white bg-red border-2 border-white rounded-full -bottom-2 -right-2 dark:border-gray-100">
                {c?.nationality?.work_permit_requests.length}
              </div>
            )}
          </button>
          <p className="self-center">
            <p>
              {c?.first_name} {c?.father_name}
            </p>
            <a
              class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
              data-te-toggle="tooltip"
              title="Organization name"
            >
              @{c?.orgnization?.name}
            </a>
          </p>
        </div>
      ),
    },
    {
      Header: "Applied date",
      accessor: "applied_date",
    },
    {
      Header: "Doc. type No",
      accessor: "doc",
    },
    {
      Header: "Financial Doc",
      accessor: "finance",
    },
    {
      Header: "Gender",
      accessor: "gender",
    },
    {
      Header: "Location",
      accessor: "location",
    },
    // {
    //   Header: "Action",
    //   accessor: "action",
    //   Cell: ({ cell }) => (
    //     <button
    //       key={cell?.row.id}
    //       onClick={() => {
    //         handleAssign(cell?.row?.original?.id);
    //       }}
    //       className="border-2 border-blue-400 text-blue-400 font-bold px-3 py-1 rounded-md hover:bg-blue-400 hover:text-white"
    //     >
    //       {!load ? "Assign" : <LoadingScreen />}
    //     </button>
    //   ),
    // },
  ];

  // const fetchData = [];

  return (
    <div className="bg-white">
      {/* <Tabs color="blue" /> */}
      <div className="flex flex-wrap">
        <div className="w-full">
          {/* <ul
            className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
            role="tablist"
          >
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase mx-3 px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 1
                    ? "text-white bg-" + color + "-500"
                    : "text-black bg-white")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                Work Permit
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase mx-3 px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 2
                    ? "text-white bg-" + color + "-500"
                    : "text-black bg-white")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                Organization
              </a>
            </li>
          </ul> */}
          <ul className="flex mb-0 bg-gray-300 list-none flex-wrap pt-3 pb-4 flex-row">
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-normal uppercase mx-3 px-5 py-3 shadow-lg rounded-lg block leading-normal " +
                  (openTab === 1
                    ? "text-white bg-" + color
                    : "text-gray-700 bg-gray-100 hover:bg-gray-200")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                Work Permit
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-normal uppercase mx-3 px-5 py-3 shadow-lg rounded-lg block leading-normal " +
                  (openTab === 2
                    ? "text-white bg-" + color
                    : "text-gray-700 bg-gray-100 hover:bg-gray-200")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                Organization
              </a>
            </li>
          </ul>
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <CustomTable
                    title={"BlackList:  Work Permit"}
                    columns={columns && columns}
                    loading={loading && loading}
                    pageCount={pageCount && pageCount}
                    // fetchData={fetchData ? fetchData : []}
                    data={rowdata ? rowdata : []}
                  />
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <CustomTable
                    title={"BlackList: organization"}
                    columns={columns && columns}
                    loading={loading && loading}
                    pageCount={pageCount && pageCount}
                    // fetchData={fetchData ? fetchData : []}
                    data={rowdata ? rowdata : []}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blacklist;
