import React, { useState, useEffect } from "react";
import Avatar from "../../assets/images/avatar.jpg";
import {
  BsTelephoneFill,
  BsSignpostFill,
  BsFillCheckCircleFill,
  BsXCircleFill,
} from "react-icons/bs";
import logo from "../../assets/images/federal-logo.png";
import { GET_APPLICANT_REQUEST_BYID } from "../../graph-query/queries";
import { useQuery } from "@apollo/client";

const testData = [
  {
    id: 1,
    name: "Test Example",
    emrNumber: 12323222,
    nationality: "China",
    gender: "Female",
    occupation_category: "Procurement",
    orgnization: "Desta PLC",
    phone_number: "+123223232323",
    passport_number: "21312323",
  },
  {
    id: 2,
    name: "sample user",
    emrNumber: 8784545,
    nationality: "Japan",
    gender: "Male",
    occupation_category: "catagory three",
    orgnization: "Somewhere",
    phone_number: "+852122121",
    passport_number: "5544545",
  },
  {
    id: 3,
    name: "some name",
    emrNumber: 12544554,
    nationality: "Ethiopia",
    gender: "Female",
    occupation_category: "Procurement",
    orgnization: "PLC",
    phone_number: "+154423434",
    passport_number: "9784333",
  },
];

const BlackListModal = ({ id, handleClick }) => {
  const [step, setStep] = useState(0);
  const [stepData, setStepData] = useState({});

  // const { data } = useQuery(GET_APPLICANT_REQUEST_BYID, {
  //   variables: { id: id },
  // });
  console.log("idModal: ", id);

  useEffect(() => {
    setStepData(id?.nationality?.work_permit_requests[step]);
  }, []);

  const handleChange = () => {
    console.log("change clicked");
    setStep(step + 1);
    setStepData(id?.nationality?.work_permit_requests[step]);
    console.log(stepData);
  };

  return (
    <div className=" h-screen w-full z-[60] bg-white/10 backdrop-blur-sm fixed flex items-center justify-center top-0">
      <div
        onClick={handleClick}
        className="absolute h-full w-full top-0 left-0 z-10"
      ></div>
      <div className=" bg-white p-4 2xl:py-8 rounded-md shadow w-3/5 z-40">
        <h2 className=" bg-red/10 text-red font-medium min-w-[30%] flex justify-center text-lg capitalize w-fit px-4 py-3 rounded-full mx-auto mb-4 2xl:mb-6">
          {id?.work_permit_request_documents.length} person black listed
        </h2>
        <div className="flex gap-8 w-11/12 mx-auto">
          <div className="w-1/2 mx-auto p-4 shadow rounded-md relative">
            <div className="absolute top-1/2 opacity-70 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <img className="opacity-60" src={logo} alt="logo" />
            </div>
            <h3 className=" bg-prime40/10 text-primary capitalize mx-auto py-2 mb-4 p-4 w-fit rounded">
              new applied person
            </h3>
            <div class="w-36 h-36 relative z-50 object-cover rounded-full mx-auto mb-2 border-2 shadow border-prime70 bg-gray-500 flex justify-center items-center">
              <span class="font-medium text-gray-600 text-5xl dark:text-gray-300">
                {id?.first_name?.substring(0, 1) + id?.father_name?.substring(0, 1)}
              </span>
            </div>
            {/* <img
              src={Avatar}
              alt=" applied person"
              className="w-36 h-36 relative z-50 object-cover rounded-md mx-auto mb-2 border-2 shadow border-prime70"
            /> */}
            <h4 className=" text-center relative z-50 text-lg capitalize text-primary font-medium">
              {id?.first_name + " " + id?.father_name}
            </h4>
            <p className=" text-center relative z-50 capitalize text-sm text-N50 mb-2">
              {id?.nationality?.name}
            </p>
            <p className=" text-center relative z-50 uppercase font-semibold mb-6 text-N10">
              {id?.gender}
            </p>
            <div className=" flex flex-col gap-3 capitalize 2xl:w-11/12 mx-auto">
              <div className="flex items-center justify-between">
                <h3 className=" text-primary">occupation</h3>
                <p className=" text-sm text-N50">
                  {id?.occupation_category?.name}
                </p>
              </div>
              <div className="flex items-center justify-between">
                <h3 className=" text-primary">Organization</h3>
                <p className=" text-sm text-N50">{id?.orgnization?.name}</p>
              </div>
              <div className="flex items-center justify-between">
                <BsTelephoneFill className=" text-primary" />
                <p className=" text-sm text-N50">{id?.phone_number}</p>
              </div>
              <div className="flex items-center justify-between">
                <BsSignpostFill className=" text-primary" />
                <p className=" text-sm text-N50">{id?.passport_number}</p>
              </div>
            </div>
          </div>
          <div className="w-1/2 mx-auto">
            {/* {testData.map(item => (
              
            ))} */}
            <div className=" p-4 shadow rounded-md bg-red/5 relative translate-x-11">
              <div className="absolute top-1/2 opacity-70 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <img className="opacity-60" src={logo} alt="logo" />
              </div>
              <h3 className=" bg-red/20 text-red font-semibold capitalize mx-auto py-2 mb-4 p-4 w-fit rounded">
                {stepData.passport_number}
              </h3>

              {stepData?.avatar ? (
                <img
                  src={Avatar}
                  alt=" applied person"
                  className="w-36 h-36 object-cover relative z-50  rounded-full mx-auto mb-2 border-2 shadow border-prime70"
                />
              ) : (
                <div class="w-36 h-36 relative z-50 object-cover rounded-full mx-auto mb-2 border-2 shadow border-prime70 bg-gray-500 flex justify-center items-center">
                  <span class="font-medium text-gray-600 text-5xl dark:text-gray-300">
                    {stepData?.first_name?.substring(0, 1) +
                      stepData?.father_name?.substring(0, 1)}
                  </span>
                </div>
              )}
              <h4 className=" text-center relative z-50 text-lg capitalize text-primary font-medium">
                {stepData?.first_name + " " + stepData?.father_name}
              </h4>
              <p className=" text-center capitalize relative z-50  text-sm text-N50 mb-2">
                {stepData?.nationality?.name}
              </p>
              <p className=" text-center uppercase relative z-50  font-semibold mb-6 text-N10">
                {stepData?.gender}
              </p>
              <div className=" flex flex-col gap-3 capitalize 2xl:w-11/12 mx-auto">
                <div className="flex items-center justify-between">
                  <h3 className=" text-primary">occupation</h3>
                  <p className=" text-sm text-N50">
                    Centrak Planning & Procurement
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <h3 className=" text-primary">Organization</h3>
                  <p className=" text-sm text-N50">
                    {stepData?.orgnization?.name}
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <BsTelephoneFill className=" text-primary" />
                  <p className=" text-sm text-N50">{stepData?.phone_number}</p>
                </div>
                <div className="flex items-center justify-between">
                  <BsSignpostFill className=" text-primary" />
                  <p className=" text-sm text-N50">
                    {stepData?.passport_number}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex gap-4 mt-4">
              <button
                disabled={id?.nationality?.work_permit_requests.length === step}
                className="disabled:opacity-40 flex py-3 rounded-sm w-1/2 items-center gap-2 justify-center bg-error50 text-white"
              >
                <BsXCircleFill />
                <p>same person</p>
              </button>
              <button
                onClick={handleChange}
                disabled={id?.nationality?.work_permit_requests.length === step}
                className="disabled:opacity-40 flex py-3 rounded-sm w-1/2 items-center gap-2 justify-center bg-green text-white"
              >
                <BsFillCheckCircleFill />
                <p>not same</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlackListModal;
