import React from "react";
import { useForm } from "react-hook-form";
import { CInputField, FormProvider } from "../Forms/Fields";
import CButton from "../Forms/CButton";

const EMRReplacementModal = ({
  modalData,
  letterLoading,
  handleCancelModal,
  handleApplication,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  return (
    <div
      id="defaultModal"
      className="w-full h-screen bg-black/15 fixed backdrop-blur-md flex justify-center items-center p-4 overflow-x-hidden overflow-y-auto md:inset-0"
    >
      <div className="relative w-full max-w-2xl max-h-full">
        <div className="relative bg-white rounded-lg shadow">
          <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-300">
            <h3 className="text-xl font-semibold text-gray-900">
              Replacement Update
            </h3>
            <button
              onClick={handleCancelModal}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="defaultModal"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <FormProvider onSubmithandler={handleSubmit(handleApplication)}>
            <div className="p-6 space-y-6">
              <div className="grid grid-cols-2 gap-4">
                <CInputField
                  name="emr_number"
                  register={register}
                  errors={errors}
                  defaultValue={modalData?.emr}
                  disabled
                  type="text"
                  label="EMR Number"
                  placeholder=""
                />
                <CInputField
                  name="job"
                  register={register}
                  errors={errors}
                  validation={{
                    required: true,
                  }}
                  type="text"
                  label="Job Position"
                />
                <CInputField
                  name="expiryDate"
                  register={register}
                  errors={errors}
                  validation={{
                    required: true,
                  }}
                  type="date"
                  label="Expiry Date"
                />
                <CInputField
                  name="requestedDate"
                  register={register}
                  errors={errors}
                  validation={{
                    required: true,
                  }}
                  type="date"
                  label="Requested Date"
                />
              </div>
            </div>
            <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-300">
              <CButton
                data-modal-hide="defaultModal"
                type="submit"
                btnLabel={"Update"}
                loading={letterLoading}
                classes="text-white bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              />
              <button
                data-modal-hide="defaultModal"
                type="button"
                onClick={handleCancelModal}
                className="text-gray-500 mt-5 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                Cancel
              </button>
            </div>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default EMRReplacementModal;
