import React from "react";

const DropdownSelect = ({ options }) => {
  return (
    <select className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
      <option value="">Select an option</option>
      {options &&
        Object.entries(options).map(([key, value]) => (
          <option key={key} value={value}>
            {key}
          </option>
        ))}
    </select>
  );
};

export default DropdownSelect;
