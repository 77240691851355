export const PermitField = {
  firstName: "first_name",
  lastName: "last_name",
  grandFatherName: "grand_father_name",
  birth: "date_of_birth",
  nationality: "nationality_id",
  passportNumber: "passport_number",
  visaNumber: "visa_number",
  visaType: "visa_type_id",
  nationality: "nationality_id",
  referenceNumber: "reference_number",
};

export const OrganizationField = {
  name: "name",
  email: "email",
  sector: "sector_id",
  region: "region_id",
  zone: "zone_id",
  subCity: "subcity_id",
  houseNumber: "house_number",
  phone_number: "phone_no",
  pobox: "email",
  fax: "email",
  localEmployeesCount: "email",
  expatriatesCount: "expatriates_count",
  tinNumber: "tin_number",
};
