import React, { useEffect, useState } from "react";
import { MdArrowCircleRight, MdAutorenew, MdCancel } from "react-icons/md";
import PieChart from "../../components/chart/PieChart";
import AreaChart from "../../components/chart/AreaChart";
import CreateOrganizationCard from "../../components/cards/CreateOrganizationCard";
import { useNavigate } from "react-router-dom";
import OrganizationRejectModal from "../../components/modals/OrganizationRejectModal";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import { useLocalStore } from "../../store";
import ReportCard from "../../components/cards/ReportCard";

const OrganizationHome = ({ context }) => {
  const [emrModal, setEmrModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [firstStartModal, setFirstStartModal] = useState(false);
  const [disableDashboard, setDisableDashboard] = useState(true);
  const navigate = useNavigate();

  const {
    userData,
    currentOrganization,
    userOrganizationData,
    setUser,
    setUserData,
    setCurrentOrganization,
    organizationDashboardData,
  } = useLocalStore();

  useEffect(() => {
    console.log(currentOrganization);
    const hasSeenModal = localStorage.getItem("hasSeenModal");
    // const userData = JSON.parse(localStorage.getItem("userData"));

    if (!hasSeenModal && userData) {
      setFirstStartModal(true);
      // setUser(userData);
      localStorage.setItem("hasSeenModal", true);
    }
  }, []);

  const handleCloseModal = () => {
    setFirstStartModal(false);
  };
  const handleButton = (status) => {
    switch (status) {
      case "pending":
        handlePending();
        break;

      case "rejected":
        handleReject();
        break;

      default:
        handleCreateOrg();
        break;
    }
  };
  const handleCreateOrg = () => {
    navigate("/create-organization-profile");
  };
  const handlePending = () => {
    console.log("pending clicked");
    navigate(
      `/update-organization-profile/update/${currentOrganization[0]?.id}`
    );
  };
  const handleReject = () => {
    console.log("reject btn clicked");
    setRejectModal(!rejectModal);
  };
  const handleCancel = () => {
    console.log("cancel btn");
    setRejectModal(!rejectModal);
  };

  return (
    <>
      {/* {emrModal && <SearchModal />}
      {applicationModal && <ApplicationModal />} */}
      <div className="h-screen ">
        <div className="w-full flex flex-col justify-center h-auto ">
          <div className="w-full h-auto bg-transparent rounded-t-xl md:px-6 md:pb-6">
            {/* <div className="mt-48">
              <ReportCard />
            </div> */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* create new card dashboard */}
              {currentOrganization?.review_status !== true && (
                <CreateOrganizationCard
                  handleBtn={handleButton}
                  status={
                    currentOrganization
                      ? ""
                      : currentOrganization?.review_status !== true ||
                        currentOrganization?.review_status !== false
                      ? "pending"
                      : currentOrganization?.review_status === false
                      ? "rejected"
                      : ""
                  }
                />
              )}
              {/* weekly stastic */}
              <div
                className={`${
                  currentOrganization?.review_status !== true
                    ? "select-none pointer-events-none opacity-60"
                    : ""
                } relative select-none col-span-1 row-span-2 justify-center items-center w-full p-8 border-2 bg-white shadow-lg rounded-lg`}
              >
                <div className="flex flex-col justify-between h-full">
                  <div className="pb-6">
                    <span className="text-gray-400 text-xs font-semibold uppercase tracking-wide">
                      Report
                    </span>
                    <h1 className="text-5xl font-bold leading-tight text-gray-900 mt-2">
                      {organizationDashboardData?.count || 0}
                    </h1>
                    <div className="mt-4">
                      <span className="text-lg font-semibold text-gray-700 tracking-wide">
                        Weekly Stat
                      </span>
                    </div>
                  </div>
                  <div className="border-t pt-6">
                    <div className="grid grid-cols-2 gap-2">
                      <div>
                        <span className="text-sm text-gray-600 font-semibold tracking-wide">
                          New Applicant
                        </span>
                        <p className="text-xl font-bold text-gray-900 mt-1">
                          {currentOrganization?.exp_work_permits_json?.New || 0}
                        </p>
                      </div>
                      <div>
                        <span className="text-sm text-gray-600 font-semibold tracking-wide">
                          Replacement
                        </span>
                        <p className="text-xl font-bold text-gray-900 mt-1">
                          {currentOrganization?.exp_work_permits_json
                            ?.Replacement || 0}
                        </p>
                      </div>
                      <div>
                        <span className="text-sm text-gray-600 font-semibold tracking-wide">
                          Canceled
                        </span>
                        <p className="text-xl font-bold text-gray-900 mt-1">
                          0
                        </p>
                      </div>
                      <div>
                        <span className="text-sm text-gray-600 font-semibold tracking-wide">
                          Renewal
                        </span>
                        <p className="text-xl font-bold text-gray-900 mt-1">
                          {currentOrganization?.exp_work_permits_json
                            ?.Renewal || 0}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`${
                  currentOrganization?.review_status !== true
                    ? "select-none pointer-events-none opacity-60"
                    : ""
                } h-40 w-full px-4 py-6 border-2 bg-white shadow-lg rounded-lg flex flex-col justify-between`}
              >
                <span className="text-gray-400 text-xs font-semibold uppercase tracking-wide">
                  Total Organizations
                </span>
                <h1 className="text-4xl font-bold leading-tight text-gray-900 mt-2">
                  0
                </h1>
                <div className="flex justify-between items-center mt-4">
                  <div className="flex items-center">
                    {/* <FiExchangeVertical className="mr-2 w-4 h-4 text-gray-600" /> */}
                    <span className="text-sm text-gray-600 font-semibold tracking-wide">
                      <MdArrowCircleRight />
                    </span>
                  </div>
                  <button className="px-4 py-2 text-sm font-medium text-gray-400 bg-transparent hover:bg-primary hover:bg-opacity-10 rounded-md transition-colors">
                    View details
                  </button>
                </div>
              </div>

              <div
                className={`${
                  currentOrganization?.review_status !== true
                    ? "select-none pointer-events-none opacity-60"
                    : ""
                } h-40 w-full px-4 py-6 border-2 bg-white shadow-lg rounded-lg flex flex-col justify-between`}
              >
                <span className="text-gray-400 text-xs font-semibold uppercase tracking-wide">
                  Overall Expatriates
                </span>
                <h1 className="text-4xl font-bold leading-tight text-gray-900 mt-2">
                  0
                </h1>
                <div className="flex justify-between items-center mt-4">
                  <div className="flex items-center">
                    {/* <FiExchangeVertical className="mr-2 w-4 h-4 text-gray-600" /> */}
                    <span className="text-sm text-gray-600 font-semibold tracking-wide">
                      0
                    </span>
                  </div>
                  <button className="px-4 py-2 text-sm font-medium text-gray-400 bg-transparent hover:bg-primary hover:bg-opacity-10 rounded-md transition-colors">
                    View details
                  </button>
                </div>
              </div>
            </div>
            {/* chart */}
            <div
              className={`${
                currentOrganization?.review_status !== true
                  ? "select-none pointer-events-none opacity-60"
                  : ""
              } grid grid-cols-1 md:grid-cols-3 gap-3 mt-10`}
            >
              {currentOrganization?.exp_work_permits_json?.New && (
                <div className="md:col-span-1">
                  <PieChart
                    pieData={
                      currentOrganization?.exp_work_permits_json &&
                      Object.values(currentOrganization?.exp_work_permits_json)
                    }
                    pieLabel={
                      currentOrganization?.exp_work_permits_json &&
                      Object.keys(currentOrganization?.exp_work_permits_json)
                    }
                  />
                </div>
              )}
              {currentOrganization?.exp_work_permits_json && (
                <div className="col-span-2">
                  <AreaChart
                    // disabled={disableDashboard}
                    areaCatagory={[
                      "2018-09-19T00:00:00.000Z",
                      "2018-09-19T01:30:00.000Z",
                      "2018-09-19T02:30:00.000Z",
                      "2018-09-19T03:30:00.000Z",
                      "2018-09-19T04:30:00.000Z",
                      "2018-09-19T05:30:00.000Z",
                      "2018-09-19T06:30:00.000Z",
                    ]}
                    areaData={[
                      {
                        name: "series1",
                        data: [31, 40, 28, 51, 42, 109, 100],
                      },
                      {
                        name: "series2",
                        data: [11, 32, 45, 32, 34, 52, 41],
                      },
                    ]}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* modal */}
      {rejectModal && (
        <OrganizationRejectModal
          data={currentOrganization[0]?.review_note}
          handleCancel={handleCancel}
        />
      )}

      {confirmModal && <ConfirmationModal handleCancel={handlePending} />}

      {firstStartModal && (
        <div className=" h-screen w-full z-[60] bg-black/40 backdrop-blur-sm fixed flex items-center justify-center top-0 left-0">
          <div
            onClick={handleCloseModal}
            className="absolute h-full w-full top-0 left-0 z-10"
          ></div>
          <div className="bg-white overflow-hidden rounded-md shadow w-full md:w-3/5 2xl:w-1/2 z-20">
            <div className="px-7 py-8 bg-white shadow-sm">
              <h2 className="text-black text-2xl mb-4">Welcome</h2>
              <p className="text-gray-800">
                <h2>Thank you, {userData?.email}!</h2>
                <p>You have successfully registered and logged in.</p>
                <p>
                  Use Your email address to login : <code></code>
                </p>
                <p>
                  Create your organization profile to get started.{" "}
                  <button
                    className="border-2 rounded-lg px-4 py-1"
                    onClick={() => navigate("/create-organization-profile")}
                  >
                    click here
                  </button>
                </p>
              </p>
            </div>
            <div className="bg-white p-8 flex justify-end">
              <button
                onClick={handleCloseModal}
                type="button"
                className="px-5 py-2 border-2 rounded-md border-gray-400 text-black"
              >
                Okay
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrganizationHome;
