import { useEffect } from "react";

const COTPField = ({
  name,
  type,
  fields,
  errors,
  register,
  watch,
  validation,
  inputRefs,
}) => {
  const otpValues = watch(`/${name}\d*/`) || [];

  useEffect(() => {
    // console.log(values.join(""));
    console.log(name, otpValues?.current);
  }, [otpValues]);

  useEffect(() => {
    // Focus on the next input field when the current input field is filled
    const handleInput = (index, event) => {
      if (event.target.value.length === 1) {
        inputRefs.current[index + 1]?.focus();
      }
    };

    inputRefs.current.forEach((ref, index) => {
      ref.addEventListener("input", handleInput.bind(null, index));
    });

    return () => {
      inputRefs.current.forEach((ref, index) => {
        ref?.removeEventListener("input", handleInput.bind(null, index));
      });
    };
  }, [inputRefs]);

  const { message } = errors[name] || [];

  return (
    <>
      <div className="flex space-x-4">
        {Array(fields)
          .fill()
          .map((_, index) => (
            <input
              key={index}
              type={type}
              id={`${name}[${index}]`}
              maxLength={1}
              className="w-10 h-10 border rounded text-center"
              {...register(`${name}[${index}]`, validation)}
              ref={(el) => (inputRefs.current[index] = el)}
            />
          ))}
      </div>
      {message && (
        <p className="text-start text-xs text-red60 dark:text-red40">
          {message}
        </p>
      )}
      <p>{otpValues.join("")}</p> {/* Display the OTP value */}
    </>
  );
};

COTPField.defaultProps = {
  type: "text",
};

export default COTPField;
