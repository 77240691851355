import React from "react";
import { AiFillFolderOpen } from "react-icons/ai";
import { Link } from "react-router-dom";
import Navbar from "../components/common/Navbar";
import Avatar from "../assets/images/avatar.jpg";

const ApplicationsList = () => {
  const lists = [2, 2, 2, 2, 2, 2, 2, 2];
  return (
    <>
      <div className="h-screen overflow-hidden">
        <Navbar custom={"bg-[#e5edf5]"}>
          <Link
            to={"/employer-profile"}
            className="cursor-pointer flex items-center gap-4 px-4 py-2 bg-white rounded-md"
          >
            <div>
              <h3 className="font-medium">john doe</h3>
              <p className="text-xs text-N50">johndoe@mymail.com</p>
            </div>
            <img
              src={Avatar}
              alt="avatar"
              className="w-12 h-12 rounded-full object-cover"
            />
          </Link>
        </Navbar>
        <div className="w-full flex flex-col justify-center h-screen px-20 mt-10 ">
          <div className="w-full h-full bg-white rounded-t-xl p-6">
            <div className="w-full flex justify-between items-center py-6">
              {/* Count Card */}
              <div className="flex items-center gap-4 px-4 py-3 bg-white shadow-md rounded-md w-[20%]">
                <AiFillFolderOpen className="text-4xl text-primary" />{" "}
                <div>
                  <h3 className="font-medium text-primary">
                    List of Applications
                  </h3>
                  <p className="text-xs text-N50">26</p>
                </div>
              </div>
              {/* Count Card */}
              <div className="flex items-center gap-4 px-4 py-3 bg-white shadow-md rounded-md w-[20%]">
                <AiFillFolderOpen className="text-4xl text-primary" />{" "}
                <div>
                  <h3 className="font-medium text-primary">Renewals</h3>
                  <p className="text-xs text-N50">26</p>
                </div>
              </div>
              {/* Count Card */}
              <div className="flex items-center gap-4 px-4 py-3 bg-white shadow-md rounded-md w-[20%]">
                <AiFillFolderOpen className="text-4xl text-primary" />{" "}
                <div>
                  <h3 className="font-medium text-primary">Replacments</h3>
                  <p className="text-xs text-N50">26</p>
                </div>
              </div>
              {/* Count Card */}
              <div className="flex items-center gap-4 px-4 py-3 bg-white shadow-md rounded-md w-[20%]">
                <AiFillFolderOpen className="text-4xl text-primary" />{" "}
                <div>
                  <h3 className="font-medium text-primary">Cancelations</h3>
                  <p className="text-xs text-N50">26</p>
                </div>
              </div>
            </div>
            <div className="w-full h-full overflow-auto">
              <div className="grid grid-cols-4 gap-8">
                {/* List of application card  */}
                {lists.map((list) => (
                  <Link to={"/detail"}>
                    <div className="cursor-pointer flex flex-col gap-2 p-6 rounded-lg shadow-lg bg-white">
                      <p className="text-xl font-medium text-primary">
                        Julian Alvarez
                      </p>
                      <p className="text-sm text-primary">25 Files Submited</p>
                      <p className="text-xs text-N60">
                        To begin the application process for a new work permit,
                        please click here.
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationsList;
