import React from "react";
import { Navigate, Route, redirect } from "react-router-dom";
import { ability } from "../permission/Ability"; // Replace with the path to your ability file
import ForbiddenPage from "../pages/NotAuthorized";
import { Can } from "../permission/Can";

const ProtectedRoute = ({ path, permission, component }) => {
  if (!ability.can(permission.I, permission.a)) {
    // User is not authorized to access the route
    // You can display a message or redirect the user to a different route
    return <Navigate to="/unauthorized" replace/>;
  }

  return (
    <Can I={permission.I} a={permission.a}>
      {React.createElement(component)}
    </Can>
  );
};

export default ProtectedRoute;
