import React, { useEffect } from "react";
import SideNav from "../../components/Navbar/SideNav";
import StepperList from "../../components/utilities/StepperList";
import Nav from "../../components/Navbar/Navbar";
import Avatar from "../../assets/images/avatar.jpg";
import { useLocalStore } from "../../store";
import { HiBuildingLibrary } from "react-icons/hi2";
import OrganizationData from "../../components/Forms/OrganizationData";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import {
  GET_ORGANIZATION,
  GET_ORGANIZATION_BYID,
} from "../../graph-query/queries";
import OrganizationDataUpdate from "../../components/Forms/OrganizationDataUpdate";

const UpdateOrganizationProfile = () => {
  const { currentRole, user, userOrganizationData, setUserOrganizationData } =
    useLocalStore();
  const { id } = useParams();

  const router = useNavigate();

  /**
   * @description get logged user data with assigned organization if there is any
   */
  const [getUserData, { error: userErr }] = useLazyQuery(
    GET_ORGANIZATION_BYID,
    {
      variables: { id: id },
      context: {
        headers: {
          "Content-Type": "application/json",
        },
      },
      onCompleted: (data) => {
        // setUser(data?.credentials[0]);
        console.log("getUserData: ", data.organizations[0]);
        setUserOrganizationData(data?.organizations[0]);
      },
    }
  );

  //request new data if not found
  useEffect(() => {
    // if (userOrganizationData.length === 0) {
    // Get Data from server
    console.log("getting data from server....");
    getUserData();
    // } else {
    //   console.log("getting from store...");
    // }
  }, [userOrganizationData, setUserOrganizationData]);

  useEffect(() => {
    let r = localStorage.getItem("work-permit");
    if (!r) {
      router("/login");
    }
  }, []);

  return (
    <>
      <Nav
        optionOrg={false}
        Avatar={Avatar}
        userType={currentRole}
        name={userOrganizationData}
      />
      <div className="flex flex-col md:flex-row gap-8 2xl:gap-12 pb-10 mt-10 md:mt-20 layout items-start">
        <SideNav>
          {/* <Can I="view" a="newRequest">
            {() => ( */}
          <StepperList
            _link={"/create-organization-profile/"}
            icon={<HiBuildingLibrary className="text-xl" />}
            stepperText={"Organization Data "}
            activeClassName="bg-primary text-white"
          />
          {/* )}
          </Can> */}
        </SideNav>
        <div className="w-full">
          {/* Form here */}
          <button
            onClick={() => router(-1)}
            className="border-2 rounded-md border-gray-300 text-gray-600 mb-2 px-4 py-1"
          >
            back
          </button>
          {/* {userOrganizationData?.length === 0 ? ( */}
          <div className="bg-white shadow-sm p-8">
            <OrganizationDataUpdate defaultData={userOrganizationData} />
          </div>
        </div>
      </div>
      <div className="mt-16">{/* Footer */}</div>
    </>
  );
};

export default UpdateOrganizationProfile;
