import React from "react";
import { GET_DOCUMENT_TYPES } from "../../graph-query/queries";
import FileUpload from "./FileUpload";
import CSelectField from "../Forms/Fields/CSelectField";
import FormProvider from "../Forms/Fields/FormProvider";
import { useForm } from "react-hook-form";

const UploadFileModal = ({ optionData, setUploadModal, onUpload, onPersist }) => {
  const [selectedCategory, setSelectedCategory] = React.useState(false);
  const [tempFiles, setTempFiles] = React.useState([]);

  // form
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm();

  const watchSelected = watch('selectedCategory', false);

  const onSubmit = (data) => {
    console.log("clciked", data);
  };
  return (
    <>
      <div className="w-full h-screen fixed top-0 left-0 z-50 bg-black/20 backdrop-blur-sm flex justify-center items-center ">
        <div className="w-11/12 lg:w-1/3 bg-Prime99 rounded-lg overflow-hidden flex flex-col gap-6 p-10">
          <FormProvider onSubmithandler={handleSubmit(onSubmit)}>
            {selectedCategory && (
              <div className="text-center text-sm font-bold text-red">
                before changing your document type you need to click the add
                button for your documents to be persisted
              </div>
            )}
            <div className="w-full flex gap-6">
              <div className=" w-full ">
                <CSelectField
                  name="selectedCategory"
                  register={register}
                  errors={errors}
                  validation={{ required: "professional title is required" }}
                  options={optionData?.map((item) => {
                    return { ...item, value: item.id };
                  })}
                  label=""
                  placeholder=""
                />
              </div>
            </div>
            {!selectedCategory && (
              <div className="p-4 text-sm font-bold text-primary -mb-3">
                you need to select a document type before uploading your files
              </div>
            )}
            <FileUpload
              tempFiles={tempFiles}
              setTempFiles={setTempFiles}
              selectedCategory={watchSelected}
            />
            <div className="w-full mt-3 flex justify-end items-center gap-4">
              <button
                handleClick={() => setUploadModal(false)}
                className={
                  "w-1/4  rounded bg-primary flex gap-2 items-center justify-center py-1"
                }
              >
                <p className="text-sm text-white">Cancel</p>
              </button>
              <button
                handleClick={() => {
                  // onPersist(
                  //   "educational documents",
                  //   selectedCategory,
                  //   [...tempFiles],
                  //   () => {
                  //     setUploadModal(false);
                  //     onUpload([...tempFiles]);
                  //   }
                  // );
                }}
                className={
                  "w-1/4  rounded bg-primary flex gap-2 items-center justify-center py-1"
                }
              >
                <p className="text-sm text-white">Add</p>
              </button>
            </div>
          </FormProvider>
        </div>
      </div>
    </>
  );
};

export default UploadFileModal;
