import React, { useState } from "react";
import ApexChart from "react-apexcharts";

const PieChart = ({ pieData, pieLabel, title }) => {
  const [pieState, setPieState] = useState({
    options: {
      labels: pieLabel,
      series: pieData,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
              height: 300
            },
            dataLabels:{
              enabled: false
            },
            // legend: {
            //   position: "bottom",
            //   display: 'none'
            // },
          },
        },
      ],
    },
  });
  return (
    <div className="bg-white h-full p-6 rounded-lg shadow-lg">
      <h2 className="text-lg font-semibold text-gray-900 mb-4">{title}</h2>
      {/* <div className="flex items-center justify-center"> */}
        <ApexChart
          options={pieState?.options}
          series={pieState?.options?.series}
          type="donut"
          width={380}

        />
      {/* </div> */}
      {/* <div className="grid grid-cols-2 gap-4 mt-8">
        {pieLabel?.map((item) => (
          <div className="flex items-center">
            <div className="w-3 h-3 rounded-full bg-blue-500 mr-2"></div>
            <span className="text-sm text-gray-600 font-semibold tracking-wide">
              {item}
            </span>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default PieChart;
