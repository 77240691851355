import React from "react";
import ApplicantStepper from "../components/common/ApplicantStepper";
import Navbar from "../components/common/Navbar";
import SearchApplicant from "../components/common/SearchApplicant";
import SingleApplicationDetail from "../components/Forms/SingleApplicationDetail";

const ApplicationDetail = () => {
  return (
    <>
      <Navbar custom={"bg-white"} />
      <div className="w-full p-8 md:p-16 flex-col md:flex-row flex gap-8 md:gap-16 items-start h-max md:h-screen">
        <SearchApplicant />
        <SingleApplicationDetail />
      </div>
    </>
  );
};

export default ApplicationDetail;
