import React, { useState } from "react";
import { BiCalendarAlt } from "react-icons/bi";
import Datepicker from "tailwind-datepicker-react";

const options = {
  title: "07/2023",
  autoHide: true,
  todayBtn: false,
  clearBtn: false,
  // clearBtnText: "Reservations are available for up to two days.",
  maxDate: new Date("2030-01-01"),
  minDate: new Date("1950-01-01"),
  theme: {
    background: "bg-white",
    todayBtn: "",
    clearBtn: "",
    icons: "",
    text: "",
    disabledText: "bg-red-500",
    input: "",
    inputIcon: "",
    selected: "",
  },
  // icons: {
  // 	// () => ReactElement | JSX.Element
  // 	prev: () => <span>Previous</span>,
  // 	next: () => <span>Next</span>,
  // },
  datepickerClassNames: "top-12",
  defaultDate: new Date("2022-01-01"),
  language: "en",
  disabledDates: [],
  weekDays: ["M", "T", "W", "T", "F", "S", "S"],

  inputNameProp: "date",
  inputIdProp: "date",
  inputPlaceholderProp: "Select Date",
  inputDateFormatProp: {
    day: "numeric",
    month: "long",
    year: "numeric",
  },
  footerRender: (selectedDate) => {
    return (
      <div className="text-center text-gray-600 pt-2">
        Custom footer text for the selected date: {selectedDate}
      </div>
    );
  },
};

const CustomDatepicker = () => {
  const [show, setShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null)

  const handleChange = (selectedDate) => {
    console.log(selectedDate);
    setSelectedDate(selectedDate);
  };
  const handleClose = (state) => {
    setShow(state);
  };
  return (
    <div>
      <Datepicker
        options={options}
        classNames="bg-white border rounded-lg w-[350px]"
        onChange={handleChange}
        show={show}
        setShow={handleClose}
      >
        <div className="... flex border rounded-lg w-full items-center">
          <div className="...">
            <BiCalendarAlt />
          </div>
          <input
            type="text"
            className="... border-0"
            placeholder="Select Date"
            value={selectedDate}
            onFocus={() => setShow(true)}
            readOnly
          />
        </div>
      </Datepicker>
    </div>
  );
};

export default CustomDatepicker;
