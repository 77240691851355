import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Building from "../../assets/images/Building.png";
import { CInputField, FormProvider } from "../../components/Forms/Fields";
import { useForm } from "react-hook-form";
import CButton from "../../components/Forms/CButton";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD } from "../../graph-query/mutation";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { ForgotPasswordSchema } from "../../utils/SchemaValidation";
import { useEffect } from "react";

const ResetPassword = () => {
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
  });
  const [phoneType, setPhoneType] = useState(false);
  const router = useNavigate();
  const otpCode = watch("otp");

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD);

  const changeRoute = (path) => {
    return router(`/${path}`);
  };

  useEffect(() => {
    let _phone = localStorage.getItem("f-phone");
    let phone_number;
    if (_phone) {
      phone_number = JSON.parse(_phone);
    } else {
      setPhoneType(true);
    }
  }, []);

  const handleForgotPass = (e) => {
    console.log(e);
    let _phone = localStorage.getItem("f-phone");
    let phone_number;
    if (_phone) {
      phone_number = JSON.parse(_phone);
    } else {
      setPhoneType(true);
      phone_number = "251" + e?.phone_number;
    }
    resetPassword({
      variables: {
        phone: `${phone_number}`,
        otp: e.otp,
        pass: e.password,
      },
      onCompleted: (d) => {
        if (
          d?.resetPassword?.message !== null &&
          d?.resetPassword?.message !== ""
        ) {
          toast.success("Account Password reset successfully");
          localStorage.removeItem("f-phone");
          router("/login");
          // window.location.href = '/login';
          // changeRoute("login");
        } else {
          toast.error(d?.resetPassword?.error);
        }
      },
    });
  };

  return (
    <>
      <div className=" relative">
        <div className="fixed flex top-0 left-0 w-full h-screen">
          <div className="w-1/2 hidden bg-white md:flex items-center justify-center flex-col">
            <img src={Building} alt="permit" className="w-3/5" />
            <h2 className="text-xl text-N80 capitalize w-1/2 text-center font-medium">
              We work for our country, our country lives for us
            </h2>
          </div>

          {/* Send Email Section  */}

          <div className="md:w-1/2 w-full bg-gradient-to-b from-prime20 via-prime50 to-Prime95 flex items-center justify-center flex-col gap-6 2xl:gap-8">
            <div className="flex justify-center items-center flex-col">
              <h1 className=" text-white text-2xl w-max text-center font-medium">
                Reset Password
              </h1>
              <p className="text-xs font-light text-white">
                Enter your OTP Verification code
              </p>
            </div>
            <div className=" bg-white w-11/12 md:w-3/5 2xl:w-1/2 p-4 2xl:p-8 rounded-md shadow-md space-y-4">
              <FormProvider onSubmithandler={handleSubmit(handleForgotPass)}>
                {phoneType && (
                  <CInputField
                    name="phone_number"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                    type={!phoneType ? "hidden" : "text"}
                    label="Phone Number"
                  />
                )}
                <CInputField
                  name="otp"
                  register={register}
                  errors={errors}
                  validation={{ required: true }}
                  type="text"
                  label="OTP Code"
                />

                {otpCode?.length === 6 && (
                  <CInputField
                    name="password"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                    type="password"
                    label="password"
                  />
                )}
                <CButton
                  btnLabel={"Send"}
                  type={"submit"}
                  loading={loading}
                  classes="bg-primary hover:bg-prime50 w-full text-white py-3 rounded"
                />
              </FormProvider>

              <p className="text-xs w-full text-center">
                Login to your account?{" "}
                <div onClick={() => router("/login")}>
                  <span className="text-primary font-medium">Login</span>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
