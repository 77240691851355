import React, { useState, useEffect, useRef } from "react";
import {
  APPROVED_EXPATRATES_PERMITS,
  APPROVED_EXPATRATES_PERMITS_COUNT,
  APPROVED_EXPATRATES_PERMITS_FILTER_SEARCH,
  VERIFIED_WORK_PERMITS,
} from "../../graph-query/queries";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import {
  BiCalendarAlt,
  BiFile,
  BiLocationPlus,
  BiSearchAlt,
  BiUserCheck,
} from "react-icons/bi";
import { AiFillExclamationCircle, AiFillFilter } from "react-icons/ai";
import { useLocalStore } from "../../store";
import Table from "../../components/Table/CTable";
import { Can } from "../../permission/Can";
import IdModal from "../../components/utilities/IdModal";
import {
  FormProvider,
  CSelectField,
  CInputField,
} from "../../components/Forms/Fields";
import authorize from "../../hooks/withAuthorization";
import { useForm } from "react-hook-form";
import { BANK_SLIP_ID, PROFILE_IMAGE_ID } from "../../utils/constants";
import { roles } from "../../utils/roles";
import {
  GENERATE_IMMEGRATION_PAPER,
  VERIFY_PERMIT,
} from "../../graph-query/mutation";
import { yupResolver } from "@hookform/resolvers/yup";
import { LetterEMRGenerateShema } from "../../utils/SchemaValidation";
import CButton from "../../components/Forms/CButton";
import { toast } from "react-toastify";
import { useCallback } from "react";
import { VerifiedApplicants } from "../../datasets/verified/verifiedColumns";
import GenerateEMR from "../../components/modals/GenerateEMR";

const catagories = [
  { label: "Pasport Number", value: "passport_number" },
  { label: "Name", value: "name" },
  { label: "EMR Number", value: "emr_number" },
  { label: "Organization", value: "Organization" },
  { label: "TIN Number", value: "tin_number" },
];
const Verified = (props) => {
  const [rowdata, setRowData] = useState([]);
  const [showModalEMRID, setShowModalEMRID] = useState(false);
  const [showEmrModal, setShowEmrModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [currentTab, setCurrentTab] = useState("generated");

  const [pageCount, setPageCount] = useState(0);
  const [totalAssigned, setTotalAssigned] = useState(0);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const {
    register: registerEmr,
    formState: { errors: errorsEmr },
    reset: resetEmr,
    handleSubmit: handleSubmitEmr,
  } = useForm({
    resolver: yupResolver(LetterEMRGenerateShema),
  });

  //Filter Verified EMR Table
  const {
    register: verifiedFilterRegister,
    reset: resetFilterForm,
    watch: verfiedFilterWatch,
    formState: { errors: filterError },
    handleSubmit: handleSubmitFilter,
  } = useForm();

  const watchFilterValue = verfiedFilterWatch("filterValue", "").length;

  const navigate = useNavigate();

  //==========================================================
  const {
    limit,
    offset,
    setLimit,
    setOffset,
    setVerifiedWorkPermits,
    verifiedWorkPermits,
    currentRole,
  } = useLocalStore();

  const [getDataFromServer, { loading, error }] = useLazyQuery(
    VERIFIED_WORK_PERMITS,
    {
      context: {
        headers: {
          "Content-Type": "application/json",
        },
      },
      onCompleted: (data) => {
        console.log("___", data);
        setVerifiedWorkPermits(data?.expatriate_work_permits);
      },
    }
  );

  /**
   * @description verify permits
   */
  const [verifyPermit, {}] = useMutation(VERIFY_PERMIT);

  const [getAssignedApprovedDoc, { loading: emrLoading, error: emrError }] =
    useLazyQuery(APPROVED_EXPATRATES_PERMITS, {
      variables: {
        limit: limit,
        offset: offset * limit,
      },
      context: {
        headers: {
          "Content-Type": "application/json",
        },
      },
      onCompleted: (d) => {
        setRowData(d?.expatriate_work_permits);
      },
    });

  const [countAssigned, {}] = useLazyQuery(APPROVED_EXPATRATES_PERMITS_COUNT, {
    onCompleted: (d) => {
      setTotalAssigned(d?.expatriate_work_permits_aggregate?.aggregate?.count);
    },
  });

  //request new data if not found
  useEffect(() => {
    // if (verifiedWorkPermits?.length === 0) {
    // Get Data from server
    if (currentRole === roles.WP_EMR_GENERATOR) {
      // console.log("emr");
      getAssignedApprovedDoc();
      countAssigned();
    } else {
      getDataFromServer();
    }
    // } else {
    //   console.log("getting from store...");
    // }
  }, []);
  // =========================================================================

  const handleReviewStatus = (c) => {
    let r = c?.expatriate_work_permit_documents
      .filter((i) => i?.document_type?.id === BANK_SLIP_ID)
      .map((j) => j?.review_status)[0];
    console.log(r);
    if (r === true) {
      return "Verifed";
    }
    if (r === false) {
      return "Not Verified";
    }
  };

  const columns = [
    {
      Header: "Name",
      id: "name",
      accessor: (c) => (
        <div className="flex justify-start gap-2">
          <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
            <span className="font-medium text-gray-600 dark:text-gray-300">
              {c?.expatriate?.first_name.substring(0, 1) +
                c?.expatriate?.father_name.substring(0, 1)}
            </span>
          </div>
          <p className="self-center">
            {c?.expatriate?.first_name} {c?.expatriate?.father_name}
          </p>
        </div>
      ),
    },
    {
      Header: "Applied date",
      accessor: (c) => <Moment format="MMMM Do YYYY">{c?.created_at}</Moment>,
    },
    {
      Header: "Doc. type No",
      accessor: (c) => (
        <p className="flex items-center justify-center text-center">
          <BiFile className="fill-green" />
          {c?.expatriate_work_permit_documents?.length}
        </p>
      ),
    },
    {
      Header: "Financial Doc",
      accessor: (c) => (
        <div
          className={`${
            handleReviewStatus(c) === "Verifed"
              ? "bg-green/70 text-white"
              : handleReviewStatus(c) === "Not Verified"
              ? "bg-red"
              : ""
          }  px-2 py-0.5 rounded text-center text-xs bg-blue-100 `}
        >
          {handleReviewStatus(c)}
        </div>
      ),
    },
    {
      Header: "Gender",
      accessor: (c) => c?.expatriate?.gender?.name,
    },
    {
      Header: "Nationality",
      accessor: (c) => c?.expatriate?.nationality?.name,
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ cell }) => (
        <div className="flex gap-3 border-blue-400 text-blue-400 font-bold px-3 py-1 rounded-md ">
          <BiUserCheck />
          <AiFillExclamationCircle />
        </div>
      ),
    },
  ];

  const renderRowSubComponent = useCallback(
    ({ row }) => (
      <pre
        style={{
          fontSize: "10px",
        }}
      >
        <table className="w-11/12 mx-auto capitalize">
          <thead className="bg-[#F0F6FD]">
            <tr>
              <th className="text-left text-N60 p-2 py-4">Organization Name</th>
              <th className="text-left text-N60 p-2 py-4">Type</th>
              <th className="text-left text-N60 p-2 py-4">Tin Number</th>
              <th className="text-left text-N60 p-2 py-4">Business Licence</th>
            </tr>
          </thead>
          <tbody className="alternate-bg text-base">
            <tr>
              <td className="px-2 py-4 font-semibold">
                {row?.original?.organization?.name}
              </td>
              <td className="px-2 py-4 font-semibold">
                {row?.original?.organization?.organization_type?.name}
              </td>
              <td className="px-2 py-4 font-semibold">
                <span className="bg-Prime90/40 border px-2 py-1 rounded border-prime50 text-primary">
                  {row?.original?.organization?.tin_number}
                </span>
              </td>
              <td className="px-2 py-4 font-semibold">
                <div className="flex flex-col text-xs">
                  {row?.original?.organization?.business_licence_number}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </pre>
    ),
    []
  );

  const handleVerifyPermit = (e) => {
    console.log(e);
    verifyPermit({
      variables: {
        id: e?.id,
      },
      onCompleted: (d) => {
        toast.success("Work Permit Verified Successfully");
        getDataFromServer({ fetchPolicy: "network-only" });
      },
      onError: (er) => {
        toast.error("Unable to verify Work Permit");
      },
    });
  };

  const [generatePaper, { loading: letterLoading }] = useMutation(
    GENERATE_IMMEGRATION_PAPER
  );

  useEffect(() => {}, [modalData]);

  /**
   * @description Generate letter for EMR
   * @param {*} _data
   */
  const handleGenerateURL = (_data) => {
    console.log(_data, modalData);
    generatePaper({
      variables: {
        emr: modalData?.emr,
        arrivalDate: _data?.arrivalDate,
        job: _data?.job,
        expiryDate: _data?.expiryDate,
        requestedDate: _data?.requestedDate,
      },
      onCompleted: (data) => {
        console.log(data);
        resetEmr();
        setShowEmrModal(false);
        window.open(`https://${data?.generateDocument?.url}`);
      },
      onError: (er) => {
        toast.error(er?.message);
      },
    });
  };

  const openModalEMR = (e) => {
    console.log(e);
    setShowEmrModal((prev) => !prev);
    setModalData(e);
    // setShowModalEMRID((prev) => !prev);
  };

  const handleSearch = (e) => {
    console.log(e);
  };

  const convertDateString = (dateStr) => {
    const options = { year: "numeric", month: "long", day: "2-digit" };
    const date = new Date(dateStr);

    const formattedDate = date.toLocaleString("en-US", options);
    return formattedDate;
  };

  const handleGenerateLetter = (e) => {
    let _result = {
      ...e,
      arrivalDate: convertDateString(e.arrivalDate),
      expiryDate: convertDateString(e.expiryDate),
      requestedDate: convertDateString(e.requestedDate),
    };
    handleGenerateURL(_result);
  };

  const handleCancelModal = () => {
    setShowEmrModal(false);
    setModalData(null);
    resetEmr();
  };

  /**
   * @descripiton refersh table data
   */
  const handleRefershTable = () => {
    setRowData([]);
    setOffset(0);
    getAssignedApprovedDoc();
  };

  const handlePreviousPage = () => {
    if (offset > 0) {
      setOffset(offset - 1);
    }
  };

  const handleNextPage = () => {
    setOffset(offset + 1); //the first offset
  };

  const handlePageChange = (newOffset) => {
    setOffset(newOffset - 1);
  };

  /**
   * Filter Verified Account
   */
  const [filterVerifiedAccount] = useLazyQuery(
    APPROVED_EXPATRATES_PERMITS_FILTER_SEARCH,
    {
      onCompleted: (_data) => {
        console.log("__filter Search: ", _data);
        setRowData(_data?.expatriate_work_permits);
      },
    }
  );

  const FilterPermitAccount = (e) => {
    console.log("filter data: ", e);
    filterVerifiedAccount({
      variables: {
        // searchLabel: e?.catagory_id,
        searchInput: e.filterValue,
      },
    });
  };

  const resetSearch = () => {
    console.log("click reset");
    resetFilterForm();
    setRowData([]);
    getAssignedApprovedDoc();
  };

  const handlePageLimit = (_limit) => {
    setLimit(Number(_limit));
  };

  return (
    <div>
      {/* {load ? ( */}
      <Can I="view" a="verifiedFilterw">
        {() => (
          <FormProvider onSubmithandler={handleSubmit(handleSearch)}>
            <div className="bg-white grid grid-cols-9 rounded-md p-2 gap-0">
              <div className="col-span-2">
                <div class="relative">
                  {/* <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <BiSearchAlt className="text-primary" />
                  </div> */}
                  <CInputField
                    name="search"
                    register={register}
                    errors={errors}
                    classes="pl-12"
                    icon={<BiSearchAlt className="text-primary" />}
                    validation={{ required: true }}
                    type="search"
                    placeholder="UserID"
                    label=""
                  />
                  {/* <input
                    type="search"
                    id="default-search"
                    class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 bg-gray-50 dark:border-gray-100 dark:placeholder-gray-400 dark:text-white"
                    placeholder="UserID"
                    required
                  /> */}
                </div>
              </div>
              <div className="col-span-2">
                <div class="relative">
                  <CInputField
                    name="country"
                    register={register}
                    errors={errors}
                    classes="pl-12"
                    icon={<BiLocationPlus className="text-primary" />}
                    validation={{ required: true }}
                    type="search"
                    placeholder="Country"
                    label=""
                  />
                  {/* <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <BiLocationPlus className="text-primary" />
                  </div>
                  <input
                    type="search"
                    id="default-search"
                    class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-100 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Country"
                    required
                  /> */}
                </div>
              </div>
              <div className="col-span-2">
                <div class="relative">
                  {/* <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <BiCalendarAlt className="text-primary" />
                  </div>
                  <input
                    type="search"
                    id="default-search"
                    class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-100 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Applied Date"
                    required
                  /> */}
                  <CInputField
                    name="appliedDate"
                    register={register}
                    errors={errors}
                    classes="p-4 bg-gray-50"
                    icon={<BiCalendarAlt className="text-primary" />}
                    validation={{ required: true }}
                    type="date"
                    placeholder="Applied Date"
                    label=""
                  />
                </div>
              </div>
              <div className="col-span-2">
                <div class="relative">
                  <CInputField
                    name="foreignerName"
                    register={register}
                    errors={errors}
                    classes="pl-12"
                    icon={<BiUserCheck className="text-primary" />}
                    validation={{ required: true }}
                    type="search"
                    placeholder="Foreigner Name"
                    label=""
                  />
                </div>
              </div>
              <div className="col-span-1 ">
                <button
                  type="submit"
                  className="flex justify-center p-4 items-center text-white rounded-r-md bg-primary hover:opacity-70 hover:shadow-md hover:inset-8"
                >
                  Search
                </button>
              </div>
            </div>
          </FormProvider>
        )}
      </Can>

      <Can a={"showVerifiedTab"} I={"view"}>
        {() => {
          <ul className="grid mb-5 ease-in-out grid-flow-col text-center text-gray-500 bg-gray-100 rounded-full p-1">
            {" "}
            <li
              className="cursor-pointer"
              onClick={() => setCurrentTab("generated")}
            >
              {" "}
              <a
                className={`${
                  currentTab === "generated"
                    ? "bg-white rounded-full shadow"
                    : ""
                } flex justify-center py-4`}
              >
                Not Assigned
              </a>{" "}
            </li>{" "}
            <li
              className="cursor-pointer"
              onClick={() => setCurrentTab("not-generated")}
            >
              {" "}
              <a
                className={`${
                  currentTab === "not-generated"
                    ? "bg-white rounded-full shadow"
                    : ""
                } flex justify-center text-indigo-900 py-4`}
              >
                Assigned permits
              </a>{" "}
            </li>{" "}
          </ul>;
        }}
      </Can>

      {/* display table based on selected tab */}
      <div className="mt-10 ">
        {currentRole !== roles.WP_EMR_GENERATOR && verifiedWorkPermits && (
          <Table
            columns={columns}
            tableData={verifiedWorkPermits}
            loading={loading}
            error={error}
            offset={offset}
            limit={limit}
            handleNextPage={handleNextPage}
            handlePageChange={handlePageChange}
            handlePreviousPage={handlePreviousPage}
            handleRefersh={handleRefershTable}
            title={"Verified:  Work Permit Applcants"}
            headerClassess="bg-primary"
            pageCount={pageCount}
            // fetchData={fetchData}
          />
        )}

        {currentRole === roles.WP_EMR_GENERATOR && (
          <>
            <div className="p-5 mb-2 bg-white w-full">
              <h3>Filter Permit</h3>
              <FormProvider
                onSubmithandler={handleSubmitFilter(FilterPermitAccount)}
              >
                <div className="flex gap-4 items-center">
                  {/*  */}
                  <div className="w-full">
                    <CInputField
                      register={verifiedFilterRegister}
                      errors={filterError}
                      name={"filterValue"}
                      label={""}
                      placeholder={
                        "Filter by Tin number, Passport number and Name"
                      }
                      classes={""}
                    />
                  </div>
                  {/* <div className="w-full self-center justify-start"></div> */}
                  <div className="">
                    <button
                      type="submit"
                      disabled={watchFilterValue < 2}
                      className="disabled:opacity-70 disabled:pointer-events-none flex gap-3 items-center px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      <AiFillFilter /> Filter
                    </button>
                  </div>
                  <div className="flex items-center">
                    <button
                      type="button"
                      onClick={resetSearch}
                      className="flex gap-3 items-center px-4 py-2 text-sm font-medium text-white bg-red50 rounded-md hover:bg-red60 focus:outline-none focus:ring-2 focus:ring-red50"
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </FormProvider>
            </div>
            <Table
              columns={VerifiedApplicants.emrColumns(
                navigate,
                openModalEMR,
                handleVerifyPermit
              )}
              tableData={rowdata}
              loading={emrLoading}
              error={emrError}
              limit={limit}
              offset={offset}
              totalPages={totalAssigned}
              handleNextPage={handleNextPage}
              handlePageChange={handlePageChange}
              handlePageLimit={handlePageLimit}
              handlePreviousPage={handlePreviousPage}
              handleRefersh={handleRefershTable}
              renderRowSubComponent={renderRowSubComponent}
              title={"Verified:  Work Permit Applcants"}
              headerClassess="bg-primary"
              pageCount={pageCount}
            />
          </>
        )}
      </div>

      <div className="fixed top-0 left-0 right-0 overflow-hidden">
        {showModalEMRID ? (
          <IdModal IDData={modalData} handleClick={openModalEMR} />
        ) : (
          ""
        )}

        {showEmrModal && (
          <GenerateEMR
            handleCancelModal={handleCancelModal}
            letterLoading={letterLoading}
            modalData={modalData}
            handleGenerateLetter={handleGenerateLetter}
          />
          // <div
          //   id="defaultModal"
          //   className="w-full h-screen bg-black/15 fixed backdrop-blur-md flex justify-center items-center p-4 overflow-x-hidden overflow-y-auto md:inset-0"
          // >
          //   <div class="relative w-full max-w-2xl max-h-full">
          //     <div class="relative bg-white rounded-lg shadow">
          //       <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-300">
          //         <h3 class="text-xl font-semibold text-gray-900">
          //           EMR Letter Generate
          //         </h3>
          //         <button
          //           onClick={handleCancelModal}
          //           type="button"
          //           class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
          //           data-modal-hide="defaultModal"
          //         >
          //           <svg
          //             class="w-3 h-3"
          //             aria-hidden="true"
          //             xmlns="http://www.w3.org/2000/svg"
          //             fill="none"
          //             viewBox="0 0 14 14"
          //           >
          //             <path
          //               stroke="currentColor"
          //               stroke-linecap="round"
          //               stroke-linejoin="round"
          //               stroke-width="2"
          //               d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          //             />
          //           </svg>
          //           <span class="sr-only">Close modal</span>
          //         </button>
          //       </div>
          //       <FormProvider
          //         onSubmithandler={handleSubmitEmr(handleGenerateLetter)}
          //       >
          //         <div class="p-6 space-y-6">
          //           <div className="grid grid-cols-2 gap-4">
          //             <CInputField
          //               name="emr_number"
          //               register={registerEmr}
          //               errors={errorsEmr}
          //               defaultValue={modalData}
          //               disabled
          //               validation={{
          //                 required: true,
          //               }}
          //               // defaultValue={modalData}
          //               type="text"
          //               label="EMR Number"
          //               placeholder=""
          //             />
          //             <CInputField
          //               name="job"
          //               register={registerEmr}
          //               errors={errorsEmr}
          //               validation={{
          //                 required: true,
          //               }}
          //               type="text"
          //               label="Job"
          //             />
          //             <CInputField
          //               name="arrivalDate"
          //               register={registerEmr}
          //               errors={errorsEmr}
          //               validation={{
          //                 required: true,
          //               }}
          //               type="date"
          //               label="Arrival Date"
          //             />
          //             <CInputField
          //               name="expiryDate"
          //               register={registerEmr}
          //               errors={errorsEmr}
          //               validation={{
          //                 required: true,
          //               }}
          //               type="date"
          //               label="Expirey Date"
          //             />
          //             <CInputField
          //               name="requestedDate"
          //               register={registerEmr}
          //               errors={errorsEmr}
          //               validation={{
          //                 required: true,
          //               }}
          //               type="date"
          //               label="Requested Date"
          //             />
          //           </div>
          //         </div>
          //         <div class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-300">
          //           <CButton
          //             data-modal-hide="defaultModal"
          //             type="submit"
          //             btnLabel={"Generate"}
          //             loading={letterLoading}
          //             classes="text-white bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          //           />
          //           <button
          //             data-modal-hide="defaultModal"
          //             type="button"
          //             onClick={handleCancelModal}
          //             className="text-gray-500 mt-5 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
          //           >
          //             Cancel
          //           </button>
          //         </div>
          //       </FormProvider>
          //     </div>
          //   </div>
          // </div>
        )}
      </div>
    </div>
  );
};

export default authorize("view")(Verified);
