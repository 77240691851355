import React, { useEffect, useState, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  useTable,
  useSortBy,
  useExpanded,
  usePagination,
  useFilters,
} from "react-table";
// import CInputField from "../Forms/Fields/CInputField";

const CustomTable = ({
  title,
  columns,
  data,
  renderRowSubComponent,
  pageCount: controlledPageCount,
  loading,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    setFilter,
    state: { pageIndex, pageSize, expanded },
  } = useTable(
    {
      columns,
      data,
      // manualPagination: true,
      pageCount: controlledPageCount,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const handleChangePageSize = (event) => {
    setPageSize(Number(event.target.value));
  };

  const [searchable, setSearchable] = useState("");
  const [dropDoc, setDropDownDoc] = useState([]);

  const handleSearch = (_data) => {
    //handle table filter
    console.log("searchable data: ", _data);
    // loading(true);
    return data
      .filter((item) => item.name === _data?.search)
      .map((data) => data);
  };
  const handleFilter = (e) => {
    console.log(e);
    const value = e || undefined;
    setFilter("name", e?.toLowerCase());
    console.log(data.filter((item) => item.name === e).map((data) => data));
    setSearchable(value);
  };

  return (
    <div className="flex flex-col w-full">
      <div className="-my-2 py-2 "> 
      {/* //sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 */}
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg border-b border-gray-200">
          <div className=" bg-primary px-5 py-5 flex items-center justify-between pb-4">
            <div>
              <p className="text-white">{title && title}</p>
            </div>
            <label htmlFor="table-search" className="sr-only">
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <FormProvider onSubmithandler={handleSubmit(handleSearch)}>
                <input
                  type="text"
                  id="table-search"
                  name="search"
                  register={register}
                  errors={errors}
                  validation={{ required: true }}
                  label="Search"
                  onChange={(e) => handleFilter(e.target.value)}
                  className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search for items"
                />
              </FormProvider>
            </div>
          </div>
          <table
            className="min-w-full divide-y divide-gray-200"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      {...column.getHeaderProps()}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? "🔽"
                            : "🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            {getTableBodyProps() ? (
              <tbody
                className="bg-white divide-y divide-gray-200"
                {...getTableBodyProps()}
              >
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <React.Fragment {...row.getRowProps()}>
                      <tr {...row.getRowProps()}>
                        {row?.cells.map((cell) => {
                          return (
                            <td
                              className="px-3 py-3 whitespace-no-wrap text-xs leading-5 font-normal text-gray-900"
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                      {row?.isExpanded ? (
                        <tr>
                          <td colSpan={visibleColumns.length}>
                            {renderRowSubComponent({ row })}
                          </td>
                        </tr>
                      ) : null}
                    </React.Fragment>
                  );
                })}
                <tr>
                  {loading ? (
                    <td colSpan="10000">
                      <div
                        className="w-full flex justify-center items-center py-4"
                        role="status"
                      >
                        <svg
                          aria-hidden="true"
                          className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span className="sr-only">Loading...</span>
                      </div>
                    </td>
                  ) : (
                    <td colSpan="10000" Showing>
                      {page?.length} of ~ {controlledPageCount * pageSize}
                      {""}
                    </td>
                  )}
                </tr>
              </tbody>
            ) : (
              <div className="bg-gray-300 w-full flex justify-center items-center text-black px-5 py-3">
                <p className="flex w-full text-center items-center px-6 py-4 text-gray-900 ">
                  No Data found
                </p>
              </div>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default CustomTable;
